import React from "react"
import { TreeSelect } from "antd"
import { ModalForm, ProFormText, ProFormTreeSelect } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import SecurityUtils from "@/framework/util/SecurityUtils"

import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"
import { requestRuleByUser, requestSetRule } from "@/member/rule/api/RuleAPI"
import RuleEvent from "@/member/rule/event/RuleEvent"

export default class SetRuleModal extends React.Component {

    state = {
        open: false,
        userRules: []
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(RuleEvent.onTriggerSetRule, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(RuleEvent.onTriggerSetRule, console.log)
    }

    toggle = (open) => {
        this.setState({ open })
    }

    onFinish = (params) => {
        const { id, rules } = params
        return requestSetRule(id, rules).then(() => {
            EventBus.emit(RuleEvent.onRuleSet)
            return true
        }).catch((err) => {
            console.error("编辑数据权限失败", err)
            FormUtils.setFormError(this.formRef, err.response?.data, "name")
            return false
        })
    }

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true, userRules: [] }, () => {
            this.onRequestUserRules(item.id)
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    onRequestUserRules = (user) => {
        requestRuleByUser(user).then(res => {
            this.formRef.current?.setFieldsValue({ rules: res.data.map(v => v.department) })
        })
    }

    request = () => requestDepartmentByTree().then(res => {
        if (SecurityUtils.hasSuperPermission()) {
            res.data.unshift({ name: "所有单位", id: "0" })
        }
        return res.data
    }).catch(() => {
        const result = []
        if (SecurityUtils.hasSuperPermission()) {
            result.push({ name: "所有单位", id: "0" })
        }
        return result
    })

    render() {
        return (<ModalForm title="设置数据权限"
            modalProps={{ width: 560, destroyOnClose: true }}
            open={this.state.open}
            onOpenChange={this.toggle}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />
            <ProFormTreeSelect name="rules" label="权限单位"
                request={this.request}
                fieldProps={{
                    showCheckedStrategy: TreeSelect.SHOW_PARENT,
                    treeCheckable: true,
                    fieldNames: { label: "name", value: "id" }
                }} />
        </ModalForm>)
    }
}

SetRuleModal.open = (item) => {
    EventBus.emit(RuleEvent.onTriggerSetRule, item)
}