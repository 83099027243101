import React from "react"
import {useNavigate} from "react-router-dom";
import {Button, Space} from "antd";
import {ProTable} from "@ant-design/pro-components";

import EventBus from "@/framework/util/EventBusUtils";
import PermissionWrapper from "@/framework/component/PermissionWrapper";

import {requestCodeTableByPage} from "../api/CodeTableAPI"
import DeleteCodeTableButton from "./DeleteCodeTableButton";
import CodeTableEvent from "../event/CodeTableEvent";
import CodeTemplateEvent from "../../template/event/CodeTemplateEvent";
import MatchCodeTemplateDrawer from "../../template/interaction/MatchCodeTemplateDrawer";

const CodeTableDataTableColumnsState = {
    persistenceKey: "ColumnStateForCodeTable",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        author: {show: false},
        modifyTime: {show: false}
    }
}

const CodeTableTableOperations = () => {
    const navigate = useNavigate()
    return (<Space>
        <PermissionWrapper permissions={["*:*"]}>
            <Button type="primary" onClick={() => navigate("/home/common/code/table/new")}>新增代码生成表</Button>
        </PermissionWrapper>
    </Space>)
}

const CodeTableColumnOperations = (props) => {
    const navigate = useNavigate()
    return (<Space>
        <PermissionWrapper permissions={["*:*"]}>
            <Button type="link" size="small"
                    onClick={() => navigate(`/home/common/code/table/${props.item.id}/edit`)}>编辑</Button>
        </PermissionWrapper>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(CodeTemplateEvent.onTriggerMatchCodeTemplate, props.item)}>生成</Button>
        <PermissionWrapper permissions={["*:*"]}>
            <DeleteCodeTableButton id={props.item.id}/>
        </PermissionWrapper>
    </Space>)
}

const CodeTableDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        copyable: true,
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "表名",
        dataIndex: "name",
        copyable: true,
        ellipsis: true
    },
    {
        title: "表空间",
        dataIndex: "schema",
        copyable: true,
        ellipsis: true
    },
    {
        title: "注释",
        dataIndex: "comment",
        ellipsis: true
    },
    {
        title: "作者",
        dataIndex: "author",
        hideInSearch: true,
        ellipsis: true
    },
    {
        title: "实体名称",
        dataIndex: "entityName",
        copyable: true,
        ellipsis: true
    },
    {
        title: "包名",
        dataIndex: "packageName",
        hideInSearch: true,
        ellipsis: true
    },
    {
        title: "创建时间",
        dataIndex: "createTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<CodeTableColumnOperations item={item}/>)
    }
]

export default class CodeTableTable extends React.Component {

    actionRef = React.createRef()

    componentDidMount() {
        EventBus.on(CodeTableEvent.onCodeTableUpdated, () => this.actionRef.current?.reload())
        EventBus.on(CodeTableEvent.onCodeTableDeleted, () => this.actionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(CodeTableEvent.onCodeTableUpdated, console.log)
        EventBus.off(CodeTableEvent.onCodeTableDeleted, console.log)
    }

    request = (params) => requestCodeTableByPage(params).then(res => {
        return res.data
    }).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      scroll={{x: "max-content"}}
                      columns={CodeTableDataTableColumns}
                      columnsState={CodeTableDataTableColumnsState}
                      actionRef={this.actionRef}
                      toolBarRender={() => (<CodeTableTableOperations/>)}
                      request={this.request}/>
            <MatchCodeTemplateDrawer/>
        </>);
    }

}