/**
 * 新增公告
 */
export const requestCreateAnnouncement = (params) => window.axios.post("/common/announcement", params)

/**
 * 分页查询公告
 * @param params 查询参数
 */
export const requestAnnouncementByPage = (params) => window.axios.get("/common/announcement/page", {params})

/**
 * 查询公告
 * @param params 查询参数
 */
export const requestAnnouncementByList = (params) => window.axios.get("/common/announcement/list", {params})

/**
 * 查询指定ID公告
 * @param id {string} 公告ID
 */
export const requestAnnouncementById = (id) => window.axios.get(`/common/announcement/${id}`)

/**
 * 更新公告
 */
export const requestUpdateAnnouncement = (params) => window.axios.patch(`/common/announcement/${params.id}`, params)

/**
 * 删除公告
 */
export const requestDeleteAnnouncement = (id) => window.axios.delete(`/common/announcement/${id}`)
