/**
 * 分页查询账户
 * @param params 请求参数
 */
export const requestUserByPage = (params) => window.axios.get("/member/user/page", { params })

/**
 * 查询所有
 * @param params 请求参数
 */
export const requestUserByList = (params) => window.axios.get("/member/user/list", { params })

/**
 * 查询当前账户详情
 */
export const requestCurrentUser = () => window.axios.get("/member/user")

/**
 * 查询指定ID账户详情
 * @param id {string} 账户ID
 */
export const requestUserById = (id) => window.axios.get(`/member/user/${id}`)

/**
 * 请求账户角色
 * @param id 账户ID
 */
export const requestUserRoles = (id) => window.axios.get(`/member/user/${id}/roles`)

export const requestUserGroups = (id) => window.axios.get(`/member/user/${id}/groups`)

/**
 * 创建账户
 * @param params 账户信息
 */
export const requestCreateUser = (params) => window.axios.post("/member/user", params)

/**
 * 更新指定ID账户
 * @param params 更新参数
 */
export const requestUpdateUser = (params) => window.axios.patch(`/member/user/${params.id}`, params)

/**
 * 更新指定ID的账户角色
 * @param params 更新参数
 */
export const requestUpdateUserRoles = (params) => window.axios.put(`/member/user/${params.id}/roles`, params)

export const requestUpdateUserGroups = (params) => window.axios.put(`/member/user/${params.id}/groups`, params)

/**
 * 删除指定ID账户
 * @param id {string} 账户ID
 */
export const requestDeleteUser = (id) => window.axios.delete(`/member/user/${id}`)

export const requestAuthenticationLogByPage = (params) => window.axios.get("/member/authentication/log/page", { params })

export const requestDownloadUserExcelTemplate = () => window.axios("/member/user/template/excel", { responseType: "blob" })
