import React from "react";
import {Skeleton, Timeline} from "antd";
import dayjs from "dayjs";

import {
    requestHistoryProcessInstanceFinishedTaskByList,
    requestHistoryProcessInstanceVariablesByList
} from "../api/WorkflowAPI";

import ProcessInstanceTaskFormDescription from "./description/ProcessInstanceTaskFormDescription";
import ProcessInstanceStartFormDescription from "./description/ProcessInstanceStartFormDescription";

export default class ProcessInstanceTaskTimeline extends React.Component {

    state = {
        loadingForStartForm: true,
        loadingForHistoryTaskForm: true,
        loadingForVariables: true,
        items: []
    }

    componentDidMount() {
        requestHistoryProcessInstanceVariablesByList(this.props.task.processInstanceId).then(res => {
            const variables = {}
            for (let datum of res.data) {
                variables[datum.variableName] = datum.textValue
            }
            this.requestProcessStarForm(this.props.task, variables)
        }).catch(err => {
            console.warn("查询流程对象表单信息失败", err)
        }).finally(() => {
            this.setState({loadingForVariables: false})
        })
    }

    requestProcessStarForm = ({processDefinitionId, created}, variables) => {
        const starForm = {
            label: "开始\t" + dayjs(created).format("YYYY-MM-DD HH:mm:ss"),
            children: (
                <ProcessInstanceStartFormDescription processDefinitionId={processDefinitionId} variables={variables}
                                                     bordered size="small"/>)
        }
        this.setState({items: [starForm]}, () => {
            this.requestProcessHistoryTask(this.props.task, variables)
        })
    }

    requestProcessHistoryTask = ({processInstanceId}, variables) => requestHistoryProcessInstanceFinishedTaskByList(processInstanceId).then(res => {
        res.data.sort((v, v1) => v.endTime - v1.endTime)
        const tasks = res.data.map(v => ({
            label: v.name + "\t" + dayjs(v.endTime).format("YYYY-MM-DD HH:mm:ss"),
            children: (<ProcessInstanceTaskFormDescription task={v} variables={variables} bordered size="small"/>)
        }));
        this.setState({items: [...this.state.items, ...tasks]})
    }).catch(err => {
        console.warn("查询历史任务失败", err)
    }).finally(() => {
        this.setState({loadingForHistoryTaskForm: false})
    })

    render() {
        return (<Skeleton active
                          loading={this.state.loadingForStartForm && this.state.loadingForHistoryTaskForm && this.state.loadingForVariables}>
            <Timeline mode="right" items={this.state.items}/>
        </Skeleton>)
    }

}