/**
 * 新建用户组
 */
export const requestCreateGroup = (params) => window.axios.post("/member/group", params)

/**
 * 分页查询用户组
 * @param params 查询参数
 */
export const requestGroupByPage = (params) => window.axios.get("/member/group/page", { params })

/**
 * 查询用户组
 * @param params 查询参数
 */
export const requestGroupByList = (params) => window.axios.get("/member/group/list", { params })

/**
 * 查询指定ID用户组
 * @param id {string} 用户组ID
 */
export const requestGroupById = (id) => window.axios.get(`/member/group/${id}`)

/**
 * 更新用户组
 */
export const requestUpdateGroup = (params) => window.axios.patch(`/member/group/${params.id}`, params)

/**
 * 删除用户组
 */
export const requestDeleteGroup = (id) => window.axios.delete(`/member/group/${id}`)
