import React from "react";
import {Button, Space} from "antd";
import {ProTable} from "@ant-design/pro-components";

import PermissionWrapper from "@/framework/component/PermissionWrapper";
import EventBus from "@/framework/util/EventBusUtils";

import {requestMetaByPage} from "../api/MetaAPI";
import MetaEvent from "../event/MetaEvent";
import DeleteMetaButton from "./DeleteMetaButton";
import CreateMetaDrawer from "../interaction/CreateMetaDrawer";
import UpdateMetaDrawer from "../interaction/UpdateMetaDrawer";

const MetaDataTableColumnState = {
    persistenceKey: "ColumnStateForMetaDataTable",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        createTime: {show: false},
        modifyTime: {show: false}
    }
}

const MetaDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["meta:insert"]}>
        <CreateMetaDrawer/>
    </PermissionWrapper>
</Space>)

const MetaDataTableColumnOperations = ({item}) => {
    const execute = () => {
        // eslint-disable-next-line
        const func = new Function(item.content)
        func();
    }
    return (<Space>
        <PermissionWrapper permissions={["meta:update"]}>
            <Button type="link" size="small"
                    onClick={() => EventBus.emit(MetaEvent.onTriggerUpdateMeta, item)}>编辑</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["meta:delete"]}>
            <DeleteMetaButton id={item.id}/>
        </PermissionWrapper>
        <Button type="link" size="small" onClick={execute}>执行</Button>
    </Space>)
}

const MetaDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        hideInSearch: true
    },
    {
        title: "类型",
        dataIndex: "type",
        valueType: "select",
        valueEnum: {
            "json": {text: "JSON"},
            "javascript": {text: "JavaScript"}
        }
    },
    {
        title: "功能模块",
        dataIndex: "module"
    },
    {
        title: "开发标识符",
        dataIndex: "devIdentifier"
    },
    {
        title: "业务标识符",
        dataIndex: "bizIdentifier"
    },
    {
        title: "备注",
        dataIndex: "comment",
        hideInSearch: true
    },
    {
        title: "描述",
        dataIndex: "description",
        hideInSearch: true
    },
    {
        title: "Meta",
        dataIndex: "content",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "创建人",
        dataIndex: "creator",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "创建时间",
        dataIndex: "createTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "修改人",
        dataIndex: "modifier",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<MetaDataTableColumnOperations item={item}/>)
    }
]

export default class MetaDataTable extends React.Component {

    actionRef = React.createRef()

    componentDidMount() {
        EventBus.on(MetaEvent.onMetaCreated, () => this.actionRef.current?.reload())
        EventBus.on(MetaEvent.onMetaUpdated, () => this.actionRef.current?.reload())
        EventBus.on(MetaEvent.onMetaDeleted, () => this.actionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(MetaEvent.onMetaCreated, () => console.log("EventOff", MetaEvent.onMetaCreated))
        EventBus.off(MetaEvent.onMetaUpdated, () => console.log("EventOff", MetaEvent.onMetaUpdated))
        EventBus.off(MetaEvent.onMetaDeleted, () => console.log("EventOff", MetaEvent.onMetaDeleted))
    }

    request = (params) => {
        return requestMetaByPage(params).then(res => res.data).catch(() => ({data: [], success: true, total: 0}))
    }

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      columns={MetaDataTableColumns}
                      actionRef={this.actionRef}
                      columnsState={MetaDataTableColumnState}
                      toolBarRender={() => (<MetaDataTableOperations/>)}
                      request={this.request}/>
            <PermissionWrapper permissions={["meta:update"]}>
                <UpdateMetaDrawer/>
            </PermissionWrapper>
        </>);
    }

}