import React, { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { Dropdown } from "antd"
import { FormOutlined, PoweroffOutlined, UnlockOutlined } from "@ant-design/icons"
import { ProHelp, ProLayout } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import SecurityUtils, { getUser, setUser as setLocalStorageUser } from "@/framework/util/SecurityUtils"
import { getMenus } from "@/framework/route/Route"

import { requestLogout } from "@/login/api/LoginAPI"
import UserEvent from "@/member/user/event/UserEvent"

import { requestCurrentUser } from "@/member/user/api/UserAPI"
import UpdatePasswordModal from "@/member/authentication/interaction/UpdatePasswordModal"
import AuthenticationFailureModal from "@/member/user/interaction/AuthenticationFailureModal"
import { requestHelpCategoryByTree } from "@/common/help/api/HelpAPI"
import { requestHelpItemByList } from "@/common/help/api/HelpItemAPI"
import UpdateUserDrawer from "@/member/user/interaction/UpdateUserDrawer"

/**
 * 页面框架 / 侧边栏菜单 / 底部用户头像及点击菜单
 *
 * @author Wayne Carder
 */
const FrameworkAvatarDropdown = (props) => {

    const navigate = useNavigate()

    const onLogout = () => requestLogout().finally(() => {
        window.localStorage.clear()
        navigate("/", { replace: true })
    })
    const onUpdatePassword = () => UpdatePasswordModal.open()
    const onUpdateUser = () => UpdateUserDrawer.open(getUser())

    const items = [
        { key: "update-user-info", icon: <FormOutlined />, label: "更新资料", onClick: onUpdateUser },
        { key: "update-user-password", icon: <UnlockOutlined />, label: "修改密码", onClick: onUpdatePassword },
        { key: "logout", icon: <PoweroffOutlined />, label: "退出登录", danger: true, onClick: onLogout },
    ]

    return (<Dropdown menu={{ items: items }} placement="topRight">{props.dom}</Dropdown>)
}

const handlerHelpNodes = (nodes, result) => {
    for (let node of nodes) {
        if (node.children !== undefined && node.children !== null && node.children.length > 0) {
            const children = []
            handlerHelpNodes(node.children, children)
            result.push({ title: node.name, key: node.key, children })
        } else {
            result.push({ title: node.name, key: node.key, asyncLoad: true, children: [] })
        }
    }
}

/**
 * 页面框架
 *
 * @author Wayne Carder
 */
export default function Framework() {

    const navigate = useNavigate()
    const [user, setUser] = React.useState(getUser())
    const [helpDataSource, setHelpDataSource] = useState([])

    useEffect(() => {
        EventBus.on(UserEvent.onUserUpdated, (updateUser) => {
            if (updateUser.id !== getUser().id) {
                return
            }
            requestCurrentUser().then(res => {
                console.log("update current user", res.data)
                setLocalStorageUser(res.data)
                setUser(res.data)
            })
        })
        requestHelpCategoryByTree().then(res => {
            const newHelpDataSource = []
            handlerHelpNodes(res.data, newHelpDataSource)
            setHelpDataSource(newHelpDataSource)
        }).catch(err => {
            console.error("查询帮助说明文档失败", err)
        })
    }, [])

    const onLoadContext = (key) => requestHelpItemByList({ categoryKey: key }).then(res => {
        return res.data.map(v => ({ valueType: "html", children: { children: v.content } }))
    }).catch(err => {
        console.error("查询帮助文档细则失败", err)
        return []
    })

    return (<ProHelp dataSource={helpDataSource} onLoadContext={onLoadContext}>
        <ProLayout title={`${SecurityUtils.getUser().tenant.name}`}
            bgLayoutImgList={[
                { src: "/images/bg-atmosphere-1.png", left: 85, bottom: 100, height: "303px", },
                { src: "/images/bg-atmosphere-2.png", bottom: -68, right: -45, height: "303px", },
                { src: "/images/bg-atmosphere-3.png", bottom: 0, left: 0, width: "331px", },
            ]}
            logo="/images/logo/logo-48x48.svg"
            suppressSiderWhenMenuEmpty
            menu={{ request: getMenus }}
            breadcrumbProps={{ itemRender: (v) => v.title }}
            menuItemRender={(item, dom) => (<div onClick={() => navigate(item.path)}>{dom}</div>)}
            avatarProps={{
                src: !!user?.avatar ? user?.avatar : "/icons/user.svg",
                size: "small",
                title: user?.name,
                render: (props, dom) => (<FrameworkAvatarDropdown dom={dom} />)
            }}>
            <Outlet />
            <UpdatePasswordModal />
            <UpdateUserDrawer />
            <AuthenticationFailureModal />
        </ProLayout>
    </ProHelp>)
}
