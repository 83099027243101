/**
 * 新增附件分类
 */
export const requestCreateCategory = (params) => window.axios.post("/common/attachment/category", params);

/**
 * 分页查询附件分类
 * @param params 查询参数
 */
export const requestCategoryByPage = (params) => window.axios.get("/common/attachment/category/page", {params})

/**
 * 查询附件分类
 * @param params 查询参数
 */
export const requestCategoryByList = (params) => window.axios.get("/common/attachment/category/list", {params})

/**
 * 查询指定ID附件分类
 * @param id {string} 附件分类ID
 */
export const requestCategoryById = (id) => window.axios.get(`/common/attachment/category/${id}`)

/**
 * 更新附件分类
 */
export const requestUpdateCategory = (params) => window.axios.patch(`/common/attachment/category/${params.id}`, params)

/**
 * 删除附件分类
 */
export const requestDeleteCategory = (id) => window.axios.delete(`/common/attachment/category/${id}`)