import React from "react"
import { Button, Space, Typography } from "antd"
import { ProTable } from "@ant-design/pro-components"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestPostByPage } from "@/member/post/api/PostAPI"
import PostEvent from "@/member/post/event/PostEvent"
import DeletePostButton from "@/member/post/component/DeletePostButton"
import CreatePostModal from "@/member/post/interaction/CreatePostModal"
import UpdatePostModal from "@/member/post/interaction/UpdatePostModal"

const PostDataTableColumnsState = {
    persistenceKey: "ColumnStateForPost",
    persistenceType: "localStorage",
    defaultValue: {
        id: { show: false },
        modifyTime: { show: false }
    }
}

const PostDataTableOperations = () => (<Space size="middle">
    <PermissionWrapper permissions={["post:insert"]}>
        <CreatePostModal />
    </PermissionWrapper>
</Space>)

const PostDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["post:update"]}>
        <Button type="link" size="small"
            onClick={() => EventBus.emit(PostEvent.onTriggerUpdatePost, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["post:delete"]}>
        <DeletePostButton id={props.item.id} />
    </PermissionWrapper>
</Space>)

export default class PostDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            hideInSearch: true
        },
        {
            title: "单位",
            dataIndex: ["department", "name"],
            valueType: "treeSelect",
            hideInSearch: true,
            render: (_, item) => !!item.department?.name ? item.department.name : "通用岗位（无归属单位）"
        },
        {
            title: "名称",
            dataIndex: "name"
        },
        {
            title: "级别",
            dataIndex: "level",
            align: "right"
        },
        {
            title: "查询层级",
            dataIndex: "exactDepartment",
            hideInTable: true,
            formItemProps: { initialValue: "1" },
            valueEnum: { "1": { text: "仅本单位" }, "0": { text: "含下级单位" } }
        },
        {
            title: "岗位职责",
            dataIndex: "duty",
            hideInSearch: true
        },
        {
            title: "描述",
            dataIndex: "description",
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            valueType: "fromNow",
            sorter: true,
            width: 128,
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            valueType: "fromNow",
            width: 128,
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            align: "center",
            with: 128,
            fixed: "right",
            render: (_, item) => (<PostDataTableColumnOperations item={item} />)
        }
    ]

    componentDidMount() {
        EventBus.on(PostEvent.onPostCreated, () => this.tableActionRef.current?.reload())
        EventBus.on(PostEvent.onPostUpdated, () => this.tableActionRef.current?.reload())
        EventBus.on(PostEvent.onPostDeleted, () => this.tableActionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(PostEvent.onPostCreated, console.log)
        EventBus.off(PostEvent.onPostUpdated, console.log)
        EventBus.off(PostEvent.onPostDeleted, console.log)
    }

    request = (params) => requestPostByPage(params).then(res => {
        return res.data
    }).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        if (!!this.props.department) {
            return (<>
                <ProTable rowKey="id"
                    headerTitle="岗位列表"
                    scroll={{ x: "max-content" }}
                    columns={this.columns}
                    columnsState={PostDataTableColumnsState}
                    params={{ department: this.props.department }}
                    actionRef={this.tableActionRef}
                    toolBarRender={() => (<PostDataTableOperations />)}
                    request={this.request} />
                <PermissionWrapper permissions={["post:update"]}>
                    <UpdatePostModal />
                </PermissionWrapper>
            </>)
        } else {
            return (<Typography.Paragraph>请选择单位</Typography.Paragraph>)
        }
    }

}