import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import ActivityDataTable from "./component/ActivityDataTable";

export default class Activity extends React.Component {

    render() {
        return (<PageContainer>
            <ActivityDataTable/>
        </PageContainer>)
    }

}