import {requestDictionaryVariableByList, requestDictionaryVariableByTree} from "../api/DictionaryVariableAPI";

/**
 * 以数组的形式获取所有字典值（dv 是字典值 DictionaryVariable 的简称）
 * @param dictionaryName 字典名称
 * @param keywords 搜索关键字
 * @returns promise 对象
 */
export function requestDvByList(dictionaryName, keywords) {
    return requestDictionaryVariableByList({dictionaryName, label: keywords}).then(res => {
        return res.data?.map(v => ({label: v.label, value: v.variable.toString(), disabled: v.available === '禁用', comment: v.comment}))
    }).catch(err => {
        console.warn(`查询字典选项失败(${dictionaryName})`, err)
        return []
    })
}

/**
 * 以树形数据结构的形式获取所有字典值（dv 是字典值 DictionaryVariable 的简称）
 * @param dictionaryName 字典名称
 * @param keywords 搜索关键字
 * @returns promise 对象
 */
export function requestDvByTree(dictionaryName, keywords) {
    return requestDictionaryVariableByTree({dictionaryName, label: keywords}).then(res => {
        for (let datum of res.data) {
            handleParentOptions(datum)
        }
        return res.data
    }).catch(err => {
        console.warn(`查询字典选项失败(${dictionaryName})`, err)
        return []
    })
}

function handleParentOptions(treeNode) {
    treeNode.disabled = treeNode.available === "禁用"
    if (treeNode.children !== undefined && treeNode.children !== null && treeNode.children.length > 0) {
        for (let child of treeNode.children) {
            handleParentOptions(child)
        }
    }
}

const DictionaryVariableUtils = {
    requestDvByList, requestDvByTree
}
export default DictionaryVariableUtils