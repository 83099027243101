import React from "react"
import {Alert, Button, Space} from "antd"
import {ProTable} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"

import {requestDictionaryLabelByPage} from "../api/DictionaryLabelAPI"
import CreateDictionaryLabelModal from "../interaction/CreateDictionaryLabelModal"
import DictionaryLabelDataTypeEnum from "../common/DictionaryLabelDataTypeEnum"
import DictionaryLabelEvent from "../event/DictionaryLabelEvent"
import UpdateDictionaryLabelModal from "../interaction/UpdateDictionaryLabelModal";
import DeleteDictionaryLabelButton from "./DeleteDictionaryLabelButton";
import PermissionWrapper from "@/framework/component/PermissionWrapper";
import {useNavigate} from "react-router-dom";

const DictionaryLabelDataTableColumnState = {
    persistenceKey: "ColumnStateForDictionaryLabel",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        modifyTime: {show: false}
    }
}

const DictionaryLabelTableToolBar = () => (<Space>
    <CreateDictionaryLabelModal/>
</Space>)

const DictionaryLabelColumnOperations = (props) => {
    const navigate = useNavigate();
    return (<Space>
        <PermissionWrapper permissions={["dictionary-label:update"]}>
            <Button size="small" type="link"
                    onClick={() => EventBus.emit(DictionaryLabelEvent.onTriggerUpdateDictionaryLabel, props.item)}>
                编辑
            </Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["dictionary-label:update"]}>
            <Button size="small" type="link"
                    onClick={() => navigate(`/home/common/dictionary/${props.item.id}/variable`)}>
                管理值
            </Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["dictionary-label:delete"]}>
            <DeleteDictionaryLabelButton id={props.item.id}/>
        </PermissionWrapper>
    </Space>)
}

export default class DictionaryLabelTable extends React.Component {

    actionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "显示名称",
            dataIndex: "label",
            ellipsis: true,
            width: 200
        },
        {
            title: "字典名称",
            dataIndex: "name",
            ellipsis: true,
            copyable: true,
            width: 200
        },
        {
            title: "BusinessKey",
            dataIndex: "businessKey",
            ellipsis: true,
            copyable: true
        },
        {
            title: "字典描述",
            dataIndex: "description",
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "数据类型",
            dataIndex: "dataType",
            width: 96,
            valueEnum: DictionaryLabelDataTypeEnum
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            valueType: "fromNow",
            width: 96,
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            valueType: "fromNow",
            width: 96,
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<DictionaryLabelColumnOperations item={item}/>)
        }
    ]

    componentDidMount() {
        EventBus.on(DictionaryLabelEvent.onDictionaryLabelCreated, this.actionRef.current?.reload)
        EventBus.on(DictionaryLabelEvent.onDictionaryLabelUpdated, this.actionRef.current?.reload)
        EventBus.on(DictionaryLabelEvent.onDictionaryLabelDeleted, this.actionRef.current?.reload)
    }

    request = (params) => requestDictionaryLabelByPage(params).then(res => {
        return res.data
    }).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<>
            <Alert type="warning" style={{marginBottom: 24}} closable
                   message="请谨慎操作字典，操作不当可能会影响系统的正常运转。"/>
            <ProTable rowKey="id"
                      cardBordered
                      scroll={{x: "100%"}}
                      request={this.request}
                      actionRef={this.actionRef}
                      toolBarRender={() => (<DictionaryLabelTableToolBar/>)}
                      columnsState={DictionaryLabelDataTableColumnState}
                      columns={this.columns}/>
            <PermissionWrapper permissions={["dictionary-label:update"]}>
                <UpdateDictionaryLabelModal/>
            </PermissionWrapper>
        </>)
    }

}