import React from "react"
import { Button, Space } from "antd"
import { ProTable } from "@ant-design/pro-components"
import PropTypes from "prop-types"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestSpecificationPropertyByPage } from "@/specification/property/api/SpecificationPropertyAPI"
import SpecificationPropertyEvent from "@/specification/property/event/SpecificationPropertyEvent"
import DeleteSpecificationPropertyButton from "@/specification/property/component/DeleteSpecificationPropertyButton"
import CreateSpecificationPropertyModal from "@/specification/property/interaction/CreateSpecificationPropertyModal"
import UpdateSpecificationPropertyModal from "@/specification/property/interaction/UpdateSpecificationPropertyModal"

const SpecificationPropertyDataTableOperations = ({ specification }) => (<Space>
    <PermissionWrapper permissions={["specification-property:insert"]}>
        <CreateSpecificationPropertyModal specification={specification} />
    </PermissionWrapper>
</Space>)

const SpecificationPropertyDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["specification-property:update"]}>
        <Button type="link" size="small"
            onClick={() => EventBus.emit(SpecificationPropertyEvent.onTriggerUpdateSpecificationProperty, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["specification-property:delete"]}>
        <DeleteSpecificationPropertyButton id={props.item.id} />
    </PermissionWrapper>
</Space>)

const SpecificationPropertyDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "规格",
        dataIndex: "specification",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "规格属性",
        dataIndex: "property"
    },
    {
        title: "创建人",
        dataIndex: "creator",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "创建时间",
        dataIndex: "createTime",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "修改人",
        dataIndex: "modifier",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<SpecificationPropertyDataTableColumnOperations item={item} />)
    }
]

export default class SpecificationPropertyDataTable extends React.Component {

    tableActionRef = React.createRef()

    componentDidMount() {
        EventBus.on(SpecificationPropertyEvent.onSpecificationPropertyCreated, this.tableActionRef.current?.reload)
        EventBus.on(SpecificationPropertyEvent.onSpecificationPropertyUpdated, this.tableActionRef.current?.reload)
        EventBus.on(SpecificationPropertyEvent.onSpecificationPropertyDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(SpecificationPropertyEvent.onSpecificationPropertyCreated, console.log)
        EventBus.off(SpecificationPropertyEvent.onSpecificationPropertyUpdated, console.log)
        EventBus.off(SpecificationPropertyEvent.onSpecificationPropertyDeleted, console.log)
    }

    request = (params) => requestSpecificationPropertyByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                cardBordered
                params={{ specification: this.props.specification }}
                columns={SpecificationPropertyDataTableColumns}
                actionRef={this.tableActionRef}
                search={false}
                toolBarRender={() => (
                    <SpecificationPropertyDataTableOperations specification={this.props.specification} />)}
                request={this.request} />
            <PermissionWrapper permissions={["specification-property:update"]}>
                <UpdateSpecificationPropertyModal />
            </PermissionWrapper>
        </>)
    }

}

SpecificationPropertyDataTable.defaultProps = {
    specification: PropTypes.string
}