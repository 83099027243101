import React from "react";
import {useParams} from "react-router-dom";
import {PageContainer} from "@ant-design/pro-components";

import ProcessInstanceDetailsDataTable from "../component/table/ProcessInstanceDetailsDataTable";

export default function ProcessDefinitionInstanceDataTablePage() {

    const {processDefinitionId} = useParams()

    return (<PageContainer header={{onBack: () => window.history.go(-1)}}>
        <ProcessInstanceDetailsDataTable processDefinitionId={processDefinitionId}/>
    </PageContainer>)

}