import React from "react"
import { Button, Space, Typography } from "antd"
import { ProTable } from "@ant-design/pro-components"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestGroupByPage } from "@/member/group/api/GroupAPI"
import GroupEvent from "@/member/group/event/GroupEvent"
import DeleteGroupButton from "@/member/group/component/DeleteGroupButton"
import CreateGroupModal from "@/member/group/interaction/CreateGroupModal"
import UpdateGroupModal from "@/member/group/interaction/UpdateGroupModal"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"

const GroupDataTableOperations = ({ department }) => (<Space>
    <PermissionWrapper permissions={["group:insert"]}>
        <CreateGroupModal department={department} />
    </PermissionWrapper>
</Space>)

const GroupDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["group:update"]}>
        <Button type="link" size="small"
            onClick={() => EventBus.emit(GroupEvent.onTriggerUpdateGroup, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["group:delete"]}>
        <DeleteGroupButton id={props.item.id} />
    </PermissionWrapper>
</Space>)

export default class GroupDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "单位",
            dataIndex: "department",
            hideInSearch: true,
            valueType: "treeSelect",
            fieldProps: { fieldNames: { label: "name", value: "id" } },
            request: () => requestDepartmentByTree().then(res => res.data).catch(() => [])
        },
        {
            title: "名称",
            dataIndex: "name"
        },
        {
            title: "标识",
            dataIndex: "identifier"
        },
        {
            title: "查询层级",
            dataIndex: "exactDepartment",
            hideInTable: true,
            formItemProps: { initialValue: "1" },
            valueEnum: { "1": { text: "仅本单位" }, "0": { text: "含下级单位" } }
        },
        {
            title: "创建人",
            dataIndex: "creator",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "修改人",
            dataIndex: "modifier",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            width: 128,
            align: "center",
            fixed: "right",
            render: (_, item) => (<GroupDataTableColumnOperations item={item} />)
        }
    ]

    componentDidMount() {
        EventBus.on(GroupEvent.onGroupCreated, () => this.tableActionRef.current?.reload())
        EventBus.on(GroupEvent.onGroupUpdated, () => this.tableActionRef.current?.reload())
        EventBus.on(GroupEvent.onGroupDeleted, () => this.tableActionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(GroupEvent.onGroupCreated, console.log)
        EventBus.off(GroupEvent.onGroupUpdated, console.log)
        EventBus.off(GroupEvent.onGroupDeleted, console.log)
    }

    onRequestGroupPage = (params) => requestGroupByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        if (!!this.props.department) {
            return (<>
                <ProTable rowKey="id"
                    headerTitle="用户组列表"
                    scroll={{ x: "max-content" }}
                    columns={this.columns}
                    params={{ department: this.props.department }}
                    actionRef={this.tableActionRef}
                    toolBarRender={() => (<GroupDataTableOperations department={this.props.department} />)}
                    request={this.onRequestGroupPage} />
                <PermissionWrapper permissions={["group:update"]}>
                    <UpdateGroupModal />
                </PermissionWrapper>
            </>)
        } else {
            return (<Typography.Paragraph>请选择单位</Typography.Paragraph>)
        }
    }

}