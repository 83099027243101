import React, {useState} from "react";
import {PageContainer, ProCard} from "@ant-design/pro-components";
import HelpCategoryTree from "../component/HelpCategoryTree";
import HelpCategoryDescription from "../component/HelpCategoryDescription";
import HelpItemDataTable from "../component/HelpItemDataTable";

export default function HelpManagePage() {

    const [category, setCategory] = useState({})

    const tabs = {
        type: "card",
        items: [
            {
                label: "分类信息",
                key: "1",
                children: (<HelpCategoryDescription category={category}/>)
            },
            {
                label: "说明细则",
                key: "2",
                disabled: category?.leaf === 0,
                children: (<HelpItemDataTable category={category}/>)
            }
        ]
    }

    return (<PageContainer>
        <ProCard split="vertical" ghost bordered>
            <ProCard title="分类列表" headerBordered colSpan={6}>
                <HelpCategoryTree onChange={setCategory}/>
            </ProCard>
            <ProCard title="分类信息" headerBordered colSpan={18} tabs={tabs}/>
        </ProCard>
    </PageContainer>)

}