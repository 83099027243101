/**
 * 新建会员申请
 */
export const requestCreateMemberApply = (params) => window.axios.post("/association/member-apply", params)

/**
 * 分页查询会员申请
 * @param params 查询参数
 */
export const requestMemberApplyByPage = (params) => window.axios.get("/association/member-apply/page", {params})

/**
 * 查询会员申请
 * @param params 查询参数
 */
export const requestMemberApplyByList = (params) => window.axios.get("/association/member-apply/list", {params})

/**
 * 查询指定ID会员申请
 * @param id {string} 会员申请ID
 */
export const requestMemberApplyById = (id) => window.axios.get(`/association/member-apply/${id}`)

/**
 * 更新会员申请
 */
export const requestUpdateMemberApply = (params) => window.axios.patch(`/association/member-apply/${params.id}`, params)

/**
 * 删除会员申请
 */
export const requestDeleteMemberApply = (id) => window.axios.delete(`/association/member-apply/${id}`)
