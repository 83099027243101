import React from "react"
import {DrawerForm, ProFormText, ProFormTextArea} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import {requestUpdateMeta} from "../api/MetaAPI"
import MetaEvent from "../event/MetaEvent"

export default class UpdateMetaDrawer extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(MetaEvent.onTriggerUpdateMeta, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(MetaEvent.onTriggerUpdateMeta, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({open})
    }

    onFinish = (params) => requestUpdateMeta(params).then(() => {
        EventBus.emit(MetaEvent.onMetaUpdated)
        return true
    }).catch((err) => {
        console.error("编辑Meta失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    onTrigger = (item) => this.setState({open: true}, () => setTimeout(() => {
        const form = JSON.parse(JSON.stringify(item))
        this.formRef.current?.setFieldsValue(form)
    }, 100))

    render() {
        return (<DrawerForm title="编辑 Meta"
                            drawerProps={{destroyOnClose: true}}
                            open={this.state.open}
                            onOpenChange={this.toggle}
                            formRef={this.formRef}
                            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden/>
            <ProFormText width="md" name="comment" label="备注" rules={Rule.text}/>
            <ProFormText width="md" name="description" label="描述" rules={Rule.text}/>
            <ProFormTextArea label="Meta" name="content"
                             fieldProps={{rows: 16}}
                             rules={Rule.require}/>
        </DrawerForm>)
    }
}