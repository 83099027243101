/**
 * 新建会员
 */
export const requestCreateMember = (params) => window.axios.post("/association/member", params)

/**
 * 分页查询会员
 * @param params 查询参数
 */
export const requestMemberByPage = (params) => window.axios.get("/association/member/page", {params})

/**
 * 查询会员
 * @param params 查询参数
 */
export const requestMemberByList = (params) => window.axios.get("/association/member/list", {params})

export const requestMemberAsExcel = (params) => window.axios.get("/association/member/excel", { responseType: "blob" })

/**
 * 查询指定ID会员
 * @param id {string} 会员ID
 */
export const requestMemberById = (id) => window.axios.get(`/association/member/${id}`)

/**
 * 更新会员
 */
export const requestUpdateMember = (params) => window.axios.patch(`/association/member/${params.id}`, params)

export const requestAuditMember = (params) => window.axios.patch(`/association/member/${params.id}/audit`, params)

/**
 * 删除会员
 */
export const requestDeleteMember = (id) => window.axios.delete(`/association/member/${id}`)
