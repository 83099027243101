import React, { useRef, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { App, Button, Transfer } from "antd";
import { ModalForm } from "@ant-design/pro-components";

import EventBus from "../../../framework/util/EventBusUtils";

import ActivityMemberEvent from "../event/ActivityMemberEvent";
import { requestMemberByList } from "../../member/api/MemberAPI";
import { requestActivityMemberByList, requestCreateActivityMember } from "../api/ActivityMemberAPI";

export default function InviteActivityMemberModal({ activity }) {

    const { message } = App.useApp()
    const formRef = useRef();
    const [open, setOpen] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [targetKeys, setTargetKeys] = useState([])

    const onOpenChange = (open) => {
        if (open) {
            requestDataSource()
        } else {
            setDataSource([])
            setTargetKeys([])
        }
        setOpen(open)
    }

    const requestDataSource = () => requestMemberByList().then(res => {
        const allMember = res.data;
        allMember.forEach((member) => {
            member.key = member.id
        })
        requestActivityMemberByList({ activity: activity.id }).then(res => {
            for (let invertedUser of res.data) {
                for (let member of allMember) {
                    if (member.manager?.contract === invertedUser.userCellphone) {
                        member.disabled = true
                    }
                }
            }
        }).finally(() => {
            setDataSource(allMember)
        })
    }).catch(() => {
        setDataSource([])
    })

    const onFinish = () => {
        const inviteMember = []
        for (let targetKey of targetKeys) {
            for (let member of dataSource) {
                if (targetKey === member.id) {
                    inviteMember.push(member)
                }
            }
        }
        const params = inviteMember.map(v => ({
            activity: activity.id,
            userName: v.manager?.name,
            userCellphone: v.manager?.contract,
            userOrgName: v.name,
            origin: "会员",
            temporary: 0
        }))
        return requestCreateActivityMember(activity.id, params).then(() => {
            message.success("操作成功", 2).then()
            EventBus.emit(ActivityMemberEvent.onActivityMemberCreated)
            return true
        }).catch(() => {
            message.error("操作失败", 2).then()
            return false
        })
    }

    return (<ModalForm title="邀请会员"
                       trigger={InviteActivityMemberModal.trigger}
                       modalProps={{ destroyOnClose: true, width: 560 }}
                       formRef={formRef}
                       open={open}
                       onOpenChange={onOpenChange}
                       onFinish={onFinish}>
        <Transfer showSearch
                  listStyle={{ width: "100%", height: 400 }}
                  titles={["所有会员", "本次邀请会员"]}
                  targetKeys={targetKeys}
                  dataSource={dataSource}
                  render={(item) => item.manager.name}
                  onChange={targetKeys => setTargetKeys(targetKeys)}/>
    </ModalForm>)

}

InviteActivityMemberModal.trigger = (<Button icon={<PlusOutlined/>} type="primary">邀请会员</Button>)