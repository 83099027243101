import React from "react"
import { Avatar, Button, Image, Space, Tag } from "antd"
import { ProTable } from "@ant-design/pro-components"
import { PlusOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestSpuByPage } from "@/specification/spu/api/SpuAPI"
import SpuEvent from "@/specification/spu/event/SpuEvent"
import DeleteSpuButton from "@/specification/spu/component/DeleteSpuButton"
import { requestCategoryByTree } from "@/specification/category/api/CategoryAPI"
import UpdateSpuDrawer from "@/specification/spu/interaction/UpdateSpuModal"
import UpdateSpuSkuDrawer from "@/specification/sku/interaction/UpdateSpuSkuDrawer"

const SpuDataTableOperations = () => {
    const navigate = useNavigate()
    return (<Space>
        <PermissionWrapper permissions={["specification:insert"]}>
            <Button type="primary" icon={<PlusOutlined />}
                onClick={() => navigate("/home/specification/spu/new-spu")}>新建SPU</Button>
        </PermissionWrapper>
    </Space>)
}

const SpuDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["spu:update"]}>
        <Button type="link" size="small" onClick={() => UpdateSpuDrawer.open(props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["spu:update"]}>
        <Button type="link" size="small" onClick={() => UpdateSpuSkuDrawer.open(props.item)}>编辑规格</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["spu:delete"]}>
        <DeleteSpuButton id={props.item.id} />
    </PermissionWrapper>
</Space>)

const SpuDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "封面",
        dataIndex: "cover",
        hideInSearch: true,
        align: "center",
        width: 64,
        render: (_, v) => (<Avatar shape="square" size="small" src={<Image src={v.cover} alt="产品封面" />} />)
    },
    {
        title: "分类",
        dataIndex: "category",
        valueType: "treeSelect",
        fieldProps: { fieldNames: { label: "name", value: "id" } },
        request: () => requestCategoryByTree().then(res => res.data).catch(() => [])
    },
    {
        title: "名称",
        dataIndex: "name"
    },
    {
        title: "编号",
        dataIndex: "sn"
    },
    {
        title: "条码",
        dataIndex: "barCode"
    },
    {
        title: "排序",
        dataIndex: "sort",
        hideInSearch: true
    },
    {
        title: "规格",
        dataIndex: "properties",
        search: false,
        render: (text) => text.split("|").map(v => (<Tag key={v} color="blue">{v}</Tag>))
    },
    {
        title: "备注",
        dataIndex: "comment",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "创建人",
        dataIndex: "creator",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "创建时间",
        dataIndex: "createTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "修改人",
        dataIndex: "modifier",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<SpuDataTableColumnOperations item={item} />)
    }
]

export default class SpuDataTable extends React.Component {

    tableActionRef = React.createRef()

    componentDidMount() {
        EventBus.on(SpuEvent.onSpuCreated, this.tableActionRef.current?.reload)
        EventBus.on(SpuEvent.onSpuUpdated, this.tableActionRef.current?.reload)
        EventBus.on(SpuEvent.onSpuDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(SpuEvent.onSpuCreated, console.log)
        EventBus.off(SpuEvent.onSpuUpdated, console.log)
        EventBus.off(SpuEvent.onSpuDeleted, console.log)
    }

    request = (params) => requestSpuByPage(params)
        .then(res => res.data)
        .catch(() => ({ data: [], success: true, total: 0 }))

    render() {
        return (<>
            <ProTable rowKey="id"
                cardBordered
                params={{ category: this.props.category }}
                columns={SpuDataTableColumns}
                actionRef={this.tableActionRef}
                toolBarRender={() => (<SpuDataTableOperations />)}
                request={this.request} />

            <UpdateSpuDrawer />
            <UpdateSpuSkuDrawer />
        </>)
    }

}
