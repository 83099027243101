import PropTypes from "prop-types"
import { Button, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeleteDailyStatistic } from "@/statistic/daily/api/DailyStatisticAPI"
import DailyStatisticEvent from "@/statistic/daily/event/DailyStatisticEvent"


export default function DeleteDailyStatisticButton(props) {

    const onConfirm = () => requestDeleteDailyStatistic(props.id).then(res => {
        EventBus.emit(DailyStatisticEvent.onDailyStatisticDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除每日统计" okText="删除" cancelText="取消" okButtonProps={{ danger: true }}
        onCancel={onCancel}
        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteDailyStatisticButton.propTypes = {
    id: PropTypes.string.isRequired
}