import React from 'react'
import { EditableProTable, ModalForm, nanoid } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"

import SkuEvent from "../event/SkuEvent"
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils"
import { Button, Space } from "antd"
import { requestUpdateSku } from "../api/SkuAPI"

export default class UpdateSpuSkuExtraModal extends React.Component {

    state = {
        open: false,
        sku: {},
        extra: []
    }

    columns = [
        {
            title: "ID",
            dateIndex: "id",
            hideInTable: true
        },
        {
            title: "Extra",
            dataIndex: "name",
            valueType: "treeSelect",
            fieldProps: { fieldNames: { label: "name", value: "variable" } },
            request: () => DictionaryVariableUtils.requestDvByTree("spu-extra")
        },
        {
            title: "Value",
            dataIndex: "value",
            valueType: "text"
        },
        {
            title: '操作', valueType: 'option', width: 200, render: (_, row, __, action) => (<Space size="small">
                <Button type="link" size="small" onClick={() => action.startEditable(row.id)}>编辑</Button>
            </Space>)
        }
    ]

    componentDidMount() {
        EventBus.on(SkuEvent.onTriggerUpdateSkuExtra, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(SkuEvent.onTriggerUpdateSkuExtra, console.log)
    }

    onTrigger = (sku) => {
        sku = { ...sku }
        const extraObj = JSON.parse(sku.extra)
        console.log(extraObj)
        const extra = []
        if (!!extraObj) {
            for (let key in extraObj) {
                extra.push({ id: nanoid(), name: key, value: extraObj[key] })
            }
        }
        this.setState({ open: true, sku, extra })
    }

    onFinish = () => {
        const params = {}
        for (let i of this.state.extra) {
            params[i.name] = i.value
        }
        return requestUpdateSku({ id: this.state.sku.id, extra: JSON.stringify(params) }).then(res => {
            EventBus.emit(SkuEvent.onSkuUpdated, res.data)
            return true
        }).catch(() => {
            return false
        })
    }

    render() {
        return (<ModalForm title="编辑SKU Extra"
            open={this.state.open}
            onOpenChange={open => this.setState({ open })}
            modalProps={{ destroyOnClose: true, width: 560 }}
            onFinish={this.onFinish}>
            <EditableProTable rowKey="id"
                recordCreatorProps={{ record: () => ({ id: nanoid() }) }}
                cardProps={{ ghost: true }}
                columns={this.columns}
                value={this.state.extra}
                onChange={extra => this.setState({ extra })} />
        </ModalForm>)
    }

}

UpdateSpuSkuExtraModal.open = (sku) => {
    EventBus.emit(SkuEvent.onTriggerUpdateSkuExtra, sku)
}