const VALUE_SUPER_PERMISSION = "*:*"
const KEY_AUTHORIZATION = "Authorization"
const KEY_USER = "User"

/**
 * 获取 JWT TOKEN
 * @returns {string} JWT TOKEN
 */
export function getToken() {
    const tokenStr = window.localStorage.getItem(KEY_AUTHORIZATION)
    return !!tokenStr ? tokenStr : ""
}

/**
 * 保存 JWT TOKEN
 * @param jwtStr {string} JWT TOKEN
 */
export function setToken(jwtStr) {
    window.localStorage.setItem(KEY_AUTHORIZATION, jwtStr)
}

/**
 * 删除 JWT
 */
export function removeToken() {
    window.localStorage.removeItem(KEY_AUTHORIZATION)
}

/**
 * 获取用户信息
 * @returns {{id:string, name:string, available:string, avatar:string, roles:[{}]} | null} 用户信息
 */
export function getUser() {
    const userJsonStr = window.localStorage.getItem(KEY_USER)
    if (!!userJsonStr) {
        try {
            return JSON.parse(userJsonStr)
        } catch (e) {
            console.error(e)
            return null
        }
    }
    return null
}

/**
 * 设置用户信息
 * @param user {object} 用户信息
 */
export const setUser = (user) => {
    window.localStorage.setItem(KEY_USER, JSON.stringify(user))
}

/**
 * 删除用户
 */
export const removeUser = () => {
    window.localStorage.removeItem(KEY_USER)
}

/**
 * 用户用户权限
 * @returns {[string]} 用户角色
 */
export function getUserRoles() {
    const user = getUser()
    if (user === undefined || user === null) {
        return []
    }
    return user.roles.map(role => role.identifier)
}

/**
 * 获取用户权限
 * @returns {[string]} 用户权限
 */
export function getUserPermissions() {
    const user = getUser()
    if (user === undefined || user === null) {
        return []
    }
    const roles = user.roles
    if (roles === undefined || roles === null || roles.length === 0) {
        return []
    }
    const permissions = []
    for (const role of roles) {
        if (role.permissions === undefined || role.permissions === null || role.permissions.length === 0) {
            continue
        }
        for (const permission of role.permissions) {
            permissions.push(permission.identifier)
        }
    }
    return permissions
}

export function hasRole(requireRoles = [], strict = false, skipAdmin = false) {
    if (requireRoles === undefined || requireRoles === null || requireRoles.length < 1) {
        return true
    }
    const roles = getUserRoles()
    if (roles === undefined || roles === null || roles.length < 1) {
        return false
    }
    if (!skipAdmin && SecurityUtils.hasSuperPermission()) {
        return true
    }
    if (strict) {
        let check = true
        roles.forEach(role => check = check && requireRoles.includes(role))
        return check
    } else {
        for (let role of roles) {
            if (requireRoles.includes(role)) {
                return true
            }
        }
        return false
    }
}

/**
 * 检查用户是否拥有指定权限
 *
 * @description 用于判断用户是否拥有指定的权限
 * @description 默认检查模式为不严格且不会跳过超级权限检查
 *
 * @param requirePermissions {[string]} 用户需要拥有的权限
 * @param userPermissions {[string]} 用户当前的权限（可选）
 * @param strict {boolean} 严格模式 —— 启用严格模式则会要求用户拥有所有的指定权限，否则只需要拥有其中一个权限即可
 * @param skipSupper {boolean} 是否跳过超级权限 —— 如果用户拥有超级权限（*:*）则不会进行检查，直接返回 true
 *
 * @returns {boolean} true 检查通过 | false 用户权限不满足要求
 *
 * @author Wayne Carder
 * @version 1.0.0
 */
export function hasPermission(requirePermissions, userPermissions = getUserPermissions(), strict = false, skipSupper = false) {
    // 需要检查的权限数组为空，直接返回 true
    if (requirePermissions === undefined || requirePermissions === null || requirePermissions.length === 0) {
        return true
    }
    // 用户权限为空，直接返回 false
    if (userPermissions === undefined || userPermissions === null || userPermissions.length === 0) {
        return false
    }
    // 不忽略管理员权限，并且当前用户拥有管理员权限，直接返回 true
    if (!skipSupper && userPermissions.includes(VALUE_SUPER_PERMISSION)) {
        return true
    }
    // 开始检查权限
    if (strict) {
        // 严格检查模式（要求用户拥有全部的指定权限）
        let check = true
        userPermissions.forEach(permission => {
            check = check && requirePermissions.includes(permission)
        })
        return check
    } else {
        // 默认/宽松模式（用户只需要拥有指定权限中的任意一个即可）
        for (let requirePermission of requirePermissions) {
            if (userPermissions.includes(requirePermission)) {
                return true
            }
        }
        return false
    }
}

function isUser(id, matchSupperPermission = true) {
    if (matchSupperPermission && hasSuperPermission()) {
        return true
    }
    if (id === undefined || id === null) {
        return false
    }
    return getUser().id === id
}

function hasSuperPermission() {
    return hasPermission([VALUE_SUPER_PERMISSION])
}

const SecurityUtils = {
    getUser,
    setUser,
    getToken,
    setToken,
    getUserRoles,
    getUserPermissions,
    hasRole,
    hasPermission,
    isUser,
    hasSuperPermission
}

export default SecurityUtils
