import React from "react";
import { ModalForm, ProFormRadio, ProFormText, ProFormTextArea } from "@ant-design/pro-components";

import EventBus from "../../../framework/util/EventBusUtils";
import FormUtils from "../../../framework/util/FormUtils";
import Rule from "../../../framework/form/Rule";

import { requestAuditMember } from "../api/MemberAPI";
import MemberEvent from "../event/MemberEvent";

export default class AuditMemberModal extends React.Component {

    static open = (item) => {
        EventBus.emit(MemberEvent.onTriggerAuditMember, item)
    }

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(MemberEvent.onTriggerAuditMember, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(MemberEvent.onTriggerAuditMember, console.log)
    }

    onFinish = (params) => {
        return requestAuditMember(params).then(() => {
            EventBus.emit(MemberEvent.onMemberUpdated)
            return true
        }).catch((err) => {
            console.error("编辑会员失败", err);
            FormUtils.setFormError(this.formRef, err.response?.data, "name")
            return false;
        })
    }

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        form.trade = form.trade?.split(",")
        form.operatePlatform = form.operatePlatform?.split(",")
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100);
        })
    }

    render() {
        return (<ModalForm title="审核会员"
                           modalProps={{ destroyOnClose: true, width: 480 }}
                           open={this.state.open}
                           onOpenChange={open => this.setState({ open })}
                           formRef={this.formRef}
                           onFinish={this.onFinish}>
            <ProFormText name="id" label="ID" hidden/>
            <ProFormRadio.Group name="audit" label="审核状态"
                                options={[{ label: "通过", value: 1 }, { label: "未审核", value: 0 }]}
                                rules={Rule.require}/>
            <ProFormTextArea name="auditComment" label="审核意见" rules={Rule.requireText}/>
        </ModalForm>)
    }

}