import React from "react";
import {message} from "antd";
import {DrawerForm, ProFormText} from "@ant-design/pro-components";

import EventBus from "../../../framework/util/EventBusUtils";
import FormUtils from "../../../framework/util/FormUtils";
import Rule from "../../../framework/form/Rule";

import {requestUpdateActivityMember} from "../api/ActivityMemberAPI";
import ActivityMemberEvent from "../event/ActivityMemberEvent";

export default class UpdateActivityMemberDrawer extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(ActivityMemberEvent.onTriggerUpdateActivityMember, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(ActivityMemberEvent.onTriggerUpdateActivityMember, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({open})
    }

    onFinish = (params) => requestUpdateActivityMember(params).then(() => {
        message.success("操作成功", 2).then()
        EventBus.emit(ActivityMemberEvent.onActivityMemberUpdated)
        return true
    }).catch((err) => {
        console.error("编辑活动参与人员失败", err);
        message.error("操作失败", 2).then()
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false;
    })

    onTrigger = (item) => this.setState({open: true}, () => {
        setTimeout(() => this.formRef.current?.setFieldsValue(JSON.parse(JSON.stringify(item))), 100);
    })

    render() {
        return (<DrawerForm title="编辑活动参与人员"
                            drawerProps={{destroyOnClose: true, width: 480}}
                            open={this.state.open}
                            onOpenChange={open => this.setState({open})}
                            formRef={this.formRef}
                            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden/>
            <ProFormText width="sm" name="userName" label="姓名" rules={Rule.require}/>
            <ProFormText width="md" name="userOrgName" label="组织名称" rules={Rule.require}/>
            <ProFormText width="md" name="userCellphone" label="手机号码" disabled rules={Rule.require}/>
            <ProFormText width="sm" name="seatNumber" label="座位号"/>
            <ProFormText width="sm" name="manager" label="负责人"/>
        </DrawerForm>)
    }
}