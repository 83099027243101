/**
 * 新建规格
 */
export const requestCreateSpecification = (params) => window.axios.post("/specification/specification", params)

/**
 * 分页查询规格
 * @param params 查询参数
 */
export const requestSpecificationByPage = (params) => window.axios.get("/specification/specification/page", { params })

/**
 * 查询规格
 * @param params 查询参数
 */
export const requestSpecificationByList = (params) => window.axios.get("/specification/specification/list", { params })

/**
 * 查询指定ID规格
 * @param id {string} 规格ID
 */
export const requestSpecificationById = (id) => window.axios.get(`/specification/specification/${id}`)

/**
 * 更新规格
 */
export const requestUpdateSpecification = (params) => window.axios.patch(`/specification/specification/${params.id}`, params)

/**
 * 删除规格
 */
export const requestDeleteSpecification = (id) => window.axios.delete(`/specification/specification/${id}`)
