/**
 * 新增权限
 */
export const requestCreatePermission = (params) => window.axios.post("/member/permission", params)

/**
 * 分页查询权限
 * @param params 查询参数
 */
export const requestPermissionByPage = (params) => window.axios.get("/member/permission/page", { params })

/**
 * 查询权限
 * @param params 查询参数
 */
export const requestPermissionByList = (params) => window.axios.get("/member/permission/list", { params })

/**
 * 查询指定ID权限
 * @param id {string} 权限ID
 */
export const requestPermissionById = (id) => window.axios.get(`/member/permission/${id}`)

/**
 * 更新权限
 */
export const requestUpdatePermission = (params) => window.axios.patch(`/member/permission/${params.id}`, params)

/**
 * 删除权限
 */
export const requestDeletePermission = (id) => window.axios.delete(`/member/permission/${id}`)

/**
 * 下载导入权限Excel模版
 */
export const requestDownloadPermissionExcelTemplate = () => window.axios.get("/member/permission/template/excel", { responseType: "blob" })