/**
 * 新增附件文件
 */
export const requestCreateFile = (params) => window.axios.post("/common/attachment/file", params)

/**
 * 分页查询附件文件
 * @param params 查询参数
 */
export const requestFileByPage = (params) => window.axios.get("/common/attachment/file/page", {params})

/**
 * 查询附件文件
 * @param params 查询参数
 */
export const requestFileByList = (params) => window.axios.get("/common/attachment/file/list", {params})

/**
 * 查询指定ID附件文件
 * @param id {string} 附件文件ID
 */
export const requestFileById = (id) => window.axios.get(`/common/attachment/file/${id}`)

/**
 * 更新附件文件
 */
export const requestUpdateFile = (params) => window.axios.patch(`/common/attachment/file/${params.id}`, params)

/**
 * 删除附件文件
 */
export const requestDeleteFile = (id) => window.axios.delete(`/common/attachment/file/${id}`)