import React from "react";
import {Avatar, Button, Image, Space, Tag} from "antd";
import {ProTable} from "@ant-design/pro-components";

import PermissionWrapper from "@/framework/component/PermissionWrapper";
import EventBus from "@/framework/util/EventBusUtils";

import {requestArticleByPage} from "@/content/article/api/ArticleAPI";
import ArticleEvent from "@/content/article/event/ArticleEvent";
import DeleteArticleButton from "@/content/article/component/DeleteArticleButton";
import CreateArticleDrawer from "@/content/article/interaction/CreateArticleDrawer";
import UpdateArticleDrawer from "@/content/article/interaction/UpdateArticleDrawer";
import PreviewActivityQrCodeModal from "@/association/activity/interaction/PreviewActivityQrCodeModal";
import PreviewArticleWxappQrCodeModal from "@/content/article/interaction/PreviewArticleWxappQrCodeModal";

const ArticleDataTableColumnState = {
    persistenceKey: "ColumnStateForArticle",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        modifyTime: {show: false}
    }
}

const ArticleDataTableOperations = ({section}) => (<Space>
    <PermissionWrapper permissions={["article:insert"]}>
        <CreateArticleDrawer section={section}/>
    </PermissionWrapper>
</Space>)

const ArticleDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["article:update"]}>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(ArticleEvent.onTriggerUpdateArticle, props.item)}>编辑</Button>
    </PermissionWrapper>
    <Button type="link" size="small" onClick={() => PreviewArticleWxappQrCodeModal.open(props.item)}>二维码</Button>
    <PermissionWrapper permissions={["article:delete"]}>
        <DeleteArticleButton id={props.item.id}/>
    </PermissionWrapper>
</Space>)

export default class ArticleDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInSearch: true
        },
        {
            title: "封面",
            dataIndex: "cover",
            hideInSearch: true,
            render: (_, v) => !!v.cover ? (<Avatar size="small" src={<Image src={v.cover} alt="文章封面"/>}/>) : "-"
        },
        {
            title: "标题",
            dataIndex: "title"
        },
        {
            title: "副标题",
            dataIndex: "subtitle"
        },
        {
            title: "作者",
            dataIndex: "author"
        },
        {
            title: "排序",
            dataIndex: "sort",
            hideInSearch: true
        },
        {
            title: "类型",
            dataIndex: "type",
            render: (_, v) => v.type.split(',').map((v, i) => (<Tag key={i} color="blue">{v}</Tag>))
        },
        {
            title: "查看次数",
            dataIndex: "numView",
            hideInSearch: true
        },
        {
            title: "创建人",
            dataIndex: "creator",
            hideInSearch: true,
            hideInTable: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            valueType: "fromNow",
            hideInSearch: true
        },
        {
            title: "修改人",
            dataIndex: "modifier",
            hideInSearch: true,
            hideInTable: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            valueType: "fromNow",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<ArticleDataTableColumnOperations item={item}/>)
        }
    ]

    componentDidMount() {
        EventBus.on(ArticleEvent.onArticleCreated, () => this.tableActionRef.current?.reload())
        EventBus.on(ArticleEvent.onArticleUpdated, () => this.tableActionRef.current?.reload())
        EventBus.on(ArticleEvent.onArticleDeleted, () => this.tableActionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(ArticleEvent.onArticleCreated, console.log)
        EventBus.off(ArticleEvent.onArticleUpdated, console.log)
        EventBus.off(ArticleEvent.onArticleDeleted, console.log)
    }

    onRequestArticlePage = (params) => requestArticleByPage(params).then(res => res.data).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      columns={this.columns}
                      actionRef={this.tableActionRef}
                      params={{section: this.props.section}}
                      columnsState={ArticleDataTableColumnState}
                      toolBarRender={() => (<ArticleDataTableOperations section={this.props.section}/>)}
                      request={this.onRequestArticlePage}/>
            <PermissionWrapper permissions={["article:update"]}>
                <UpdateArticleDrawer/>
            </PermissionWrapper>
            <PreviewArticleWxappQrCodeModal/>
        </>);
    }

}