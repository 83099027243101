import React from "react";
import {Modal} from "antd";

import EventBus from "../../framework/util/EventBusUtils";

import ProcessBpmn from "../component/ProcessBpmn";
import WorkflowEvent from "../event/WorkflowEvent";

export default class PreviewProcessDefinitionModal extends React.Component {

    state = {
        definition: {},
        open: false
    }

    componentDidMount() {
        EventBus.on(WorkflowEvent.onTriggerPreviewProcessDefinitionEvent, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onTriggerPreviewProcessDefinitionEvent, console.log)
    }

    onTrigger = (definition) => this.setState({definition, open: true})

    render() {
        return (<Modal title={this.state.definition.name}
                       open={this.state.open}
                       width={800}
                       destroyOnClose
                       onOk={() => this.setState({open: false})}
                       onCancel={() => this.setState({open: false})}>
            <ProcessBpmn processDefinitionId={this.state.definition.id}/>
        </Modal>)
    }

}

PreviewProcessDefinitionModal.open = (definition) => {
    EventBus.emit(WorkflowEvent.onTriggerPreviewProcessDefinitionEvent, definition)
}