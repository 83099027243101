import React from "react";
import {Skeleton} from "antd";
import {DrawerForm, ProFormText} from "@ant-design/pro-components";

import EventBus from "../../framework/util/EventBusUtils";

import WorkflowEvent from "../event/WorkflowEvent";
import {formConverter} from "../util/FormConverter";
import {
    requestCreateProcessInstance,
    requestProcessDefinitionExtensionPropertiesById,
    requestProcessDefinitionStartForm
} from "../api/WorkflowAPI";
import WorkflowLifecycleUtils from "../util/WorkflowLifecycleUtils";

export default class StartProcessInstanceDrawer extends React.Component {

    formRef = React.createRef()

    state = {
        open: false,
        processDefinition: {},
        form: undefined,
        startFormLoading: true,
        processDefinitionExtensionProperties: {}
    }

    componentDidMount() {
        EventBus.on(WorkflowEvent.onTriggerCreateProcessInstanceEvent, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onTriggerCreateProcessInstanceEvent, console.log)
    }

    onTrigger = (processDefinition) => this.setState({startFormLoading: true, open: true}, () => {
        requestProcessDefinitionStartForm(processDefinition.id).then(res => {
            processDefinition.hasStartForm = true
            this.setState({form: res.data, startFormLoading: false, processDefinition})
        }).catch(() => {
            processDefinition.hasStartForm = false
            this.setState({form: undefined, startFormLoading: false, processDefinition})
        })
        requestProcessDefinitionExtensionPropertiesById(processDefinition.id).then(res => {
            this.setState({processDefinitionExtensionProperties: res.data})
            WorkflowLifecycleUtils.onFormRendered(processDefinition, {}, res.data, this.formRef)
        }).catch(err => {
            console.warn("requestProcessDefinitionExtensionPropertiesById", err)
        }).finally(() => {
            this.formRef.current?.setFieldsValue({processDefinitionId: processDefinition.id})
        })
    })

    onFinish = (params) => requestCreateProcessInstance(params).then(res => {
        EventBus.emit(WorkflowEvent.onProcessInstanceCreateEvent, res.data)
        return true
    }).catch(err => {
        console.error(err)
        return false
    })

    render() {
        return (<DrawerForm title={`发起【${this.state.processDefinition.name}】流程`}
                            disabled={this.state.startFormLoading}
                            open={this.state.open}
                            formRef={this.formRef}
                            drawerProps={{destroyOnClose: true, width: "60vw"}}
                            onFinish={this.onFinish}
                            onOpenChange={open => this.setState({open})}>
            <ProFormText label="流程定义ID" name="processDefinitionId" hidden/>
            <Skeleton active loading={this.state.startFormLoading}>
                {formConverter(this.state.form?.components, this.formRef)}
            </Skeleton>
        </DrawerForm>)
    }

}

StartProcessInstanceDrawer.open = (processDefinition) => {
    EventBus.emit(WorkflowEvent.onTriggerCreateProcessInstanceEvent, processDefinition)
}