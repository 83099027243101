import React from "react";
import {
    DrawerForm,
    ProFormDateTimePicker,
    ProFormDateTimeRangePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormField,
    ProFormRadio,
    ProFormSelect,
    ProFormText
} from "@ant-design/pro-components";

import EventBus from "../../../framework/util/EventBusUtils";
import FormUtils from "../../../framework/util/FormUtils";
import Rule from "../../../framework/form/Rule";

import { requestUpdateActivity } from "../api/ActivityAPI";
import ActivityEvent from "../event/ActivityEvent";
import AttachmentPicker from "../../../common/attachment/file/component/AttachmentPicker";
import DictionaryVariableUtils from "../../../common/dictionary/variable/util/DictionaryVariableUtils";

export default class UpdateActivityDrawer extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(ActivityEvent.onTriggerUpdateActivity, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(ActivityEvent.onTriggerUpdateActivity, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({ open })
    }

    onFinish = (params) => {
        if (params.allowSignup === 1) {
            params.signupStartTime = params.signupTime[0]
            params.signupEndTime = params.signupTime[1]
            params.signupTime = undefined
        }
        return requestUpdateActivity(params).then(() => {
            EventBus.emit(ActivityEvent.onActivityUpdated)
            return true
        }).catch((err) => {
            console.error("编辑活动失败", err);
            FormUtils.setFormError(this.formRef, err.response?.data, "name")
            return false;
        })
    }

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        form.signupTime = [form.signupStartTime, form.signupEndTime]
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100);
        })
    }

    render() {
        return (<DrawerForm title="编辑活动"
                            drawerProps={{ destroyOnClose: true, width: 640 }}
                            open={this.state.open}
                            onOpenChange={this.toggle}
                            formRef={this.formRef}
                            onFinish={this.onFinish}>

            <ProFormField label="封面" name="cover">
                <AttachmentPicker accept={["image/png", "image/jpeg"]}/>
            </ProFormField>
            <ProFormText name="id" label="ID" hidden/>
            <ProFormText width="md" name="name" label="名称" rules={[
                ...Rule.require,
                { max: 20, message: "此项长度不能超过20" }
            ]}/>
            <ProFormSelect width="sm" name="type" label="活动类型" rules={Rule.require}
                           request={() => DictionaryVariableUtils.requestDvByList("activity-type")}/>
            <ProFormDateTimePicker width="sm" name="time" label="活动时间"
                                   fieldProps={{ format: "YYYY-MM-DD HH:mm" }}
                                   rules={Rule.require}/>
            <ProFormText width="xl" name="address" label="活动地址" rules={Rule.require}/>
            <ProFormDigit width="xs" name="maxCount" label="最大活动人数"
                          fieldProps={{ precision: 0 }}
                          rules={Rule.require}/>
            <ProFormDigit width="xs" name="virtualSignupCount" label="虚拟报名人数"
                          fieldProps={{ precision: 0 }}/>

            <ProFormRadio.Group label="允许报名" name="allowSignup"
                                initialValue={0}
                                options={[{ label: "开放报名", value: 1 }, { label: "禁止报名", value: 0 }]}
                                rules={Rule.require}/>

            <ProFormDependency name={["allowSignup"]}>
                {({ allowSignup }) => {
                    return allowSignup === 1 ? (
                        <ProFormDateTimeRangePicker width="md" name="signupTime" label="报名时间"
                                                    fieldProps={{ format: "YYYY-MM-DD HH:mm" }}
                                                    rules={Rule.require}/>) : undefined
                }}
            </ProFormDependency>

            <ProFormDependency name={["allowSignup"]}>
                {({ allowSignup }) => {
                    return allowSignup === 1 ? (
                        <ProFormDigit width="xs" name="signupCount" label="最大报名人数"
                                      fieldProps={{ precision: 0 }}
                                      rules={Rule.require}/>) : undefined
                }}
            </ProFormDependency>

            <ProFormText label="活动海报" name="content" tooltip="海报URL地址"/>
        </DrawerForm>)
    }
}