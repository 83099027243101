import React from "react";
import {Drawer, message, Skeleton, Space} from "antd";
import EventBus from "../../framework/util/EventBusUtils";
import WorkflowEvent from "../event/WorkflowEvent";

import "../index.css"
import ProcessBpmn from "../component/ProcessBpmn";
import ProcessInstanceStartFormDescription from "../component/description/ProcessInstanceStartFormDescription";
import {
    requestHistoryProcessInstanceFinishedTaskByList,
    requestHistoryProcessInstanceVariablesByList
} from "../api/WorkflowAPI";
import ProcessInstanceTaskDescription from "../component/description/ProcessInstanceTaskDescription";
import ProcessInstanceDescription from "../component/description/ProcessInstanceDescription";
import ProcessInstanceTaskFormDescription from "../component/description/ProcessInstanceTaskFormDescription";

export default class PreviewProcessInstanceDrawer extends React.Component {

    state = {
        instance: {},
        open: false,
        variables: undefined,
        tasks: undefined,
        task: undefined,
        loadingForTasks: true,
        loadingForVariables: true
    }

    componentDidMount() {
        EventBus.on(WorkflowEvent.onTriggerPreviewProcessInstanceEvent, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onTriggerPreviewProcessInstanceEvent, console.log)
    }

    onTrigger = (instance) => this.setState({
        instance,
        open: true,
        loadingForTasks: true,
        loadingForVariables: true
    }, () => {
        requestHistoryProcessInstanceFinishedTaskByList(instance.id).then(res => {
            const tasks = {}
            for (let datum of res.data) {
                tasks[datum.taskDefinitionKey] = datum
            }
            this.setState({tasks, loadingForTasks: false})
        }).catch(() => {
            this.setState({tasks: {}, loadingForTasks: false})
        })
        requestHistoryProcessInstanceVariablesByList(instance.id).then(res => {
            const variables = {}
            for (let datum of res.data) {
                variables[datum.variableName] = datum.textValue
            }
            this.setState({variables, loadingForVariables: false})
        }).catch((err) => {
            console.log(err)
            this.setState({variables: {}, loadingForVariables: false})
        })
    })

    onElementClick = ({element}) => {
        if (element.type === "bpmn:UserTask") {
            const task = this.state.tasks[element.id]
            if (!!task) {
                this.setState({task})
            } else {
                message.info("该任务未完成，无法查看", 1).then()
                this.setState({task: undefined})
            }
        } else {
            this.setState({task: undefined})
        }
    }

    render() {
        return (<Drawer title="查看流程记录信息"
                        width="60vw"
                        open={this.state.open}
                        destroyOnClose
                        onClose={() => this.setState({open: false, variables: undefined, task: undefined})}>
            <Skeleton active loading={this.state.loadingForTasks && this.state.loadingForVariables}>
                <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                    <ProcessBpmn processDefinitionId={this.state.instance?.processDefinitionId}
                                 processInstanceId={this.state.instance?.id}
                                 onElementClick={this.onElementClick}/>

                    <ProcessInstanceDescription bordered title="流程记录信息" size="small"
                                                processInstance={this.state.instance}/>

                    <ProcessInstanceStartFormDescription bordered title="流程提交信息" size="small"
                                                         processDefinitionId={this.state.instance?.processDefinitionId}
                                                         variables={this.state.variables}/>

                    <ProcessInstanceTaskDescription task={this.state.task}
                                                    bordered title="任务信息" size="small"/>

                    <ProcessInstanceTaskFormDescription task={this.state.task} variables={this.state.variables}
                                                        bordered title="任务提交信息" size="small"/>
                </Space>
            </Skeleton>
        </Drawer>)
    }

}

PreviewProcessInstanceDrawer.open = (instance) => {
    EventBus.emit(WorkflowEvent.onTriggerPreviewProcessInstanceEvent, instance)
}