import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { DrawerForm, ProFormText } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"

import { requestCreatePrintTemplate } from "../api/PrintTemplateAPI"
import PrintTemplateEvent from "../event/PrintTemplateEvent"

export default function CreatePrintTemplateDrawer() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined/>}>新增打印模版</Button>)

    const onOpenChange = (open) => {
        if (open) {
            setTimeout(() => {

            }, 200)

        }
    }

    const onFinish = (params) => requestCreatePrintTemplate(params).then(res => {
        EventBus.emit(PrintTemplateEvent.onPrintTemplateCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增打印模版失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<DrawerForm title="新增打印模版"
                        drawerProps={{ destroyOnClose: true, width: window.innerWidth < 700 ? "100%" : "70%" }}
                        onOpenChange={onOpenChange}
                        formRef={formRef}
                        trigger={trigger}
                        onFinish={onFinish}>
        <ProFormText name="name" label="名称" rules={Rule.require}/>
        <ProFormText name="description" label="描述"/>
        <ProFormText name="comment" label="备注"/>
        <div id="container" style={{ height: 480 }}></div>
        {/* <ProFormText name="content" label="内容" rules={Rule.require} /> */}
    </DrawerForm>)
}