import React from "react";
import {ModalForm, ProFormText, ProFormTextArea} from "@ant-design/pro-components";

import EventBus from "@/framework/util/EventBusUtils";
import FormUtils from "@/framework/util/FormUtils";
import Rule from "@/framework/form/Rule";

import DictionaryLabelEvent from "../event/DictionaryLabelEvent";
import {requestUpdateDictionaryLabel} from "../api/DictionaryLabelAPI";

export default class UpdateDictionaryLabelModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false
    }

    componentDidMount() {
        EventBus.on(DictionaryLabelEvent.onTriggerUpdateDictionaryLabel, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(DictionaryLabelEvent.onTriggerUpdateDictionaryLabel, console.log)
    }

    onTrigger = (item) => {
        this.setState({open: true})
        setTimeout(() => this.formRef.current?.setFieldsValue(item), 100)
    }

    onFinish = (params) => requestUpdateDictionaryLabel(params).then(res => {
        EventBus.emit(DictionaryLabelEvent.onDictionaryLabelUpdated, res.data)
        this.formRef.current?.resetFields()
        return true
    }).catch(err => {
        console.log("编辑字典失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    render() {
        return (<ModalForm title="编辑字典"
                           modalProps={{width: 560, destroyOnClose: true}}
                           open={this.state.open}
                           formRef={this.formRef}
                           onOpenChange={open => this.setState({open})}
                           onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden rules={Rule.require}/>
            <ProFormText width="md" name="label" label="显示名称" rules={Rule.requireText}/>
            <ProFormTextArea name="description" label="描述" rules={Rule.text}/>

        </ModalForm>)
    }

}