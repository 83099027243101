import React from "react"
import {ModalForm, ProFormSelect, ProFormText} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"

import {requestUpdateFile} from "../api/FileAPI"
import FileEvent from "../event/FileEvent"
import {requestCategoryByList} from "../../category/api/CategoryAPI"

export default class UpdateFileCategoryModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false
    }

    componentDidMount() {
        EventBus.on(FileEvent.onTriggerFileCategoryUpdate, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(FileEvent.onTriggerFileCategoryUpdate, console.log)
    }

    onTrigger = (item) => this.setState({open: true}, () => {
        this.formRef.current?.setFieldsValue({id: item.id, category: item.category})
    })

    onRequestCategoryOptions = () => requestCategoryByList().then(res => {
        const options = res.data.map(i => ({label: i.name, value: i.id}))
        options.push({label: "未分类", value: "0"})
        return options
    }).catch(() => {
        return []
    })

    onFinish = (params) => requestUpdateFile(params).then(res => {
        EventBus.emit(FileEvent.onFileUpdated, res.data)
        return true
    }).catch(err => {
        FormUtils.setFormError(this.formRef, err.response?.data, "roles")
        return false
    })

    render() {
        return (<ModalForm title="移动文件"
                           grid
                           rowProps={{gutter: [16, 0]}}
                           open={this.state.open}
                           formRef={this.formRef}
                           onOpenChange={visible => this.setState({open: visible})}
                           modalProps={{centered: true, width: 520, destroyOnClose: true, forceRender: true}}
                           onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden colProps={{span: 0}}/>

            <ProFormSelect name="category" label="文件分类" colProps={{span: 24}}
                           request={this.onRequestCategoryOptions}
                           rules={[{required: true, message: "请选择分类"}]}/>
        </ModalForm>)
    }

}