import React from "react"
import {ProTable} from "@ant-design/pro-components"
import {Button, Space} from "antd"
import BooleanWrapper from "@/framework/component/BooleanWrapper"
import UserWrapper from "@/framework/component/UserWrapper"
import {requestProcessInstanceTaskByList} from "../../api/WorkflowAPI"
import CompleteProcessInstanceTaskDrawer from "../../interaction/CompleteProcessInstanceTaskDrawer"
import ClaimProcessInstanceTaskButton from "../button/ClaimProcessInstanceTaskButton"
import AssignProcessInstanceTaskModal from "../../interaction/AssignProcessInstanceTaskModal"
import PreviewProcessTaskModal from "../../interaction/PreviewProcessTaskModal"
import EventBus from "@/framework/util/EventBusUtils";
import WorkflowEvent from "../../event/WorkflowEvent";

export const ProcessInstanceTaskDataTableColumnsState = {
    persistenceKey: "ColumnStateForProcessInstanceTask",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        taskDefinitionKey: {show: false}
    }
}

export const ProcessTaskDataTableColumnOperations = ({item}) => (<Space size="small">
    <Button type="link" size="small" onClick={() => PreviewProcessTaskModal.open(item)}>查看</Button>
    <BooleanWrapper value={!item.assignee}>
        <ClaimProcessInstanceTaskButton item={item}/>
    </BooleanWrapper>
    <BooleanWrapper value={!item.assignee}>
        <Button type="link" size="small" onClick={() => AssignProcessInstanceTaskModal.open(item)}>指派</Button>
    </BooleanWrapper>
    <BooleanWrapper value={!!item.assignee}>
        <UserWrapper user={item.assignee}>
            <Button type="link" size="small" onClick={() => CompleteProcessInstanceTaskDrawer.open(item)}>处理</Button>
        </UserWrapper>
    </BooleanWrapper>
</Space>)

export const ProcessTaskDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        copyable: true,
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "待办名称",
        dataIndex: "name",
        copyable: true,
        ellipsis: true
    },
    {
        title: "Key",
        dataIndex: "taskDefinitionKey",
        fieldProps: {placeholder: "此项为精确匹配"},
        copyable: true,
        ellipsis: true
    },
    {
        title: "优先级",
        dataIndex: "priority",
        hideInSearch: true
    },
    {
        title: "挂起",
        dataIndex: "suspended",
        hideInSearch: true,
        valueType: "select",
        valueEnum: {
            "false": {text: "否", status: "success"},
            "true": {text: "是", status: "error"}
        }
    },
    {
        title: "截止日期",
        dataIndex: "due",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "创建时间",
        dataIndex: "created",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "创建时间",
        dataIndex: "createdPicker",
        valueType: "dateRange",
        hideInTable: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<ProcessTaskDataTableColumnOperations item={item}/>)
    }
]


export default class ProcessTaskDataTable extends React.Component {

    actionRef = React.createRef()

    componentDidMount() {
        EventBus.on(WorkflowEvent.onProcessTaskUpdateEvent, this.actionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onProcessTaskUpdateEvent, () => console.log("EventOff", WorkflowEvent.onProcessTaskUpdateEvent))
    }

    request = (params) => requestProcessInstanceTaskByList(params).then(res => {
        return {data: res.data, success: true, total: res.data}
    }).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<ProTable rowKey="id"
                          actionRef={this.actionRef}
                          scroll={{x: "max-content"}}
                          columns={ProcessTaskDataTableColumns}
                          columnsState={ProcessInstanceTaskDataTableColumnsState}
                          request={this.request}
                          params={{processInstanceId: this.props.processInstanceId}}
                          headerTitle={false}
                          search={false}
                          options={false}
                          pagination={false}/>
        )
    }

}