import React from "react";
import {ModalForm, ProFormRadio, ProFormText} from "@ant-design/pro-components";

import EventBus from "@/framework/util/EventBusUtils";
import FormUtils from "@/framework/util/FormUtils";

import {requestUpdateCategory} from "../api/CategoryAPI";
import CategoryEvent from "../event/CategoryEvent";
import {getAvailableEnumAsOptions} from "@/framework/enum/AvailableEnum";
import Rule from "@/framework/form/Rule";

export default class UpdateCategoryModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(CategoryEvent.onTriggerCategoryUpdate, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(CategoryEvent.onTriggerCategoryUpdate, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({open})
    }

    onFinish = (params) => requestUpdateCategory(params).then(() => {
        EventBus.emit(CategoryEvent.onCategoryUpdated)
        return true
    }).catch((err) => {
        console.error("编辑附件分类失败", err);
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false;
    })

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({open: true}, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100);
        })
    }

    render() {
        return (<ModalForm title="编辑附件分类"
                           modalProps={{destroyOnClose: true, width: 560}}
                           open={this.state.open}
                           onOpenChange={this.toggle}
                           formRef={this.formRef}
                           onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden/>

            <ProFormText width="sm" name="name" label="名称" rules={Rule.requireText}/>

            <ProFormRadio.Group width="sm" name="available" label="状态"
                                fieldProps={{defaultValue: "可用"}}
                                options={getAvailableEnumAsOptions()}/>
        </ModalForm>)
    }
}