import React from "react";
import {ProCard} from "@ant-design/pro-components";
import {requestHelpCategoryByTree} from "../api/HelpAPI";
import {Button, Input, Skeleton, Space, Tooltip, Tree} from "antd";
import EmptyWrapper from "@/framework/component/EmptyWrapper";
import {ReloadOutlined} from "@ant-design/icons";
import CreateHelpCategoryModal from "../interaction/CreateHelpCategoryModal";
import EventBus from "@/framework/util/EventBusUtils";
import HelpEvent from "../event/HelpEvent";
import PropTypes from "prop-types";

export default class HelpCategoryTree extends React.Component {

    state = {
        loading: true,
        treeData: [],
        selectedKeys: undefined,
        defaultExpandedKeys: [],
        searchValue: ""
    }

    componentDidMount() {
        this.request()
        EventBus.on(HelpEvent.onHelpCategoryCreated, () => this.request())
        EventBus.on(HelpEvent.onHelpCategoryUpdated, (updateKey) => {
            // When a help-category is updated, reset select-keys and re-trigger onSelect function after request is successful.
            this.request(undefined, (!!this.state.selectedKeys && this.state.selectedKeys[0] === updateKey) ? updateKey : undefined)
        })
        EventBus.on(HelpEvent.onHelpCategoryDeleted, (updateKey) => {
            // When a help-category is deleted, clear selectedKeys and request again
            if (!!this.state.selectedKeys && this.state.selectedKeys[0] === updateKey) {
                this.setState({selectedKeys: undefined}, this.request)
            } else {
                this.request()
            }
        })
    }

    componentWillUnmount() {
        EventBus.off(HelpEvent.onHelpCategoryCreated, console.log)
        EventBus.off(HelpEvent.onHelpCategoryUpdated, console.log)
        EventBus.off(HelpEvent.onHelpCategoryDeleted, console.log)
    }

    request = (name, updateKey) => {
        this.setState({loading: true})
        requestHelpCategoryByTree({name}).then((res) => {
            // If no element selected, set the first element as selected.
            if (!this.state.selectedKeys && res.data.length > 0) {
                this.setState({selectedKeys: [res.data[0].key]}, () => this.props.onChange(res.data[0]))
            }
            const defaultExpandedKeys = this.extractExpandedRowKeys(res.data, updateKey);
            this.setState({loading: false, treeData: res.data, defaultExpandedKeys})
        }).catch(() => {
            this.setState({loading: false, treeData: []})
        })
    }

    extractExpandedRowKeys = (tree, updateKey, keys = []) => {
        for (let v of tree) {
            if (!!updateKey && (v.key === updateKey)) {
                this.props.onChange(v)
            }
            if (v.children !== undefined && v.children !== null && v.children.length > 0) {
                keys.push(v.key)
                this.extractExpandedRowKeys(v.children, updateKey, keys)
            }
        }
        return keys
    }

    onSearch = (name) => {
        this.request(name)
    }

    onSelect = (selectedKeys, event) => {
        if (selectedKeys === undefined || selectedKeys === null || selectedKeys.length < 1) {
            return
        }
        this.setState({selectedKeys: [...selectedKeys]}, () => {
            this.props.onChange(event.node)
        })
    }

    render() {
        return (<ProCard ghost>
            <Space.Compact block style={{marginBottom: 16}}>
                <Input.Search placeholder="搜索分类"
                              value={this.state.searchValue}
                              onSearch={this.onSearch}
                              onChange={(e) => this.setState({searchValue: e.target.value})}/>
                <CreateHelpCategoryModal parent={0}/>
                <Tooltip title="刷新">
                    <Button icon={<ReloadOutlined/>}
                            loading={this.state.loading}
                            onClick={() => this.setState({searchValue: ""}, () => this.request())}/>
                </Tooltip>
            </Space.Compact>
            <Skeleton loading={this.state.loading} active>
                <EmptyWrapper empty={this.state.treeData.length < 1}>
                    <Tree blockNode
                          showLine
                          style={{fontSize: 14, minHeight: 480}}
                          defaultExpandedKeys={this.state.defaultExpandedKeys}
                          selectedKeys={this.state.selectedKeys}
                          fieldNames={{title: "name", key: "key", children: "children"}}
                          onSelect={this.onSelect}
                          treeData={this.state.treeData}/>
                </EmptyWrapper>
            </Skeleton>
        </ProCard>)
    }

}

HelpCategoryTree.propTypes = {
    onChange: PropTypes.func
}