import PropTypes from "prop-types";
import {App, Button, Popconfirm} from "antd";

import EventBus from "@/framework/util/EventBusUtils";

import {requestDeleteAnnouncement} from "../api/AnnouncementAPI";
import AnnouncementEvent from "../event/AnnouncementEvent";

/**
 * 删除通知公告按钮
 * @param id
 */
export default function DeleteAnnouncementButton({id}) {

    const {message} = App.useApp()

    const onConfirm = () => requestDeleteAnnouncement(id).then(res => {
        EventBus.emit(AnnouncementEvent.onAnnouncementDeleted, res.data)
        message.success("操作成功", 2).then()
        return true
    }).catch((err) => {
        console.error("requestDeleteAnnouncement", err)
        message.error("操作失败", 2).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除公告" okText="删除" cancelText="取消"
                        okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteAnnouncementButton.propTypes = {
    id: PropTypes.string.isRequired
}