import React from "react";
import { PageContainer } from "@ant-design/pro-components";
import CooperativePartnerDataTable from "./component/CooperativePartnerDataTable";

export default class Partner extends React.Component {

    render() {
        return (<PageContainer>
            <CooperativePartnerDataTable/>
        </PageContainer>)
    }

}