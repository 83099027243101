/**
 * 分页查询认证信息
 * @param params 请求参数
 */
export const requestAuthenticationByPage = (params) => window.axios.get("/member/authentication/page", { params })

/**
 * 批量查询认证信息
 * @param params 请求参数
 */
export const requestAuthenticationByList = (params) => window.axios.get("/member/authentication/list", { params })

/**
 * 查询指定ID认证信息详情
 * @param id {string} 认证信息ID
 */
export const requestAuthenticationById = (id) => window.axios.get(`/member/authentication/${id}`)

/**
 * 创建认证信息
 * @param params 认证信息信息
 */
export const requestCreateAuthentication = (params) => window.axios.post("/member/authentication", params)

/**
 * 更新指定ID认证信息
 * @param params 更新参数
 */
export const requestUpdateAuthentication = (params) => window.axios.patch(`/member/authentication/${params.id}`, params)


/**
 * 删除指定ID认证信息
 * @param id {string} 认证信息ID
 */
export const requestDeleteAuthentication = (id) => window.axios.delete(`/member/authentication/${id}`)
