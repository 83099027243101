import React from "react";
import {Spin, Upload} from "antd";

import UploadFileModal from "../interaction/UploadFileModal";
import {UploadButton} from "./AttachmentSelect";
import EventBus from "@/framework/util/EventBusUtils";
import FileEvent from "../event/FileEvent";
import FileUtils from "../util/FileUtils";
import {requestDeleteFile, requestFileById, requestFileByList} from "../api/FileAPI";
import PropTypes from "prop-types";

export default class AttachmentUpload extends React.Component {

    /**
     * 是否多选
     * @type boolean
     */
    multiple = false
    /**
     * 文件索引
     * @type Object
     */
    selectedFilesReference = {}

    state = {
        /**
         * 当前文件数组
         */
        selectedFiles: [],
        /**
         * 选择弹窗是否打开
         */
        open: false,
        /**
         * 加载中（回显文件）
         */
        loading: true,
    }

    componentDidMount() {
        EventBus.on(FileEvent.onFileCreated, v => this.onFileSelect([v]))
        const {value, count} = this.props
        this.multiple = count > 1
        if (!value) {
            this.setState({selectedFiles: [], loading: false})
            return
        }
        let selectedFiles
        if (this.props.result === "path") {
            if (this.multiple && value instanceof Array) {
                selectedFiles = value.map(v => FileUtils.convertPathToObj(v));
                selectedFiles.forEach(v => this.selectedFilesReference[v.url] = v)
            } else {
                selectedFiles = [FileUtils.convertPathToObj(value)]
                this.selectedFilesReference[selectedFiles[0].url] = selectedFiles[0]
            }
            this.setState({selectedFiles, loading: false})
        } else {
            if (this.multiple && value instanceof Array) {
                requestFileByList({id: value.toString()}).then(res => {
                    selectedFiles = res.data.map(v => ({uid: v.id, name: v.name, status: "done", url: v.path}));
                    selectedFiles.forEach(v => this.selectedFilesReference[v.id] = v)
                    this.setState({selectedFiles, loading: false})
                }).catch(err => {
                    console.error(`根据ID查询文件失败:${value}`, err)
                    this.setState({selectedFiles: [], loading: false})
                })
            } else {
                requestFileById(value).then(res => {
                    selectedFiles = [{uid: res.data.id, name: res.data.name, status: "done", url: res.data.path}]
                    this.selectedFilesReference[res.data.id] = res.data
                    this.setState({selectedFiles, loading: false})
                }).catch(err => {
                    console.error(`根据ID查询文件失败:${value}`, err)
                    this.setState({selectedFiles: [], loading: false})
                })
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState !== this.state) {
            return true
        }
        console.log(nextProps)
        if (nextProps.value === undefined || nextProps.value === null || nextProps.value.length < 1) {
            this.setState({selectedFiles: []})
            this.selectedFilesReference = {}
            return true
        }
        if (nextProps.result === "path") {
            this.updateByPath(nextProps.value)
        } else {
            this.updateById(nextProps.value)
        }
        return true
    }

    componentWillUnmount() {
        this.multiple = true
        this.selectedFilesReference = {}
    }

    updateByPath = (value) => {
        let selectedFiles
        if (this.multiple && value instanceof Array) {
            selectedFiles = []
            for (let v of value) {
                if (Object.hasOwn(this.selectedFilesReference, v)) {
                    selectedFiles.push(this.selectedFilesReference[v])
                } else {
                    this.selectedFilesReference[v] = FileUtils.convertPathToObj(v)
                    selectedFiles.push(this.selectedFilesReference[v])
                }
            }
        } else {
            selectedFiles = [Object.hasOwn(this.selectedFilesReference, value) ? this.selectedFilesReference[value] : FileUtils.convertPathToObj(value)]
        }
        this.setState({selectedFiles})
    }

    updateById = (value) => {
        let selectedFiles
        if (this.multiple) {
            const existingFiles = []
            const unknownFiles = []
            value.forEach(v => Object.hasOwn(this.selectedFilesReference, v) ? existingFiles.push(v) : unknownFiles.push(v))
            if (existingFiles.length === value.length) {
                selectedFiles = existingFiles.map(v => this.selectedFilesReference[v])
                this.setState({existingFiles})
            } else {
                this.setState({loading: true})
                requestFileByList({id: unknownFiles.toString()}).then(res => {
                    res.data.forEach(v => this.selectedFilesReference[v.id] = v)
                    selectedFiles = value.map(v => this.selectedFilesReference[v])
                    this.setState({selectedFiles, loading: false})
                }).catch(err => {
                    console.error(`根据ID查询文件失败:${value}`, err)
                    this.setState({selectedFiles: [], loading: false})
                })
            }
        } else {
            if (Object.hasOwn(this.selectedFilesReference, value)) {
                selectedFiles = [this.selectedFilesReference[value]]
                this.setState({selectedFiles})
            } else {
                this.setState({loading: true})
                requestFileById(value).then(res => {
                    selectedFiles = [{uid: res.data.id, name: res.data.name, status: "done", url: res.data.url}]
                    this.setState({selectedFiles, loading: false})
                }).catch(err => {
                    console.error(`根据ID查询文件失败:${value}`, err)
                    this.setState({selectedFiles: [], loading: false})
                })
            }
        }
    }

    onFileSelect = (files) => {
        if (files === undefined || files === null || files.length < 1) {
            this.props.onChange(this.multiple ? [] : "")
        }
        files = files?.map(v => ({uid: v.id, name: v.name, status: "done", url: v.url}))
        if (this.props.result === "path") {
            files.forEach(v => this.selectedFilesReference[v.url] = v)
            this.props.onChange(this.multiple ? files?.map(v => v.url) : files[0].url)
            this.setState({selectedFiles: files})
        } else {
            files.forEach(v => this.selectedFilesReference[v.uid] = v)
            this.props.onChange(this.multiple ? files?.map(v => v.uid) : files[0].uid)
            this.setState({selectedFiles: files})
        }
        this.setState({open: false})
    }

    onFileRemove = (file) => {
        const selectedFiles = [...this.state.selectedFiles]
        let index = -1
        if (this.props.result === "path") {
            this.selectedFilesReference[file.url] = undefined
            for (let i = 0; i < selectedFiles.length; i++) {
                if (selectedFiles[i].url === file.url) {
                    index = i
                    break
                }
            }
        } else {
            this.selectedFilesReference[file.uid] = undefined
            for (let i = 0; i < selectedFiles.length; i++) {
                if (selectedFiles[i].uid === file.uid) {
                    index = i
                    break
                }
            }
        }
        selectedFiles.splice(index, 1)
        if (selectedFiles.length < 1) {
            this.props.onChange(this.multiple ? [] : "")
        } else {
            if (this.props.result === "path") {
                this.props.onChange(this.multiple ? selectedFiles.map(v => v.url) : selectedFiles[0].url)
            } else {
                this.props.onChange(this.multiple ? selectedFiles.map(v => v.uid) : selectedFiles[0].uid)
            }
        }
        this.setState({selectedFiles}, () => {
            requestDeleteFile(file.id).then(res => console.info("requestDeleteFile:" + file.id, res.data))
        })
        return true;
    }

    onOpenSelect = () => EventBus.emit(FileEvent.onTriggerFileUpload)

    render = () => (<Spin spinning={this.state.loading}>
        <Upload listType="picture-card"
                fileList={this.state.selectedFiles}
                onRemove={this.onFileRemove}
                openFileDialogOnClick={false}>
            {this.state.selectedFiles?.length >= this.props.count ? null : (
                <UploadButton onOpenSelect={this.onOpenSelect}/>)}
        </Upload>
        <UploadFileModal/>
    </Spin>)

}

AttachmentUpload.propTypes = {
    count: PropTypes.number.isRequired,
    /**
     * Antd ProFrom 将会传入此项
     */
    value: PropTypes.any,
    onChange: PropTypes.func,
    accept: PropTypes.arrayOf(PropTypes.string).isRequired,
    result: PropTypes.string.isRequired
}

AttachmentUpload.defaultProps = {
    count: 1,
    accept: ["*"],
    result: "path"
}