import React from "react"
import { Button, Space, Typography } from "antd"
import { ProDescriptions } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import PermissionWrapper from "@/framework/component/PermissionWrapper"

import DeleteCategoryButton from "@/specification/category/component/DeleteCategoryButton"
import CategoryEvent from "@/specification/category/event/CategoryEvent"
import UpdateCategoryModal from "@/specification/category/interaction/UpdateCategoryModal"
import CreateCategoryModal from "@/specification/category/interaction/CreateCategoryModal"

function CategoryDescriptionExtra({ category }) {
    return (<Space size="small">
        <PermissionWrapper permissions={["category:insert"]}>
            <Button type="link" size="small" onClick={() => CreateCategoryModal.open(category.id)}>新增子类目</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["category:insert"]}>
            <Button size="small" type="link"
                onClick={() => EventBus.emit(CategoryEvent.onTriggerUpdateCategory, category)}>
                编辑
            </Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["category:delete"]}>
            <DeleteCategoryButton id={category.id} />
        </PermissionWrapper>
    </Space>)
}

export default function CategoryDescription({ category }) {
    if (!!category) {
        return (<><ProDescriptions title="分类信息" dataSource={category}
            bordered size="small"
            extra={<CategoryDescriptionExtra category={category} />}>
            <ProDescriptions.Item label="名称" dataIndex="name" />
            <ProDescriptions.Item label="创建时间" dataIndex="createTime" valueType="fromNow" />
        </ProDescriptions>
            <PermissionWrapper permissions={["category:update"]}>
                <UpdateCategoryModal />
            </PermissionWrapper>
        </>)
    } else {
        return (<Typography.Paragraph>请选择分类</Typography.Paragraph>)
    }

}
