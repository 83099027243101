import React from "react"
import { ModalForm, ProFormText } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import { requestUpdateMonthlyStatistic } from "@/statistic/monthly/api/MonthlyStatisticAPI"
import MonthlyStatisticEvent from "@/statistic/monthly/event/MonthlyStatisticEvent"

export default class UpdateMonthlyStatisticModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(MonthlyStatisticEvent.onTriggerUpdateMonthlyStatistic, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(MonthlyStatisticEvent.onTriggerUpdateMonthlyStatistic, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({ open })
    }

    onFinish = (params) => requestUpdateMonthlyStatistic(params).then(() => {
        EventBus.emit(MonthlyStatisticEvent.onMonthlyStatisticUpdated)
        return true
    }).catch((err) => {
        console.error("编辑每月统计失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    render() {
        return (<ModalForm title="编辑每月统计"
            layout="horizontal"
            modalProps={{ destroyOnClose: true, width: 560 }}
            open={this.state.open}
            onOpenChange={this.toggle}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />
            <ProFormText name="module" label="功能模块" rules={Rule.require} />
            <ProFormText name="label" label="指标名称" rules={Rule.require} />
            <ProFormText name="year" label="年份" rules={Rule.require} />
            <ProFormText name="month" label="月份" rules={Rule.require} />
            <ProFormText name="devIdentifier" label="开发标识符" rules={Rule.require} />
            <ProFormText name="bizIdentifier" label="业务标识符" rules={Rule.require} />
            <ProFormText name="comment" label="备注" rules={Rule.require} />
            <ProFormText name="statisticValue" label="统计值" rules={Rule.require} />
        </ModalForm>)
    }
}