import SecurityUtils from "@/framework/util/SecurityUtils";
import AttachmentSelect from "./AttachmentSelect";
import AttachmentUpload from "./AttachmentUpload";
import PropTypes from "prop-types";

export default function AttachmentPicker(props) {
    if (SecurityUtils.hasPermission(["attachment-category:manage","attachment-file:manage"])) {
        return (<AttachmentSelect {...props}/>)
    } else {
        return (<AttachmentUpload {...props}/>)
    }
}

AttachmentPicker.propTypes = {
    count: PropTypes.number.isRequired,
    /**
     * Antd ProFrom 将会传入此项
     */
    value: PropTypes.any,
    onChange: PropTypes.func,
    accept: PropTypes.arrayOf(String).isRequired,
    result: PropTypes.string.isRequired
}

AttachmentPicker.defaultProps = {
    count: 1,
    accept: ["*"],
    result: "path"
}