import React from "react"
import {Row, Col} from "antd"
import {PageContainer, ProCard, ProDescriptions, ProList} from "@ant-design/pro-components"

import {getUser} from "@/framework/util/SecurityUtils"

export default function Overview() {

    return (<PageContainer>
        <Row gutter={[16, 16]}>
            <Col span={16}>
                <ProCard title="账户信息">
                    <ProDescriptions dataSource={getUser()}>
                        <ProDescriptions.Item label="用户名" dataIndex="name"/>
                    </ProDescriptions>
                </ProCard>
            </Col>

            <Col span={8}>
                <ProList headerTitle="通知公告">

                </ProList>
            </Col>
        </Row>
    </PageContainer>)
}