import React from "react"
import PropTypes from "prop-types"
import { Button, message } from "antd"
import { DrawerForm, ProFormText } from "@ant-design/pro-components"
import Quill from 'quill'
import 'quill/dist/quill.snow.css';

import EventBus from "@/framework/util/EventBusUtils"

import { requestCreateHelpItem } from "../api/HelpItemAPI"
import HelpEvent from "../event/HelpEvent"
import Rule from "@/framework/form/Rule"

export default class CreateHelpItemDrawer extends React.Component {

    state = { open: true }

    quill

    onOpenChange = (open) => {
        if (open) {
            this.quill = new Quill("#editor", { theme: "snow" })
        }
    }

    onFinish = (params) => {
        // params.content = this.quill?.getText()
        params.content = this.quill.root.innerHTML
        return requestCreateHelpItem(params).then(res => {
            EventBus.emit(HelpEvent.onHelpItemCreated, res.data)
            return true
        }).catch(err => {
            message.warning(err.response?.data, 2).then()
            return false
        })
    }

    render() {
        return (<DrawerForm title="新建帮助说明细则"
            trigger={this.props.trigger}
            drawerProps={{ destroyOnClose: true }}
            onOpenChange={this.onOpenChange}
            onFinish={this.onFinish}>

            <ProFormText label="分类" name="category" initialValue={this.props.category?.id} hidden />
            <ProFormText label="标题" name="title" rules={Rule.require} />
            <div id="editor" style={{ height: 480 }}></div>
        </DrawerForm>)
    }

}

CreateHelpItemDrawer.propTypes = {
    trigger: PropTypes.element.isRequired
}

CreateHelpItemDrawer.defaultProps = {
    trigger: (<Button>新建帮助说明细则</Button>)
}