/**
 * 新建文章
 */
export const requestCreateArticle = (params) => window.axios.post("/content/article", params)

/**
 * 分页查询文章
 * @param params 查询参数
 */
export const requestArticleByPage = (params) => window.axios.get("/content/article/page", {params})

/**
 * 查询文章
 * @param params 查询参数
 */
export const requestArticleByList = (params) => window.axios.get("/content/article/list", {params})

/**
 * 查询指定ID文章
 * @param id {string} 文章ID
 */
export const requestArticleById = (id) => window.axios.get(`/content/article/${id}`)

/**
 * 更新文章
 */
export const requestUpdateArticle = (params) => window.axios.patch(`/content/article/${params.id}`, params)

/**
 * 删除文章
 */
export const requestDeleteArticle = (id) => window.axios.delete(`/content/article/${id}`)
