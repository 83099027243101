import React from "react"
import {
    DrawerForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormField,
    ProFormRadio,
    ProFormSelect,
    ProFormText,
    ProFormTreeSelect
} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"
import SecurityUtils from "@/framework/util/SecurityUtils"

import { requestUpdateUser } from "@/member/user/api/UserAPI"
import UserEvent from "@/member/user/event/UserEvent"
import { getAvailableEnumAsOptions } from "@/framework/enum/AvailableEnum"
import { requestPostByList } from "@/member/post/api/PostAPI"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils"
import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker"

export default class UpdateUserDrawer extends React.Component {

    state = {
        visible: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(UserEvent.onTriggerUpdateUser, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(UserEvent.onTriggerUpdateUser, console.log)
    }

    onFinish = (params) => {
        params.post = params.post === undefined ? "0" : params.post
        params.department = params.department === undefined ? "0" : params.department
        return requestUpdateUser(params).then(() => {
            EventBus.emit(UserEvent.onUserUpdated, params)
            return true
        }).catch((err) => {
            console.warn("编辑用户失败", err)
            FormUtils.setFormError(this.formRef, err.response?.data, "name")
            return false
        })
    }

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        form.post = !!form.post ? form.post.id : undefined
        form.department = !!form.department ? form.department.id : undefined
        this.setState({ visible: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 150)
        })
    }

    render() {
        return (<DrawerForm title="编辑用户"
            open={this.state.visible}
            onOpenChange={(visible) => this.setState({ visible })}
            drawerProps={{ width: 560, desitroyOnClose: true }}
            formRef={this.formRef}
            scrollToFirstError
            isKeyPressSubmit
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />

            <ProFormField label="头像" name="avatar">
                <AttachmentPicker count={1} accept={["image/png", "image/jpeg"]} />
            </ProFormField>

            <ProFormText width="md" name="name" label="姓名"
                rules={Rule.requireText} />

            <ProFormSelect width="xs" name="type" label="用户类型"
                request={() => DictionaryVariableUtils.requestDvByList("user-type")} />

            <ProFormSelect width="xs" name="grade" label="用户级别"
                request={() => DictionaryVariableUtils.requestDvByList("user-grade")} />

            <ProFormRadio.Group name="available" label="可用状态"
                disabled={!SecurityUtils.hasPermission(["user:manage"])}
                rules={Rule.require}
                options={getAvailableEnumAsOptions()} />

            <ProFormTreeSelect width="md" name="department" label="单位"
                disabled={!SecurityUtils.hasSuperPermission()}
                rules={Rule.require}
                fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                request={() => requestDepartmentByTree().then(res => res.data).catch(() => [])} />

            <ProFormDependency name={["department"]}>
                {({ department }) => (<ProFormSelect width="sm" name="post" label="岗位"
                    params={{ department }}
                    fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                    request={(params) => requestPostByList(params).then(res => res.data).catch(() => [])} />)}
            </ProFormDependency>

            <ProFormText width="sm" name="contract" label="联系方式" />

            <ProFormSelect width="xs" name="status" label="状态"
                request={() => DictionaryVariableUtils.requestDvByList('user-status')} />

            <ProFormSelect width="xs" name="policy" label="政治面貌"
                request={() => DictionaryVariableUtils.requestDvByList('policy')} />

            <ProFormDatePicker width="sm" name="joinPartyDate" label="入党日期" />

            <ProFormSelect width="xs" name="nation" label="民族"
                request={() => DictionaryVariableUtils.requestDvByList('nation')} />

            <ProFormSelect width="sm" name="nativePlace" label="籍贯"
                request={() => DictionaryVariableUtils.requestDvByList('native-place')} />

            <ProFormText width="md" name="school" label="毕业院校" rules={Rule.text} />

            <ProFormText width="md" name="major" label="专业" rules={Rule.text} />

            <ProFormSelect width="xs" name="education" label="学历"
                request={() => DictionaryVariableUtils.requestDvByList('education')} />

            <ProFormSelect width="xs" name="degree" label="学位"
                request={() => DictionaryVariableUtils.requestDvByList('degree')} />

            <ProFormDatePicker width="sm" name="birthday" label="出生日期" />

        </DrawerForm>)
    }
}

UpdateUserDrawer.open = (user) => {
    EventBus.emit(UserEvent.onTriggerUpdateUser, user)
}