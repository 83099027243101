import React from "react"
import { ModalForm, ProFormDependency, ProFormSelect, ProFormText, ProFormTreeSelect } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import { requestUpdateWarehouse } from "@/warehouse/warehouse/api/WarehouseAPI"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"
import { requestUserByList } from "@/member/user/api/UserAPI"

import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils"
import WarehouseEvent from "@/warehouse/warehouse/event/WarehouseEvent"

export default class UpdateWarehouseModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(WarehouseEvent.onTriggerUpdateWarehouse, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(WarehouseEvent.onTriggerUpdateWarehouse, console.log)
    }

    onFinish = (params) => requestUpdateWarehouse(params).then(() => {
        EventBus.emit(WarehouseEvent.onWarehouseUpdated)
        return true
    }).catch((err) => {
        console.error("编辑仓库失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    onTrigger = (item) => this.setState({ open: true }, () => {
        const form = JSON.parse(JSON.stringify(item))
        form.environment = form.environment.split(",")
        setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
    })

    render() {
        return (<ModalForm title="编辑仓库"
            modalProps={{ destroyOnClose: true, width: 560 }}
            open={this.state.open}
            onOpenChange={open => this.setState({ open })}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />

            <ProFormText width="sm" name="name" label="名称" rules={Rule.requireText} />
            <ProFormText width="lg" name="address" label="地址" rules={Rule.text} />

            <ProFormTreeSelect width="sm" name="department" label="单位"
                fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                request={() => requestDepartmentByTree().then(res => res.data).catch(() => [])}
                rules={Rule.require} />

            <ProFormDependency name={['department']}>
                {
                    ({ department }) => (<ProFormSelect width="sm" name="manager" label="管理员"
                        params={{ department }}
                        fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                        request={({ department }) => requestUserByList({ department, exactDepartment: 1 }).then(res => res.data).catch(() => [])}
                        rules={Rule.require} />)
                }
            </ProFormDependency>

            <ProFormSelect width="md" name="environment" label="仓库存储环境"
                mode="tags"
                request={() => DictionaryVariableUtils.requestDvByList("warehouse-environment")} />
            <ProFormSelect width="sm" name="type" label="仓库类型"
                request={() => DictionaryVariableUtils.requestDvByList("warehouse-type")} />
        </ModalForm>)
    }
}