import React from "react";
import {Button, Space} from "antd";
import {ProTable} from "@ant-design/pro-components";

import PermissionWrapper from "../../../framework/component/PermissionWrapper";
import EventBus from "../../../framework/util/EventBusUtils";

import {requestCooperativePartnerByPage} from "../api/PartnerAPI";
import CooperativePartnerEvent from "../event/CooperativePartnerEvent";
import DeleteCooperativePartnerButton from "./DeleteCooperativePartnerButton";
import CreateCooperativePartnerDrawer from "../interaction/CreateCooperativePartnerDrawer";
import UpdateCooperativePartnerDrawer from "../interaction/UpdateCooperativePartnerDrawer";
import ColumnForUserAvatar from "@/member/user/component/column/ColumnForUserAvatar";
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils";

const CooperativePartnerDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["association-partner:insert"]}>
        <CreateCooperativePartnerDrawer/>
    </PermissionWrapper>
</Space>)

const CooperativePartnerDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["association-partner:update"]}>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(CooperativePartnerEvent.onTriggerUpdateCooperativePartner, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["association-partner:delete"]}>
        <DeleteCooperativePartnerButton id={props.item.id}/>
    </PermissionWrapper>
</Space>)

export default class CooperativePartnerDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "头像",
            dataIndex: "avatar",
            width: 88,
            align: "center",
            hideInSearch: true,
            render: (_, v ) => (<ColumnForUserAvatar user={v}/>)
        },
        {
            title: "姓名",
            dataIndex: "name"
        },
        {
            title: "单位",
            dataIndex: "department"
        },
        {
            title: "手机号码",
            dataIndex: "cellphone"
        },
        {
            title: "岗位",
            dataIndex: "post"
        },
        {
            title: "描述",
            dataIndex: "description",
            hideInSearch: true
        },
        {
            title: "海报",
            dataIndex: "posters",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "类型",
            dataIndex: "type",
            valueType: "select",
            request: () => DictionaryVariableUtils.requestDvByList("partner-type")
        },
        {
            title: "排序",
            dataIndex: "sort",
            hideInSearch: true
        },
        {
            title: "创建人",
            dataIndex: "creator",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "修改人",
            dataIndex: "modifier",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<CooperativePartnerDataTableColumnOperations item={item}/>)
        }
    ]

    componentDidMount() {
        EventBus.on(CooperativePartnerEvent.onCooperativePartnerCreated, this.tableActionRef.current?.reload)
        EventBus.on(CooperativePartnerEvent.onCooperativePartnerUpdated, this.tableActionRef.current?.reload)
        EventBus.on(CooperativePartnerEvent.onCooperativePartnerDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(CooperativePartnerEvent.onCooperativePartnerCreated, console.log)
        EventBus.off(CooperativePartnerEvent.onCooperativePartnerUpdated, console.log)
        EventBus.off(CooperativePartnerEvent.onCooperativePartnerDeleted, console.log)
    }

    onRequestCooperativePartnerPage = (params) => requestCooperativePartnerByPage(params).then(res => res.data).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      columns={this.columns}
                      actionRef={this.tableActionRef}
                      toolBarRender={() => (<CooperativePartnerDataTableOperations/>)}
                      request={this.onRequestCooperativePartnerPage}/>
            <PermissionWrapper permissions={["association-partner:update"]}>
                <UpdateCooperativePartnerDrawer/>
            </PermissionWrapper>
        </>);
    }

}