/**
 * 新增岗位
 */
export const requestCreatePost = (params) => window.axios.post("/member/post", params)

/**
 * 分页查询岗位
 * @param params 查询参数
 */
export const requestPostByPage = (params) => window.axios.get("/member/post/page", { params })

/**
 * 查询岗位
 * @param params 查询参数
 */
export const requestPostByList = (params) => window.axios.get("/member/post/list", { params })

/**
 * 查询指定ID岗位
 * @param id {string} 岗位ID
 */
export const requestPostById = (id) => window.axios.get(`/member/post/${id}`)

/**
 * 更新岗位
 */
export const requestUpdatePost = (params) => window.axios.patch(`/member/post/${params.id}`, params)

/**
 * 删除岗位
 */
export const requestDeletePost = (id) => window.axios.delete(`/member/post/${id}`)
