import React from "react"
import { Button, Space } from "antd"
import { ProTable } from "@ant-design/pro-components"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestMonthlyStatisticByPage } from "@/statistic/monthly/api/MonthlyStatisticAPI"
import MonthlyStatisticEvent from "@/statistic/monthly/event/MonthlyStatisticEvent"
import DeleteMonthlyStatisticButton from "./DeleteMonthlyStatisticButton"
import CreateMonthlyStatisticModal from "@/statistic/monthly/interaction/CreateMonthlyStatisticModal"
import UpdateMonthlyStatisticModal from "@/statistic/monthly/interaction/UpdateMonthlyStatisticModal"

const MonthlyStatisticDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["statistic:insert"]}>
        <CreateMonthlyStatisticModal />
    </PermissionWrapper>
</Space>)

const MonthlyStatisticDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["statistic:update"]}>
        <Button type="link" size="small"
            onClick={() => EventBus.emit(MonthlyStatisticEvent.onTriggerUpdateMonthlyStatistic, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["statistic:delete"]}>
        <DeleteMonthlyStatisticButton id={props.item.id} />
    </PermissionWrapper>
</Space>)

export default class MonthlyStatisticDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "功能模块",
            dataIndex: "module"
        },
        {
            title: "指标名称",
            dataIndex: "label"
        },
        {
            title: "年份",
            dataIndex: "year"
        },
        {
            title: "月份",
            dataIndex: "month"
        },
        {
            title: "开发标识符",
            dataIndex: "devIdentifier"
        },
        {
            title: "业务标识符",
            dataIndex: "bizIdentifier"
        },
        {
            title: "创建人",
            dataIndex: "creator"
        },
        {
            title: "创建时间",
            dataIndex: "createTime"
        },
        {
            title: "修改人",
            dataIndex: "modifier"
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime"
        },
        {
            title: "备注",
            dataIndex: "comment"
        },
        {
            title: "统计值",
            dataIndex: "statisticValue"
        },
        {
            title: "操作",
            valueType: "option",
            fixed: 'right',
            render: (_, item) => (<MonthlyStatisticDataTableColumnOperations item={item} />)
        }
    ]

    componentDidMount() {
        EventBus.on(MonthlyStatisticEvent.onMonthlyStatisticCreated, this.tableActionRef.current?.reload)
        EventBus.on(MonthlyStatisticEvent.onMonthlyStatisticUpdated, this.tableActionRef.current?.reload)
        EventBus.on(MonthlyStatisticEvent.onMonthlyStatisticDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(MonthlyStatisticEvent.onMonthlyStatisticCreated, console.log)
        EventBus.off(MonthlyStatisticEvent.onMonthlyStatisticUpdated, console.log)
        EventBus.off(MonthlyStatisticEvent.onMonthlyStatisticDeleted, console.log)
    }

    onRequestMonthlyStatisticPage = (params) => requestMonthlyStatisticByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                cardBordered
                columns={this.columns}
                actionRef={this.tableActionRef}
                toolBarRender={() => (<MonthlyStatisticDataTableOperations />)}
                request={this.onRequestMonthlyStatisticPage} />
            <PermissionWrapper permissions={["statistic:update"]}>
                <UpdateMonthlyStatisticModal />
            </PermissionWrapper>
        </>)
    }

}