import React from "react"
import { Button, Space, Tag } from "antd"
import { ProTable } from "@ant-design/pro-components"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestWarehouseByPage } from "@/warehouse/warehouse/api/WarehouseAPI"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"

import WarehouseEvent from "@/warehouse/warehouse/event/WarehouseEvent"
import DeleteWarehouseButton from "@/warehouse/warehouse/component/DeleteWarehouseButton"
import CreateWarehouseModal from "@/warehouse/warehouse/interaction/CreateWarehouseModal"
import UpdateWarehouseModal from "@/warehouse/warehouse/interaction/UpdateWarehouseModal"
import { requestUserByList } from "@/member/user/api/UserAPI"
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils"

const WarehouseDataTableColumnState = {
    persistenceKey: "WarehouseDataTableColumnState",
    persistenceType: "localStorage",
    defaultValue: {
        id: { show: false },
        createTime: { show: true },
        modifyTime: { show: false }
    }
}

const WarehouseDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["warehouse:insert"]}>
        <CreateWarehouseModal />
    </PermissionWrapper>
</Space>)

const WarehouseDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["warehouse:update"]}>
        <Button type="link" size="small"
            onClick={() => EventBus.emit(WarehouseEvent.onTriggerUpdateWarehouse, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["warehouse:delete"]}>
        <DeleteWarehouseButton id={props.item.id} />
    </PermissionWrapper>
</Space>)

const WarehouseDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "名称",
        dataIndex: "name"
    },
    {
        title: "地址",
        dataIndex: "address",
        hideInSearch: true
    },
    {
        title: "单位",
        dataIndex: "department",
        valueType: "treeSelect",
        fieldProps: { fieldNames: { label: "name", value: "id" } },
        request: () => requestDepartmentByTree().then(res => res.data).catch(() => [])
    },
    {
        title: "管理员",
        dataIndex: "manager",
        valueType: "select",
        fieldProps: { fieldNames: { label: "name", value: "id" } },
        request: () => requestUserByList().then(res => res.data).catch(() => [])
    },
    {
        title: "存储环境",
        dataIndex: "environment",
        hideInSearch: true,
        render: (_, v) => v.environment.split(",").map((t, i) => (<Tag key={i}>{t}</Tag>))
    },
    {
        title: "类型",
        dataIndex: "type",
        valueType: "select",
        request: () => DictionaryVariableUtils.requestDvByList("warehouse-type")
    },
    {
        title: "创建人",
        dataIndex: "creator",
        hideInSearch: true,
        hideInTable: true
    },
    {
        title: "创建时间",
        dataIndex: "createTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "修改人",
        dataIndex: "modifier",
        hideInSearch: true,
        hideInTable: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<WarehouseDataTableColumnOperations item={item} />)
    }
]

export default class WarehouseDataTable extends React.Component {

    actionRef = React.createRef()

    componentDidMount() {
        EventBus.on(WarehouseEvent.onWarehouseCreated, () => this.actionRef.current?.reload())
        EventBus.on(WarehouseEvent.onWarehouseUpdated, () => this.actionRef.current?.reload())
        EventBus.on(WarehouseEvent.onWarehouseDeleted, () => this.actionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(WarehouseEvent.onWarehouseCreated, () => console.log("EventOff", WarehouseEvent.onWarehouseCreated))
        EventBus.off(WarehouseEvent.onWarehouseUpdated, () => console.log("EventOff", WarehouseEvent.onWarehouseUpdated))
        EventBus.off(WarehouseEvent.onWarehouseDeleted, () => console.log("EventOff", WarehouseEvent.onWarehouseDeleted))
    }

    onRequestWarehousePage = (params) => requestWarehouseByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                columns={WarehouseDataTableColumns}
                columnsState={WarehouseDataTableColumnState}
                actionRef={this.actionRef}
                toolBarRender={() => (<WarehouseDataTableOperations />)}
                request={this.onRequestWarehousePage} />
            <PermissionWrapper permissions={["warehouse:update"]}>
                <UpdateWarehouseModal />
            </PermissionWrapper>
        </>)
    }

}