import React from "react";
import {Modal, Space} from "antd";
import EventBus from "../../framework/util/EventBusUtils";
import WorkflowEvent from "../event/WorkflowEvent";

import ProcessInstanceTaskDescription from "../component/description/ProcessInstanceTaskDescription";
import ProcessBpmn from "../component/ProcessBpmn";
import ProcessInstanceStartFormDescription from "../component/description/ProcessInstanceStartFormDescription";

export default class PreviewProcessTaskModal extends React.Component {

    state = {
        task: {},
        open: false
    }

    componentDidMount() {
        EventBus.on(WorkflowEvent.onTriggerPreviewProcessInstanceTaskEvent, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onTriggerPreviewProcessInstanceTaskEvent, console.log)
    }

    onTrigger = (task) => this.setState({task, open: true})

    render() {
        return (<Modal title="查看任务"
                       open={this.state.open}
                       width={800}
                       destroyOnClose
                       onOk={() => this.setState({open: false})}
                       onCancel={() => this.setState({open: false})}>
            <Space direction="vertical" size="middle" style={{display: 'flex'}}>
                <ProcessBpmn processDefinitionId={this.state.task.processDefinitionId}
                             processInstanceId={this.state.task.processInstanceId}
                             taskDefinitionKey={this.state.task.taskDefinitionKey}/>
                <ProcessInstanceTaskDescription task={this.state.task}
                                                bordered title="任务信息" size="small"/>
                <ProcessInstanceStartFormDescription processDefinitionId={this.state.task.processDefinitionId}
                                                     processInstanceId={this.state.task.processInstanceId}
                                                     bordered title="流程记录信息" size="small"/>
            </Space>
        </Modal>)
    }

}

PreviewProcessTaskModal.open = (task) => {
    EventBus.emit(WorkflowEvent.onTriggerPreviewProcessInstanceTaskEvent, task)
}