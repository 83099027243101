import React from "react"
import { ModalForm, ProFormRadio, ProFormText } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import { requestUpdateRole } from "@/member/role/api/RoleAPI"
import RoleEvent from "@/member/role/event/RoleEvent"
import { getAvailableEnumAsOptions } from "@/framework/enum/AvailableEnum"

export default class UpdateRoleModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(RoleEvent.onTriggerUpdateRole, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(RoleEvent.onTriggerUpdateRole, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({ open })
    }

    onFinish = (params) => requestUpdateRole(params).then(() => {
        EventBus.emit(RoleEvent.onRoleUpdated)
        this.formRef.current?.resetFields()
        return true
    }).catch((err) => {
        console.error("编辑角色失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    render() {
        return (<ModalForm title="编辑角色"
            isKeyPressSubmit
            scrollToFirstError
            modalProps={{ width: 560, destroyOnClose: true }}
            open={this.state.open}
            onOpenChange={this.toggle}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />

            <ProFormText width="md" name="name" label="名称" rules={Rule.requireText} />

            <ProFormText width="md" name="identifier" label="标识符" disabled tooltip="此项不可编辑" />

            <ProFormRadio.Group width="md" name="available" label="状态" rules={Rule.require}
                initialValue="可用" options={getAvailableEnumAsOptions()} />

        </ModalForm>)
    }

}