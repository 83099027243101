import React from "react";
import {useNavigate} from "react-router-dom";
import {PageContainer, ProCard, ProForm, ProFormText, ProFormTextArea} from "@ant-design/pro-components";

import {requestCreateCodeTemplate} from "./api/CodeTemplateAPI";
import FormUtils from "@/framework/util/FormUtils";
import Rule from "@/framework/form/Rule";

export default function CreateCodeTemplate() {

    const navigate = useNavigate()
    const formRef = React.useRef()

    const onFinish = (params) => requestCreateCodeTemplate(params).then(() => {
        navigate(-1)
        return true
    }).catch(err => {
        FormUtils.setFormError(formRef, err.response?.data, "name")
        return false
    })

    return (<PageContainer header={{onBack: () => navigate(-1)}}>
        <ProCard>
            <ProForm formRef={formRef}
                     onFinish={onFinish}
                     layout="horizontal"
                     labelCol={{span: 2}}
                     wrapperCol={{span: 22}}>
                <ProFormText width="md" name="name" label="名称" rules={Rule.requireText}/>
                <ProFormText width="lg" name="description" label="描述" rules={Rule.text}/>
                <ProFormTextArea name="content" label="模版内容" fieldProps={{rows: 18}} rules={Rule.require}
                                 tooltip="建议使用专业的代码编辑器编写模版内容"/>
            </ProForm>
        </ProCard>
    </PageContainer>)

}