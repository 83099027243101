import React from "react"
import { ModalForm, ProFormSelect, ProFormText } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import UserEvent from "@/member/user/event/UserEvent"
import FormUtils from "@/framework/util/FormUtils"

import { getAuthenticationTypeEnumAsOptions } from "@/member/user/common/AuthenticationTypeEnum"
import { getAuthenticationMethodEnumAsOptions } from "@/member/user/common/AuthenticationMethodEnum"
import { requestUpdateAuthentication } from "@/member/user/api/AuthenticationAPI"

export default class UpdateAuthenticationModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false
    }

    componentDidMount() {
        EventBus.on(UserEvent.onTriggerUpdateAuthentication, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(UserEvent.onTriggerUpdateAuthentication, console.log)
    }

    onOpenChange = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({ open })
    }

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    onFinish = (params) => requestUpdateAuthentication(params).then(res => {
        EventBus.emit(UserEvent.onAuthenticationUpdated, res.data)
        this.formRef.current?.resetFields()
        return true
    }).catch(err => {
        FormUtils.setFormError(this.formRef, err.response?.data, "authentication")
        return false
    })

    render() {
        return (<ModalForm title="编辑用户"
            open={this.state.open}
            onOpenChange={this.onOpenChange}
            grid
            formRef={this.formRef}
            rowProps={{ gutter: [16, 0] }}
            scrollToFirstError
            modalProps={{ destroyOnClose: true, width: 480 }}
            onFinish={this.onFinish}>

            <ProFormText colProps={{ span: 0 }} name="id" label="ID" hidden />

            <ProFormText colProps={{ span: 24 }} name="authentication" label="认证凭证"
                rules={[
                    { required: true, message: "请输入认证凭证" },
                    { min: 4, message: "认证凭证长度应在[4,24]" },
                    { max: 24, message: "认证凭证长度应在[4,24]" }
                ]} />

            <ProFormText.Password colProps={{ span: 24 }} name="credential" label="认证凭据"
                rules={[
                    { min: 6, message: "认证凭据长度应在[6,32]" },
                    { max: 32, message: "认证凭据长度应在[6,32]" }
                ]} />

            <ProFormSelect colProps={{ span: 12 }} name="type" label="认证类型"
                options={getAuthenticationTypeEnumAsOptions()}
                rules={[{ required: true, message: "请选择认证类型" }]} />

            <ProFormSelect colProps={{ span: 12 }} name="authenticationMethod" label="认证方式"
                options={getAuthenticationMethodEnumAsOptions()}
                rules={[{ required: true, message: "请选择认证类型" }]} />

        </ModalForm>)
    }

}