import React from "react";
import {Avatar, Button, Image, Space} from "antd";
import {ProTable} from "@ant-design/pro-components";

import PermissionWrapper from "@/framework/component/PermissionWrapper";
import EventBus from "@/framework/util/EventBusUtils";

import {requestAnnouncementByPage} from "../api/AnnouncementAPI";
import AnnouncementEvent from "../event/AnnouncementEvent";
import DeleteAnnouncementButton from "./DeleteAnnouncementButton";
import CreateAnnouncementModal from "../interaction/CreateAnnouncementModal";
import UpdateAnnouncementModal from "../interaction/UpdateAnnouncementModal";

const AnnouncementDataTableColumnState = {
    persistenceKey: "ColumnStateForAnnouncement",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        modifyTime: {show: false}
    }
}

const AnnouncementDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["announcement:insert"]}>
        <CreateAnnouncementModal/>
    </PermissionWrapper>
</Space>)

const AnnouncementDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["announcement:update"]}>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(AnnouncementEvent.onTriggerUpdateAnnouncement, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["announcement:delete"]}>
        <DeleteAnnouncementButton id={props.item.id}/>
    </PermissionWrapper>
</Space>)

const AnnouncementDataTableColumnForCover = (props) => {
    if (!!props.item.cover) {
        return <Avatar shape="square" src={<Image src={props.item.cover}/>}/>
    } else {
        return "-"
    }
}

const AnnouncementDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        copyable: true,
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "封面",
        dataIndex: "cover",
        hideInSearch: true,
        align: "center",
        render: (_, item) => (<AnnouncementDataTableColumnForCover item={item}/>)
    },
    {
        title: "标题",
        dataIndex: "title",
        copyable: true,
        ellipsis: true
    },
    {
        title: "副标题",
        dataIndex: "subtitle",
        ellipsis: true
    },
    {
        title: "公告内容",
        dataIndex: "content",
        hideInSearch: true,
        ellipsis: true
    },
    {
        title: "创建时间",
        dataIndex: "createTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<AnnouncementDataTableColumnOperations item={item}/>)
    }
]

export default class AnnouncementDataTable extends React.Component {

    actionRef = React.createRef()

    componentDidMount() {
        EventBus.on(AnnouncementEvent.onAnnouncementCreated, () => this.actionRef.current?.reload())
        EventBus.on(AnnouncementEvent.onAnnouncementUpdated, () => this.actionRef.current?.reload())
        EventBus.on(AnnouncementEvent.onAnnouncementDeleted, () => this.actionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(AnnouncementEvent.onAnnouncementCreated, console.log)
        EventBus.off(AnnouncementEvent.onAnnouncementUpdated, console.log)
        EventBus.off(AnnouncementEvent.onAnnouncementDeleted, console.log)
    }

    request = (params) => requestAnnouncementByPage(params).then(res => {
        return res.data
    }).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      scroll={{x: "max-content"}}
                      columns={AnnouncementDataTableColumns}
                      columnsState={AnnouncementDataTableColumnState}
                      actionRef={this.actionRef}
                      toolBarRender={() => (<AnnouncementDataTableOperations/>)}
                      request={this.request}/>
            <PermissionWrapper permissions={["announcement:update"]}>
                <UpdateAnnouncementModal/>
            </PermissionWrapper>
        </>);
    }

}