import React from "react"
import { ModalForm, ProFormRadio, ProFormText, ProFormTextArea, ProFormTreeSelect } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"
import SecurityUtils from "@/framework/util/SecurityUtils"

import DepartmentEvent from "@/member/department/event/DepartmentEvent"
import { getLeafEnumAsOptions } from "@/member/department/common/LeafEnum"
import { requestCreateDepartment, requestDepartmentByTree } from "../api/DepartmentAPI"

export default class CreateDepartmentModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false
    }

    componentDidMount() {
        EventBus.on(DepartmentEvent.onTriggerDepartmentCreate, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(DepartmentEvent.onTriggerDepartmentCreate, console.log)
    }

    onTrigger = (parent) => this.setState({ open: true }, () => {
        setTimeout(() => this.formRef.current?.setFieldsValue({ parent }), 100)
    })

    onFinish = (params) => requestCreateDepartment(params).then(() => {
        EventBus.emit(DepartmentEvent.onDepartmentCreated)
        this.formRef.current?.resetFields()
        return true
    }).catch(err => {
        FormUtils.setFormError(this.formRef, err.response.data, "name")
        return false
    })

    onRequestParentTree = (params) => requestDepartmentByTree(params).then(res => {
        if (SecurityUtils.hasSuperPermission()) {
            res.data.push({ name: "顶层单位（无父单位）", id: "0", disabled: false })
        }
        for (let datum of res.data) {
            this.handleParentOptions(datum)
        }
        return res.data
    }).catch(() => {
        return []
    })

    handleParentOptions = (treeNode) => {
        treeNode.disabled = treeNode.leaf === "是"
        if (treeNode.children !== undefined && treeNode.children !== null && treeNode.children.length > 0) {
            for (let child of treeNode.children) {
                this.handleParentOptions(child)
            }
        }
    }

    render() {
        return (<ModalForm title="新增单位"
            modalProps={{ width: 560, destroyOnClose: true }}
            trigger={undefined}
            open={this.state.open}
            formRef={this.formRef}
            onOpenChange={open => this.setState({ open })}
            onFinish={this.onFinish}>

            <ProFormTreeSelect width="md" name="parent" label="父单位" rules={Rule.require}
                fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                request={this.onRequestParentTree} />

            <ProFormText width="md" name="name" label="名称" rules={Rule.requireText} />
            <ProFormRadio.Group width="md" name="leaf" label="叶子节点" rules={Rule.require} initialValue="否"
                options={getLeafEnumAsOptions()} />
            <ProFormTextArea name="description" label="描述" rules={Rule.text} />
        </ModalForm>)
    }

}

CreateDepartmentModal.open = (parent) => {
    EventBus.emit(DepartmentEvent.onTriggerDepartmentCreate, parent)
}