import {ProDescriptions} from "@ant-design/pro-components";
import {requestUserById} from "@/member/user/api/UserAPI";

export default function ProcessInstanceDescription({processInstance, ...restProps}) {

    const columns = [
        {
            title: "流程名称",
            dataIndex: "processDefinitionName",
            ellipsis: true
        },
        {
            title: "发起人",
            dataIndex: "startUserId",
            valueType: "select",
            fieldProps: {fieldNames: {label: "name", value: "id"}},
            request: (_, v) => requestUserById(v.text).then(res => [res.data]).catch(() => v.text)
        },
        {
            title: "状态",
            dataIndex: "state",
            valueEnum: {
                "COMPLETED": {text: "已完成", status: "success"},
                "ACTIVE": {text: "进行中", status: "processing"},
                "INTERNALLY_TERMINATED": {text: "用户终止", status: "error"}
            }
        },
        {
            title: "发起时间",
            dataIndex: "startTime",
            valueType: "dateTime"
        },
        {
            title: "结束时间",
            dataIndex: "endTime",
            valueType: "dateTime"
        },
    ]

    if (!processInstance) {
        return undefined
    }

    return (<ProDescriptions columns={columns} dataSource={processInstance} {...restProps}/>)

}