import React from "react"
import {ModalForm, ProFormRadio, ProFormText} from "@ant-design/pro-components"
import {Button} from "antd"
import {PlusOutlined} from "@ant-design/icons"
import {requestCreateCategory} from "../api/CategoryAPI"
import {getAvailableEnumAsOptions} from "@/framework/enum/AvailableEnum"
import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import CategoryEvent from "../event/CategoryEvent";
import Rule from "@/framework/form/Rule";

export default function CreateCategoryModal() {

    const formRef = React.useRef()

    const trigger = (<Button icon={<PlusOutlined/>} type="primary">新增分类</Button>)

    const onFinish = (params) => requestCreateCategory(params).then(res => {
        EventBus.emit(CategoryEvent.onCategoryCreated, res.data)
        formRef.current?.resetFields()
        return true
    }).catch(err => {
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增分类"
                       modalProps={{destroyOnClose: true, width: 560}}
                       formRef={formRef}
                       trigger={trigger}
                       onFinish={onFinish}>

        <ProFormText width="sm" name="name" label="名称" rules={Rule.requireText}/>

        <ProFormRadio.Group width="sm" name="available" label="状态"
                            fieldProps={{defaultValue: "可用"}}
                            options={getAvailableEnumAsOptions()}/>

    </ModalForm>)

}