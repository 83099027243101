import React from "react";
import {ProFormTextArea} from "@ant-design/pro-components";
import {requestMatchCodeTemplate} from "../api/CodeTemplateAPI";

export default function CodeTemplate(props) {

    const [content, setContent] = React.useState("加载中...")

    const onRequestMatchCodeTemplate = () => {
        requestMatchCodeTemplate(props.template, props.codeTable).then(res => {
            setContent(res.data)
        }).catch(err => {
            setContent(err)
        })
    }

    React.useEffect(onRequestMatchCodeTemplate, [props.codeTable, props.template])

    return (<ProFormTextArea fieldProps={{value: content, rows:22}}/>)

}