import React from "react"
import { Avatar, Button, Image, Space, Tag } from "antd"
import { PageContainer, ProTable } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import AvailableEnum from "@/framework/enum/AvailableEnum"
import PermissionWrapper from "@/framework/component/PermissionWrapper"

import { requestTenantByPage } from "@/member/tenant/api/TenantAPI"
import CreateTenantDrawer from "@/member/tenant/interaction/CreateTenantDrawer"
import UpdateTenantModal from "@/member/tenant/interaction/UpdateTenantModal"
import TenantEvent from "@/member/tenant/event/TenantEvent"
import DeleteTenantButton from "@/member/tenant/component/DeleteTenantButton"
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils"

const TenantDataTableColumnState = {
    persistenceKey: "ColumnStateForTenant",
    persistenceType: "localStorage",
    defaultValue: {
        id: { show: false },
        createTime: { show: false },
        modifyTime: { show: false }
    }
}
const TenantColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["tenant:update"]}>
        <Button type="link" size="small"
            onClick={() => EventBus.emit(TenantEvent.onTriggerUpdateTenant, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["tenant:delete"]}>
        <DeleteTenantButton id={props.item.id} />
    </PermissionWrapper>
</Space>)

const TenantTableOperations = () => (<Space size="middle">
    <PermissionWrapper permissions={["tenant:insert"]}>
        <CreateTenantDrawer />
    </PermissionWrapper>
</Space>)

const TenantDataTableColumnOfLogo = ({ item }) => {
    const logo = item?.logo
    if (!!logo) {
        return <Avatar size="small" src={<Image src={logo} />} />
    } else {
        return (<Avatar size="small">{item.name.slice(0, 1)}</Avatar>)
    }
}

export default class Tenants extends React.Component {

    tableActionRef = React.createRef()

    componentDidMount() {
        EventBus.on(TenantEvent.onTenantCreated, this.tableActionRef.current?.reload)
        EventBus.on(TenantEvent.onTenantUpdated, this.tableActionRef.current?.reload)
        EventBus.on(TenantEvent.onTenantDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(TenantEvent.onTenantCreated, console.log)
        EventBus.off(TenantEvent.onTenantUpdated, console.log)
        EventBus.off(TenantEvent.onTenantDeleted, console.log)
    }

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: "name",
            render: (_, v) => (<Space>
                <TenantDataTableColumnOfLogo item={v} />
                {v.name}
            </Space>)
        },
        {
            title: "类型",
            dataIndex: "type",
            hideInSearch: true,
            render: (_, v) => (<Tag color={v.type === "永久租户" ? "blue" : "yellow"}>{v.type}</Tag>)
        },
        {
            title: "有效期",
            dataIndex: "shelfLife",
            valueType: "fromNow",
            hideInSearch: true
        },
        {
            title: "管理员",
            dataIndex: ["manager", "name"],
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "行业",
            dataIndex: "trade",
            valueType: "select",
            request: () => DictionaryVariableUtils.requestDvByList("trade")
        },
        {
            title: "状态",
            dataIndex: "available",
            valueEnum: AvailableEnum,
            width: 72
        },
        {
            title: "创建时间",
            valueType: "fromNow",
            width: 128,
            hideInSearch: true
        },
        {
            title: "修改时间",
            valueType: "fromNow",
            width: 128,
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            width: 128,
            align: "center",
            fixed: "right",
            render: (_, item) => <TenantColumnOperations item={item} />
        }
    ]

    request = (params) => requestTenantByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return <PageContainer>
            <ProTable rowKey="id"
                scroll={{ x: "max-content" }}
                columnsState={TenantDataTableColumnState}
                actionRef={this.tableActionRef}
                columns={this.columns}
                toolBarRender={() => (<TenantTableOperations />)}
                request={this.request} />

            <PermissionWrapper permissions={["tenant:update"]}>
                <UpdateTenantModal />
            </PermissionWrapper>

        </PageContainer>
    }

}