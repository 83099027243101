import React from "react"
import { useParams } from "react-router-dom"
import { PageContainer } from "@ant-design/pro-components"
import dayjs from "dayjs"

import EventBus from "@/framework/util/EventBusUtils"
import NotfoundWrapper from "@/framework/component/NotfoundWrapper"
import PermissionWrapper from "@/framework/component/PermissionWrapper"

import { requestUserById } from "@/member/user/api/UserAPI"
import TabForUserAuthentication from "@/member/user/component/tab/TabForUserAuthentication"
import TabForUserRole from "@/member/user/component/tab/TabForUserRole"
import TabForUserInfo from "@/member/user/component/tab/TabForUserInfo"
import UserEvent from "@/member/user/event/UserEvent"
import UpdateUserDrawer from "@/member/user/interaction/UpdateUserDrawer"

class UserDetailPage extends React.Component {

    state = {
        id: undefined,
        notfound: true,
        error: "",
        loading: true,
        user: {},
        tab: "0"
    }


    tabList = [{ key: "0", label: "基础信息" }, { key: "1", label: "认证信息" }, { key: "2", label: "角色信息" }]

    componentDidMount() {
        this.request()
        EventBus.on(UserEvent.onUserRoleUpdated, this.request)
        EventBus.on(UserEvent.onAuthenticationCreated, this.request)
        EventBus.on(UserEvent.onAuthenticationUpdated, this.request)
        EventBus.on(UserEvent.onAuthenticationDeleted, this.request)
    }

    componentWillUnmount() {
        EventBus.off(UserEvent.onUserRoleUpdated, console.log)
        EventBus.off(UserEvent.onAuthenticationCreated, console.log)
        EventBus.off(UserEvent.onAuthenticationUpdated, console.log)
        EventBus.off(UserEvent.onAuthenticationDeleted, console.log)
    }

    request = () => {
        requestUserById(this.props.params.id).then(res => {
            const user = res.data
            user.authenticationTimes = 0
            user.authenticationTime = null
            for (let item of res.data.authentications) {
                // 累加登录次数
                user.authenticationTimes += Number(item.authenticationTimes)
                // 获取最后登录时间
                const authenticationTime = item.authenticationTime === null ? null : dayjs(item.authenticationTime, "YYYY-MM-DD HH:mm:ss")
                if (authenticationTime !== null) {
                    if (user.authenticationTime === null) {
                        user.authenticationTime = authenticationTime
                    } else {
                        if (authenticationTime.isAfter(user.authenticationTime)) {
                            user.authenticationTime = authenticationTime
                        }
                    }
                }
            }
            this.setState({ user, notfound: false, loading: false })
        }).catch(err => {
            this.setState({ error: err.response?.data, notfound: true, loading: false })
        })
    }

    render() {
        return (<PageContainer header={{ onBack: () => window.history.go(-1) }}
            title="用户详情"
            loading={this.state.loading}
            tabList={this.tabList}
            tabProps={{ type: "card" }}
            onTabChange={(tab) => this.setState({ tab })}>
            <NotfoundWrapper notfound={this.state.notfound} subTitle={this.state.error}>
                {{
                    "0": (<TabForUserInfo user={this.state.user} />),
                    "1": (<TabForUserAuthentication user={this.state.user} />),
                    "2": (<TabForUserRole user={this.state.user} />)
                }[this.state.tab]}
                <PermissionWrapper permissions={["user:update"]}>
                    <UpdateUserDrawer />
                </PermissionWrapper>
            </NotfoundWrapper>
        </PageContainer>)
    }

}

export default function useRouterParams() {
    const params = useParams()
    return (<UserDetailPage params={params} />)
}