import axios from "axios"
import { message } from "antd"

import SecurityUtils from "@/framework/util/SecurityUtils"
import EventBus from "@/framework/util/EventBusUtils"
import UserEvent from "@/member/user/event/UserEvent"

export const baseURL = process.env.NODE_ENV === "development" ? "http://127.0.0.1:8080" : "https://api.underwork.cn/llgh"
// export const baseURL = "https://api.underwork.cn/eca"

const axiosInstance = axios.create({
    baseURL,
    timeout: 1000 * 30
});

axiosInstance.interceptors.request.use((config) => {
    config.headers = { Authorization: SecurityUtils.getToken() }
    return config
})

function onRequestSuccess(response) {
    return response
}

function onRequestRejected(err) {
    if (err.response.status === 401) {
        EventBus.emit(UserEvent.onAuthenticationFailure)
    }
    if (err.response.status === 403) {
        message.warning("您没有权限执行该操作", 2).then()
    }
    return Promise.reject(err)
}

axiosInstance.interceptors.response.use(onRequestSuccess, onRequestRejected)

window.axios = axiosInstance

export default axiosInstance
