import React from "react"
import { Button, Space } from "antd"
import { ProTable } from "@ant-design/pro-components"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"
import AvailableEnum from "@/framework/enum/AvailableEnum"

import { requestRoleByPage } from "@/member/role/api/RoleAPI"
import RoleEvent from "@/member/role/event/RoleEvent"
import DeleteRoleButton from "./DeleteRoleButton"
import CreateRoleModal from "@/member/role/interaction/CreateRoleModal"
import UpdateRoleModal from "@/member/role/interaction/UpdateRoleModal"
import UpdateRolePermissionsModal from "@/member/role/interaction/UpdateRolePermissionsModal"

const RoleDataTableColumnsState = {
    persistenceKey: "ColumnStateForRole",
    persistenceType: "localStorage",
    defaultValue: {
        id: { show: false },
        modifyTime: { show: false }
    }
}

const RoleDataTableOperations = () => (<Space size="middle">
    <PermissionWrapper permissions={["role:insert"]}>
        <CreateRoleModal />
    </PermissionWrapper>
</Space>)

const RoleDataTableColumnOperations = (props) => {
    const onEdit = () => {
        EventBus.emit(RoleEvent.onTriggerUpdateRole, props.item)
    }
    const onEditPermission = () => {
        EventBus.emit(RoleEvent.onTriggerUpdateRolePermissions, props.item.id)
    }
    return (<Space>
        <PermissionWrapper permissions={["role:update"]}>
            <Button type="link" size="small" onClick={onEdit}>编辑</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["role:update"]}>
            <Button type="link" size="small" onClick={onEditPermission}>设置权限</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["role:delete"]}>
            <DeleteRoleButton id={props.item.id} />
        </PermissionWrapper>
    </Space>)
}

export default class RoleDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: "name"
        },
        {
            title: "标识符",
            dataIndex: "identifier",
            copyable: true
        },
        {
            title: "状态",
            dataIndex: "available",
            width: 72,
            valueEnum: AvailableEnum
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            valueType: "fromNow",
            width: 128,
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            valueType: "fromNow",
            width: 128,
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            align: "center",
            with: 160,
            fixed: "right",
            render: (_, item) => (<RoleDataTableColumnOperations item={item} />)
        }
    ]

    componentDidMount() {
        EventBus.on(RoleEvent.onRoleCreated, () => this.tableActionRef.current?.reload())
        EventBus.on(RoleEvent.onRoleUpdated, () => this.tableActionRef.current?.reload())
        EventBus.on(RoleEvent.onRoleDeleted, () => this.tableActionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(RoleEvent.onRoleCreated, console.log)
        EventBus.off(RoleEvent.onRoleUpdated, console.log)
        EventBus.off(RoleEvent.onRoleDeleted, console.log)
    }

    onRequest = (params) => requestRoleByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                scroll={{ x: "max-content" }}
                columns={this.columns}
                columnsState={RoleDataTableColumnsState}
                actionRef={this.tableActionRef}
                toolBarRender={() => (<RoleDataTableOperations />)}
                request={this.onRequest} />
            <PermissionWrapper permissions={["role:update"]}>
                <UpdateRoleModal />
            </PermissionWrapper>
            <PermissionWrapper permissions={["role:update"]}>
                <UpdateRolePermissionsModal />
            </PermissionWrapper>
        </>)
    }

}