import React from "react";
import { Button, Space, Tag } from "antd";
import { ProTable } from "@ant-design/pro-components";

import PermissionWrapper from "../../../framework/component/PermissionWrapper";
import EventBus from "../../../framework/util/EventBusUtils";

import { requestActivityMemberByPage } from "../api/ActivityMemberAPI";
import ActivityMemberEvent from "../event/ActivityMemberEvent";
import DeleteActivityMemberButton from "./DeleteActivityMemberButton";
import CreateActivityMemberDrawer from "../interaction/CreateActivityMemberDrawer";
import UpdateActivityMemberDrawer from "../interaction/UpdateActivityMemberDrawer";
import PropTypes from "prop-types";
import InviteActivityMemberModal from "../interaction/InviteActivityMemberModal";
import ExportActivityMemberButton from "@/association/activity/component/ExportActivityMemberButton";
import UploadActivityMemberTemplateModal from "@/association/activity/interaction/UploadActivityMemberTemplateModal";

const ActivityMemberDataTableOperations = ({ activity }) => (<Space>
    <PermissionWrapper permissions={["association-activity-member:insert"]}>
        <InviteActivityMemberModal activity={activity}/>
    </PermissionWrapper>
    <PermissionWrapper permissions={["association-activity-member:insert"]}>
        <CreateActivityMemberDrawer activity={activity}/>
    </PermissionWrapper>
    <PermissionWrapper permissions={["association-activity-member:insert"]}>
        <UploadActivityMemberTemplateModal activity={activity.id}/>
    </PermissionWrapper>
    <PermissionWrapper permissions={["association-activity-member:select"]}>
        <ExportActivityMemberButton activity={activity.id}/>
    </PermissionWrapper>
</Space>)

const ActivityMemberDataTableColumnOperations = ({ item }) => (<Space>
    <PermissionWrapper permissions={["association-activity-member:update"]}>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(ActivityMemberEvent.onTriggerUpdateActivityMember, item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["association-activity-member:delete"]}>
        <DeleteActivityMemberButton id={item.id}/>
    </PermissionWrapper>
</Space>)

const ActivityMemberDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "姓名",
        dataIndex: "userName"
    },
    {
        title: "组织名称",
        dataIndex: "userOrgName"
    },
    {
        title: "手机号码",
        dataIndex: "userCellphone"
    },
    {
        title: "参会人来源",
        dataIndex: "origin",
        valueEnum: {
            0: { text: "会员" },
            1: { text: "非会员" }
        },
        render: (_, v) => (<Tag color={v.origin === "会员" ? "blue" : "orange"}>{v.origin}</Tag>)
    },
    {
        title: "座位号",
        dataIndex: "seatNumber",
        hideInSearch: true
    },
    {
        title: "是否排号",
        dataIndex: "assigned",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "负责人",
        dataIndex: "manager"
    },
    {
        title: "通知情况",
        dataIndex: "notified",
        valueType: "select",
        hideInSearch: true,
        valueEnum: {
            0: { text: "未通知", status: "Warning" },
            1: { text: "已通知", status: "Success" }
        }
    },
    {
        title: "通知时间",
        dataIndex: "notifyTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "签到情况",
        dataIndex: "signin",
        valueEnum: {
            0: { text: "未签到", status: "Warning" },
            1: { text: "已签到", status: "Success" }
        }
    },
    {
        title: "签到时间",
        dataIndex: "signinTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "临时的参会人",
        dataIndex: "temporary",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<ActivityMemberDataTableColumnOperations item={item}/>)
    }
]

export default class ActivityMemberDataTable extends React.Component {

    actionRef = React.createRef()

    componentDidMount() {
        EventBus.on(ActivityMemberEvent.onActivityMemberCreated, () => this.actionRef.current?.reload())
        EventBus.on(ActivityMemberEvent.onActivityMemberUpdated, () => this.actionRef.current?.reload())
        EventBus.on(ActivityMemberEvent.onActivityMemberDeleted, () => this.actionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(ActivityMemberEvent.onActivityMemberCreated, console.log)
        EventBus.off(ActivityMemberEvent.onActivityMemberUpdated, console.log)
        EventBus.off(ActivityMemberEvent.onActivityMemberDeleted, console.log)
    }

    onRequestActivityMemberPage = (params) => requestActivityMemberByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      columns={ActivityMemberDataTableColumns}
                      actionRef={this.actionRef}
                      params={{ activity: this.props.activity?.id }}
                      toolBarRender={() => (<ActivityMemberDataTableOperations activity={this.props.activity}/>)}
                      request={this.onRequestActivityMemberPage}/>
            <PermissionWrapper permissions={["association-activity-member:update"]}>
                <UpdateActivityMemberDrawer/>
            </PermissionWrapper>
        </>);
    }

}

ActivityMemberDataTable.propTypes = {
    activity: PropTypes.object
}