import React from "react";
import { Button, Space } from "antd";
import { ProTable } from "@ant-design/pro-components";

import PermissionWrapper from "../../../framework/component/PermissionWrapper";
import EventBus from "../../../framework/util/EventBusUtils";

import { requestMemberApplyByPage } from "../api/MemberApplyAPI";
import MemberApplyEvent from "../event/MemberApplyEvent";
import DisapproveMemberApplyButton from "./DisapproveMemberApplyButton";
import { requestUserByList } from "@/member/user/api/UserAPI";
import CreateMemberDrawer from "../../member/interaction/CreateMemberDrawer";
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils";
import MemberEvent from "@/association/member/event/MemberEvent";

const MemberApplyDataTableOperations = () => (<Space>

</Space>)

const MemberApplyDataTableColumnOperations = ({ item }) => {
    const onApprove = () => {
        const params = {
            name: item.userOrgName,
            manager: item.managerId,
            managerBirthday: item.birthday,
            managerGander: item.gander,
            level: item.level,
            address: item.address,
            businessArea: item.businessArea
        }
        CreateMemberDrawer.open(params)
    }
    return (<Space>
        <Button type="link" size="small" onClick={onApprove}>同意</Button>
        <PermissionWrapper permissions={["association-member-apply:delete"]}>
            <DisapproveMemberApplyButton id={item.id}/>
        </PermissionWrapper>
    </Space>)
}

export default class MemberApplyDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "单位名称",
            dataIndex: "userOrgName"
        },
        {
            title: "负责人",
            dataIndex: "managerId",
            valueType: "select",
            hideInSearch: true,
            fieldProps: { showSearch: true, fieldNames: { label: "name", value: "id" } },
            request: () => requestUserByList().then(res => res.data)
        },
        {
            title: "联系方式",
            dataIndex: "userCellphone"
        },
        {
            title: "申请入会性质",
            dataIndex: "level",
            hideInSearch: true
        },
        {
            title: "业务范围",
            dataIndex: "businessArea",
            hideInSearch: true
        },
        {
            title: "地址",
            dataIndex: "address",
            hideInSearch: true
        },
        {
            title: "生日",
            dataIndex: "birthday",
            hideInSearch: true
        },
        {
            title: "性别",
            dataIndex: "gander",
            valueType: "select",
            hideInSearch: true,
            request: () => DictionaryVariableUtils.requestDvByList("gander")
        },
        {
            title: "创建人",
            dataIndex: "creator",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "申请时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "修改人",
            dataIndex: "modifier",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<MemberApplyDataTableColumnOperations item={item}/>)
        }
    ]

    componentDidMount() {
        EventBus.on(MemberEvent.onMemberCreated, () => this.tableActionRef.current?.reload())
        EventBus.on(MemberApplyEvent.onMemberApplyCreated, () => this.tableActionRef.current?.reload())
        EventBus.on(MemberApplyEvent.onMemberApplyUpdated, () => this.tableActionRef.current?.reload())
        EventBus.on(MemberApplyEvent.onMemberApplyDeleted, () => this.tableActionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(MemberEvent.onMemberCreated, console.log)
        EventBus.off(MemberApplyEvent.onMemberApplyCreated, console.log)
        EventBus.off(MemberApplyEvent.onMemberApplyUpdated, console.log)
        EventBus.off(MemberApplyEvent.onMemberApplyDeleted, console.log)
    }

    onRequestMemberApplyPage = (params) => requestMemberApplyByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      columns={this.columns}
                      actionRef={this.tableActionRef}
                      toolBarRender={() => (<MemberApplyDataTableOperations/>)}
                      request={this.onRequestMemberApplyPage}/>
            <CreateMemberDrawer/>
        </>);
    }

}