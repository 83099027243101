import PropTypes from "prop-types";
import {Button, Popconfirm} from "antd";

import EventBus from "@/framework/util/EventBusUtils";

import {requestDeletePrintTemplate} from "../api/PrintTemplateAPI";
import PrintTemplateEvent from "../event/PrintTemplateEvent";


export default function DeletePrintTemplateButton({id}) {

    const onConfirm = () => requestDeletePrintTemplate(id).then(res => {
        EventBus.emit(PrintTemplateEvent.onPrintTemplateDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除打印模版" okText="确认" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeletePrintTemplateButton.propTypes = {
    id: PropTypes.string.isRequired
}