import React from "react"
import { Flex, Typography } from "antd"
import { ProCard } from "@ant-design/pro-components"

import PasswordLoginForm from "@/login/component/PasswordLoginForm"

export default function Login() {

    const [bgUrl, setBgUrl] = React.useState("")
    const [bgColor, setBgColor] = React.useState("rgba(255, 255, 255, 0.85)")

    React.useEffect(() => {
        document.title = "系统登录"
        const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches
        setBgUrl(isDark ? "url('/images/bg-login-dark.png')" : "url('/images/bg-login-light.png')")
        setBgColor(isDark ? "rgba(0, 0, 0, 0.65)" : "rgba(255, 255, 255, 0.85)")
        window.matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", (event) => {
                const matches = event.matches
                setBgUrl(matches ? "url('/images/bg-login-dark.png')" : "url('/images/bg-login-light.png')")
                setBgColor(matches ? "rgba(0, 0, 0, 0.65)" : "rgba(255, 255, 255, 0.85)")
            })
    }, [])

    return (<Flex style={{ width: "100vw", height: "100vh", background: bgUrl }} align="center" justify="center">
        <Flex>
            <ProCard ghost bordered style={{
                backgroundColor: bgColor,
                backdropFilter: "blur(10px)",
                WebkitBackdropFilter: "blur(10px)",
                borderRadius: 8
            }}>
                <PasswordLoginForm/>
                <Typography.Text style={{ paddingTop: 16 }}>
                    ICP备案号：
                    <a href="https://beian.miit.gov.cn/" target="_blank" style={{ color: "black" }}
                       rel="noopener noreferrer">
                        赣ICP备2024029847号
                    </a>
                </Typography.Text>
            </ProCard>
        </Flex>
    </Flex>)

}
