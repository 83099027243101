import React from "react"
import { DrawerForm, ProFormText } from "@ant-design/pro-components"
import Quill from 'quill'
import 'quill/dist/quill.snow.css';

import EventBus from "@/framework/util/EventBusUtils"

import { requestUpdateHelpItem } from "../api/HelpItemAPI"
import HelpEvent from "../event/HelpEvent"
import Rule from "@/framework/form/Rule"

export default class UpdateHelpItemDrawer extends React.Component {

    formRef = React.createRef()

    state = { open: false }

    quill

    componentDidMount() {
        EventBus.on(HelpEvent.onTriggerUpdateHelpItem, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(HelpEvent.onTriggerUpdateHelpItem, console.log)
    }

    onTrigger = (item) => {
        this.setState({ open: true }, () => {
            this.quill = new Quill("#editor", { theme: 'snow' })
            this.quill.clipboard.dangerouslyPasteHTML(item.content)
            setTimeout(() => this.formRef.current?.setFieldsValue(item), 200)
        })
    }

    onFinish = (params) => {
        params.content = this.quill?.getText()
        return requestUpdateHelpItem(params).then(res => {
            EventBus.emit(HelpEvent.onHelpItemUpdated, res.data)
            return true
        }).catch(err => {
            console.error("requestUpdateHelpItem:" + params.id, err)
            return false
        })
    }

    render() {
        return (<DrawerForm title="编辑帮助说明细则"
            open={this.state.open}
            formRef={this.formRef}
            drawerProps={{ destroyOnClose: true }}
            onOpenChange={open => this.setState({ open })}
            onFinish={this.onFinish}>

            <ProFormText label="ID" name="id" hidden />
            <ProFormText label="标题" name="title" rules={Rule.require} />
            <div id="editor" style={{ height: 480 }}></div>
        </DrawerForm>)
    }

}

UpdateHelpItemDrawer.open = (helpItem) => {
    EventBus.emit(HelpEvent.onTriggerUpdateHelpItem, helpItem)
}