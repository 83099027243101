import React from "react";
import {Tag} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";

import {requestHistoryProcessInstanceVariablesByList, requestProcessDefinitionStartForm} from "../../api/WorkflowAPI";
import ColumnsConverter from "../../util/ColumnsConverter";
import PropTypes from "prop-types";

/**
 * 流程开始提交信息
 */
export default class ProcessInstanceStartFormDescription extends React.Component {

    state = {
        loading: true,
        columns: [],
        dataSource: {}
    }

    componentDidMount() {
        if (!this.props.processDefinitionId) {
            return
        }
        this.requestForm()
    }

    requestForm = () => requestProcessDefinitionStartForm(this.props.processDefinitionId).then(res => {
        this.setState({columns: ColumnsConverter(res.data)})
        if (!!this.props.variables) {
            this.setState({dataSource: this.props.variables})
        } else {
            if (!!this.props.processInstanceId) {
                this.requestVariables()
            }
        }
    }).catch(err => {
        console.error(`开始表单查询失败:${this.props.processDefinitionId}`, err)
    }).finally(() => {
        this.setState({loading: false})
    })

    requestVariables = () => requestHistoryProcessInstanceVariablesByList(this.props.processInstanceId).then(res => {
        const data = {}
        for (let datum of res.data) {
            data[datum.variableName] = datum.textValue
        }
        this.setState({dataSource: data})
    }).catch(err => {
        console.warn("查询流程对象表单信息失败", err)
    })

    render() {
        if (this.state.columns.length < 1) {
            return <Tag>此待办无提交信息</Tag>
        }
        return (<ProDescriptions loading={this.state.loading}
                                 columns={this.state.columns}
                                 column={1}
                                 dataSource={this.state.dataSource}
                                 bordered={this.props.bordered}
                                 title={this.props.title}
                                 size={this.props.size}/>)
    }

}

ProcessInstanceStartFormDescription.propTypes = {
    processDefinitionId: PropTypes.string,
    variables: PropTypes.object
}