/**
 * 新建类目
 */
export const requestCreateCategory = (params) => window.axios.post("/specification/category", params)

/**
 * 分页查询类目
 * @param params 查询参数
 */
export const requestCategoryByPage = (params) => window.axios.get("/specification/category/page", { params })

/**
 * 查询类目
 * @param params 查询参数
 */
export const requestCategoryByList = (params) => window.axios.get("/specification/category/list", { params })

export const requestCategoryByTree = (params) => window.axios.get("/specification/category/tree", { params })

/**
 * 查询指定ID类目
 * @param id {string} 类目ID
 */
export const requestCategoryById = (id) => window.axios.get(`/specification/category/${id}`)

/**
 * 更新类目
 */
export const requestUpdateCategory = (params) => window.axios.patch(`/specification/category/${params.id}`, params)

/**
 * 删除类目
 */
export const requestDeleteCategory = (id) => window.axios.delete(`/specification/category/${id}`)
