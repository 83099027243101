import React from "react";
import {Button, Space} from "antd";
import {ProTable} from "@ant-design/pro-components";

import PermissionWrapper from "@/framework/component/PermissionWrapper";
import EventBus from "@/framework/util/EventBusUtils";

import {requestCategoryByPage} from "../api/CategoryAPI";
import AttachmentCategoryEvent from "../event/CategoryEvent";
import DeleteCategoryButton from "./DeleteCategoryButton";
import CreateCategoryModal from "../interaction/CreateCategoryModal";
import UpdateCategoryModal from "../interaction/UpdateCategoryModal";

const CategoryDataTableColumnState = {
    persistenceKey: "ColumnStateForCategory",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        modifyTime: {show: false}
    }
}

const AttachmentCategoryDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["attachment-category:insert"]}>
        <CreateCategoryModal/>
    </PermissionWrapper>
</Space>)

const AttachmentCategoryDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["attachment-category:update"]}>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(AttachmentCategoryEvent.onTriggerCategoryUpdate, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["attachment-category:delete"]}>
        <DeleteCategoryButton id={props.item.id}/>
    </PermissionWrapper>
</Space>)

export default class CategoryDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: "name"
        },
        {
            title: "状态",
            dataIndex: "available"
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            valueType: "fromNow",
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            valueType: "fromNow",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<AttachmentCategoryDataTableColumnOperations item={item}/>)
        }
    ]

    componentDidMount() {
        EventBus.on(AttachmentCategoryEvent.onCategoryCreated, this.tableActionRef.current?.reload)
        EventBus.on(AttachmentCategoryEvent.onCategoryUpdated, this.tableActionRef.current?.reload)
        EventBus.on(AttachmentCategoryEvent.onCategoryDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(AttachmentCategoryEvent.onCategoryCreated, console.log)
        EventBus.off(AttachmentCategoryEvent.onCategoryUpdated, console.log)
        EventBus.off(AttachmentCategoryEvent.onCategoryDeleted, console.log)
    }

    onRequestAttachmentCategoryPage = (params) => requestCategoryByPage(params).then(res => res.data).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      scroll={{x: "max-content"}}
                      columns={this.columns}
                      columnsState={CategoryDataTableColumnState}
                      actionRef={this.tableActionRef}
                      toolBarRender={() => (<AttachmentCategoryDataTableOperations/>)}
                      request={this.onRequestAttachmentCategoryPage}/>
            <PermissionWrapper permissions={["attachment-category:update"]}>
                <UpdateCategoryModal/>
            </PermissionWrapper>
        </>);
    }

}