import PropTypes from "prop-types"
import { App, Button, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeletePermission } from "@/member/permission/api/PermissionAPI"
import PermissionEvent from "@/member/permission/event/PermissionEvent"

export default function DeletePermissionButton(props) {

    const { message } = App.useApp()

    const onConfirm = () => requestDeletePermission(props.id).then(res => {
        EventBus.emit(PermissionEvent.onPermissionDeleted, res.data)
        message.success("删除成功", 2).then()
        return true
    }).catch(() => {
        message.error("删除失败", 2).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除权限" okText="确认" cancelText="取消" okButtonProps={{ danger: true }}
        onCancel={onCancel}
        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeletePermissionButton.propTypes = {
    id: PropTypes.string.isRequired
}