import React from "react";
import {App, Button} from "antd";
import {requestClaimProcessInstanceTask} from "../../api/WorkflowAPI";
import EventBus from "@/framework/util/EventBusUtils";
import WorkflowEvent from "../../event/WorkflowEvent";

export default function ClaimProcessInstanceTaskButton({item}) {

    const {modal, message} = App.useApp()

    const request = () => requestClaimProcessInstanceTask(item).then(res => {
        message.info("认领成功", 2).then()
        EventBus.emit(WorkflowEvent.onProcessTaskUpdateEvent, res.data)
        return true
    }).catch(err => {
        message.error("认领失败", 2).then()
        console.error("认领任务失败", err)
        return false
    })

    const onClick = () => modal.confirm({
        title: "认领任务",
        content: `认领 【${item.name}】 任务。请确认`,
        okText: "认领",
        centered: true,
        onOk: request
    });

    return (<Button size="small" type="link" onClick={onClick}>认领</Button>)


}