/**
 * 新建仓库
 */
export const requestCreateWarehouse = (params) => window.axios.post("/warehouse/warehouse", params)

/**
 * 分页查询仓库
 * @param params 查询参数
 */
export const requestWarehouseByPage = (params) => window.axios.get("/warehouse/warehouse/page", { params })

/**
 * 查询仓库
 * @param params 查询参数
 */
export const requestWarehouseByList = (params) => window.axios.get("/warehouse/warehouse/list", { params })

/**
 * 查询指定ID仓库
 * @param id {string} 仓库ID
 */
export const requestWarehouseById = (id) => window.axios.get(`/warehouse/warehouse/${id}`)

/**
 * 更新仓库
 */
export const requestUpdateWarehouse = (params) => window.axios.patch(`/warehouse/warehouse/${params.id}`, params)

/**
 * 删除仓库
 */
export const requestDeleteWarehouse = (id) => window.axios.delete(`/warehouse/warehouse/${id}`)
