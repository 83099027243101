import React from "react"
import {ProTable} from "@ant-design/pro-components"

import {requestProcessDefinitionByPage} from "../../api/WorkflowAPI"
import {Button, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {PlusOutlined} from "@ant-design/icons";
import DeleteProcessDefinitionButton from "../button/DeleteProcessDefinitionButton";
import EventBus from "@/framework/util/EventBusUtils";
import WorkflowEvent from "../../event/WorkflowEvent";
import StartProcessInstanceDrawer from "../../interaction/StartProcessInstanceDrawer";
import PreviewProcessDefinitionModal from "../../interaction/PreviewProcessDefinitionModal";
import StartBatchProcessInstanceDrawer from "../../interaction/StartBatchProcessInstanceDrawer";

const ProcessDefinitionDataTableColumnsState = {
    persistenceKey: "ProcessDefinitionDataTableColumnsState",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false}
    }
}

const ProcessDefinitionColumnOperations = ({item}) => {
    const navigate = useNavigate();
    return (<Space>
        <Button size="small" type="link" onClick={() => StartProcessInstanceDrawer.open(item)}>发起</Button>
        <Button size="small" type="link" onClick={() => StartBatchProcessInstanceDrawer.open(item)}>批量发起</Button>
        <Button size="small" type="link"
                onClick={() => navigate(`/home/workflow/process-definition/${item.id}/process-instance`)}>历史</Button>
        <Button size="small" type="link" onClick={() => PreviewProcessDefinitionModal.open(item)}>查看</Button>
        <DeleteProcessDefinitionButton item={item}/>
    </Space>)
}

const ProcessDefinitionDataTableOperations = () => {
    const navigate = useNavigate();
    return (<Space>
        <Button type="primary" icon={<PlusOutlined/>}
                onClick={() => navigate("/home/workflow/modeler")}>新建流程</Button>
    </Space>)
}

export default class ProcessDefinitionDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: "name",
            copyable: true,
            ellipsis: true
        },
        {
            title: "KEY",
            dataIndex: "key",
            copyable: true,
            ellipsis: true
        },
        {
            title: "描述",
            dataIndex: "description",
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "版本",
            dataIndex: "versionTag",
            hideInSearch: true
        },
        {
            title: "挂起",
            dataIndex: "suspended",
            valueType: "select",
            valueEnum: {
                "false": {text: "否", status: "success"},
                "true": {text: "是", status: "error"}
            }
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<ProcessDefinitionColumnOperations item={item}/>)
        }
    ]

    componentDidMount() {
        EventBus.on(WorkflowEvent.onProcessDefinitionCreateEvent, this.tableActionRef.current?.reload)
        EventBus.on(WorkflowEvent.onProcessDefinitionUpdateEvent, this.tableActionRef.current?.reload)
        EventBus.on(WorkflowEvent.onProcessDefinitionDeleteEvent, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onProcessDefinitionCreateEvent, () => console.log("EventOff", WorkflowEvent.onProcessDefinitionCreateEvent))
        EventBus.off(WorkflowEvent.onProcessDefinitionUpdateEvent, () => console.log("EventOff", WorkflowEvent.onProcessDefinitionUpdateEvent))
        EventBus.off(WorkflowEvent.onProcessDefinitionDeleteEvent, () => console.log("EventOff", WorkflowEvent.onProcessDefinitionDeleteEvent))
    }

    onRequest = (params) => requestProcessDefinitionByPage(params).then(res => {
        return res.data
    }).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      scroll={{x: "max-content"}}
                      columns={this.columns}
                      toolBarRender={() => (<ProcessDefinitionDataTableOperations/>)}
                      columnsState={ProcessDefinitionDataTableColumnsState}
                      actionRef={this.tableActionRef}
                      request={this.onRequest}/>
            <StartProcessInstanceDrawer/>
            <StartBatchProcessInstanceDrawer/>
            <PreviewProcessDefinitionModal/>
        </>)
    }

}