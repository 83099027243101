/**
 * 新建栏目
 */
export const requestCreateSection = (params) => window.axios.post("/content/section", params)

/**
 * 分页查询栏目
 * @param params 查询参数
 */
export const requestSectionByPage = (params) => window.axios.get("/content/section/page", { params })

/**`
 * 查询栏目
 * @param params 查询参数
 */
export const requestSectionByList = (params) => window.axios.get("/content/section/list", { params })

export const requestSectionByTree = (params) => window.axios.get("/content/section/tree", { params })

/**
 * 查询指定ID栏目
 * @param id {string} 栏目ID
 */
export const requestSectionById = (id) => window.axios.get(`/content/section/${id}`)

/**
 * 更新栏目
 */
export const requestUpdateSection = (params) => window.axios.patch(`/content/section/${params.id}`, params)

/**
 * 删除栏目
 */
export const requestDeleteSection = (id) => window.axios.delete(`/content/section/${id}`)
