import React, { useState } from "react"
import { PageContainer, ProCard } from "@ant-design/pro-components"

import DepartmentTree from "@/member/department/component/DepartmentTree"
import DepartmentDescription from "@/member/department/component/DepartmentDescription"
import UserDataTable from "@/member/user/component/table/UserDataTable"
import PostDataTable from "@/member/post/component/PostDataTable"
import GroupDataTable from "@/member/group/component/GroupDataTable"

export default function Departments() {

    const [department, setDepartment] = useState({})

    const tabs = {
        defaultActiveKey: "1",
        type: "card",
        items: [
            { key: "0", label: "单位", children: (<DepartmentDescription department={department}/>) },
            { key: "1", label: "用户", children: (<UserDataTable department={department.id}/>) },
            { key: "2", label: "岗位", children: (<PostDataTable department={department.id}/>) },
            { key: "3", label: "用户组", children: (<GroupDataTable department={department.id}/>) }
        ]
    }

    return (<PageContainer>
        <ProCard split="vertical" ghost bordered wrap>
            <ProCard size="small" colSpan={{ xs: 24, sm: 24, md: 7, lg: 7, xl: 7, xxl: 7 }}>
                <DepartmentTree onChange={setDepartment}/>
            </ProCard>
            <ProCard size="small" colSpan={{ xs: 24, sm: 24, md: 17, lg: 17, xl: 17, xxl: 17 }} tabs={tabs}/>
        </ProCard>
    </PageContainer>)

}