import React from "react";
import {ModalForm, ProFormRadio, ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import Rule from "@/framework/form/Rule";
import EventBus from "@/framework/util/EventBusUtils";
import DictionaryVariableEvent from "../event/DictionaryVariableEvent";
import {requestUpdateDictionaryVariable} from "../api/DictionaryVariableAPI";
import FormUtils from "@/framework/util/FormUtils";
import {getAvailableEnumAsOptions} from "@/framework/enum/AvailableEnum";

export default class UpdateDictionaryVariableModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false
    }

    componentDidMount() {
        EventBus.on(DictionaryVariableEvent.onTriggerUpdateDictionaryVariable, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(DictionaryVariableEvent.onTriggerUpdateDictionaryVariable, console.log)
    }

    onTrigger = (item) => this.setState({open: true}, () => {
        setTimeout(() => this.formRef.current?.setFieldsValue(item), 100)
    })

    onFinish = (params) => {
        return requestUpdateDictionaryVariable(params).then(res => {
            this.formRef.current?.resetFields()
            EventBus.emit(DictionaryVariableEvent.onDictionaryVariableCreated, res.data)
            return true
        }).catch(err => {
            console.warn("新建字典值失败", err)
            FormUtils.setFormError(this.formRef, err.response.data, "label")
            return false
        })
    }

    render() {
        return (<ModalForm title="编辑字典值"
                           modalProps={{width: 560, destroyOnClose: true}}
                           trigger={undefined}
                           open={this.state.open}
                           formRef={this.formRef}
                           onOpenChange={open => this.setState({open})}
                           onFinish={this.onFinish}>
            <ProFormText name="id" label="ID" rules={Rule.require} hidden/>
            <ProFormText name="parent" label="父选项" rules={Rule.require} hidden/>
            <ProFormText name="dictionaryLabel" label="字典" rules={Rule.require} hidden/>
            <ProFormText width="md" name="label" label="显示名称" rules={Rule.requireText}/>
            <ProFormText width="md" name="variable" label="字典值" rules={Rule.require} disabled/>
            <ProFormRadio.Group name="available" label="可用状态"
                                rules={Rule.require}
                                options={getAvailableEnumAsOptions()}/>
            <ProFormTextArea name="description" label="描述" rules={Rule.text}/>
            <ProFormTextArea name="comment" label="备注" rules={Rule.text}/>
        </ModalForm>)
    }

}