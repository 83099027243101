import React from "react";
import {ModalForm, ProFormField, ProFormText, ProFormTextArea} from "@ant-design/pro-components";

import EventBus from "@/framework/util/EventBusUtils";
import FormUtils from "@/framework/util/FormUtils";

import {requestUpdateAnnouncement} from "../api/AnnouncementAPI";
import AnnouncementEvent from "../event/AnnouncementEvent";
import AttachmentPicker from "../../attachment/file/component/AttachmentPicker";
import Rule from "@/framework/form/Rule";

export default class UpdateAnnouncementModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(AnnouncementEvent.onTriggerUpdateAnnouncement, (item) => {
            const form = JSON.parse(JSON.stringify(item))
            this.setState({open: true}, () => {
                setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
            })
        })
    }

    componentWillUnmount() {
        EventBus.off(AnnouncementEvent.onTriggerUpdateAnnouncement, console.log)
    }

    onFinish = (params) => requestUpdateAnnouncement(params).then(() => {
        EventBus.emit(AnnouncementEvent.onAnnouncementUpdated)
        this.formRef.current?.resetFields()
        return true
    }).catch((err) => {
        console.error("编辑公告失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false;
    })

    render() {
        return (<ModalForm title="编辑公告"
                           modalProps={{destroyOnClose: true, width: 560}}
                           open={this.state.open}
                           onOpenChange={open => this.setState({open})}
                           formRef={this.formRef}
                           isKeyPressSubmit
                           onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden rules={Rule.requireText}/>

            <ProFormField label="封面" name="cover">
                <AttachmentPicker accept={["image/png", "image/jpeg"]}/>
            </ProFormField>

            <ProFormText width="md" name="title" label="标题" rules={Rule.requireText}/>

            <ProFormText width="md" name="subtitle" label="副标题" rules={Rule.text}/>

            <ProFormTextArea name="content" label="公告内容"
                             fieldProps={{rows: 5, showCount: true}}
                             rules={Rule.requireTextarea}/>
        </ModalForm>)
    }
}