const LeafEnum = {
    "是": { text: "是", status: "Error" },
    "否": { text: "否", status: "Success" }
}

export const getLeafEnumAsOptions = () => {
    const options = []
    for (let availableEnumKey in LeafEnum) {
        options.push({ label: availableEnumKey, value: LeafEnum[availableEnumKey].text })
    }
    return options
}

export const LEAF = "可用"
export const NORMAL = "禁用"
export default LeafEnum