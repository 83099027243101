/**
 * 新建Meta
 */
export const requestCreateMeta = (params) => window.axios.post("/common/meta", params)

/**
 * 分页查询Meta
 * @param params 查询参数
 */
export const requestMetaByPage = (params) => window.axios.get("/common/meta/page", {params})

/**
 * 查询Meta
 * @param params 查询参数
 */
export const requestMetaByList = (params) => window.axios.get("/common/meta/list", {params})

/**
 * 查询指定IDMeta
 * @param id {string} MetaID
 */
export const requestMetaById = (id) => window.axios.get(`/common/meta/${id}`)

/**
 * 更新Meta
 */
export const requestUpdateMeta = (params) => window.axios.patch(`/common/meta/${params.id}`, params)

/**
 * 删除Meta
 */
export const requestDeleteMeta = (id) => window.axios.delete(`/common/meta/${id}`)
