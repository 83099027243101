import React from "react"
import {PageContainer} from "@ant-design/pro-components"
import CodeTableTable from "./component/CodeTableTable";

export default function CodeTables() {

    return (<PageContainer>
        <CodeTableTable/>
    </PageContainer>)

}