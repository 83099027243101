import PropTypes from "prop-types";
import { Button, Popconfirm } from "antd";

import EventBus from "../../../framework/util/EventBusUtils";

import { requestDeleteCooperativePartner } from "../api/PartnerAPI";
import CooperativePartnerEvent from "../event/CooperativePartnerEvent";


export default function DeleteCooperativePartnerButton({ id }) {

    const onConfirm = () => requestDeleteCooperativePartner(id).then(res => {
        EventBus.emit(CooperativePartnerEvent.onCooperativePartnerDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除合作伙伴" okText="删除" cancelText="取消" okButtonProps={{ danger: true }}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteCooperativePartnerButton.propTypes = {
    id: PropTypes.string.isRequired
}