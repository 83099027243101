import React from "react"
import {
    ModalForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormField,
    ProFormRadio,
    ProFormSelect,
    ProFormText
} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import { getAvailableEnumAsOptions } from "@/framework/enum/AvailableEnum"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import { requestUpdateTenant } from "@/member/tenant/api/TenantAPI"
import TenantEvent from "@/member/tenant/event/TenantEvent"
import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker"
import { requestDictionaryVariableByList } from "@/common/dictionary/variable/api/DictionaryVariableAPI"

export default class UpdateTenantModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(TenantEvent.onTriggerUpdateTenant, this.onTriggerUpdateTenant)
    }

    componentWillUnmount() {
        EventBus.off(TenantEvent.onTriggerUpdateTenant, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({ open })
    }

    onFinish = (params) => requestUpdateTenant(params).then(() => {
        EventBus.emit(TenantEvent.onTenantUpdated)
        this.formRef.current?.resetFields()
        return true
    }).catch((err) => {
        FormUtils.setFormError(this.formRef, err.response?.data, "available")
        return false
    })

    onTriggerUpdateTenant = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    render() {
        return (<ModalForm title="编辑租户"
            modalProps={{ width: 480, destroyOnClose: true }}
            open={this.state.open}
            onOpenChange={this.toggle}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />

            <ProFormField label="企业Logo" name="logo">
                <AttachmentPicker count={1} multiple={false} accept={["image/png", "image/jpeg"]} />
            </ProFormField>

            <ProFormText width="sm" name="name" label="名称" placeholder="请输入租户名称" rules={Rule.requireText} />

            <ProFormSelect width="sm" name="trade" label="行业"
                rules={Rule.require}
                initialValue="0"
                request={() => requestDictionaryVariableByList({ dictionaryName: "trade" })
                    .then(res => res.data.map(v => ({ label: v.label, value: v.variable })))
                    .catch(() => [])} />

            <ProFormSelect width="xs" name="type" label="类型"
                options={[
                    { label: "临时租户", value: "临时租户" },
                    { label: "永久租户", value: "永久租户" }
                ]} />

            <ProFormDependency name={["type"]}>
                {({ type }) => {
                    return type === "永久租户" ?
                        undefined :
                        (<ProFormDatePicker width="sm" label="有效期" name="shelfLife" />)
                }}
            </ProFormDependency>

            <ProFormRadio.Group width="md" name="available" label="可用状态" rules={Rule.require}
                options={getAvailableEnumAsOptions()} />

        </ModalForm>)
    }
}