/**
 * 分页查询角色
 * @param params 查询参数
 */
export const requestRoleByPage = (params) => window.axios.get("/member/role/page", { params })

/**
 * 批量查询角色
 * @param params 查询参数
 */
export const requestRoleByList = (params) => window.axios.get("/member/role/list", { params })

/**
 * 查询指定ID角色
 * @param id {string} 角色ID
 */
export const requestRoleById = (id) => window.axios.get(`/member/role-detail/${id}`)

export const requestRolePermissionByList = (id) => window.axios.get(`/member/role/${id}/permissions`)

/**
 * 创建角色
 * @param params 创建角色
 */
export const requestCreateRole = (params) => window.axios.post("/member/role", params)

/**
 * 更新指定ID角色
 * @param params 更新参数
 */
export const requestUpdateRole = (params) => window.axios.patch(`/member/role/${params.id}`, params)

/**
 * 更新指定ID的角色权限
 * @param params 更新参数
 */
export const requestUpdateRolePermissions = (params) => window.axios.put(`/member/role/${params.id}/permissions`, params)

/**
 * 删除指定ID角色
 * @param id {string} 角色ID
 */
export const requestDeleteRole = (id) => window.axios.delete(`/member/role/${id}`)