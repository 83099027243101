import {Button, Popconfirm} from "antd";

import EventBus from "@/framework/util/EventBusUtils";
import HelpEvent from "../event/HelpEvent";
import {requestDeleteHelpItem} from "../api/HelpItemAPI";

export default function DeleteHelpItemButton(props) {

    const onConfirm = () => requestDeleteHelpItem(props.id).then(res => {
        EventBus.emit(HelpEvent.onHelpItemDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除帮助说明细则" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}
