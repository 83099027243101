/**
 * 新建Spu
 */
export const requestCreateSpu = (params) => window.axios.post("/specification/spu", params)

/**
 * 分页查询Spu
 * @param params 查询参数
 */
export const requestSpuByPage = (params) => window.axios.get("/specification/spu/page", { params })

/**
 * 查询Spu
 * @param params 查询参数
 */
export const requestSpuByList = (params) => window.axios.get("/specification/spu/list", { params })

/**
 * 查询指定IDSpu
 * @param id {string} SpuID
 */
export const requestSpuById = (id) => window.axios.get(`/specification/spu/${id}`)

/**
 * 更新Spu
 */
export const requestUpdateSpu = (params) => window.axios.patch(`/specification/spu/${params.id}`, params)

/**
 * 删除Spu
 */
export const requestDeleteSpu = (id) => window.axios.delete(`/specification/spu/${id}`)
