import React from "react"
import { Alert, Button, Col, message, Modal, Row, Space, Upload } from "antd"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"

import EventBus from "@/framework/util/EventBusUtils"
import { baseURL } from "@/framework/axios/Axios"
import DownloadUtils from "@/framework/util/DownloadUtils"
import { requestActivityMemberExcelTemplate } from "@/association/activity/api/ActivityMemberAPI";
import ActivityMemberEvent from "@/association/activity/event/ActivityMemberEvent";

export default class UploadActivityMemberTemplateModal extends React.Component {

    state = {
        open: false,
        uploading: false,
        downloading: false
    }

    footerRender = () => (<Space>
        <Button loading={this.state.downloading} onClick={this.onDownloadImportTemplate}>下载导入模版</Button>
        <Button onClick={this.closeModal} disabled={this.state.uploading}>关闭</Button>
    </Space>)

    showModal = () => this.setState({ open: true })

    closeModal = () => this.setState({ open: false })

    onChange = (file) => {
        if (file.file.status === "done") {
            this.onFileUploadSuccess(file.file)
            return
        }
        if (file.file.status === "error") {
            this.onFileUploadFailure(file.file)
        }
    }

    onFileUploadSuccess = () => {
        EventBus.emit(ActivityMemberEvent.onActivityMemberCreated)
        this.closeModal()
    }

    onFileUploadFailure = (file) => {
        message.open({ type: "error", content: file.response }).then()
    }

    onDownloadImportTemplate = () => {
        this.setState({ downloading: true })
        requestActivityMemberExcelTemplate().then(res => {
            DownloadUtils.download(res.data, res.headers.contentType, "活动与会人员模版.xlsx")
            this.setState({ downloading: false })
        }).catch(err => {
            this.setState({ downloading: false })
            message.open({ type: "error", content: err.message }).then()
        })
    }

    render() {
        return (<>
            <Button onClick={this.showModal}>
                <UploadOutlined/>导入Excel
            </Button>
            <Modal width={560}
                   destroyOnClose
                   title="导入活动与会人员"
                   open={this.state.open}
                   closable={!this.state.uploading}
                   onCancel={this.closeModal}
                   footer={this.footerRender()}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Alert type="info" showIcon message="推荐使用系统提供的 Excel 模版进行导入"/>
                    </Col>
                    <Col span={24}>
                        <Upload.Dragger action={`${baseURL}/association/activity/${this.props.activity}/member/excel`}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        method="POST"
                                        maxCount={1}
                                        name="excel"
                                        headers={{ Authorization: localStorage.getItem("Authorization") }}
                                        onChange={this.onChange}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined/>
                            </p>
                            <p className="ant-upload-text">
                                点击或拖拽模版文件到此处上传模版文件
                            </p>
                            <p className="ant-upload-hint">
                                仅支持后缀为 ".xls" 和 ".xlsx" 的 Excel 文件
                            </p>
                        </Upload.Dragger>
                    </Col>
                </Row>
            </Modal>
        </>)
    }

}