import Regular from "@/framework/regular/Regular"

/**
 * 常用 Form Item 校验规则
 */
const Rule = {
    require: [
        { required: true, message: "此项不能为空" },
    ],
    requireText: [
        { required: true, message: "此项不能为空" },
        { max: 200, message: "此项长度不能超过60" },
        { pattern: Regular.text, message: "此项不能包含特殊字符" },
        { pattern: Regular.trimText, message: "此项首尾不能包含空格" }
    ],
    text: [
        { max: 200, message: "此项长度不能超过60" },
        { pattern: Regular.text, message: "此项不能包含特殊字符" },
        { pattern: Regular.trimText, message: "此项首尾不能包含空格" }
    ],
    requireTextarea: [
        { required: true, message: "此项不能为空" },
        { max: 1000, message: "此项长度不能超过1000" }
    ],
    textarea: [
        { max: 1000, message: "此项长度不能超过1000" }
    ]
}

export default Rule