import React from "react"
import { Button, Space, Typography } from "antd"
import { ProDescriptions } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import PermissionWrapper from "@/framework/component/PermissionWrapper"

import DeleteSpecificationButton from "@/specification/specification/component/DeleteSpecificationButton"
import SpecificationEvent from "@/specification/specification/event/SpecificationEvent"
import UpdateSpecificationModal from "@/specification/specification/interaction/UpdateSpecificationModal"

function SpecificationPropertyDescriptionExtra({ specificationProperty }) {
    return (<Space size="small">
        <PermissionWrapper permissions={["specification:update"]}>
            <Button size="small" type="link"
                onClick={() => EventBus.emit(SpecificationEvent.onTriggerUpdateSpecification, specificationProperty)}>
                编辑
            </Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["specification:delete"]}>
            <DeleteSpecificationButton id={specificationProperty.id} />
        </PermissionWrapper>
    </Space>)
}

export default function SpecificationPropertyDescription({ specificationProperty }) {
    if (!!specificationProperty) {
        return (<><ProDescriptions title="规格信息" dataSource={specificationProperty}
            bordered size="small"
            extra={<SpecificationPropertyDescriptionExtra specificationProperty={specificationProperty} />}>
            <ProDescriptions.Item label="名称" dataIndex="name" />
            <ProDescriptions.Item label="创建时间" dataIndex="createTime" valueType="fromNow" />
        </ProDescriptions>
            <PermissionWrapper permissions={["specification:update"]}>
                <UpdateSpecificationModal />
            </PermissionWrapper>
        </>)
    } else {
        return (<Typography.Paragraph>请选择规格</Typography.Paragraph>)
    }

}
