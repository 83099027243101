import React from "react"
import PropTypes from "prop-types"
import { Button, message, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeleteAuthentication } from "@/member/user/api/AuthenticationAPI"
import UserEvent from "@/member/user/event/UserEvent"


export default function DeleteAuthenticationButton(props) {
    const onConfirm = () => requestDeleteAuthentication(props.id).then(res => {
        EventBus.emit(UserEvent.onAuthenticationDeleted, res.data)
        return true
    }).catch((err) => {
        message.error(err.response?.data.id, 1).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除认证信息" okText="删除" cancelText="取消" okButtonProps={{ danger: true }}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger disabled={props.disabled}>删除</Button>
    </Popconfirm>)
}

DeleteAuthenticationButton.propTypes = {
    id: PropTypes.string.isRequired
}