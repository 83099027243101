const StatisticModule = {
    "user": {
        label: "用户",
        value: "user",
    },
    "spu": {
        label: "SPU",
        value: "spu",
    }
}

export function getOptions() {
    const options = []
    for (let key in StatisticModule) {
        options.push({ label: StatisticModule[key].label, value: StatisticModule[key].value })
    }
    return options
}

export default StatisticModule