import React from "react"
import {useNavigate, useParams} from "react-router-dom"
import {PageContainer} from "@ant-design/pro-components"

import DictionaryVariableDataTable from "./component/DictionaryVariableDataTable"

export default function DictionaryVariableDetailsPage() {

    const navigate = useNavigate()
    const params = useParams()
    const items = [{title: "系统设置"}, {title: "字典管理"}, {title: "字典值管理"}]

    return (<PageContainer title="字典值管理"
                           header={{onBack: () => navigate(-1)}}
                           breadcrumb={{items}}>
        <DictionaryVariableDataTable dictionary={params.dictionary}/>
    </PageContainer>)

}