import React from "react"
import { Col, Row, Skeleton, Transfer } from "antd"
import { ModalForm, ProFormItem, ProFormText } from "@ant-design/pro-components"

import { setFormError } from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"

import { requestUpdateUserGroups, requestUserGroups } from "@/member/user/api/UserAPI"
import { requestGroupByList } from "@/member/group/api/GroupAPI"
import UserEvent from "@/member/user/event/UserEvent"

export default class UpdateUserGroupsModal extends React.Component {

    state = {
        loadingSource: true,
        loadingTarget: true,
        source: [],
        target: [],
        id: undefined
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(UserEvent.onTriggerUpdateUserGroup, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(UserEvent.onTriggerUpdateUserGroup, console.log)
    }

    onTrigger = (user) => {
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue({ id: user.id }), 100)
            this.requestSource()
            this.requestTarget(user.id)
        })
    }

    requestSource = () => {
        this.setState({ loadingSource: true })
        return requestGroupByList().then(res => {
            this.setState({
                loadingSource: false,
                source: res.data.map(item => ({ key: item.id, title: item.name, disabled: item.available === "禁用" }))
            })
        }).catch(() => {
            this.setState({ loadingSource: false, source: [] })
        })
    }

    requestTarget = (id) => {
        this.setState({ loadingTarget: true })
        return requestUserGroups(id).then(res => {
            this.setState({ loadingTarget: false, target: res.data.map(item => item.id) })
        }).catch(() => {
            this.setState({ loadingTarget: false, target: [] })
        })
    }


    onFinish = (params) => requestUpdateUserGroups(params).then(() => {
        return true
    }).catch(err => {
        setFormError(this.formRef, err.response?.data, "roles")
        return false
    })

    render() {
        return (<ModalForm title="设置用户用户组"
            open={this.state.open}
            onOpenChange={(open) => this.setState({ open })}
            formRef={this.formRef}
            modalProps={{ centered: true, width: 520, destroyOnClose: true }}
            onFinish={this.onFinish}>

            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <ProFormText name="id" label="ID" hidden />
                </Col>

                <Col span={24}>
                    <Skeleton active loading={this.state.loadingSource || this.state.loadingTarget}>
                        <ProFormItem name="groups">
                            <Transfer showSearch
                                onChange={(target) => this.setState({ target })}
                                titles={["所有用户组", "已加入用户组"]}
                                listStyle={{ width: "100%", height: 440 }}
                                dataSource={this.state.source}
                                targetKeys={this.state.target}
                                render={(item) => item.title} />
                        </ProFormItem>
                    </Skeleton>
                </Col>
            </Row>
        </ModalForm>)
    }
}

UpdateUserGroupsModal.open = (user) => {
    EventBus.emit(UserEvent.onTriggerUpdateUserGroup, user)
}