import React from "react"
import { ModalForm, ProFormRadio, ProFormText, ProFormTreeSelect } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"

import { requestCategoryByTree, requestCreateCategory } from "@/specification/category/api/CategoryAPI"
import CategoryEvent from "@/specification/category/event/CategoryEvent"

export default class CreateCategoryModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false
    }

    componentDidMount() {
        EventBus.on(CategoryEvent.onTriggerCreateCategory, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(CategoryEvent.onTriggerCreateCategory, console.log)
    }

    requestParentOption = () => requestCategoryByTree().then(res => {
        res.data.unshift({ name: "顶层类目", id: "0" })
        return res.data
    }).catch(() => {
        return [{ name: "顶层类目", id: "0" }]
    })

    onFinish = (params) => requestCreateCategory(params).then(res => {
        EventBus.emit(CategoryEvent.onCategoryCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增类目失败", err)
        FormUtils.setFormError(this.formRef, err.response.data, "name")
        return false
    })

    onTrigger = (parent) => {
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue({ parent }), 100)
        })
    }

    render() {
        return (<ModalForm title="新增类目"
            modalProps={{ destroyOnClose: true, width: 560 }}
            open={this.state.open}
            onOpenChange={(open) => this.setState({ open })}
            formRef={this.formRef}
            onFinish={this.onFinish}>
            <ProFormText name="name" label="名称" rules={Rule.require} />
            <ProFormTreeSelect name="parent" label="父分类" rules={Rule.require}
                fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                request={this.requestParentOption} />
            <ProFormRadio.Group name="status" label="状态" rules={Rule.require}
                initialValue={1}
                options={[
                    { label: "可用", value: 1 },
                    { label: "禁用", value: 0 }
                ]} />
        </ModalForm>)
    }
}

CreateCategoryModal.open = (parent) => {
    EventBus.emit(CategoryEvent.onTriggerCreateCategory, parent)
}