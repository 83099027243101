/**
 * 新增权限
 */
export const requestCreateDepartment = (params) => window.axios.post("/member/department", params)

/**
 * 分页查询权限
 * @param params 查询参数
 */
export const requestDepartmentByPage = (params) => window.axios.get("/member/department/page", { params })

export const requestDepartmentByTree = (params) => window.axios.get("/member/department/tree", { params })

/**
 * 查询权限
 * @param params 查询参数
 */
export const requestDepartmentByList = (params) => window.axios.get("/member/department/list", { params })

/**
 * 查询指定ID权限
 * @param id {string} 权限ID
 */
export const requestDepartmentById = (id) => window.axios.get(`/member/department/${id}`)

/**
 * 更新权限
 */
export const requestUpdateDepartment = (params) => window.axios.patch(`/member/department/${params.id}`, params)

/**
 * 删除权限
 */
export const requestDeleteDepartment = (id) => window.axios.delete(`/member/department/${id}`)

/**
 * 下载导入权限Excel模版
 */
export const requestDownloadDepartmentExcelTemplate = () => window.axios.get("/member/department/template/excel", { responseType: "blob" })