const DictionaryLabelDataTypeEnum = {
    "字符串": {text: "字符串"},
    "数字": {text: "数字"},
    "日期": {text: "日期"},
    "日期时间": {text: "日期时间"}
}

export const getDictionaryLabelDataTypeEnumAsOptions = () => {
    const options = []
    for (let availableEnumKey in DictionaryLabelDataTypeEnum) {
        options.push({label: availableEnumKey, value: DictionaryLabelDataTypeEnum[availableEnumKey].text})
    }
    return options
}

export default DictionaryLabelDataTypeEnum