import React from "react";
import {Avatar, Button, Image, Space} from "antd";
import {ProTable} from "@ant-design/pro-components";

import PermissionWrapper from "../../../framework/component/PermissionWrapper";
import EventBus from "../../../framework/util/EventBusUtils";

import {requestMemberByPage} from "../api/MemberAPI";
import MemberEvent from "../event/MemberEvent";
import DeleteMemberButton from "./DeleteMemberButton";
import CreateMemberDrawer from "../interaction/CreateMemberDrawer";
import UpdateMemberModal from "../interaction/UpdateMemberModal";
import PreviewMemberMarkDrawer from "../interaction/PreviewMemberMarkDrawer";
import {PlusOutlined} from "@ant-design/icons";
import ExportMemberButton from "@/association/member/component/ExportMemberButton";
import AuditMemberModal from "@/association/member/interaction/AuditMemberModal";
import ColumnForUserName from "@/member/user/component/column/ColumnForUserName";
import PreviewUserDrawer from "@/member/user/interaction/PreviewUserDrawer";

const MemberDataTableColumnState = {
    persistenceKey: "MemberDataTableColumnState",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        address: {show: false},
        businessArea: {show: false},
        createTime: {show: false},
        modifyTime: {show: false},
        auditTime: {show: false}
    }
}

const MemberDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["association-member:insert"]}>
        <Button type="primary" icon={<PlusOutlined/>} onClick={() => CreateMemberDrawer.open()}>新增会员</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["association-member:select"]}>
        <ExportMemberButton/>
    </PermissionWrapper>
</Space>)

const MemberDataTableColumnOperations = ({item}) => (<Space>
    <PermissionWrapper permissions={["association-member:update"]}>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(MemberEvent.onTriggerUpdateMember, item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["association-member:update"]}>
        <Button type="link" size="small" onClick={() => AuditMemberModal.open(item)}>审核</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["association-member:update"]}>
        <Button type="link" size="small" onClick={() => PreviewMemberMarkDrawer.open(item)}>标注</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["association-member:delete"]}>
        <DeleteMemberButton id={item.id}/>
    </PermissionWrapper>
</Space>)

const MemberDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "营业执照",
        dataIndex: "businessLicenceUrl",
        valueType: "image",
        hideInSearch: true,
        render: (_, v) => !!v.businessLicenceUrl ? (
            <Avatar shape="square" size="small" src={<Image src={v.businessLicenceUrl}/>}/>) : ""
    },
    {
        title: "单位名称",
        dataIndex: "name"
    },
    {
        title: "行业",
        dataIndex: "trade",
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "业务范围",
        dataIndex: "businessArea",
        hideInSearch: true
    },
    {
        title: "NUSCC",
        dataIndex: "nuscc",
        tooltip: "统一社会信用代码",
        ellipsis: true
    },
    {
        title: "负责人",
        dataIndex: "manager",
        hideInSearch: true,
        render: (_, item) => (<div style={{cursor: "pointer"}} onClick={() => PreviewUserDrawer.open(item?.manager.id)}>
            <ColumnForUserName user={item?.manager}/>
        </div>)
    },
    {
        title: "参会性质",
        dataIndex: "level"
    },
    {
        title: "业务方向",
        dataIndex: "businessDirection",
        hideInSearch: true
    },
    {
        title: "电商平台",
        dataIndex: "operatePlatform",
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "地址",
        dataIndex: "address",
        hideInSearch: true
    },
    {
        title: "邀请人",
        dataIndex: "inviter",
        hideInSearch: true
    },
    {
        title: "审核状态",
        dataIndex: "audit",
        valueType: "select",
        valueEnum: {
            0: {text: "未审核", status: "Default"},
            1: {text: "已审核", status: "Success"},
        }
    },
    {
        title: "审核意见",
        dataIndex: "auditComment",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "创建人",
        dataIndex: "creator",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "申请时间",
        dataIndex: "createTime",
        hideInSearch: true
    },
    {
        title: "修改人",
        dataIndex: "modifier",
        hideInTable: true,
        hideInSearch: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        hideInSearch: true
    },
    {
        title: "审核时间",
        dataIndex: "auditTime",
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<MemberDataTableColumnOperations item={item}/>)
    }
]

export default class MemberDataTable extends React.Component {

    actionRef = React.createRef()

    columns = []

    componentDidMount() {
        EventBus.on(MemberEvent.onMemberCreated, () => this.actionRef.current?.reload())
        EventBus.on(MemberEvent.onMemberUpdated, () => this.actionRef.current?.reload())
        EventBus.on(MemberEvent.onMemberDeleted, () => this.actionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(MemberEvent.onMemberCreated, console.log)
        EventBus.off(MemberEvent.onMemberUpdated, console.log)
        EventBus.off(MemberEvent.onMemberDeleted, console.log)
    }

    request = (params) => requestMemberByPage(params).then(res => res.data).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      scroll={{x: "max-content"}}
                      columns={MemberDataTableColumns}
                      columnsState={MemberDataTableColumnState}
                      actionRef={this.actionRef}
                      toolBarRender={() => (<MemberDataTableOperations/>)}
                      request={this.request}/>
            <CreateMemberDrawer/>
            <PreviewUserDrawer/>
            <PermissionWrapper permissions={["association-member:update"]}>
                <UpdateMemberModal/>
                <AuditMemberModal/>
            </PermissionWrapper>
            <PreviewMemberMarkDrawer/>
        </>);
    }

}