import React, { useState } from "react";
import { PageContainer, ProCard } from "@ant-design/pro-components";
import SectionTree from "@/content/section/component/SectionTree";
import ArticleDataTable from "@/content/article/component/ArticleDataTable";
import SectionDescription from "@/content/section/component/SectionDescription";


export default function Section() {

    const [section, setSection] = useState({})

    const tabs = {
        defaultActiveKey: "1",
        type: "card",
        items: [
            { key: "0", label: "栏目", children: (<SectionDescription section={section}/>) },
            { key: "1", label: "文章", children: (<ArticleDataTable section={section.id}/>) },
        ]
    }

    return (<PageContainer>
        <ProCard split="vertical" ghost bordered wrap>
            <ProCard size="small" colSpan={{ xs: 24, sm: 24, md: 7, lg: 7, xl: 7, xxl: 7 }}>
                <SectionTree onChange={setSection}/>
            </ProCard>
            <ProCard size="small" colSpan={{ xs: 24, sm: 24, md: 17, lg: 17, xl: 17, xxl: 17 }} tabs={tabs}/>
        </ProCard>
    </PageContainer>)

}