import React from "react";
import {ModalForm, ProFormText} from "@ant-design/pro-components";

import EventBus from "@/framework/util/EventBusUtils";
import FormUtils from "@/framework/util/FormUtils";
import Rule from "@/framework/form/Rule";

import {requestUpdatePrintTemplate} from "../api/PrintTemplateAPI";
import PrintTemplateEvent from "../event/PrintTemplateEvent";

export default class UpdatePrintTemplateModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(PrintTemplateEvent.onTriggerUpdatePrintTemplate, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(PrintTemplateEvent.onTriggerUpdatePrintTemplate, console.log)
    }

    onFinish = (params) => requestUpdatePrintTemplate(params).then(() => {
        EventBus.emit(PrintTemplateEvent.onPrintTemplateUpdated)
        return true
    }).catch((err) => {
        console.error("编辑打印模版失败", err);
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false;
    })

    onTrigger = (item) => this.setState({open: true}, () => {
        setTimeout(() => this.formRef.current?.setFieldsValue(JSON.parse(JSON.stringify(item))), 100);
    })

    render() {
        return (<ModalForm title="编辑打印模版"
                           modalProps={{destroyOnClose: true, width: 560}}
                           open={this.state.open}
                           onOpenChange={open => this.setState({open})}
                           formRef={this.formRef}
                           onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden/>
            <ProFormText name="name" label="模版名称" rules={Rule.require}/>
            <ProFormText name="description" label="描述" rules={Rule.require}/>
            <ProFormText name="comment" label="备注" rules={Rule.require}/>
            <ProFormText name="content" label="内容" rules={Rule.require}/>
        </ModalForm>)
    }
}