const Regular = {
    /**
     * 文本
     * @description 只能输入数字、字母、汉字以及符号（@ , . ( ) （ ） 。 ，）
     */
    text: /^[\u4E00-\u9FA5A-Za-z0-9_@.,+\-*/():（）《》。，：；;、“”"\s]+$/,
    /**
     * 文本
     * @description 首尾不能包含空格的
     */
    trimText: /^\S+(\s+\S+)*$/
}

export default Regular
