import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {ProDescriptions} from "@ant-design/pro-components";
import {requestUserById} from "@/member/user/api/UserAPI";

export default function ProcessInstanceTaskDescription({task, ...restProps}) {

    useEffect(() => {
        if (!!task) {
            task.duration = task.duration / 1000
        }
    }, [task]);

    const columns = [
        {title: "任务名称", dataIndex: "name"},
        {
            title: "任务负责人",
            dataIndex: "assignee",
            fieldProps: {fieldNames: {label: "name", value: "id"}},
            request: (_, v) => requestUserById(v.text).then(res => [res.data]).catch(() => v.text)
        },
        {title: "优先级", dataIndex: "priority"},
        {title: "开始时间", dataIndex: "startTime", valueType: "dateTime"},
        {title: "结束时间", dataIndex: "endTime", valueType: "dateTime"}
    ]

    if (!task) {
        return undefined
    }

    return (<ProDescriptions columns={columns} dataSource={task} {...restProps}/>)

}

ProcessInstanceTaskDescription.propTypes = {
    task: PropTypes.object
}