import React from "react"
import { PageContainer } from "@ant-design/pro-components"

import RoleDataTable from "@/member/role/component/RoleDataTable"

/**
 * 角色管理表格页
 *
 * @description 该页面采用可编辑表格，除新增之外的操作皆在表格内完成
 * @description 该页面监听事件：onRoleCreate
 *
 * @author Wayne Carder
 */
export default function Roles() {

    return (<PageContainer>
        <RoleDataTable />
    </PageContainer>)

}