import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormText } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"

import { requestCreateMonthlyStatistic } from "@/statistic/monthly/api/MonthlyStatisticAPI"
import MonthlyStatisticEvent from "@/statistic/monthly/event/MonthlyStatisticEvent"

export default function CreateMonthlyStatisticModal() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增每月统计</Button>)

    const onFinish = (params) => requestCreateMonthlyStatistic(params).then(res => {
        EventBus.emit(MonthlyStatisticEvent.onMonthlyStatisticCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增每月统计失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增每月统计"
        layout="horizontal"
        modalProps={{ destroyOnClose: true, width: 560 }}
        formRef={formRef}
        trigger={trigger}
        onFinish={onFinish}>
        <ProFormText name="module" label="功能模块" rules={Rule.require} />
        <ProFormText name="label" label="指标名称" rules={Rule.require} />
        <ProFormText name="year" label="年份" rules={Rule.require} />
        <ProFormText name="month" label="月份" rules={Rule.require} />
        <ProFormText name="devIdentifier" label="开发标识符" rules={Rule.require} />
        <ProFormText name="bizIdentifier" label="业务标识符" rules={Rule.require} />
        <ProFormText name="comment" label="备注" rules={Rule.require} />
        <ProFormText name="statisticValue" label="统计值" rules={Rule.require} />
    </ModalForm>)
}