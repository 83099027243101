import {requestMetaById} from "../../common/meta/api/MetaAPI";

/**
 * 工作流生命周期 - 表单渲染完成
 * @param processDefinition 流程定义
 * @param variables 流程变量
 * @param extensionProperties 流程扩展属性
 * @param formRef 流程表单 Reference
 * @param batchIndex 批量新建时，数据下标
 */
function onFormRendered(processDefinition, variables, extensionProperties, formRef, batchIndex = 0) {
    if (!extensionProperties["onFormRendered"]) {
        return
    }
    requestMetaById(extensionProperties["onFormRendered"]).then(res => {
        // eslint-disable-next-line no-new-func
        const onFormRendered = new Function("processDefinition", "variables", "extensionProperties", "formRef", "batchIndex", res.data.content)
        onFormRendered(processDefinition, variables, extensionProperties, formRef, batchIndex);
    }).catch(err => {
        console.log(err)
    })
}

function beforeProcessStart(processDefinition, variables, extensionProperties, formRef) {
    if (!extensionProperties["beforeProcessStart"]) {
        return
    }
    requestMetaById(extensionProperties["beforeProcessStart"]).then(res => {
        // eslint-disable-next-line no-new-func
        const beforeProcessStart = new Function("processDefinition", "variables", "extensionProperties", "formRef", res.data.content)
        beforeProcessStart(processDefinition, variables, extensionProperties, formRef);
    }).catch(err => {
        console.log(err)
    })
}

function onProcessStarted(processDefinition, variables, extensionProperties, formRef) {
    if (!extensionProperties["onProcessStarted"]) {
        return
    }
    requestMetaById(extensionProperties["onProcessStarted"]).then(res => {
        // eslint-disable-next-line no-new-func
        const onProcessStarted = new Function("processDefinition", "variables", "extensionProperties", "formRef", res.data.content)
        onProcessStarted(processDefinition, variables, extensionProperties, formRef);
    }).catch(err => {
        console.log(err)
    })
}

const WorkflowLifecycleUtils = {
    onFormRendered: onFormRendered,
    beforeProcessStart: beforeProcessStart,
    onProcessStarted: onProcessStarted
}

export default WorkflowLifecycleUtils