import React, { useEffect, useState } from "react"
import { Button, Modal, Typography } from "antd"
import EventBus from "@/framework/util/EventBusUtils"
import UserEvent from "@/member/user/event/UserEvent"
import { useNavigate } from "react-router-dom"

export default function AuthenticationFailureModal() {

    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        EventBus.on(UserEvent.onAuthenticationFailure, () => setOpen(true))
        return () => {
            EventBus.off(UserEvent.onAuthenticationFailure, console.log)
        }
    }, [])

    const onOk = () => {
        setOpen(false)
        navigate("/", { replace: true })
        window.localStorage.removeItem("User")
        window.localStorage.removeItem("Authorization")
    }

    return (<Modal title="登录状态失效"
        open={open}
        centered
        closable={false}
        footer={() => (<Button onClick={onOk}>好的</Button>)}>
        <Typography.Paragraph>
            您需要重新登录后才能访问系统，登录状态失效原因可能有：
            <ol>
                <li>距离上次登录时间较长</li>
                <li>您的账户在其他地点登录</li>
                <li>您被管理员强制要求下线</li>
            </ol>
        </Typography.Paragraph>
    </Modal>)

}