import React from "react";
import { DrawerForm, ProFormDigit, ProFormField, ProFormSelect, ProFormText } from "@ant-design/pro-components";

import EventBus from "../../../framework/util/EventBusUtils";
import FormUtils from "../../../framework/util/FormUtils";
import Rule from "../../../framework/form/Rule";

import { requestUpdateCooperativePartner } from "../api/PartnerAPI";
import CooperativePartnerEvent from "../event/CooperativePartnerEvent";
import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker";
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils";

export default class UpdateCooperativePartnerDrawer extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(CooperativePartnerEvent.onTriggerUpdateCooperativePartner, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(CooperativePartnerEvent.onTriggerUpdateCooperativePartner, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({ open })
    }

    onFinish = (params) => requestUpdateCooperativePartner(params).then(() => {
        EventBus.emit(CooperativePartnerEvent.onCooperativePartnerUpdated)
        return true
    }).catch((err) => {
        console.error("编辑合作伙伴失败", err);
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false;
    })

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100);
        })
    }

    render() {
        return (<DrawerForm title="编辑合作伙伴"
                            drawerProps={{ destroyOnClose: true, width: 640 }}
                            open={this.state.open}
                            onOpenChange={this.toggle}
                            formRef={this.formRef}
                            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden/>
            <ProFormField name="avatar" label="头像">
                <AttachmentPicker accept={["image/png", "image/jpeg"]}/>
            </ProFormField>
            <ProFormText name="department" label="单位" rules={Rule.require}/>
            <ProFormText name="name" label="姓名" rules={Rule.require}/>
            <ProFormText name="cellphone" label="手机号码" rules={Rule.require}/>
            <ProFormText name="post" label="岗位" rules={Rule.require}/>
            <ProFormText name="description" label="描述" rules={Rule.require}/>
            <ProFormText name="posters" label="海报" rules={Rule.require}/>
            <ProFormSelect name="type" label="类型" rules={Rule.require}
                           request={() => DictionaryVariableUtils.requestDvByList("partner-type")}/>
            <ProFormDigit fieldProps={{ precision: 0 }} name="sort" label="排序" rules={Rule.require}/>
        </DrawerForm>)
    }
}