import React from "react";
import {ProTable} from "@ant-design/pro-components";
import EventBus from "@/framework/util/EventBusUtils";
import DictionaryVariableEvent from "../event/DictionaryVariableEvent";
import {requestDictionaryVariableByTree} from "../api/DictionaryVariableAPI";
import {Alert, Button, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import PermissionWrapper from "@/framework/component/PermissionWrapper";
import CreateDictionaryVariableModal from "../interaction/CreateDictionaryVariableModal";
import DeleteDictionaryVariableButton from "./DeleteDictionaryVariableButton";
import UpdateDictionaryVariableModal from "../interaction/UpdateDictionaryVariableModal";
import AvailableEnum from "@/framework/enum/AvailableEnum";

const DictionaryVariableDataTableColumnState = {
    persistenceKey: "ColumnStateForDictionaryVariable",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false}
    }
}

const DictionaryVariableDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["dictionary-variable:insert"]}>
        <Button type="primary" icon={<PlusOutlined/>}
                onClick={() => EventBus.emit(DictionaryVariableEvent.onTriggerCreateDictionaryVariable)}>新增字典值</Button>
    </PermissionWrapper>
</Space>)

const DictionaryVariableDataTableColumnOperations = ({item}) => (<Space>
    <PermissionWrapper permissions={["dictionary-variable:update"]}>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(DictionaryVariableEvent.onTriggerUpdateDictionaryVariable, item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["dictionary-variable:insert"]}>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(DictionaryVariableEvent.onTriggerCreateDictionaryVariable, item)}>新增子选项</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["dictionary-variable:delete"]}>
        <DeleteDictionaryVariableButton id={item.id}/>
    </PermissionWrapper>
</Space>)

const DictionaryVariableDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "显示名称",
        dataIndex: "label",
        ellipsis: true
    },
    {
        title: "变量值",
        dataIndex: "variable",
        copyable: true,
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "描述",
        dataIndex: "description",
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "备注",
        dataIndex: "comment",
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "是否可用",
        dataIndex: "available",
        valueEnum: AvailableEnum,
        hideInSearch: true,
        width: 80
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<DictionaryVariableDataTableColumnOperations item={item}/>)
    }
]

export default class DictionaryVariableDataTable extends React.Component {

    tableActionRef = React.createRef()

    componentDidMount() {
        EventBus.on(DictionaryVariableEvent.onDictionaryVariableCreated, this.tableActionRef.current?.reload)
        EventBus.on(DictionaryVariableEvent.onDictionaryVariableUpdated, this.tableActionRef.current?.reload)
        EventBus.on(DictionaryVariableEvent.onDictionaryVariableDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(DictionaryVariableEvent.onDictionaryVariableCreated, console.log)
        EventBus.off(DictionaryVariableEvent.onDictionaryVariableUpdated, console.log)
        EventBus.off(DictionaryVariableEvent.onDictionaryVariableDeleted, console.log)
    }

    request = (params) => requestDictionaryVariableByTree(params).then(res => {
        return {data: res.data, success: true, total: res.data.length}
    }).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<>
            <Alert type="warning" style={{marginBottom: 24}} closable
                   message="请谨慎操作字典值，操作不当可能会影响系统的正常运转。"/>
            <ProTable rowKey="id"
                      cardBordered
                      actionRef={this.tableActionRef}
                      scroll={{x: "100%"}}
                      params={{dictionaryLabel: this.props.dictionary}}
                      columns={DictionaryVariableDataTableColumns}
                      columnsState={DictionaryVariableDataTableColumnState}
                      toolBarRender={() => (<DictionaryVariableDataTableOperations/>)}
                      pagination={false}
                      request={this.request}/>
            <PermissionWrapper permissions={["dictionary-variable:insert"]}>
                <CreateDictionaryVariableModal dictionary={this.props.dictionary}/>
            </PermissionWrapper>
            <PermissionWrapper permissions={["dictionary-variable:update"]}>
                <UpdateDictionaryVariableModal/>
            </PermissionWrapper>
        </>)
    }

}