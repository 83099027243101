import React from "react";
import {ModalForm, ProFormRadio, ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import Rule from "@/framework/form/Rule";
import EventBus from "@/framework/util/EventBusUtils";
import DictionaryVariableEvent from "../event/DictionaryVariableEvent";
import {requestCreateDictionaryVariable} from "../api/DictionaryVariableAPI";
import FormUtils from "@/framework/util/FormUtils";
import {getAvailableEnumAsOptions} from "@/framework/enum/AvailableEnum";

export default class CreateDictionaryVariableModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false
    }

    componentDidMount() {
        EventBus.on(DictionaryVariableEvent.onTriggerCreateDictionaryVariable, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(DictionaryVariableEvent.onTriggerCreateDictionaryVariable, console.log)
    }

    onTrigger = (parent) => this.setState({open: true}, () => {
        setTimeout(() => {
            this.formRef.current?.setFieldsValue({parent: parent?.id, dictionaryLabel: this.props.dictionary})
        }, 150)
    })

    onFinish = (params) => requestCreateDictionaryVariable(params).then(res => {
        EventBus.emit(DictionaryVariableEvent.onDictionaryVariableCreated, res.data)
        return true
    }).catch(err => {
        console.warn("新建字典值失败", err)
        FormUtils.setFormError(this.formRef, err.response.data, "label")
        return false
    })

    render() {
        return (<ModalForm title="新增字典值"
                           modalProps={{width: 560, destroyOnClose: true}}
                           trigger={undefined}
                           open={this.state.open}
                           formRef={this.formRef}
                           onOpenChange={open => this.setState({open})}
                           onFinish={this.onFinish}>

            <ProFormText name="parent" label="父选项" hidden/>
            <ProFormText name="dictionaryLabel" label="字典" hidden/>

            <ProFormText width="md" name="label" label="显示名称" rules={Rule.requireText}/>

            <ProFormText width="md" name="variable" label="字典值" rules={Rule.require}/>

            <ProFormRadio.Group name="available" label="可用状态"
                                initialValue="可用"
                                rules={Rule.require}
                                options={getAvailableEnumAsOptions()}/>

            <ProFormTextArea name="description" label="描述" rules={Rule.text}/>

            <ProFormTextArea name="comment" label="备注" rules={Rule.text}/>

        </ModalForm>)
    }

}