import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { DrawerForm, ProFormDigit, ProFormField, ProFormSelect, ProFormText } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule";

import { requestCreateCooperativePartner } from "../api/PartnerAPI"
import CooperativePartnerEvent from "../event/CooperativePartnerEvent"
import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker";
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils";

export default function CreateCooperativePartnerDrawer() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined/>}>新增合作伙伴</Button>)

    const onFinish = (params) => requestCreateCooperativePartner(params).then(res => {
        EventBus.emit(CooperativePartnerEvent.onCooperativePartnerCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增合作伙伴失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<DrawerForm title="新增合作伙伴"
                        drawerProps={{ destroyOnClose: true, width: 640 }}
                        formRef={formRef}
                        trigger={trigger}
                        onFinish={onFinish}>
        <ProFormField name="avatar" label="头像">
            <AttachmentPicker accept={["image/png", "image/jpeg"]}/>
        </ProFormField>
        <ProFormText name="department" label="单位" rules={Rule.require}/>
        <ProFormText name="name" label="姓名" rules={Rule.require}/>
        <ProFormText name="cellphone" label="手机号码" rules={Rule.require}/>
        <ProFormText name="post" label="岗位" rules={Rule.require}/>
        <ProFormText name="description" label="描述" rules={Rule.require}/>
        <ProFormText name="posters" label="海报" rules={Rule.require}/>
        <ProFormSelect name="type" label="类型" rules={Rule.require}
                       request={() => DictionaryVariableUtils.requestDvByList("partner-type")}/>
        <ProFormDigit fieldProps={{ precision: 0 }} name="sort" label="排序" rules={Rule.require}/>
    </DrawerForm>)
}