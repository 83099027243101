import React, { useState } from 'react'
import { PageContainer } from "@ant-design/pro-components"

import DailyStatisticDataTable from "@/statistic/daily/component/DailyStatisticDataTable";
import MonthlyStatisticDataTable from "@/statistic/monthly/component/MonthlyStatisticDataTable";

export default function Statistic() {

    const [tab, setTab] = useState("daily")

    const tabItem = {
        "daily": <DailyStatisticDataTable />,
        "monthly": <MonthlyStatisticDataTable />
    }

    const tabList = [
        {
            tab: "每日统计",
            key: "daily",
        },
        {
            tab: "每月统计",
            key: "monthly"
        }
    ]

    return (<PageContainer tabList={tabList} tabProps={{ defaultActiveKey: "daily" }} onTabChange={setTab}>
        {tabItem[tab]}
    </PageContainer>)

}