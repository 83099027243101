import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import {
    ModalForm,
    ProFormDigit,
    ProFormField,
    ProFormRadio,
    ProFormText,
    ProFormTreeSelect
} from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule";
import SecurityUtils from "@/framework/util/SecurityUtils";

import { requestCreateSection, requestSectionByTree } from "@/content/section/api/SectionAPI"
import SectionEvent from "@/content/section/event/SectionEvent"
import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker";

export default class CreateSectionModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    trigger = (<Button type="primary" icon={<PlusOutlined/>}>新增栏目</Button>)

    componentDidMount() {
        EventBus.on(SectionEvent.onTriggerCreateSection, (parent) => this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue({ parent }), 100)
        }))
    }

    componentWillUnmount() {
        EventBus.off(SectionEvent.onTriggerCreateSection, console.log)
    }

    onFinish = (params) => requestCreateSection(params).then(res => {
        EventBus.emit(SectionEvent.onSectionCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增栏目失败", err)
        FormUtils.setFormError(this.formRef, err.response.data, "name")
        return false
    })

    onRequestParentTree = (params) => requestSectionByTree(params).then(res => {
        if (SecurityUtils.hasSuperPermission()) {
            res.data.push({ name: "顶层栏目", id: "0", disabled: false })
        }
        for (let datum of res.data) {
            this.handleParentOptions(datum)
        }
        return res.data
    }).catch(() => {
        return [{ name: "顶层栏目", id: "0", disabled: false }]
    })

    handleParentOptions = (treeNode) => {
        treeNode.disabled = treeNode.leaf === 1
        if (treeNode.children !== undefined && treeNode.children !== null && treeNode.children.length > 0) {
            for (let child of treeNode.children) {
                this.handleParentOptions(child)
            }
        }
    }

    render() {
        return (<ModalForm title="新增栏目"
                           open={this.state.open}
                           onOpenChange={open => this.setState({ open })}
                           modalProps={{ destroyOnClose: true, width: 480 }}
                           formRef={this.formRef}
                           onFinish={this.onFinish}>
            <ProFormField name="icon">
                <AttachmentPicker accept={["image/png", "image/jpeg"]}/>
            </ProFormField>
            <ProFormTreeSelect width="sm" name="parent" label="父栏目" rules={Rule.require}
                               fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                               request={this.onRequestParentTree}/>
            <ProFormText width="md" name="name" label="名称"
                         rules={Rule.require}/>
            <ProFormDigit width="xs" name="sort" label="排序"
                          initialValue={1}
                          fieldProps={{ precision: 0, max: 999 }}
                          rules={Rule.require}/>
            <ProFormRadio.Group name="top" label="置顶"
                                initialValue={0}
                                options={[{ label: "是", value: 1 }, { label: "否", value: 0 }]}
                                rules={Rule.require}/>
            <ProFormRadio.Group name="leaf" label="叶子结点"
                                initialValue={1}
                                options={[{ label: "是", value: 1 }, { label: "否", value: 0 }]}
                                rules={Rule.require}/>
        </ModalForm>)
    }
}

CreateSectionModal.open = (parent) => {
    EventBus.emit(SectionEvent.onTriggerCreateSection, parent)
}