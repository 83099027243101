// Definition API
export const requestProcessDefinitionByList = (params) => window.axios.get("/workflow/process-definition/list", {params})
export const requestProcessDefinitionByPage = (params) => window.axios.get("/workflow/process-definition/page", {params})
export const requestCreateProcessDefinition = (params) => window.axios.post("/workflow/process-definition", params)
export const requestDeleteProcessDefinition = (id) => window.axios.delete(`/workflow/process-definition/${id}`)
export const requestProcessDefinitionBpmn = (id) => window.axios.get(`/workflow/process-definition/${id}/bpmn`)
export const requestProcessDefinitionStartForm = (id) => window.axios.get(`/workflow/process-definition/${id}/form`)
export const requestProcessDefinitionExtensionPropertiesById = (id) => window.axios.get(`/workflow/process-definition/${id}/extension-properties`)


// Instance API
export const requestCreateProcessInstance = (params) => window.axios.post(`/workflow/process-definition/${params.processDefinitionId}/start`, params)
export const requestCreateBatchProcessInstance = ({id, variables}) => window.axios.post(`/workflow/process-definition/${id}/start-batch`, variables)
export const requestProcessInstanceByList = (params) => window.axios.get("/workflow/process-instance/list", {params})
export const requestProcessInstanceByPage = (params) => window.axios.get("/workflow/process-instance/page", {params})
export const requestDeleteProcessInstance = (params) => window.axios.delete(`/workflow/process-instance/${params.id}`, {data: params})


// Task API
export const requestClaimProcessInstanceTask = (params) => window.axios.patch(`/workflow/process-instance/task/${params.id}/claim`, params)
export const requestAssignProcessInstanceTask = (params) => window.axios.patch(`/workflow/process-instance/task/${params.id}/assignee`, params)
export const requestProcessInstanceTaskByPage = (params) => window.axios.get("/workflow/process-instance/task/page", {params})
export const requestProcessInstanceTaskByList = (params) => window.axios.get("/workflow/process-instance/task/list", {params})
export const requestCompleteProcessInstanceTask = (params) => window.axios.patch(`/workflow/process-instance/task/${params.id}/process`, params)
export const requestProcessInstanceTaskFormById = (id) => window.axios.get(`/workflow/process-instance/task/${id}/form`)
export const requestProcessInstanceTaskExtensionPropertiesById = (id) => window.axios.get(`/workflow/process-instance/task/${id}/extension-properties`)
export const requestFormDeploymentByName = (deploymentName) => window.axios.get("/workflow/form", {params: {deploymentName}})

// History API
export const requestDeleteHistoryProcessInstanceById = (id) => window.axios.delete(`/workflow/history/${id}`)
export const requestHistoryProcessInstanceVariablesByList = (id) => window.axios.get(`/workflow/history/process-instance/${id}/variable/list`)
export const requestHistoryProcessVariablesByList = (params) => window.axios.get(`/workflow/history/process-instance/variables/list`, {params})
export const requestHistoryProcessInstanceFinishedTaskByList = (id) => window.axios.get(`/workflow/history/process-instance/${id}/finished-task/list`)
