const AvailableEnum = {
    "可用": { text: "可用", status: "Success" },
    "禁用": { text: "禁用", status: "Error" }
}

export const getAvailableEnumAsOptions = () => {
    const options = []
    for (let availableEnumKey in AvailableEnum) {
        options.push({ label: availableEnumKey, value: AvailableEnum[availableEnumKey].text })
    }
    return options
}

export const ENABLE = "可用"
export const DISABLE = "禁用"
export default AvailableEnum