import React from "react"
import PropTypes from "prop-types"
import { Alert, Button, Col, message, Modal, Row, Space, Upload } from "antd"
import { InboxOutlined, UploadOutlined } from "@ant-design/icons"

import EventBus from "@/framework/util/EventBusUtils"
import { baseURL } from "@/framework/axios/Axios"
import DownloadUtils from "@/framework/util/DownloadUtils"

import UserEvent from "@/member/user/event/UserEvent"
import { requestDownloadUserExcelTemplate } from "@/member/user/api/UserAPI"

export default class UploadUserTemplateModal extends React.Component {

    state = {
        open: false,
        uploading: false,
        downloading: false
    }

    footerRender = () => (<Space>
        <Button loading={this.state.downloading} onClick={this.onDownloadImportTemplate}>下载导入模版</Button>
        <Button onClick={this.closeModal} disabled={this.state.uploading}>关闭</Button>
    </Space>)

    showModal = () => this.setState({ open: true })

    closeModal = () => this.setState({ open: false })

    onChange = (file) => {
        if (file.file.status === "done") {
            this.onFileUploadSuccess(file.file)
            return
        }
        if (file.file.status === "error") {
            this.onFileUploadFailure(file.file)
        }
    }

    onFileUploadSuccess = () => {
        EventBus.emit(UserEvent.onUserCreated)
        this.closeModal()
    }

    onFileUploadFailure = (file) => {
        for (let key in file.response) {
            message.open({ type: "error", content: file.response[key] }).then()
        }
    }

    onDownloadImportTemplate = () => {
        this.setState({ downloading: true })
        requestDownloadUserExcelTemplate().then(res => {
            DownloadUtils.download(res.data, res.headers.contentType, "用户模版.xlsx")
            this.setState({ downloading: false })
        }).catch(err => {
            this.setState({ downloading: false })
            console.log(err.response)
            message.open({ type: "error", content: err.message }).then()
        })
    }

    render() {
        return (<>
            <Button onClick={this.showModal}>
                <UploadOutlined />导入
            </Button>
            <Modal width={560}
                destroyOnClose
                title="导入用户"
                open={this.state.open}
                closable={!this.state.uploading}
                onCancel={this.closeModal}
                footer={this.footerRender()}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Alert type="info" showIcon message="推荐使用系统提供的 Excel 模版进行导入" />
                    </Col>
                    <Col span={24}>
                        <Alert type="info" showIcon
                            message="系统无法为您设置导入用户的角色，请您在导入后自行配置用户角色" />
                    </Col>
                    <Col span={24}>
                        <Upload.Dragger
                            action={`${baseURL}/member/department/${this.props.department}/user/template/excel`}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            method="POST"
                            maxCount={1}
                            name="template"
                            headers={{ Authorization: localStorage.getItem("Authorization") }}
                            onChange={this.onChange}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                点击或拖拽模版文件到此处上传模版文件
                            </p>
                            <p className="ant-upload-hint">
                                仅支持后缀为 ".xls" 和 ".xlsx" 的 Excel 文件
                            </p>
                        </Upload.Dragger>
                    </Col>
                </Row>
            </Modal>
        </>)
    }

}

UploadUserTemplateModal.propTypes = {
    department: PropTypes.string.isRequired
}