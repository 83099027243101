export default function ColumnsConverter({ components }) {
    const columns = []
    for (let component of components) {
        switch (component.type) {
            case "number":
                columns.push(convertNumber2Column(component))
                break
            case "textfield":
                columns.push(convertText2Column(component))
                break
            case "select":
                columns.push(convertSelect2Column(component))
                break
            case "image":
                columns.push(convertImage2Column(component))
                break
            default:
                columns.push(convertText2Column(component))
                break
        }
    }
    return columns
}


function convertSelect2Column(item) {
    return {
        label: item.label,
        title: item.label,
        dataIndex: item.key,
        valueType: true === item.properties?.tree ? "treeSelect" : "select",
        fieldProps: getFieldProps(item),
        request: getFieldRequest(item)
    }
}

function convertText2Column({ label, key }) {
    return { label, title: label, dataIndex: key, valueType: "text" }
}

function convertImage2Column({ properties }) {
    return { label: properties.label, title: properties.label, dataIndex: properties.key, valueType: "image" }
}

function convertNumber2Column({ label, key }) {
    return { label, title: label, dataIndex: key, valueType: "digit" }
}

function getFieldRequest(component) {
    if (!!component.properties?.requestUrl) {
        const { requestUrl, requestParam } = component.properties
        const params = !!requestParam ? JSON.parse(requestParam) : undefined
        return () => window.axios.get(requestUrl, { params }).then(res => {
            return res.data
        }).catch(err => {
            console.error("数据源请求失败" + component.key, err)
            return []
        })
    }
    return undefined
}

function getFieldProps(component) {
    if (!!component.properties?.fieldProps) {
        return JSON.parse(component.properties.fieldProps)
    }
    return undefined
}