import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormDependency, ProFormSelect, ProFormText, ProFormTreeSelect } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"

import { requestCreateWarehouse } from "@/warehouse/warehouse/api/WarehouseAPI"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"
import { requestUserByList } from "@/member/user/api/UserAPI"

import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils"
import WarehouseEvent from "@/warehouse/warehouse/event/WarehouseEvent"


export default function CreateWarehouseModal() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增仓库</Button>)

    const onFinish = (params) => {
        params.environment = params.environment.join(",")
        return requestCreateWarehouse(params).then(res => {
            EventBus.emit(WarehouseEvent.onWarehouseCreated, res.data)
            return true
        }).catch(err => {
            console.log("新增仓库失败", err)
            FormUtils.setFormError(formRef, err.response.data, "name")
            return false
        })
    }

    return (<ModalForm title="新增仓库"
        modalProps={{ destroyOnClose: true, width: 560 }}
        formRef={formRef}
        trigger={trigger}
        onFinish={onFinish}>
        <ProFormText width="sm" name="name" label="名称" rules={Rule.requireText} />
        <ProFormText width="lg" name="address" label="地址" rules={Rule.text} />

        <ProFormTreeSelect width="sm" name="department" label="单位"
            fieldProps={{ fieldNames: { label: "name", value: "id" } }}
            request={() => requestDepartmentByTree().then(res => res.data).catch(() => [])}
            rules={Rule.require} />

        <ProFormDependency name={['department']}>
            {
                ({ department }) => (<ProFormSelect width="sm" name="manager" label="管理员"
                    params={{ department }}
                    fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                    request={({ department }) => requestUserByList({ department, exactDepartment: 1 }).then(res => res.data).catch(() => [])}
                    rules={Rule.require} />)
            }
        </ProFormDependency>

        <ProFormSelect width="md" name="environment" label="仓库存储环境"
            mode="tags"
            request={() => DictionaryVariableUtils.requestDvByList("warehouse-environment")} />
        <ProFormSelect width="sm" name="type" label="仓库类型"
            request={() => DictionaryVariableUtils.requestDvByList("warehouse-type")} />
    </ModalForm>)
}