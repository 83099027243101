/**
 * 新建规格属性
 */
export const requestCreateSpecificationProperty = (params) => window.axios.post("/specification/specification/property", params)

/**
 * 分页查询规格属性
 * @param params 查询参数
 */
export const requestSpecificationPropertyByPage = (params) => window.axios.get("/specification/specification/property/page", { params })

/**
 * 查询规格属性
 * @param params 查询参数
 */
export const requestSpecificationPropertyByList = (params) => window.axios.get("/specification/specification/property/list", { params })

/**
 * 查询指定ID规格属性
 * @param id {string} 规格属性ID
 */
export const requestSpecificationPropertyById = (id) => window.axios.get(`/specification/specification/property/${id}`)

/**
 * 更新规格属性
 */
export const requestUpdateSpecificationProperty = (params) => window.axios.patch(`/specification/specification/property/${params.id}`, params)

/**
 * 删除规格属性
 */
export const requestDeleteSpecificationProperty = (id) => window.axios.delete(`/specification/specification/property/${id}`)
