import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import {
    DrawerForm,
    ProFormDependency,
    ProFormField,
    ProFormRadio,
    ProFormSelect,
    ProFormText,
    ProFormTreeSelect
} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import { getAvailableEnumAsOptions } from "@/framework/enum/AvailableEnum"

import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker"
import { requestCreateUser } from "@/member/user/api/UserAPI"
import UserEvent from "@/member/user/event/UserEvent"
import Rule from "@/framework/form/Rule"
import { requestPostByList } from "@/member/post/api/PostAPI"
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"

export default function CreateUserDrawer() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增</Button>)

    const onFinish = (params) => requestCreateUser(params).then((res) => {
        EventBus.emit(UserEvent.onUserCreated, res.data)
        formRef.current?.resetFields()
        return true
    }).catch(err => {
        FormUtils.setFormError(formRef, err.response?.data, "name")
        return false
    })

    return (<DrawerForm title="新增用户"
        drawerProps={{ width: 560, destroyOnClose: true }}
        scrollToFirstError
        isKeyPressSubmit
        formRef={formRef}
        trigger={trigger}
        onFinish={onFinish}>

        <ProFormField label="头像" name="avatar">
            <AttachmentPicker count={1} multiple={false} accept={["image/png", "image/jpeg"]} />
        </ProFormField>

        <ProFormText width="md" name="name" label="姓名"
            rules={Rule.requireText} />

        <ProFormText width="md" name="authentication" label="用户名"
            rules={Rule.requireText} />

        <ProFormText.Password width="md" name="credential" label="密码"
            rules={[
                { required: true, message: "请输入密码" },
                { min: 6, message: "密码长度应在[6,24]" },
                { max: 24, message: "密码长度应在[6,24]" },
                {
                    pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,24}/,
                    message: "密码应包含大小写字母和数字"
                }
            ]} />

        <ProFormText.Password width="md" name="confirmCredential" label="确认密码"
            rules={[
                { required: true, message: "请输入密码" },
                { min: 6, message: "密码长度应在[6,32]" },
                { max: 32, message: "密码长度应在[6,32]" },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('credential') === value) {
                            return Promise.resolve()
                        }
                        return Promise.reject(new Error('确认密码匹配失败'))
                    },
                })
            ]} />

        <ProFormSelect width="xs" name="type" label="用户类型"
            initialValue="0"
            request={() => DictionaryVariableUtils.requestDvByList("user-type")} />

        <ProFormSelect width="xs" name="grade" label="用户级别"
            initialValue="0"
            request={() => DictionaryVariableUtils.requestDvByList("user-grade")} />

        <ProFormRadio.Group name="available" label="可用状态"
            initialValue="可用"
            rules={Rule.require}
            options={getAvailableEnumAsOptions()} />

        <ProFormTreeSelect width="md" name="department" label="单位"
            rules={Rule.require}
            fieldProps={{ fieldNames: { label: "name", value: "id", children: "children" } }}
            request={() => requestDepartmentByTree().then(res => res.data).catch(() => [])} />

        <ProFormDependency name={["department"]}>
            {({ department }) => (<ProFormSelect width="sm" name="post" label="岗位" showSearch
                params={{ department }}
                fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                request={(params) => requestPostByList(params).then(res => res.data).catch(() => [])} />)}
        </ProFormDependency>

    </DrawerForm>)
}