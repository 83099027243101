import React from "react";
import { Button, Space } from "antd";
import { ProTable } from "@ant-design/pro-components";

import PermissionWrapper from "../../../framework/component/PermissionWrapper";
import EventBus from "../../../framework/util/EventBusUtils";

import { requestActivityByPage } from "../api/ActivityAPI";
import ActivityEvent from "../event/ActivityEvent";
import DeleteActivityButton from "./DeleteActivityButton";
import CreateActivityDrawer from "../interaction/CreateActivityDrawer";
import UpdateActivityDrawer from "../interaction/UpdateActivityDrawer";
import PreviewActivityDrawer from "../interaction/PreviewActivityDrawer";
import DictionaryVariableUtils from "../../../common/dictionary/variable/util/DictionaryVariableUtils";
import PreviewActivityQrCodeModal from "@/association/activity/interaction/PreviewActivityQrCodeModal";
import NotifyActivityMemberButton from "@/association/activity/component/NotifyActivityMemberButton";

const ActivityDataTableColumnState = {
    persistenceKey: "ActivityDataTableColumnState",
    persistenceType: "localStorage",
    defaultValue: {
        id: { show: false },
        createTime: { show: false },
        modifyTime: { show: false }
    }
}

const ActivityDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["association-activity:insert"]}>
        <CreateActivityDrawer/>
    </PermissionWrapper>
</Space>)

const ActivityDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["association-activity:update"]}>
        <Button type="link" size="small"
                onClick={() => EventBus.emit(ActivityEvent.onTriggerUpdateActivity, props.item)}>编辑</Button>
    </PermissionWrapper>
    <Button type="link" size="small" onClick={() => PreviewActivityDrawer.open(props.item)}>人员</Button>
    <Button type="link" size="small" onClick={() => PreviewActivityQrCodeModal.open(props.item)}>二维码</Button>
    <NotifyActivityMemberButton activity={props.item.id}/>
    <PermissionWrapper permissions={["association-activity:delete"]}>
        <DeleteActivityButton id={props.item.id}/>
    </PermissionWrapper>
</Space>)

export default class ActivityDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "封面",
            dataIndex: "cover",
            valueType: "image",
            hideInSearch: true
        },
        {
            title: "名称",
            dataIndex: "name"
        },
        {
            title: "活动时间",
            dataIndex: "time",
            hideInSearch: true
        },
        {
            title: "活动地址",
            dataIndex: "address",
            hideInSearch: true
        },
        {
            title: "创建人",
            dataIndex: "creator",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInSearch: true
        },
        {
            title: "修改人",
            dataIndex: "modifier",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            hideInSearch: true
        },
        {
            title: "活动人数",
            dataIndex: "maxCount",
            width: 88,
            hideInSearch: true
        },
        {
            title: "报名时间",
            dataIndex: "signupTime",
            hideInSearch: true,
            render: (_, v) => v.allowSignup === 1 ? `${v.signupStartTime} - ${v.signupEndTime}` : "不允许报名"
        },
        {
            title: "可报名人数",
            dataIndex: "signupCount",
            hideInSearch: true
        },
        {
            title: "类型",
            dataIndex: "type",
            width: 80,
            request: () => DictionaryVariableUtils.requestDvByList("activity-type")
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<ActivityDataTableColumnOperations item={item}/>)
        }
    ]

    componentDidMount() {
        EventBus.on(ActivityEvent.onActivityCreated, this.tableActionRef.current?.reload)
        EventBus.on(ActivityEvent.onActivityUpdated, this.tableActionRef.current?.reload)
        EventBus.on(ActivityEvent.onActivityDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(ActivityEvent.onActivityCreated, console.log)
        EventBus.off(ActivityEvent.onActivityUpdated, console.log)
        EventBus.off(ActivityEvent.onActivityDeleted, console.log)
    }

    onRequestActivityPage = (params) => requestActivityByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      columns={this.columns}
                      actionRef={this.tableActionRef}
                      columnsState={ActivityDataTableColumnState}
                      toolBarRender={() => (<ActivityDataTableOperations/>)}
                      request={this.onRequestActivityPage}/>
            <PermissionWrapper permissions={["association-activity:update"]}>
                <UpdateActivityDrawer/>
            </PermissionWrapper>
            <PreviewActivityDrawer/>
            <PreviewActivityQrCodeModal/>
        </>);
    }

}