import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormText, ProFormTreeSelect } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"

import { requestCreateGroup } from "@/member/group/api/GroupAPI"
import GroupEvent from "@/member/group/event/GroupEvent"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"

export default function CreateGroupModal({ department }) {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增</Button>)

    const onFinish = (params) => requestCreateGroup(params).then(res => {
        EventBus.emit(GroupEvent.onGroupCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增用户组失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增用户组"
        modalProps={{ destroyOnClose: true, width: 560 }}
        formRef={formRef}
        trigger={trigger}
        onFinish={onFinish}>

        <ProFormTreeSelect width="md" name="department" label="单位"
            rules={Rule.require}
            initialValue={department}
            fieldProps={{ fieldNames: { label: "name", value: "id", children: "children" } }}
            request={() => requestDepartmentByTree().then(res => res.data).catch(() => [])} />

        <ProFormText width="md" name="name" label="名称" rules={Rule.require} />

        <ProFormText width="md" name="identifier" label="标识符" rules={Rule.require} />

    </ModalForm>)
}