import React from "react"
import { Button, Space, Typography } from "antd"
import { ProDescriptions } from "@ant-design/pro-components"

import SecurityUtils from "@/framework/util/SecurityUtils"
import EventBus from "@/framework/util/EventBusUtils"
import PermissionWrapper from "@/framework/component/PermissionWrapper"

import DepartmentEvent from "@/member/department/event/DepartmentEvent"
import DeleteDepartmentButton from "@/member/department/component/DeleteDepartmentButton"
import UpdateDepartmentModal from "@/member/department/interaction/UpdateDepartmentModal"

function DepartmentDescriptionExtra({ department }) {
    return (<Space size="small">
        <PermissionWrapper permissions={["department:update"]}>
            <Button size="small" type="link"
                onClick={() => EventBus.emit(DepartmentEvent.onTriggerDepartmentUpdate, department)}>
                编辑
            </Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["department:update"]}>
            <Button disabled={"是" === department.leaf} size="small" type="link"
                onClick={() => EventBus.emit(DepartmentEvent.onTriggerDepartmentCreate, department.id)}>
                新增子单位
            </Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["department:delete"]}>
            <DeleteDepartmentButton id={department.id}
                disabled={SecurityUtils.getUser()?.department?.id === department.id} />
        </PermissionWrapper>
    </Space>)
}

export default function DepartmentDescription({ department }) {
    if (!!department) {
        return (<><ProDescriptions title="单位信息" dataSource={department}
            bordered size="small"
            extra={<DepartmentDescriptionExtra department={department} />}>
            <ProDescriptions.Item label="名称" dataIndex="name" />
            <ProDescriptions.Item label="创建时间" dataIndex="createTime" valueType="fromNow" />
            <ProDescriptions.Item label="叶子结点" dataIndex="leaf" />
            <ProDescriptions.Item label="描述" dataIndex="description" />
        </ProDescriptions>
            <UpdateDepartmentModal />
        </>)
    } else {
        return (<Typography.Paragraph>请选择单位</Typography.Paragraph>)
    }

}