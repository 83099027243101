import React from "react"
import {message} from "antd"
import Viewer from "bpmn-js/lib/Viewer"
import PropTypes from "prop-types";

import {requestProcessDefinitionBpmn, requestProcessInstanceTaskByList} from "../api/WorkflowAPI"

import "../index.css"

export default class ProcessBpmn extends React.Component {

    viewer

    componentDidMount() {
        this.requestProcessDefinitionBpmn()
    }

    componentWillUnmount() {
        this.viewer?.detach()
    }

    removeLogo = () => {
        // 去除 Bpmn.io 编辑器 logo
        const Logo = document.querySelector(".bjs-powered-by")
        while (Logo.firstChild) {
            Logo.removeChild(Logo.firstChild)
        }
    }

    requestProcessDefinitionBpmn = () => {
        if (!this.props.processDefinitionId) {
            return
        }
        requestProcessDefinitionBpmn(this.props.processDefinitionId).then(res => {
            this.viewer = new Viewer({
                container: "#process-bpmn-viewer",
                navigation: {
                    zoomScroll: {
                        enabled: false
                    }
                },
            })
            this.removeLogo()
            this.viewer.importXML(res.data).then(() => {
                this.viewer.get('canvas')?.zoom('fit-viewport', 'auto')
                if (!!this.props.onElementClick) {
                    this.viewer.get('eventBus').on('element.click', this.props.onElementClick)
                }
                this.requestProcessInstanceActiveTask()
            })
        }).catch(err => {
            message.warning("预览失败", 2).then()
            console.error("查询流程定义图失败", err)
            this.setState({open: false})
        })
    }

    requestProcessInstanceActiveTask = () => {
        if (!!this.props.taskDefinitionKey) {
            this.viewer.get('canvas').addMarker(this.props.taskDefinitionKey, "highlight");
            return;
        }
        if (!this.props.processInstanceId) {
            return
        }
        requestProcessInstanceTaskByList({processInstanceId: this.props.processInstanceId, active: true}).then(res => {
            for (let datum of res.data) {
                this.viewer.get('canvas').addMarker(datum.taskDefinitionKey, "highlight")
            }
        }).catch(err => {
            console.warn("查询进行中的任务失败", err)
        })
    }

    render() {
        return (<div id="process-bpmn-viewer" style={{height: this.props.height, width: this.props.width}}/>)
    }

}

ProcessBpmn.propTypes = {
    processDefinitionId: PropTypes.string,
    processInstanceId: PropTypes.string,
    taskDefinitionKey: PropTypes.string,
    height: PropTypes.any,
    width: PropTypes.any,
    onElementClick: PropTypes.func
}
ProcessBpmn.defaultProps = {
    width: "100%",
    height: 240
}


