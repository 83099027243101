import { ProTable } from "@ant-design/pro-components"
import { Space } from "antd"

const UserRoleTable = (props) => {
    const columns = [
        { title: "ID", dataIndex: "id", hideInTable: true },
        { title: "名称", dataIndex: "name" },
        { title: "标识符", dataIndex: "identifier" }
    ]
    return (<ProTable cardProps={{ title: "角色信息", bordered: true, headerBordered: true }}
        tableStyle={{ marginTop: 16 }}
        options={false}
        rowKey="id"
        search={false}
        dataSource={props.user.roles}
        pagination={false}
        columns={columns} />)
}

const UserPermissionTable = (props) => {
    const dataSource = []
    if (props.user.roles !== undefined && props.user.roles !== null && props.user.roles.length > 0) {
        for (let role of props.user.roles) {
            if (role.permissions !== undefined && role.permissions !== null && role.permissions.length > 0) {
                for (let permission of role.permissions) {
                    dataSource.push(permission)
                }
            }
        }
    }
    const columns = [
        { title: "ID", dataIndex: "id", hideInTable: true },
        { title: "名称", dataIndex: "name" },
        { title: "标识符", dataIndex: "identifier" }
    ]
    return (<ProTable cardProps={{ title: "权限信息", bordered: true, headerBordered: true }}
        tableStyle={{ marginTop: 16 }}
        options={false}
        rowKey="id"
        search={false}
        dataSource={dataSource}
        pagination={false}
        columns={columns} />)
}

export default function TabForUserRole(props) {
    return (<Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <UserRoleTable user={props.user} />
        <UserPermissionTable user={props.user} />
    </Space>)
}