import React from "react"
import {Button} from "antd"
import {PlusOutlined} from "@ant-design/icons"
import {DrawerForm, ProFormRadio, ProFormText, ProFormTextArea} from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"

import {requestCreateMeta} from "../api/MetaAPI"
import MetaEvent from "../event/MetaEvent"

export default function CreateMetaDrawer() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined/>}>新增Meta</Button>)

    const onFinish = (params) => requestCreateMeta(params).then(res => {
        EventBus.emit(MetaEvent.onMetaCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增Meta失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<DrawerForm title="新增 Meta"
                        drawerProps={{destroyOnClose: true}}
                        formRef={formRef}
                        trigger={trigger}
                        onFinish={onFinish}>
        <ProFormText width="sm" name="devIdentifier" label="开发标识符" rules={Rule.requireText}/>
        <ProFormText width="sm" name="bizIdentifier" label="业务标识符" rules={Rule.requireText}/>
        <ProFormText width="sm" name="module" label="功能模块" rules={Rule.text}/>
        <ProFormText width="xl" name="comment" label="备注" rules={Rule.text}/>
        <ProFormText width="xl" name="description" label="描述" rules={Rule.text}/>
        <ProFormRadio.Group name="type" label="类型" rules={Rule.require} initialValue="json"
                            options={[
                                {label: "JSON", value: "json"},
                                {label: "JavaScript", value: "javascript"}
                            ]}/>
        <ProFormTextArea label="Meta" name="content"
                         fieldProps={{rows: 16}}
                         rules={Rule.require}/>
    </DrawerForm>)
}