import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {PageContainer, ProCard, ProForm, ProFormText, ProFormTextArea} from "@ant-design/pro-components";

import {requestCodeTemplateById, requestUpdateCodeTemplate} from "./api/CodeTemplateAPI";
import FormUtils from "@/framework/util/FormUtils";
import NotfoundWrapper from "@/framework/component/NotfoundWrapper";
import {message} from "antd";
import Rule from "@/framework/form/Rule";

export default function UpdateCodeTemplate() {

    const id = useParams()?.id
    const navigate = useNavigate()
    const formRef = useRef()
    const [loading, setLoading] = useState(true)
    const [notFound, setNotFound] = useState(false)

    const onRequestCodeTemplate = () => {
        requestCodeTemplateById(id).then(res => {
            setLoading(false)
            setNotFound(false)
            setTimeout(() => {
                formRef.current?.setFieldsValue(res.data)
            }, 100)
        }).catch(() => {
            setLoading(false)
            setNotFound(true)
        })
    }

    const onFinish = (params) => requestUpdateCodeTemplate(params).then(() => {
        navigate(-1)
        message.success("编辑成功").then()
        return true
    }).catch(err => {
        FormUtils.setFormError(formRef, err.response?.data, "name")
        return false
    })

    useEffect(onRequestCodeTemplate, [id])

    return (<PageContainer header={{onBack: () => navigate(-1)}}
                           loading={loading}>
        <NotfoundWrapper notfound={notFound}>
            <ProCard>
                <ProForm formRef={formRef}
                         onFinish={onFinish}
                         layout="horizontal"
                         labelCol={{span: 2}}
                         wrapperCol={{span: 22}}>
                    <ProFormText width="md" name="id" label="ID" hidden rules={Rule.require}/>
                    <ProFormText width="md" name="name" label="名称" rules={Rule.requireText}/>
                    <ProFormText width="lg" name="description" label="描述" rules={Rule.text}/>
                    <ProFormTextArea name="content" label="模版内容" fieldProps={{rows: 18}} rules={Rule.require}
                                     tooltip="建议使用专业的代码编辑器编写模版内容"/>
                </ProForm>
            </ProCard>
        </NotfoundWrapper>
    </PageContainer>)

}