import React from 'react'
import { Button, Space } from "antd"
import { EditableProTable } from "@ant-design/pro-components"
import SecurityUtils from "@/framework/util/SecurityUtils"

export default class SkuDataTable extends React.Component {

    defaultColumns = [
        {
            title: "图片",
            dataIndex: "cover",
            valueType: "image",
            editable: false,
            width: 64,
            tooltip: "单个SKU图片设置暂未开放"
        },
        {
            title: "规格",
            dataIndex: "property",
            hideInTable: true,
        },
        {
            title: "Extra",
            dataIndex: "extra",
            valueType: "code",
            hideInTable: SecurityUtils.hasSuperPermission()
        }
    ]

    state = {
        skus: [],
        columns: []
    }

    componentDidMount() {
        this.calcData(this.props)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState !== this.state) {
            return true
        } else if (nextContext !== this.context) {
            return true
        } else if (nextProps !== this.props) {
            this.calcData(nextProps)
            return true
        }
        return false
    }

    calcData = ({ specification, basicFormRef, properties }) => {
        const skus = []
        const columns = [
            // 默认的列
            ...this.defaultColumns,
            ...specification.map(v => ({ title: v.label, dataIndex: v.value, editable: false })),
            { title: "条码", dataIndex: "barCode" },
            { title: "编码", dataIndex: "sn" },
            { title: "操作", valueType: "option", render: () => (<></>) }
        ]
        const cartesianProduct = Object.values(properties).reduce((a, b) => a.map((x) => b.map((y) => x.concat(y))).reduce((a, b) => a.concat(b), []), [[]])
        for (let datum of cartesianProduct) {
            const item = {
                property: datum.map(v => v.label).join("|"),
                cover: basicFormRef.current?.getFieldsValue()?.cover,
                extra: "{}"
            }
            datum.forEach(v => item[v.specification] = v.property)
            skus.push(item)
        }
        this.setState({ skus, columns })
    }

    onRemoveRow = (row) => {
        this.setState({ skus: this.state.skus.filter(v => v.property !== row.property) })
    }

    getSkus = () => this.state.skus

    actionRender = (row) => (<Space size="small">
        <Button type="link" size="small" danger onClick={() => this.onRemoveRow(row)}>删除</Button>
    </Space>)

    render() {
        return (<EditableProTable rowKey="property" columns={this.state.columns}
            value={this.state.skus}
            controlled
            recordCreatorProps={false}
            onChange={skus => this.setState({ skus })}
            editable={{
                type: "multiple",
                editableKeys: this.state.skus.map(v => v.property),
                actionRender: (v) => this.actionRender(v)
            }} />)
    }

}