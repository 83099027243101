import React from "react";
import {Button, Col, message, Modal, Row, Space, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import {baseURL} from "@/framework/axios/Axios";
import EventBus from "@/framework/util/EventBusUtils";
import FileEvent from "../event/FileEvent";

export default class UploadFileModal extends React.Component {

    state = {
        open: false,
        uploading: false,
        action: `${baseURL}/common/attachment/file` + (!!this.props.category ? `/${this.props.category}` : undefined)
    }

    componentDidMount() {
        EventBus.on(FileEvent.onTriggerFileUpload, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(FileEvent.onTriggerFileUpload, console.log)
    }

    footerRender = () => (<Space>
        <Button onClick={() => this.setState({open: false})} loading={this.state.uploading}>
            关闭
        </Button>
    </Space>)

    onTrigger = (category = this.props.category) => {
        if (category !== undefined && category !== null && category > 0) {
            this.setState({action: `${baseURL}/common/attachment/file/${category}`, open: true})
        } else {
            this.setState({action: `${baseURL}/common/attachment/file`, open: true})
        }
    }

    onChange = ({file}) => {
        if (file.status === "done") {
            this.onFileUploadSuccess(file.response)
            return
        }
        if (file.status === "error") {
            this.onFileUploadFailure(file)
        }
    }

    onFileUploadSuccess = (file) => {
        EventBus.emit(FileEvent.onFileCreated, file)
        this.setState({open: false})
    }

    onFileUploadFailure = (file) => {
        message.open({type: "error", content: file.response}).then()
    }

    render() {
        return (<Modal width={520}
                       centered
                       destroyOnClose
                       title="上传文件"
                       open={this.state.open}
                       closable={!this.state.uploading}
                       onCancel={() => this.setState({open: false})}
                       footer={this.footerRender()}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Upload.Dragger action={this.state.action}
                                    method="POST"
                                    maxCount={1}
                                    name="file"
                                    headers={{Authorization: localStorage.getItem("Authorization")}}
                                    onChange={this.onChange}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">
                            点击或拖拽模版文件到此处上传
                        </p>
                        <p className="ant-upload-hint">
                            请不要上传体积较大的文件，请自行压缩视频体积、图片体积
                        </p>
                    </Upload.Dragger>
                </Col>
            </Row>
        </Modal>)
    }

}

UploadFileModal.open = (category) => {
    EventBus.emit(FileEvent.onTriggerFileUpload, category)
}