import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormDigit, ProFormText, ProFormTextArea, ProFormTreeSelect } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"
import SecurityUtils from "@/framework/util/SecurityUtils"

import { requestCreatePost } from "@/member/post/api/PostAPI"
import PostEvent from "@/member/post/event/PostEvent"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"

export default function CreatePostModal() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增</Button>)

    const onFinish = (params) => requestCreatePost(params).then(res => {
        EventBus.emit(PostEvent.onPostCreated, res.data)
        formRef.current?.resetFields()
        return true
    }).catch(err => {
        console.error("新增岗位失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    const onRequestDepartmentTree = (params) => requestDepartmentByTree(params).then(res => {
        if (SecurityUtils.hasSuperPermission()) {
            res.data.push({ name: "通用岗位（无归属单位）", id: "0", disabled: false })
        }
        return res.data
    }).catch(() => {
        return []
    })

    return (<ModalForm title="新增岗位"
        modalProps={{ destroyOnClose: true, width: 560 }}
        formRef={formRef}
        trigger={trigger}
        onFinish={onFinish}>

        <ProFormTreeSelect width="md" name="department" label="单位"
            initialValue={SecurityUtils.getUser().department?.id}
            fieldProps={{ fieldNames: { label: "name", value: "id" } }}
            request={onRequestDepartmentTree}
            rules={Rule.require} />

        <ProFormText width="md" name="name" label="名称" rules={Rule.requireText} />

        <ProFormDigit width="sm" name="level" label="级别" rules={Rule.require}
            min={1} max={999} initialValue={1} fieldProps={{ precision: 0 }} />

        <ProFormTextArea name="duty" label="岗位职责" rules={Rule.text} />

        <ProFormTextArea name="description" label="描述" rules={Rule.text} />

    </ModalForm>)
}