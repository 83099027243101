/**
 * 新建打印模版
 */
export const requestCreatePrintTemplate = (params) => window.axios.post("/common/print-template", params)

/**
 * 分页查询打印模版
 * @param params 查询参数
 */
export const requestPrintTemplateByPage = (params) => window.axios.get("/common/print-template/page", {params})

/**
 * 查询打印模版
 * @param params 查询参数
 */
export const requestPrintTemplateByList = (params) => window.axios.get("/common/print-template/list", {params})

/**
 * 查询指定ID打印模版
 * @param id {string} 打印模版ID
 */
export const requestPrintTemplateById = (id) => window.axios.get(`/common/print-template/${id}`)

/**
 * 更新打印模版
 */
export const requestUpdatePrintTemplate = (params) => window.axios.patch(`/common/print-template/${params.id}`, params)

/**
 * 删除打印模版
 */
export const requestDeletePrintTemplate = (id) => window.axios.delete(`/common/print-template/${id}`)
