import React from "react"
import PropTypes from "prop-types"
import { Button, message, Modal } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeleteUser } from "@/member/user/api/UserAPI"
import UserEvent from "@/member/user/event/UserEvent"

export default function DeleteUserModal(props) {

    const [open, setOpen] = React.useState(false)
    const [confirmLoading, setConfirmLoading] = React.useState(false)

    const onOk = () => {
        setConfirmLoading(true)
        requestDeleteUser(props.user.id).then(() => {
            setConfirmLoading(false)
            setOpen(false)
            message.success(`用户 ${props.user.name} 已删除`, 2).then()
            EventBus.emit(UserEvent.onUserDeleted, props.user.id)
            if (props.onDelete !== null) {
                props.onDelete()
            }
        }).catch(() => {
            setConfirmLoading(false)
        })
    }

    return (<>
        <Button size="small" type="link" danger onClick={() => setOpen(true)}>删除用户</Button>
        <Modal title="删除用户"
            centered
            open={open}
            okType="danger"
            confirmLoading={confirmLoading}
            onCancel={() => setOpen(false)}
            onOk={onOk}>
            您正在删除用户：{props.user.name}，与该用于关联的数据将被同步删除且该操作不可逆，为保护您的数据安全，请您谨慎操作！
        </Modal>
    </>)

}

DeleteUserModal.propTypes = {
    user: PropTypes.object.isRequired,
    onDelete: PropTypes.func
}