/**
 * 用户名密码登录
 * @param params 登录参数
 */
export const requestUsernameLogin = (params) => window.axios.patch("/member/authentication/password-authenticating", params)

/**
 * 登录续期
 */
export const requestRenewSession = () => window.axios.patch("/member/session-validity")

/**
 * 退出登录
 */
export const requestLogout = () => window.axios.delete("/member/session/terminal")

/**
 * 分页查询登录日志
 * @param params 查询参数
 */
export const requestLoginLogByPage = (params) => window.axios.get("/member/authentication/log/page", { params })