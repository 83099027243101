/**
 * 根据用户查询用户数据权限
 * @param user 用户ID
 */
export const requestRuleByUser = (user) => window.axios.get(`/member/user/${user}/rules`)

/**
 * 新建用户数据权限
 * @param user {string} 用户ID
 * @param params {array} 用户数据权限集合
 */
export const requestSetRule = (user, params) => window.axios.post(`/member/user/${user}/rules`, params)
