import PropTypes from "prop-types";

import SecurityUtils, {getUserPermissions} from "@/framework/util/SecurityUtils";

/**
 * 权限检查器
 *
 * @description 如果用户没有拥有指定权限则不会显示子节点
 *
 * @returns {React.ReactElement|undefined}
 *
 * @author Wayne Carder
 * @version 1.0.0
 */
export default function PermissionWrapper({strict = false, skipSupper = false, permissions, children}) {
    return SecurityUtils.hasPermission(permissions, getUserPermissions(), strict, skipSupper) ? children : undefined
}

PermissionWrapper.propTypes = {
    permissions: PropTypes.array.isRequired,
    children: PropTypes.node,
    strict: PropTypes.bool,
    skipSupper: PropTypes.bool
}
