/**
 * 新建活动
 */
export const requestCreateActivity = (params) => window.axios.post("/association/activity", params)

/**
 * 分页查询活动
 * @param params 查询参数
 */
export const requestActivityByPage = (params) => window.axios.get("/association/activity/page", {params})

/**
 * 查询活动
 * @param params 查询参数
 */
export const requestActivityByList = (params) => window.axios.get("/association/activity/list", {params})

/**
 * 查询指定ID活动
 * @param id {string} 活动ID
 */
export const requestActivityById = (id) => window.axios.get(`/association/activity/${id}`)

/**
 * 更新活动
 */
export const requestUpdateActivity = (params) => window.axios.patch(`/association/activity/${params.id}`, params)

/**
 * 删除活动
 */
export const requestDeleteActivity = (id) => window.axios.delete(`/association/activity/${id}`)
