import React from "react"
import {Button, Space} from "antd";
import {ProTable} from "@ant-design/pro-components"

import {requestProcessDefinitionByList, requestProcessInstanceByPage} from "../../api/WorkflowAPI"

import EventBus from "@/framework/util/EventBusUtils";
import WorkflowEvent from "../../event/WorkflowEvent";
import BooleanWrapper from "@/framework/component/BooleanWrapper";
import PreviewProcessInstanceDrawer from "../../interaction/PreviewProcessInstanceDrawer";
import TerminateProcessInstanceModal from "../../interaction/TerminateProcessInstanceModal";
import {requestUserByList} from "@/member/user/api/UserAPI";
import ProcessTaskDataTable from "./ProcessTaskDataTable";
import CompleteProcessInstanceTaskDrawer from "../../interaction/CompleteProcessInstanceTaskDrawer";
import AssignProcessInstanceTaskModal from "../../interaction/AssignProcessInstanceTaskModal";
import PreviewProcessTaskModal from "../../interaction/PreviewProcessTaskModal";
import DeleteProcessInstanceButton from "../button/DeleteProcessInstanceButton";

const ProcessInstanceDataTableColumnsState = {
    persistenceKey: "ColumnStateForProcessInstance",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        processDefinitionId: {show: false}
    }
}

const ProcessInstanceDataTableOperations = () => {
    return (<Space>

    </Space>)
}

const ProcessInstanceColumnOperations = ({item}) => (<Space>
    <Button type="link" size="small" onClick={() => PreviewProcessInstanceDrawer.open(item)}>查看</Button>
    <BooleanWrapper value={item.state === "ACTIVE"}>
        <Button type="link" size="small" danger onClick={() => TerminateProcessInstanceModal.open(item)}>终止</Button>
    </BooleanWrapper>
    <BooleanWrapper value={item.state !== "ACTIVE"}>
        <DeleteProcessInstanceButton id={item.id}/>
    </BooleanWrapper>
</Space>)

const ProcessInstanceDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        copyable: true,
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "流程名称",
        dataIndex: "processDefinitionName",
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "流程定义",
        dataIndex: "processDefinitionId",
        valueType: "select",
        fieldProps: {showSearch: true, debounce: 500, fieldNames: {label: "name", value: "id"}},
        request: () => requestProcessDefinitionByList().then(res => res.data).catch(() => [])
    },
    {
        title: "发起人",
        dataIndex: "startUserId",
        valueType: "select",
        fieldProps: {showSearch: true, debounce: 500, fieldNames: {label: "name", value: "id"}},
        request: () => requestUserByList().then(res => res.data).catch(() => [])
    },
    {
        title: "状态",
        dataIndex: "state",
        valueEnum: {
            "COMPLETED": {text: "已完成", status: "success"},
            "ACTIVE": {text: "进行中", status: "processing"},
            "INTERNALLY_TERMINATED": {text: "用户终止", status: "error"}
        }
    },
    {
        title: "流程版本",
        dataIndex: "processDefinitionVersion",
        hideInSearch: true
    },
    {
        title: "发起时间",
        dataIndex: "startTime",
        valueType: "dateTime",
        hideInSearch: true
    },
    {
        title: "发起时间",
        dataIndex: "startTimePicker",
        valueType: "dateRange",
        hideInTable: true
    },
    {
        title: "流程耗时",
        dataIndex: "durationInMillis",
        valueType: "second",
        hideInSearch: true
    },
    {
        title: "结束时间",
        dataIndex: "endTime",
        valueType: "dateTime",
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<ProcessInstanceColumnOperations item={item}/>)
    }
]

export default class ProcessInstanceDataTable extends React.Component {

    tableActionRef = React.createRef()

    state = {
        expandedRowKeys: []
    }

    componentDidMount() {
        EventBus.on(WorkflowEvent.onProcessInstanceCreateEvent, () => this.tableActionRef.current?.reload())
        EventBus.on(WorkflowEvent.onProcessInstanceUpdateEvent, () => this.tableActionRef.current?.reload())
        EventBus.on(WorkflowEvent.onProcessInstanceDeleteEvent, () => this.tableActionRef.current?.reload())
        EventBus.on(WorkflowEvent.onProcessTaskUpdateEvent, () => this.tableActionRef.current?.reload())

    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onProcessInstanceCreateEvent, () => console.log("EventOff", WorkflowEvent.onProcessInstanceCreateEvent))
        EventBus.off(WorkflowEvent.onProcessInstanceUpdateEvent, () => console.log("EventOff", WorkflowEvent.onProcessInstanceUpdateEvent))
        EventBus.off(WorkflowEvent.onProcessInstanceDeleteEvent, () => console.log("EventOff", WorkflowEvent.onProcessInstanceDeleteEvent))
        EventBus.off(WorkflowEvent.onProcessTaskUpdateEvent, () => console.log("EventOff", WorkflowEvent.onProcessInstanceDeleteEvent))
    }

    onRequest = (params) => {
        if (!!params.startTimePicker) {
            params.startTime = params.startTimePicker[0] + " 00:00:00"
            params.endTime = params.startTimePicker[1] + " 23:59:59"
            params.startTimePicker = undefined
        }
        return requestProcessInstanceByPage(params).then(res => {
            for (let datum of res.data.data) {
                if (datum.durationInMillis !== undefined && datum.durationInMillis !== null) {
                    datum.durationInMillis = datum.durationInMillis / 1000
                }
            }
            return res.data
        }).catch(() => {
            return {data: [], success: true, total: 0}
        })
    }

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      scroll={{x: "max-content"}}
                      columns={ProcessInstanceDataTableColumns}
                      toolBarRender={() => (<ProcessInstanceDataTableOperations/>)}
                      columnsState={ProcessInstanceDataTableColumnsState}
                      expandable={{
                          expandedRowKeys: this.state.expandedRowKeys,
                          onExpandedRowsChange: (rows) => this.setState({expandedRowKeys: !!rows ? rows.filter(v => this.state.expandedRowKeys.indexOf(v) < 0) : []}),
                          expandedRowRender: (row) => (<ProcessTaskDataTable processInstanceId={row.id}/>),
                          rowExpandable: (row) => row.state === "ACTIVE"
                      }}
                      actionRef={this.tableActionRef}
                      request={this.onRequest}/>
            <PreviewProcessInstanceDrawer/>
            <TerminateProcessInstanceModal/>
            <CompleteProcessInstanceTaskDrawer/>
            <AssignProcessInstanceTaskModal/>
            <PreviewProcessTaskModal/>
        </>)
    }

}

