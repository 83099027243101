import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormText } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"

import { requestCreateSpecification } from "@/specification/specification/api/SpecificationAPI"
import SpecificationEvent from "@/specification/specification/event/SpecificationEvent"

export default function CreateSpecificationModal({ trigger }) {

    const formRef = React.useRef()

    const defaultTrigger = (<Button type="primary" icon={<PlusOutlined />}>新增规格</Button>)

    const onFinish = (params) => requestCreateSpecification(params).then(res => {
        EventBus.emit(SpecificationEvent.onSpecificationCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增规格失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增规格"
        formRef={formRef}
        modalProps={{ destroyOnClose: true, width: 560 }}
        trigger={!!trigger ? trigger : defaultTrigger}
        onFinish={onFinish}>
        <ProFormText name="name" label="名称" rules={Rule.require} />
    </ModalForm>)

}
