export const requestCreateDictionaryLabel = (params) => window.axios.post("/common/dictionary/label", params)

export const requestDictionaryLabelById = (id) => window.axios.get(`/common/dictionary/label/${id}`)

export const requestDictionaryLabelByPage = (params) => window.axios.get("/common/dictionary/label/page",{params})

export const requestDictionaryLabelByList = (params) => window.axios.get("/common/dictionary/label/list",{params})

export const requestUpdateDictionaryLabel = (params) => window.axios.patch(`/common/dictionary/label/${params.id}`, params)

export const requestDeleteDictionaryLabel = (id) => window.axios.delete(`/common/dictionary/label/${id}`)