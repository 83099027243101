export const requestCreateDictionaryVariable = (params) => window.axios.post("/common/dictionary/variable", params)

export const requestDictionaryVariable = (params) => window.axios.get("/common/dictionary/variable", {params})

export const requestUpdateDictionaryVariable = (params) => window.axios.patch(`/common/dictionary/variable/${params.id}`, params)

export const requestDictionaryVariableByPage = (params) => window.axios.get("/common/dictionary/variable/page", {params})

export const requestDictionaryVariableByList = (params) => window.axios.get("/common/dictionary/variable/list", {params})

export const requestDictionaryVariableByTree = (params) => window.axios.get("/common/dictionary/variable/tree", {params})

export const requestDeleteDictionaryVariableById = id => window.axios.delete(`/common/dictionary/variable/${id}`)