import PropTypes from "prop-types";
import {App, Button, Popconfirm} from "antd";

import EventBus from "@/framework/util/EventBusUtils";

import {requestDeleteCodeTable} from "../api/CodeTableAPI";
import CodeTableEvent from "../event/CodeTableEvent";

export default function DeleteCodeTableButton({id}) {

    const {message} = App.useApp()

    const onConfirm = () => requestDeleteCodeTable(id).then(res => {
        EventBus.emit(CodeTableEvent.onCodeTableDeleted, res.data)
        message.success("操作成功", 2).then()
        return true
    }).catch(() => {
        message.error("操作失败", 2).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除代码生成表" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)
}

DeleteCodeTableButton.propTypes = {
    id: PropTypes.string.isRequired
}