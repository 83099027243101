import PropTypes from "prop-types";
import {Button, Popconfirm} from "antd";

import EventBus from "../../../framework/util/EventBusUtils";

import {requestDeleteMember} from "../api/MemberAPI";
import MemberEvent from "../event/MemberEvent";


export default function DeleteMemberButton(props) {

    const onConfirm = () => requestDeleteMember(props.id).then(res => {
        EventBus.emit(MemberEvent.onMemberDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除会员" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteMemberButton.propTypes = {
    id: PropTypes.string.isRequired
}