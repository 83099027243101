import { EventEmitter } from "events"

const EventBus = new EventEmitter()

export function emit(eventName, args) {
    try {
        EventBus.emit(eventName, args)
    } catch (e) {
        console.error(e)
    }
}

export default EventBus