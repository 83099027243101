import React from "react"
import { Button } from "antd"

import SecurityUtils from "@/framework/util/SecurityUtils"

export default function ColumnForUserName({ user }) {
    if (SecurityUtils.hasPermission(["user:select"])) {
        return (<Button type="link" size="small" >
            {user.name}
        </Button>)
    }
    return user.name
}