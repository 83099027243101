import React from "react"
import { PropTypes } from "prop-types"
import { EllipsisOutlined } from "@ant-design/icons"
import { Button, Dropdown, Space, Tooltip, Typography } from "antd"
import { ProTable } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import SecurityUtils from "@/framework/util/SecurityUtils"
import AvailableEnum from "@/framework/enum/AvailableEnum"
import PermissionWrapper from "@/framework/component/PermissionWrapper"
import BooleanWrapper from "@/framework/component/BooleanWrapper"

import UserEvent from "@/member/user/event/UserEvent"
import CreateUserDrawer from "@/member/user/interaction/CreateUserDrawer"
import { requestUserByPage } from "@/member/user/api/UserAPI"
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils"
import ColumnForUserAvatar from "@/member/user/component/column/ColumnForUserAvatar"
import ColumnForUserName from "@/member/user/component/column/ColumnForUserName"
import UploadUserTemplateModal from "@/member/user/interaction/UploadUserTemplateModal"
import SetRuleModal from "@/member/rule/interaction/SetRuleModal"
import { requestPostByList } from "@/member/post/api/PostAPI"
import UpdateUserRolesModal from "@/member/user/interaction/UpdateUserRolesModal"
import UpdateUserGroupsModal from "@/member/user/interaction/UpdateUserGroupsModal"
import PreviewUserDrawer from "@/member/user/interaction/PreviewUserDrawer"

/**
 * 用户表格状态
 * @author Wayne Carder
 */
const UserDataTableColumnState = {
    persistenceKey: "UserDataTableColumnState",
    persistenceType: "localStorage",
    defaultValue: {
        id: { show: false },
        createTime: { show: false },
        modifyTime: { show: false },
        status: { show: false },
        type: { show: false },
        grade: { show: false },
        policy: { show: false },
        joinPartyDate: { show: false },
        nation: { show: false },
        nativePlace: { show: false },
        birthday: { show: false },
        education: { show: false },
        school: { show: false },
        degree: { show: false },
        major: { show: false }
    }
}

/**
 * 用户表格操作栏
 * @param props {user:object}
 * @author Wayne Carder
 */
const UserDataTableColumnOperations = ({ item }) => {
    const menus = []
    if (SecurityUtils.hasPermission(["user:manage"])) {
        menus.push({
            key: "SetUserDataRule",
            label: "设置数据权限",
            onClick: () => SetRuleModal.open(item)
        })
        menus.push({
            key: "SetUserRole",
            label: "设置角色",
            onClick: () => UpdateUserRolesModal.open(item)
        })
        menus.push({
            key: "SetUserGroup",
            label: "设置用户组",
            onClick: () => UpdateUserGroupsModal.open(item)
        })
    }
    return (<Space size="small">
        <PermissionWrapper permissions={["user:select"]}>
            <Button type="link" size="small" onClick={() => PreviewUserDrawer.open(item.id)}>查看</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["user:update"]}>
            <BooleanWrapper
                value={SecurityUtils.hasPermission(["user:manage"]) || item.id === SecurityUtils.getUser()?.id}>
                <Button type="link" size="small" onClick={() => EventBus.emit(UserEvent.onTriggerUpdateUser, item)}>
                    编辑
                </Button>
            </BooleanWrapper>
        </PermissionWrapper>
        <BooleanWrapper value={menus.length > 0}>
            <Dropdown arrow menu={{ items: menus }} trigger={["click"]}>
                <Tooltip title="更多操作">
                    <Button type="link" size="small" icon={<EllipsisOutlined/>}></Button>
                </Tooltip>
            </Dropdown>
        </BooleanWrapper>
    </Space>)
}

/**
 * 用户表格工具栏
 *
 * @author Wayne Carder
 */
const UserDataTableOperations = ({ department }) => (<Space size="middle">
    <PermissionWrapper permissions={["user:insert"]}>
        <CreateUserDrawer/>
    </PermissionWrapper>
    <PermissionWrapper permissions={["user:insert"]}>
        <UploadUserTemplateModal department={department}/>
    </PermissionWrapper>
</Space>)

/**
 * 用户表格字段
 * @author Wayne Carder
 */
const UserDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        copyable: true,
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "单位",
        dataIndex: ["department", "name"],
        hideInSearch: true,
        renderText: (v) => !!v ? v : "无单位"
    },
    {
        title: "名称",
        dataIndex: "name",
        render: (_, item) => (<div style={{ cursor: "pointer" }} onClick={() => PreviewUserDrawer.open(item.id)}>
            <ColumnForUserAvatar user={item}/>
            <ColumnForUserName user={item}/>
        </div>)
    },
    {
        title: "岗位",
        dataIndex: ["post", "name"],
        valueType: "select",
        formItemProps: { name: "post" },
        fieldProps: { fieldNames: { label: "name", value: "id" } },
        request: () => requestPostByList().then(res => res.data).catch(() => [])
    },
    {
        title: "查询层级",
        dataIndex: "exactDepartment",
        hideInTable: true,
        formItemProps: { initialValue: "1" },
        valueEnum: { "1": { text: "仅本单位" }, "0": { text: "含下级单位" } }
    },
    {
        title: "联系方式",
        dataIndex: "contract",
        valueType: "text"
    },
    {
        title: "类型",
        dataIndex: "type",
        valueType: "select",
        request: () => DictionaryVariableUtils.requestDvByList("user-type")
    },
    {
        title: "级别",
        dataIndex: "grade",
        valueType: "select",
        request: () => DictionaryVariableUtils.requestDvByList("user-grade")
    },
    {
        title: "状态",
        dataIndex: "status",
        valueType: "select",
        request: () => DictionaryVariableUtils.requestDvByList("user-status")
    },
    {
        title: "政治面貌",
        dataIndex: "policy",
        valueType: "select",
        request: () => DictionaryVariableUtils.requestDvByList("policy")
    },
    {
        title: "入党日期",
        dataIndex: "joinPartyDate",
        valueType: "date",
        hideInSearch: true
    },
    {
        title: "民族",
        dataIndex: "nation",
        valueType: "select",
        request: () => DictionaryVariableUtils.requestDvByList("nation")
    },
    {
        title: "籍贯",
        dataIndex: "nativePlace",
        valueType: "select",
        request: () => DictionaryVariableUtils.requestDvByList("native-place")
    },
    {
        title: "出生日期",
        dataIndex: "birthday",
        valueType: "date",
        hideInSearch: true
    },
    {
        title: "毕业院校",
        dataIndex: "school",
        hideInSearch: true
    },
    {
        title: "专业",
        dataIndex: "major",
        hideInSearch: true
    },
    {
        title: "学历",
        dataIndex: "education",
        valueType: "select",
        request: () => DictionaryVariableUtils.requestDvByList("education")
    },
    {
        title: "学位",
        dataIndex: "degree",
        valueType: "select",
        request: () => DictionaryVariableUtils.requestDvByList("degree")
    },
    {
        title: "是否可用",
        dataIndex: "available",
        valueEnum: AvailableEnum
    },
    {
        title: "创建时间",
        valueType: "fromNow",
        dataIndex: "createTime",
        hideInSearch: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        width: 192,
        align: "center",
        fixed: "right",
        render: (_, item) => (<UserDataTableColumnOperations item={item}/>)
    }
]

/**ßß
 * 用户表格
 * @author Wayne Carder
 */
export default class UserDataTable extends React.Component {

    actionRef = React.createRef()

    componentDidMount() {
        EventBus.on(UserEvent.onUserCreated, () => this.actionRef.current?.reload())
        EventBus.on(UserEvent.onUserUpdated, () => this.actionRef.current?.reload())
        EventBus.on(UserEvent.onUserDeleted, () => this.actionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(UserEvent.onUserCreated, () => console.log("Event Off", UserEvent.onUserCreated))
        EventBus.off(UserEvent.onUserUpdated, () => console.log("Event Off", UserEvent.onUserUpdated))
        EventBus.off(UserEvent.onUserDeleted, () => console.log("Event Off", UserEvent.onUserDeleted))
    }


    request = (params) => requestUserByPage(params).then(res => {
        return res.data
    }).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        if (!this.props.department) {
            return (<Typography.Paragraph>请选择单位</Typography.Paragraph>)
        }
        return (<>
            <ProTable rowKey="id"
                      headerTitle="单位用户"
                      scroll={{ x: "max-content" }}
                      columns={UserDataTableColumns}
                      columnsState={UserDataTableColumnState}
                      actionRef={this.actionRef}
                      toolBarRender={() => (<UserDataTableOperations department={this.props.department}/>)}
                      params={{ department: this.props.department }}
                      request={this.request}/>
            <PreviewUserDrawer/>
            <PermissionWrapper permissions={["user:update"]}>
                <SetRuleModal/>
                <UpdateUserRolesModal/>
                <UpdateUserGroupsModal/>
            </PermissionWrapper>
        </>)
    }

}

UserDataTable.propTypes = {
    department: PropTypes.string
}