import React from "react"
import {useNavigate} from "react-router-dom"
import {
    PageContainer,
    ProCard,
    ProFormGroup,
    ProFormList,
    ProFormSelect,
    ProFormText,
    StepsForm
} from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"

import {requestDatabaseTable, requestDatabaseTableByList} from "./api/DatabaseTableAPI"
import {requestCreateCodeTable} from "./api/CodeTableAPI";
import {getUser} from "@/framework/util/SecurityUtils";
import {message} from "antd";
import Rule from "@/framework/form/Rule";

function handleDatabaseTable(databaseTable, tablePrefix, tableSuffix) {
    const table = databaseTable
    table.entityName = table.name
    if (!!tablePrefix) {
        table.entityName = table.entityName.replace(tablePrefix, "")
    }
    if (!!tableSuffix) {
        table.entityName = table.entityName.replace(tableSuffix, "")
    }
    table.entityName = table.entityName.replace(/_(\w)/g, (_, c) => c ? c.toUpperCase() : '')
    table.entityName = table.entityName.replace(/( |^)[a-z]/g, (c) => c.toUpperCase())
    table.author = getUser().name
    return table
}

function handleDatabaseColumn(databaseColumns, columnPrefix, columnSuffix) {
    const columns = []
    for (let column of databaseColumns) {
        column.jdbcName = column.columnName
        column.javaName = column.columnName
        column.editable = 1
        column.pk = 0
        if (!!columnPrefix) {
            column.javaName = column.columnName.replace(columnPrefix, "")
        }
        if (!!columnSuffix) {
            column.javaName = column.columnName.replace(columnSuffix, "")
        }
        column.javaName = column.javaName.replace(/_(\w)/g, (_, c) => c ? c.toUpperCase() : '')
        switch (column.jdbcType) {
            case "int4":
            case "int":
                column.javaType = "Integer"
                break
            case "int8":
                column.javaType = "Long"
                break
            case "varchar":
            case "text":
                column.javaType = "String"
                break
            case "timestamp":
                column.javaType = "LocalDateTime"
                break
            case "numeric":
                column.javaType = "BigDecimal"
                break
            case "double":
                column.javaType = "Double"
                break
            default:
                column.javaType = "Object"
                break
        }
        columns.push(column)
    }
    return columns
}

export default function CreateCodeTable() {

    const navigate = useNavigate()
    const formRefForDatabaseTable = React.useRef()
    const formRefForCodeTable = React.useRef()
    const formRefForCodeColumn = React.useRef()

    const onRequestDatabaseTable = () => requestDatabaseTableByList().then(res => {
        return res.data.map(item => ({
            key: `${item.schema}-${item.name}`,
            label: `${item.comment}(${item.name})`,
            value: item.name,
            ...item
        }))
    }).catch(() => {
        return []
    })

    const onDatabaseTableFinish = (params) => requestDatabaseTable(params).then(res => {
        const table = handleDatabaseTable(res.data, params.tablePrefix, params.tableSuffix)
        const columns = handleDatabaseColumn(table.columns, params.columnPrefix, params.columnSuffix)
        formRefForCodeTable.current?.setFieldsValue(table)
        formRefForCodeColumn.current?.setFieldsValue({columns})
        return true
    }).catch(err => {
        console.error(err)
        FormUtils.setFormError(formRefForDatabaseTable, err.response?.data, "tableName")
        return false
    })

    const onFinish = (params) => requestCreateCodeTable(params).then(() => {
        navigate(-1)
        message.success("新增成功").then()
        return true
    }).catch(err => {
        console.error(err)
        return false
    })

    return (<PageContainer header={{onBack: () => navigate(-1)}}>
        <ProCard>
            <StepsForm onFinish={onFinish}>
                <StepsForm.StepForm title="导入数据库表"
                                    layout="horizontal"
                                    labelCol={{span: 5}}
                                    wrapperCol={{span: 19}}
                                    formRef={formRefForDatabaseTable}
                                    onFinish={onDatabaseTableFinish}>
                    <ProFormSelect width="md" name="tableName" label="数据库表" rules={Rule.require}
                                   fieldProps={{virtual: false}}
                                   request={onRequestDatabaseTable}/>
                    <ProFormText width="sm" name="tablePrefix" label="表名前缀"/>
                    <ProFormText width="sm" name="tableSuffix" label="表名后缀"/>
                    <ProFormText width="sm" name="columnPrefix" label="字段前缀"/>
                    <ProFormText width="sm" name="columnSuffix" label="字段后缀"/>
                </StepsForm.StepForm>

                <StepsForm.StepForm title="完善代码生成表信息"
                                    layout="horizontal"
                                    labelCol={{span: 4}}
                                    wrapperCol={{span: 20}}
                                    formRef={formRefForCodeTable}>
                    <ProFormText width="sm" name="name" label="表名" rules={Rule.requireText}/>
                    <ProFormText width="md" name="author" label="作者" rules={Rule.requireText}/>
                    <ProFormText width="md" name="comment" label="表注释" rules={Rule.requireText}/>
                    <ProFormText width="sm" name="schema" label="表空间" rules={Rule.requireText}/>
                    <ProFormText width="sm" name="entityName" label="实体名称" rules={Rule.requireText}/>
                    <ProFormText width="lg" name="packageName" label="包名" rules={Rule.requireText}/>
                </StepsForm.StepForm>

                <StepsForm.StepForm title="完善代码生成字段信息" formRef={formRefForCodeColumn}>
                    <ProFormList name="columns" min={1} max={100} copyIconProps={false}>
                        <ProFormGroup>
                            <ProFormText width={160} name="jdbcName" label="字段名称" rules={Rule.requireText}/>
                            <ProFormText width={160} name="javaName" label="字段名称" rules={Rule.requireText}/>
                            <ProFormText width={160} name="comment" label="注释" rules={Rule.requireText}/>
                            <ProFormText width={160} name="jdbcType" label="数据库数据类型" rules={Rule.requireText}/>
                            <ProFormText width={160} name="javaType" label="Java数据类型" rules={Rule.requireText}/>
                            <ProFormSelect width="xs" name="pk" label="是否主键" rules={Rule.require}
                                           options={[{label: "是", value: 1}, {label: "否", value: 0}]}/>
                            <ProFormSelect width="xs" name="editable" label="是否可编辑" rules={Rule.require}
                                           options={[{label: "是", value: 1}, {label: "否", value: 0}]}/>
                        </ProFormGroup>
                    </ProFormList>
                </StepsForm.StepForm>

            </StepsForm>
        </ProCard>
    </PageContainer>)
}