/**
 * 新建帮助说明
 */
export const requestCreateHelpItem = (params) => window.axios.post("/help/item", params)

/**
 * 分页查询帮助说明
 * @param params 查询参数
 */
export const requestHelpItemByPage = (params) => window.axios.get("/help/item/page", {params})

/**
 * 查询帮助说明
 * @param params 查询参数
 */
export const requestHelpItemByList = (params) => window.axios.get("/help/item/list", {params})

/**
 * 查询指定ID帮助说明
 * @param id {string} 帮助说明ID
 */
export const requestHelpItemById = (id) => window.axios.get(`/help/item/${id}`)

/**
 * 更新帮助说明
 */
export const requestUpdateHelpItem = (params) => window.axios.patch(`/help/item/${params.id}`, params)

/**
 * 删除帮助说明
 */
export const requestDeleteHelpItem = (id) => window.axios.delete(`/help/item/${id}`)
