import React from "react"
import {Button, Popconfirm} from "antd"
import PropTypes from "prop-types"

import EventBus from "@/framework/util/EventBusUtils"

import {requestDeleteFile} from "../api/FileAPI"
import FileEvent from "../event/FileEvent"
import {DeleteOutlined} from "@ant-design/icons"

export default function DeleteFileButton(props) {

    const onConfirm = () => requestDeleteFile(props.id).then(() => {
        EventBus.emit(FileEvent.onFileDeleted)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除文件" okText="删除" cancelText="取消"
                        description="如文件已被使用，删除可能导致文件无法查看，请谨慎操作"
                        okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button size="small" type="text" icon={<DeleteOutlined/>} danger/>
    </Popconfirm>)

}

DeleteFileButton.propTypes = {
    id: PropTypes.string.isRequired
}