/**
 * 新建会员标注
 */
export const requestCreateMemberMark = (params) => window.axios.post("/association/member/mark", params)

/**
 * 分页查询会员标注
 * @param params 查询参数
 */
export const requestMemberMarkByPage = (params) => window.axios.get("/association/member/mark/page", {params})

/**
 * 查询会员标注
 * @param params 查询参数
 */
export const requestMemberMarkByList = (params) => window.axios.get("/association/member/mark/list", {params})

/**
 * 查询指定ID会员标注
 * @param id {string} 会员标注ID
 */
export const requestMemberMarkById = (id) => window.axios.get(`/association/member/mark/${id}`)

/**
 * 更新会员标注
 */
export const requestUpdateMemberMark = (params) => window.axios.patch(`/association/member/mark/${params.id}`, params)

/**
 * 删除会员标注`
 */
export const requestDeleteMemberMark = (id) => window.axios.delete(`/association/member/mark/${id}`)
