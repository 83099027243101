import React from "react";
import {message, Skeleton} from "antd";
import {DrawerForm, ProCard, ProFormText} from "@ant-design/pro-components";

import EventBus from "../../framework/util/EventBusUtils";

import WorkflowEvent from "../event/WorkflowEvent";
import {
    requestCompleteProcessInstanceTask,
    requestHistoryProcessInstanceVariablesByList,
    requestProcessInstanceTaskExtensionPropertiesById,
    requestProcessInstanceTaskFormById
} from "../api/WorkflowAPI";
import {formConverter} from "../util/FormConverter";
import ProcessInstanceTaskTimeline from "../component/ProcessInstanceTaskTimeline";
import TaskLifecycleUtils from "../util/TaskLifecycleUtils";

/**
 * 完成用户任务弹窗
 *
 * @author Wayne Carder
 */
export default class CompleteProcessInstanceTaskDrawer extends React.Component {

    fromRef = React.createRef()

    state = {
        open: false,
        finishedTasks: [],
        task: {},
        form: undefined,
        variables: {},
        loadingForTaskForm: true,
        taskExtensionProperties: {}
    }

    componentDidMount() {
        EventBus.on(WorkflowEvent.onTriggerCompleteProcessInstanceTaskEvent, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onTriggerCompleteProcessInstanceTaskEvent, console.log)
    }

    onTrigger = (task) => {
        const state = {open: true, loadingForTaskForm: true, task}
        requestProcessInstanceTaskExtensionPropertiesById(task.id).then(res => {
            this.setState({taskExtensionProperties: res.data})
        }).catch(() => {
            console.log(`此任务无 Extension Properties:${task.id}`)
        })
        requestHistoryProcessInstanceVariablesByList(task.processInstanceId).then(res => {
            const variables = {}
            for (let datum of res.data) {
                variables[datum.variableName] = datum.textValue
            }
            this.setState({variables})
        })
        this.setState(state, () => {
            // 查询当前用户任务是否有表单需要填写
            requestProcessInstanceTaskFormById(task.id).then(res => {
                this.setState({form: res.data})
                EventBus.emit(WorkflowEvent.onProcessTaskUpdateEvent, res.data)
            }).catch(err => {
                console.log("查询当前用户任务表单失败", err)
            }).finally(() => {
                this.setState({loadingForTaskForm: false})
            })
            setTimeout(() => this.fromRef.current?.setFieldsValue(task), 200)
        })
    }

    onFinish = (params) => {
        if (this.state.form !== undefined && this.state.form !== null) {
            params[`_form_${this.state.task.taskDefinitionKey}`] = this.state.form.id
        }
        return requestCompleteProcessInstanceTask(params).then(res => {
            EventBus.emit(WorkflowEvent.onProcessTaskUpdateEvent, res.data)
            message.success("操作成功", 2).then()
            if (!!this.state.taskExtensionProperties["onTaskCompleted"]) {
                TaskLifecycleUtils.onTaskCompleted(this.state.task, this.state.variables, this.state.taskExtensionProperties)
            }
            return true
        }).catch(err => {
            console.error("完成用户任务失败", err)
            message.error("操作失败", 2).then()
            return false
        })
    }

    render() {
        return (<DrawerForm title="完成待办"
                            open={this.state.open}
                            formRef={this.fromRef}
                            onOpenChange={open => this.setState({open})}
                            drawerProps={{width: "100vw", destroyOnClose: true}}
                            onFinish={this.onFinish}>

            <ProCard split="vertical" ghost bordered>
                <ProCard title="流程记录" colSpan="60%" headerBordered>
                    <ProcessInstanceTaskTimeline task={this.state.task}/>
                </ProCard>
                <ProCard title="任务信息" headerBordered>
                    <ProFormText label="待办ID" name="id" hidden/>
                    <Skeleton active loading={this.state.loadingForTaskForm}>
                        {formConverter(this.state.form?.components, this.fromRef)}
                    </Skeleton>
                </ProCard>
            </ProCard>
        </DrawerForm>)
    }

}

CompleteProcessInstanceTaskDrawer.open = (processInstanceTask) => {
    EventBus.emit(WorkflowEvent.onTriggerCompleteProcessInstanceTaskEvent, processInstanceTask)
}