import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormDigit, ProFormText, ProFormTextArea } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"

import { requestCreateDailyStatistic } from "@/statistic/daily/api/DailyStatisticAPI"
import DailyStatisticEvent from "@/statistic/daily/event/DailyStatisticEvent"

export default function CreateDailyStatisticModal() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增每日统计</Button>)

    const onFinish = (params) => requestCreateDailyStatistic(params).then(res => {
        EventBus.emit(DailyStatisticEvent.onDailyStatisticCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增每日统计失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增每日统计"
        modalProps={{ destroyOnClose: true, width: 560 }}
        formRef={formRef}
        trigger={trigger}
        onFinish={onFinish}>
        <ProFormText name="module" label="功能模块"
            rules={Rule.require} />
        <ProFormText name="label" label="指标名称"
            rules={Rule.require} />
        <ProFormText name="devIdentifier" label="开发标识符"
            rules={Rule.require} />
        <ProFormText name="bizIdentifier" label="业务标识符"
            rules={Rule.require} />
        <ProFormDigit name="statisticValue" label="统计值"
            rules={Rule.require} />
        <ProFormTextArea name="comment" label="备注" />
    </ModalForm>)
}