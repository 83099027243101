import React from 'react';
import {Drawer} from "antd";
import ActivityMemberDataTable from "../component/ActivityMemberDataTable";
import EventBus from "../../../framework/util/EventBusUtils";
import ActivityEvent from "../event/ActivityEvent";

export default class PreviewActivityDrawer extends React.Component {

    static open = (activity) => {
        EventBus.emit(ActivityEvent.onTriggerPreviewActivity, activity)
    }

    state = {
        open: false,
        activity: {}
    }

    componentDidMount() {
        EventBus.on(ActivityEvent.onTriggerPreviewActivity, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(ActivityEvent.onTriggerPreviewActivity, console.log)
    }

    onTrigger = (activity) => this.setState({open: true, activity})

    render() {
        return (<Drawer title="活动情况"
                        width={window.innerWidth < 1200 ? "100%" : "70%"}
                        destroyOnClose
                        open={this.state.open}
                        onClose={() => this.setState({open: false})}>
            <ActivityMemberDataTable activity={this.state.activity}/>
        </Drawer>)
    }

}
