import React from "react"
import {Button, Space} from "antd";
import {ProTable} from "@ant-design/pro-components"

import {
    requestHistoryProcessVariablesByList,
    requestProcessDefinitionStartForm,
    requestProcessInstanceByPage
} from "../../api/WorkflowAPI"

import EventBus from "@/framework/util/EventBusUtils";
import WorkflowEvent from "../../event/WorkflowEvent";
import BooleanWrapper from "@/framework/component/BooleanWrapper";
import PreviewProcessInstanceDrawer from "../../interaction/PreviewProcessInstanceDrawer";
import TerminateProcessInstanceModal from "../../interaction/TerminateProcessInstanceModal";
import {requestUserByList} from "@/member/user/api/UserAPI";
import ProcessTaskDataTable from "./ProcessTaskDataTable";
import CompleteProcessInstanceTaskDrawer from "../../interaction/CompleteProcessInstanceTaskDrawer";
import AssignProcessInstanceTaskModal from "../../interaction/AssignProcessInstanceTaskModal";
import PreviewProcessTaskModal from "../../interaction/PreviewProcessTaskModal";
import PropTypes from "prop-types";
import ColumnsConverter from "../../util/ColumnsConverter";

const ProcessInstanceDataTableColumnsState = {
    persistenceKey: "ColumnStateForProcessInstance",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        processDefinitionId: {show: false},
        processDefinitionVersion: {show: false}
    }
}

const ProcessInstanceColumnOperations = ({item}) => (<Space>
    <BooleanWrapper value={item.state === "ACTIVE"}>
        <Button type="link" size="small" danger onClick={() => TerminateProcessInstanceModal.open(item)}>终止</Button>
    </BooleanWrapper>
    <Button type="link" size="small" onClick={() => PreviewProcessInstanceDrawer.open(item)}>查看</Button>
</Space>)

export default class ProcessInstanceDetailsDataTable extends React.Component {

    actionRef = React.createRef()

    state = {
        dataSource: [],
        columns: [],
        expandedRowKeys: []
    }

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            copyable: true,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "流程名称",
            dataIndex: "processDefinitionName",
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: "流程定义",
            dataIndex: "processDefinitionId",
            valueType: "select",
            hideInSearch: true,
            hideInTable: true
        },
        {
            title: "发起人",
            dataIndex: "startUserId",
            valueType: "select",
            fieldProps: {fieldNames: {label: "name", value: "id"}},
            request: () => requestUserByList().then(res => res.data).catch(() => [])
        },
        {
            title: "状态",
            dataIndex: "state",
            valueEnum: {
                "COMPLETED": {text: "已完成", status: "success"},
                "ACTIVE": {text: "进行中", status: "processing"},
                "INTERNALLY_TERMINATED": {text: "用户终止", status: "error"}
            }
        },
        {
            title: "流程版本",
            dataIndex: "processDefinitionVersion",
            hideInSearch: true
        },
        {
            title: "发起时间",
            dataIndex: "startTime",
            valueType: "dateTime",
            hideInSearch: true
        },
        {
            title: "发起时间",
            dataIndex: "startTimePicker",
            valueType: "dateRange",
            hideInTable: true
        },
        {
            title: "耗时",
            dataIndex: "durationInMillis",
            valueType: "second",
            hideInSearch: true
        },
        {
            title: "结束时间",
            dataIndex: "endTime",
            valueType: "dateTime",
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<ProcessInstanceColumnOperations item={item}/>)
        }
    ]

    componentDidMount() {
        EventBus.on(WorkflowEvent.onProcessInstanceCreateEvent, this.actionRef.current?.reload)
        EventBus.on(WorkflowEvent.onProcessInstanceUpdateEvent, this.actionRef.current?.reload)
        EventBus.on(WorkflowEvent.onProcessInstanceDeleteEvent, this.actionRef.current?.reload)
        EventBus.on(WorkflowEvent.onProcessTaskUpdateEvent, this.actionRef.current?.reload)
        this.requestProcessDefinitionStartForm()
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onProcessInstanceCreateEvent, () => console.log("EventOff", WorkflowEvent.onProcessInstanceCreateEvent))
        EventBus.off(WorkflowEvent.onProcessInstanceUpdateEvent, () => console.log("EventOff", WorkflowEvent.onProcessInstanceUpdateEvent))
        EventBus.off(WorkflowEvent.onProcessInstanceDeleteEvent, () => console.log("EventOff", WorkflowEvent.onProcessInstanceDeleteEvent))
        EventBus.off(WorkflowEvent.onProcessTaskUpdateEvent, () => console.log("EventOff", WorkflowEvent.onProcessInstanceDeleteEvent))
    }

    requestProcessDefinitionStartForm = () => {
        const {processDefinitionId} = this.props
        requestProcessDefinitionStartForm(processDefinitionId).then(res => {
            const columns = [...this.columns]
            columns.splice(5, 0, ...ColumnsConverter(res.data))
            this.setState({columns})
        }).catch(() => {
            this.setState({columns: this.columns})
        })
    }

    request = (params) => {
        if (!!params.startTimePicker) {
            params.startTime = params.startTimePicker[0] + " 00:00:00"
            params.endTime = params.startTimePicker[1] + " 23:59:59"
            params.startTimePicker = undefined
        }
        return requestProcessInstanceByPage(params).then(res => {
            for (let datum of res.data.data) {
                if (datum.durationInMillis !== undefined && datum.durationInMillis !== null) {
                    datum.durationInMillis = datum.durationInMillis / 1000
                }
            }
            const processInstanceIds = res.data.data.map(v => v.id).toString()
            return requestHistoryProcessVariablesByList({processInstanceIds}).then(varRes => {
                for (let instance of res.data.data) {
                    const variables = varRes.data[instance.id];
                    for (let v of variables) {
                        if (v.variableName !== "id") {
                            instance[v.variableName] = v.textValue
                        }
                    }
                }
                return res.data
            }).catch(() => {
                return {data: [], success: true, total: 0}
            })
        }).catch(() => {
            return {data: [], success: true, total: 0}
        })
    }

    render() {
        return (<>
            <ProTable rowKey="id"
                      cardBordered
                      scroll={{x: "max-content"}}
                      columns={this.state.columns}
                      columnsState={ProcessInstanceDataTableColumnsState}
                      params={{processDefinitionId: this.props.processDefinitionId}}
                      expandable={{
                          expandedRowKeys: this.state.expandedRowKeys,
                          onExpandedRowsChange: (rows) => this.setState({expandedRowKeys: !!rows ? rows.filter(v => this.state.expandedRowKeys.indexOf(v) < 0) : []}),
                          expandedRowRender: (row) => (<ProcessTaskDataTable processInstanceId={row.id}/>),
                          rowExpandable: (row) => row.state === "ACTIVE"
                      }}
                      request={this.request}
                      actionRef={this.actionRef}/>
            <PreviewProcessInstanceDrawer/>
            <TerminateProcessInstanceModal/>
            <CompleteProcessInstanceTaskDrawer/>
            <AssignProcessInstanceTaskModal/>
            <PreviewProcessTaskModal/>
        </>)
    }

}

ProcessInstanceDetailsDataTable.propTypes = {
    processDefinitionId: PropTypes.string.isRequired
}

