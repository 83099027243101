const HelpEvent = {
    onHelpCategoryCreated: "onHelpCategoryCreated",
    onHelpCategoryUpdated: "onHelpCategoryUpdated",
    onHelpCategoryDeleted: "onHelpCategoryDeleted",
    onTriggerUpdateHelpCategory: "onTriggerUpdateHelpCategory",

    onHelpItemCreated: "onHelpItemCreated",
    onHelpItemUpdated: "onHelpItemUpdated",
    onHelpItemDeleted: "onHelpItemDeleted",
    onTriggerUpdateHelpItem: "onTriggerUpdateHelpItem"
}
export default HelpEvent