import React from "react"
import { Alert, Button, Space } from "antd"
import { ProTable } from "@ant-design/pro-components"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestPermissionByPage } from "../api/PermissionAPI"
import PermissionEvent from "@/member/permission/event/PermissionEvent"
import DeletePermissionButton from "@/member/permission/component/DeletePermissionButton"
import CreatePermissionModal from "@/member/permission/interaction/CreatePermissionModal"
import UpdatePermissionModal from "@/member/permission/interaction/UpdatePermissionModal"
import UploadPermissionTemplateModal from "@/member/permission/interaction/UploadPermissionTemplateModal"
import AvailableEnum from "@/framework/enum/AvailableEnum"

const PermissionDataTableColumnsState = {
    persistenceKey: "ColumnStateForPermission",
    persistenceType: "localStorage",
    defaultValue: {
        id: { show: false },
        modifyTime: { show: false }
    }
}

const PermissionDataTableOperations = () => (<Space size="small">
    <PermissionWrapper permissions={["*:*"]}>
        <CreatePermissionModal />
    </PermissionWrapper>
    <PermissionWrapper permissions={["*:*"]}>
        <UploadPermissionTemplateModal />
    </PermissionWrapper>
</Space>)

const PermissionDataTableColumnOperations = ({ item }) => (<Space size="small">
    <PermissionWrapper permissions={["*:*"]}>
        <Button type="link" size="small" onClick={() => UpdatePermissionModal.open(item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["*:*"]}>
        <DeletePermissionButton id={item.id} />
    </PermissionWrapper>
</Space>)

const PermissionDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "名称",
        dataIndex: "name",
    },
    {
        title: "标识符",
        dataIndex: "identifier",
        copyable: true
    },
    {
        title: "状态",
        dataIndex: "available",
        sorter: true,
        width: 72,
        valueEnum: AvailableEnum
    },
    {
        title: "创建时间",
        dataIndex: "createTime",
        valueType: "fromNow",
        sorter: true,
        width: 128,
        hideInSearch: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        valueType: "fromNow",
        width: 128,
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        width: 128,
        align: "center",
        fixed: "right",
        render: (_, item) => (<PermissionDataTableColumnOperations item={item} />)
    }
]

export default class PermissionDataTable extends React.Component {

    actionRef = React.createRef()

    componentDidMount() {
        EventBus.on(PermissionEvent.onPermissionCreated, () => this.actionRef.current?.reload())
        EventBus.on(PermissionEvent.onPermissionUpdated, () => this.actionRef.current?.reload())
        EventBus.on(PermissionEvent.onPermissionDeleted, () => this.actionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(PermissionEvent.onPermissionCreated, () => console.log("EventOff", PermissionEvent.onPermissionCreated))
        EventBus.off(PermissionEvent.onPermissionUpdated, () => console.log("EventOff", PermissionEvent.onPermissionUpdated))
        EventBus.off(PermissionEvent.onPermissionDeleted, () => console.log("EventOff", PermissionEvent.onPermissionDeleted))
    }

    request = (params, sort) => {
        for (let sortKey in sort) {
            params.order = sortKey + ">" + sort[sortKey].replace("end", "")
        }
        return requestPermissionByPage(params).then(res => {
            return res.data
        }).catch((err) => {
            console.error("请求权限分页数据失败", err)
            return { data: [], success: true, total: 0 }
        })
    }

    render() {
        return (<>
            <Alert type="warning" style={{ marginBottom: 16 }} closable message="功能权限是系统的运行关键数据，非专业人员请勿进行操作" />
            <ProTable rowKey="id"
                scroll={{ x: "max-content" }}
                columns={PermissionDataTableColumns}
                columnsState={PermissionDataTableColumnsState}
                actionRef={this.actionRef}
                toolBarRender={() => (<PermissionDataTableOperations />)}
                request={this.request} />
            <PermissionWrapper permissions={["permission:update"]}>
                <UpdatePermissionModal />
            </PermissionWrapper>
        </>)
    }

}