import React, { Component } from 'react'
import PropTypes from "prop-types"
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Input, Skeleton, Space, Tooltip, Tree } from "antd"
import { ProCard } from "@ant-design/pro-components"

import EmptyWrapper from "@/framework/component/EmptyWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"
import CreateDepartmentModal from "@/member/department/interaction/CreateDepartmentModal"
import DepartmentEvent from "@/member/department/event/DepartmentEvent"

/**
 * @author       : AngelBeats
 * @date         : 2023-12-01 15:52:36
 * @description  : 栏目树形组件
 */
export default class DepartmentTree extends Component {

    state = {
        loading: true,
        treeData: [],
        selectedKeys: [],
        defaultExpandedKeys: [],
        searchValue: ""
    }

    componentDidMount() {
        this.request()
        // 当新增单位时
        EventBus.on(DepartmentEvent.onDepartmentCreated, () => this.request())
        // 当单位更新时
        EventBus.on(DepartmentEvent.onDepartmentUpdated, (updateId) => {
            // 如果更新的单位信息时当前选中的单位，在刷新数据后重新选中单位
            this.request(undefined, (!!this.state.selectedKeys && this.state.selectedKeys[0] === updateId) ? updateId : undefined)
        })
        EventBus.on(DepartmentEvent.onDepartmentDeleted, () => requestDepartmentByTree().then(res => {
            if (!!res.data[0]) {
                this.setState({ selectedKeys: [res.data[0].id], searchValue: '' }, () => this.props.onChange(res.data[0]))
            }
            const defaultExpandedKeys = this.extractExpandedRowKeys(res.data)
            this.setState({ loading: false, treeData: res.data, defaultExpandedKeys })
        }).catch(() => {
            this.setState({ loading: false, treeData: [] })
        }))
    }

    componentWillUnmount() {
        EventBus.off(DepartmentEvent.onDepartmentCreated, () => console.log("EventOff", DepartmentEvent.onDepartmentCreated))
        EventBus.off(DepartmentEvent.onDepartmentUpdated, () => console.log("EventOff", DepartmentEvent.onDepartmentUpdated))
        EventBus.off(DepartmentEvent.onDepartmentDeleted, () => console.log("EventOff", DepartmentEvent.onDepartmentDeleted))
    }

    request = (name, updateId) => {
        this.setState({ loading: true })
        requestDepartmentByTree({ name }).then(res => {
            if (this.state.selectedKeys.length < 1 && res.data.length > 0) {
                this.setState({ selectedKeys: [res.data[0].id] }, () => this.props.onChange(res.data[0]))
            }
            res.data.unshift({ id: "0", name: "无单位用户" })
            const defaultExpandedKeys = this.extractExpandedRowKeys(res.data, updateId)
            this.setState({ loading: false, treeData: res.data, defaultExpandedKeys })
        }).catch(() => {
            this.setState({ loading: false, treeData: [] })
        })
    }

    extractExpandedRowKeys = (tree, updateId, keys = []) => {
        for (let v of tree) {
            if (!!updateId && (v.id === updateId)) {
                this.props.onChange(v)
            }
            if (v.children !== undefined && v.children !== null && v.children.length > 0) {
                keys.push(v.id)
                this.extractExpandedRowKeys(v.children, updateId, keys)
            }
        }
        return keys
    }

    onSelect = (selectedKeys, event) => {
        if (selectedKeys === undefined || selectedKeys === null || selectedKeys.length < 1) {
            return
        }
        this.setState({ selectedKeys: [...selectedKeys] }, () => {
            this.props.onChange({ ...event.node })
        })
    }

    onSearch = (name) => {
        this.request(name)
    }

    render() {
        return (<ProCard ghost>
            <Space.Compact block style={{ marginBottom: 16 }}>
                <Input.Search placeholder="搜索单位"
                    value={this.state.searchValue}
                    onSearch={this.onSearch}
                    onChange={(e) => this.setState({ searchValue: e.target.value })} />
                <Tooltip title="新建顶层单位">
                    <Button icon={<PlusOutlined />} onClick={() => CreateDepartmentModal.open("0")} />
                </Tooltip>
                <Tooltip title="刷新">
                    <Button icon={<ReloadOutlined />}
                        loading={this.state.loading}
                        onClick={() => this.setState({ searchValue: "" }, () => this.request())} />
                </Tooltip>
            </Space.Compact>
            <Skeleton loading={this.state.loading} active>
                <EmptyWrapper empty={this.state.treeData.length < 1}>
                    <Tree blockNode showLine
                        defaultExpandedKeys={this.state.defaultExpandedKeys}
                        selectedKeys={this.state.selectedKeys}
                        fieldNames={{ title: "name", key: "id", children: "children" }}
                        onSelect={this.onSelect}
                        treeData={this.state.treeData} />
                </EmptyWrapper>
            </Skeleton>
            <CreateDepartmentModal />
        </ProCard>)
    }
}

DepartmentTree.propTypes = {
    onChange: PropTypes.func
}
