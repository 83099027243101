import PropTypes from "prop-types";
import SecurityUtils from "@/framework/util/SecurityUtils";

export default function UserWrapper({ user, matchSupperPermission = true, children }) {
    return SecurityUtils.isUser(user, matchSupperPermission) ? children : undefined
}

UserWrapper.propTypes = {
    user: PropTypes.string,
    children: PropTypes.node,
    matchSupperPermission: PropTypes.bool
}