import React from "react"
import {Button} from "antd"
import {ModalForm, ProFormRadio, ProFormText} from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule";

import {requestUpdateHelpCategory} from "../api/HelpAPI"
import HelpCategoryEvent from "../event/HelpEvent"

export default function UpdateHelpCategoryModal({item}) {

    const formRef = React.useRef()

    const trigger = (<Button>编辑</Button>)

    const onFinish = (params) => requestUpdateHelpCategory(params).then(res => {
        console.info("requestUpdateHelpCategory:" + params.id, res.data)
        EventBus.emit(HelpCategoryEvent.onHelpCategoryUpdated, params.key)
        return true
    }).catch(err => {
        console.error("requestUpdateHelpCategory:" + params.id, err)
        FormUtils.setFormError(formRef, err.response?.data, "name")
        return false
    })

    return (<ModalForm title="编辑帮助分类"
                       initialValues={item}
                       modalProps={{width: 560, destroyOnClose: true}}
                       formRef={formRef}
                       trigger={trigger}
                       onFinish={onFinish}>
        <ProFormText name="id" label="ID" rules={Rule.require} hidden/>
        <ProFormText width="md" name="key" label="Key" rules={Rule.require} disabled/>
        <ProFormText width="md" name="name" label="分类名称" rules={Rule.require}/>
        <ProFormRadio.Group name="leaf" label="叶子结点" rules={Rule.require} disabled
                            options={[{label: "否", value: 0}, {label: "是", value: 1}]}/>
    </ModalForm>)
}