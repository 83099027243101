/**
 * 新建每月统计
 */
export const requestCreateMonthlyStatistic = (params) => window.axios.post("/statistic/monthly", params)

/**
 * 分页查询每月统计
 * @param params 查询参数
 */
export const requestMonthlyStatisticByPage = (params) => window.axios.get("/statistic/monthly/page", { params })

/**
 * 查询每月统计
 * @param params 查询参数
 */
export const requestMonthlyStatisticByList = (params) => window.axios.get("/statistic/monthly/list", { params })

/**
 * 查询指定ID每月统计
 * @param id {string} 每月统计ID
 */
export const requestMonthlyStatisticById = (id) => window.axios.get(`/statistic/monthly/${id}`)

/**
 * 更新每月统计
 */
export const requestUpdateMonthlyStatistic = (params) => window.axios.patch(`/statistic/monthly/${params.id}`, params)

/**
 * 删除每月统计
 */
export const requestDeleteMonthlyStatistic = (id) => window.axios.delete(`/statistic/monthly/${id}`)
