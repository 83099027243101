import React, {useEffect, useRef, useState} from "react";
import {message} from "antd";
import {
    PageContainer,
    ProCard,
    ProFormGroup,
    ProFormList,
    ProFormSelect,
    ProFormText,
    StepsForm
} from "@ant-design/pro-components";
import {useNavigate, useParams} from "react-router-dom";

import FormUtils from "@/framework/util/FormUtils";
import NotfoundWrapper from "@/framework/component/NotfoundWrapper";

import {requestCodeTableById, requestUpdateCodeTable} from "./api/CodeTableAPI";
import Rule from "@/framework/form/Rule";

export default function UpdateCodeTable() {

    const id = useParams()?.id
    const fromRef = useRef()
    const formRefForCodeTable = React.useRef()
    const formRefForCodeColumn = React.useRef()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [notFound, setNotFound] = useState(false)

    const onRequestCodeTable = () => {
        requestCodeTableById(id).then(res => {
            setLoading(false)
            setNotFound(false)
            setTimeout(() => {
                formRefForCodeTable.current?.setFieldsValue(res.data)
                formRefForCodeColumn.current?.setFieldsValue({columns: res.data.columns})
            }, 100)
        }).catch(err => {
            console.error(err)
            setLoading(false)
            setNotFound(true)
        })
    }

    const onFinish = (params) => requestUpdateCodeTable(params).then(() => {
        navigate(-1)
        message.success("编辑成功").then()
        return true
    }).catch(err => {
        FormUtils.setFormError(fromRef, err.response?.data, "name")
        return false
    })

    useEffect(onRequestCodeTable, [id])

    return (<PageContainer header={{onBack: () => navigate(-1)}} loading={loading}>
        <NotfoundWrapper notfound={notFound}>
            <ProCard>
                <StepsForm formRef={fromRef} onFinish={onFinish}>
                    <StepsForm.StepForm title="编辑代码生成表信息"
                                        layout="horizontal"
                                        labelCol={{span: 4}}
                                        wrapperCol={{span: 20}}
                                        formRef={formRefForCodeTable}>
                        <ProFormText width="sm" name="id" label="ID" hidden rules={Rule.require}/>
                        <ProFormText width="sm" name="name" label="表名" rules={Rule.requireText}/>
                        <ProFormText width="md" name="author" label="作者" rules={Rule.requireText}/>
                        <ProFormText width="md" name="comment" label="表注释" rules={Rule.requireText}/>
                        <ProFormText width="sm" name="schema" label="表空间" rules={Rule.requireText}/>
                        <ProFormText width="sm" name="entityName" label="实体名称" rules={Rule.requireText}/>
                        <ProFormText width="lg" name="packageName" label="包名" rules={Rule.requireText}/>
                    </StepsForm.StepForm>

                    <StepsForm.StepForm title="编辑代码生成表字段" formRef={formRefForCodeColumn}>
                        <ProFormList name="columns" min={1} max={128} copyIconProps={false}>
                            <ProFormGroup>
                                <ProFormText width={160} name="jdbcName" label="字段名称" rules={Rule.requireText}/>
                                <ProFormText width={160} name="javaName" label="字段名称" rules={Rule.requireText}/>
                                <ProFormText width={160} name="comment" label="注释" rules={Rule.requireText}/>
                                <ProFormText width={160} name="jdbcType" label="数据库数据类型"
                                             rules={Rule.requireText}/>
                                <ProFormText width={160} name="javaType" label="Java数据类型" rules={Rule.requireText}/>
                                <ProFormSelect width="xs" name="pk" label="是否主键" rules={Rule.require}
                                               options={[{label: "是", value: 1}, {label: "否", value: 0}]}/>
                                <ProFormSelect width="xs" name="editable" label="是否可编辑" rules={Rule.require}
                                               options={[{label: "是", value: 1}, {label: "否", value: 0}]}/>
                            </ProFormGroup>
                        </ProFormList>
                    </StepsForm.StepForm>

                </StepsForm>
            </ProCard>
        </NotfoundWrapper>
    </PageContainer>)

}