import React from "react";
import { Button, Modal, Space } from "antd";
import EventBus from "@/framework/util/EventBusUtils";
import { baseURL } from "@/framework/axios/Axios";
import ArticleEvent from "@/content/article/event/ArticleEvent";
import DownloadUtils from "@/framework/util/DownloadUtils";

export default class PreviewArticleWxappQrCodeModal extends React.Component {

    static open = (item) => {
        EventBus.emit(ArticleEvent.onTriggerPreviewArticleWxappQrcode, item)
    }

    state = {
        open: false,
        article: 0,
        loading: false
    }

    componentDidMount() {
        EventBus.on(ArticleEvent.onTriggerPreviewArticleWxappQrcode, (item) => {
            this.setState({ open: true, article: item.id })
        })
    }

    componentWillUnmount() {
        EventBus.off(ArticleEvent.onTriggerPreviewArticleWxappQrcode, console.log)
    }

    downloadQrCode = () => {
        this.setState({loading: true}, () => {
            window.axios.get(`/content/article/${this.state.article}/wxapp-qrcode`, { responseType: "blob" }).then(res => {
                DownloadUtils.download(res.data, "image/png", "资讯小程序二维码.png")
            }).catch(() => {
                window.antMessage.error("下载小程序二维码失败", 2).then()
            }).finally(() => {
                this.setState({loading: false})
            })
        })
    }

    footer = (<Space>
        <Button loading={this.state.loading} onClick={this.downloadQrCode}>下载二维码</Button>
        <Button type="primary" onClick={() => this.setState({ open: false })}>确认</Button>
    </Space>)

    render() {
        return (<Modal title="微信小程序二维码"
                       width={700}
                       destroyOnClose
                       open={this.state.open}
                       onCancel={() => this.setState({ open: false })}
                       footer={this.footer}>
            <div id="article-qr-code" style={{ display: "flex", justifyContent: "center" }}>
                <img alt="资讯二维码" src={`${baseURL}/content/article/${this.state.article}/wxapp-qrcode`}/>
            </div>
        </Modal>)
    }

}