import React from "react"
import { Button, message, Modal, Skeleton, Space, Upload } from "antd"
import { InboxOutlined } from "@ant-design/icons"
import { DrawerForm, ProCard, ProFormGroup, ProFormList, ProFormText } from "@ant-design/pro-components"

import EventBus from "../../framework/util/EventBusUtils"
import { formConverter } from "../util/FormConverter"

import {
    requestCreateBatchProcessInstance,
    requestProcessDefinitionExtensionPropertiesById,
    requestProcessDefinitionStartForm
} from "../api/WorkflowAPI"

import WorkflowEvent from "../event/WorkflowEvent"
import WorkflowLifecycleUtils from "../util/WorkflowLifecycleUtils"
import { requestMetaById } from "../../common/meta/api/MetaAPI"

/**
 * 批量开启流程 - ProFormList Item 渲染器
 *
 * @author Wayne Carder
 */
class ProFormListItem extends React.Component {

    componentDidMount() {
        const { processDefinition, extensionProperties, index, action } = this.props
        const fakeFormRef = { current: { setFieldsValue: action.setCurrentRowData } }
        WorkflowLifecycleUtils.onFormRendered(processDefinition, {}, extensionProperties, fakeFormRef, index)
    }

    render = () => (<ProFormGroup key={`item-${this.props.index}`}>
        {formConverter(this.props.form?.components, { current: { setFieldsValue: this.props.action.setCurrentRowData } })}
    </ProFormGroup>)

}

/**
 * Excel 模版选择弹窗
 *
 * @author Wayne Carder
 */
class ExcelUploadModal extends React.Component {

    state = { open: false }

    static open = () => {
        EventBus.emit("ExcelUploadModal")
    }

    componentDidMount() {
        EventBus.on("ExcelUploadModal", () => this.setState({ open: true }))
    }

    componentWillUnmount() {
        EventBus.off("ExcelUploadModal", console.log)
    }

    beforeUpload = (file) => {
        this.setState({ open: false })
        this.props.onSelectedTemplate(file)
        return false
    }

    render = () => (<Modal title="选择 Excel 模版"
        open={this.state.open}
        destroyOnClose
        onCancel={() => this.setState({ open: false })}>
        <Upload.Dragger beforeUpload={this.beforeUpload} accept=".xlsx">
            <p className="ant-upload-drag-icon"><InboxOutlined /></p>
            <p className="ant-upload-text">点击或拖拽文件到此处进行解析</p>
            <p className="ant-upload-hint">请使用系统提供的 Excel 模版并规范填写，否则系统可能无法识别</p>
        </Upload.Dragger>
    </Modal>)

}

/**
 * 批量开启流程 - 表单底部 Submitter
 *
 * @author Wayne Carder
 */
function Submitter({ extensionProperties, resetDom, actionRef }) {

    // 下载模版按钮加载状态 State
    const [loadingForDownload, setLoadingForDownload] = React.useState(false)
    // 上传模版按钮加载状态 State
    const [loadingForUpload, setLoadingForUpload] = React.useState(false)

    const onDownload = () => {
        if (!extensionProperties?.startFormExcelConverter) {
            message.warning({ key: "onDownload", content: "此流程暂不支持Excel批量导入" }, 2).then()
            return
        }
        setLoadingForDownload(true)
        requestMetaById(extensionProperties.startFormExcelConverter).then(res => {
            const func = new Function("setLoading", res.data.content)
            func(setLoadingForDownload)
        }).catch(err => {
            console.log("执行导出流程定义 Start Form 失败", err)
            message.warning({ key: "onDownload", content: "此流程暂不支持Excel批量导入" }, 2).then()
        })
    }

    // startExcelFormConverter
    const onUpload = () => {
        if (!extensionProperties?.startExcelFormConverter) {
            message.warning({ key: "onUpload", content: "此流程暂不支持Excel批量导入" }, 2).then()
            return
        }
        ExcelUploadModal.open()
    }

    const onSelectedTemplate = (file) => {
        setLoadingForUpload(true)
        requestMetaById(extensionProperties.startExcelFormConverter).then(res => {
            const func = new Function("file", "actionRef", "setLoading", res.data.content)
            func(file, actionRef, setLoadingForUpload)
        }).catch(err => {
            console.log("执行导入流程定义 Start Form 失败", err)
            message.warning({ key: "onUpload", content: "导入失败，请检查 Excel 或相关配置" }, 2).then()
        })
    }

    return (<Space>
        <ExcelUploadModal onSelectedTemplate={onSelectedTemplate} />
        <Button key="download" loading={loadingForDownload} onClick={onDownload}>下载 Excel 模板</Button>
        <Button key="upload" loading={loadingForUpload} onClick={onUpload}>上传 Excel 模板</Button>
        {resetDom[0]}
        {resetDom[1]}
    </Space>)
}

/**
 * 批量开启流程实例
 *
 * @author Wayne Carder
 */
export default class StartBatchProcessInstanceDrawer extends React.Component {

    fromRef = React.createRef()
    actionRef = React.createRef()

    state = {
        open: false,
        // 流程定义对象
        processDefinition: {},
        // 表单对象
        form: undefined,
        // 开始表单加载中
        startFormLoading: true,
        // Process Definition Extension Properties
        extensionProperties: {}
    }

    componentDidMount() {
        EventBus.on(WorkflowEvent.onTriggerCreateBatchProcessInstanceEvent, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onTriggerCreateBatchProcessInstanceEvent, console.log)
    }

    onTrigger = (processDefinition) => {
        // 请求 Process Definition Extension Properties
        requestProcessDefinitionExtensionPropertiesById(processDefinition.id).then(res => {
            this.setState({ extensionProperties: res.data })
        }).catch(err => {
            console.warn("requestProcessDefinitionExtensionPropertiesById", err)
        })
        // 开启抽屉，初始化抽屉表单
        this.setState({ startFormLoading: true, open: true }, () => {
            requestProcessDefinitionStartForm(processDefinition.id).then(res => {
                processDefinition.hasStartForm = true
                this.setState({ form: res.data, startFormLoading: false, processDefinition })
            }).catch(() => {
                processDefinition.hasStartForm = false
                this.setState({ open: false, form: undefined, startFormLoading: false, processDefinition })
                message.warning("该流程无法批量启动（缺少开始表单）", 2).then()
            })
            setTimeout(() => this.fromRef.current?.setFieldsValue(processDefinition), 200)
        })
    }

    onFinish = (params) => requestCreateBatchProcessInstance(params).then(res => {
        EventBus.emit(WorkflowEvent.onProcessInstanceCreateEvent, res.data)
        message.success("操作成功", 2).then()
        return true
    }).catch(err => {
        console.error(err)
        return false
    })

    render = () => (<DrawerForm title={`批量发起【${this.state.processDefinition.name}】流程`}
        disabled={this.state.startFormLoading}
        open={this.state.open}
        formRef={this.fromRef}
        drawerProps={{ destroyOnClose: true, width: "100vw" }}
        onFinish={this.onFinish}
        submitter={{ render: (_, defaultDoms) => (<Submitter resetDom={defaultDoms} extensionProperties={this.state.extensionProperties} actionRef={this.actionRef} />) }}
        onOpenChange={open => this.setState({ open })}>

        <ProFormText label="流程定义ID" name="id" hidden />
        <Skeleton active loading={this.state.startFormLoading}>
            <ProFormList name="variables"
                alwaysShowItemLabel
                max={99}
                actionRef={this.actionRef}
                itemRender={(dom) => (<ProCard bordered extra={dom.action} style={{ marginBlockEnd: 16 }}>{dom.listDom}</ProCard>)}>
                {(_, index, action) => (<ProFormListItem key={index}
                    index={index}
                    action={action}
                    form={this.state.form}
                    processDefinition={this.state.processDefinition}
                    extensionProperties={this.state.extensionProperties} />)}
            </ProFormList>
        </Skeleton>

    </DrawerForm>)

}

StartBatchProcessInstanceDrawer.open = (processDefinition) => {
    EventBus.emit(WorkflowEvent.onTriggerCreateBatchProcessInstanceEvent, processDefinition)
}