import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormText } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"

import { requestCreateSpecificationProperty } from "@/specification/property/api/SpecificationPropertyAPI"
import SpecificationPropertyEvent from "@/specification/property/event/SpecificationPropertyEvent"

export default function CreateSpecificationPropertyModal({ specification }) {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增规格属性</Button>)

    const onFinish = (params) => requestCreateSpecificationProperty(params).then(res => {
        EventBus.emit(SpecificationPropertyEvent.onSpecificationPropertyCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增规格属性失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增规格属性"
        modalProps={{ destroyOnClose: true, width: 560 }}
        formRef={formRef}
        trigger={trigger}
        onFinish={onFinish}>
        <ProFormText name="specification" label="规格" hidden initialValue={specification} />
        <ProFormText name="property" label="规格属性" rules={Rule.require} />
    </ModalForm>)
}