import React from "react"
import {
    DrawerForm, ProFormDatePicker,
    ProFormDigit,
    ProFormField,
    ProFormRadio,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
    ProFormTreeSelect
} from "@ant-design/pro-components"

import FormUtils from "../../../framework/util/FormUtils"
import EventBus from "../../../framework/util/EventBusUtils"
import Rule from "../../../framework/form/Rule";

import { requestCreateMember } from "../api/MemberAPI"
import MemberEvent from "../event/MemberEvent"
import AttachmentPicker from "../../../common/attachment/file/component/AttachmentPicker";
import DictionaryVariableUtils from "../../../common/dictionary/variable/util/DictionaryVariableUtils";

export default class CreateMemberDrawer extends React.Component {

    static open = (item) => {
        EventBus.emit(MemberEvent.onTriggerCreateMember, item)
    }

    state = {
        open: false,
        hasManager: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(MemberEvent.onTriggerCreateMember, (item) => this.setState({
            open: true,
            hasManager: !!item
        }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(item), 100)
        }))
    }

    componentWillUnmount() {
        EventBus.off(MemberEvent.onTriggerCreateMember, console.log)
    }

    onFinish = (params) => {
        params.trade = params.trade.toString()
        params.operatePlatform = params.operatePlatform.toString()
        return requestCreateMember(params).then(res => {
            EventBus.emit(MemberEvent.onMemberCreated, res.data)
            return true
        }).catch(err => {
            console.log("新增会员失败", err)
            FormUtils.setFormError(this.formRef, err.response.data, "name")
            return false
        })
    }

    render() {
        return (<DrawerForm title="新增会员"
                            open={this.state.open}
                            onOpenChange={open => this.setState({ open })}
                            drawerProps={{ destroyOnClose: true, width: 640 }}
                            formRef={this.formRef}
                            onFinish={this.onFinish}>
            <ProFormField name="businessLicenceUrl" label="营业执照地址" rules={Rule.require}>
                <AttachmentPicker/>
            </ProFormField>
            <ProFormText width="md" name="nuscc" label="统一社会信用代码" rules={Rule.require}/>
            <ProFormText width="sm" name="name" label="单位名称" rules={Rule.require}/>
            <ProFormText width="lg" name="address" label="地址" rules={Rule.require}/>
            <ProFormSelect width="sm" name="level" label="参会性质"
                           fieldProps={{ onSelect: (_, v) => this.formRef.current?.setFieldsValue({ queryOtherMember: v.comment }) }}
                           request={() => DictionaryVariableUtils.requestDvByList("member-level")}
                           rules={Rule.require}/>
            <ProFormDigit width="xs" name="queryOtherMember" label="查询会员通讯录次数" rules={Rule.require}/>
            <ProFormTreeSelect width="xl" name="trade" label="行业"
                               fieldProps={{ fieldNames: { label: "name", value: "variable" }, multiple: true }}
                               request={() => DictionaryVariableUtils.requestDvByTree("member-trade")}
                               rules={Rule.require}/>
            <ProFormSelect width="xl" name="operatePlatform" label="电商平台" mode="tags"
                           request={() => DictionaryVariableUtils.requestDvByList("member-operate-platform")}
                           rules={Rule.require}/>
            <ProFormRadio.Group name="businessDirection" label="业务方向"
                                initialValue="B2B"
                                options={[{ label: "B2B", value: "B2B" }, { label: "B2C", value: "B2C" }]}
                                rules={Rule.require}/>
            <ProFormTextArea name="businessArea" label="业务范围" rules={Rule.require}/>
            {
                !this.state.hasManager ? <>
                    <ProFormText width="sm" name="managerName" label="负责人姓名" rules={Rule.require}/>
                    <ProFormText width="md" name="managerCellphone" label="负责人手机号码" rules={Rule.require}/>
                </> : <ProFormText hidden label="负责人" name="manager"/>
            }
            <ProFormText width="sm" name="managerPost" label="负责人职位"/>
            <ProFormDatePicker width="sm" name="managerBirthday" label="负责人生日" rules={Rule.require}/>
            <ProFormRadio.Group width="xs" name="managerGander" label="负责人性别"
                                initialValue={1}
                                options={[
                                    { label: "男", value: 1 },
                                    { label: "女", value: 2 },
                                ]}
                                rules={Rule.require}/>
            <ProFormSelect width="xs" name="managerPolicy" label="负责人政治面貌"
                           initialValue={0}
                           request={() => DictionaryVariableUtils.requestDvByTree("policy")}
                           rules={Rule.require}/>
            <ProFormText width="sm" name="inviter" label="推荐人"/>
        </DrawerForm>)
    }
}