import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormRadio, ProFormText } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"
import { getAvailableEnumAsOptions } from "@/framework/enum/AvailableEnum"

import { requestCreateRole } from "@/member/role/api/RoleAPI"
import RoleEvent from "@/member/role/event/RoleEvent"

export default function CreateRoleModal() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增</Button>)

    const onFinish = (params) => requestCreateRole(params).then(res => {
        EventBus.emit(RoleEvent.onRoleCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增角色失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增角色"
        isKeyPressSubmit
        scrollToFirstError
        modalProps={{ width: 560, destroyOnClose: true }}
        formRef={formRef}
        trigger={trigger}
        onFinish={onFinish}>
        <ProFormText width="md" name="name" label="名称" rules={Rule.requireText} />
        <ProFormText width="md" name="identifier" label="标识符" rules={Rule.requireText} />
        <ProFormRadio.Group width="md" name="available" label="状态" rules={Rule.require}
            initialValue="可用" options={getAvailableEnumAsOptions()} />
    </ModalForm>)

}