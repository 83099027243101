import React, { useState } from 'react'
import { PageContainer, ProCard } from "@ant-design/pro-components"

import SpecificationPropertyTree from "@/specification/specification/component/SpecificationPropertyTree"
import SpecificationPropertyDescription from "@/specification/specification/component/SpecificationPropertyDescription"
import SpecificationPropertyDataTable from "@/specification/property/component/SpecificationPropertyDataTable"

const Specifications = () => {

    const [specificationProperty, setSpecificationProperty] = useState({})

    const tabs = {
        defaultActiveKey: "1",
        type: "card",
        items: [
            {
                key: "0",
                label: "详情",
                children: (<SpecificationPropertyDescription specificationProperty={specificationProperty} />)
            },
            { key: "1", label: "属性", children: (<SpecificationPropertyDataTable specification={specificationProperty.id} />) },
        ]
    }

    return (<PageContainer>
        <ProCard split="vertical" ghost bordered>
            <ProCard size="small" colSpan={7}>
                <SpecificationPropertyTree onChange={setSpecificationProperty} />
            </ProCard>
            <ProCard size="small" colSpan={17} tabs={tabs} />
        </ProCard>
    </PageContainer>)
}

export default Specifications
