import React from "react"
import { ModalForm, nanoid, ProFormText } from "@ant-design/pro-components"
import { throttle } from "lodash"
import { useNavigate } from "react-router-dom"

import FormUtils from "@/framework/util/FormUtils"
import SecurityUtils from "@/framework/util/SecurityUtils"
import EventBus from "@/framework/util/EventBusUtils"

import { requestImageCaptcha } from "@/login/api/CaptchaAPI"
import { requestUpdateAuthenticationPassword } from "@/member/authentication/api/AuthenticationAPI"
import AuthenticationEvent from "@/member/authentication/event/AuthenticationEvent"

class UpdatePasswordModal extends React.Component {

    formRef = React.createRef()

    state = {
        acceptor: nanoid(),
        image: "",
        open: false
    }

    componentDidMount() {
        EventBus.on(AuthenticationEvent.onTriggerUpdatePassword, () => {
            this.setState({ open: true }, this.onRequestCaptcha)
        })
    }

    componentWillUnmount() {
        EventBus.off(AuthenticationEvent.onTriggerUpdatePassword, console.log)
    }

    onRequestCaptcha = () => {
        requestImageCaptcha({
            acceptor: this.state.acceptor,
            width: 135,
            height: 31,
            topic: "update-password"
        }).then(res => {
            this.setState({ image: res.data })
        }).catch(() => {
            this.setState({ image: "" })
        })
    }

    onClick = throttle(this.onRequestCaptcha, 3000, { leading: true, trailing: false })

    onFinish = (params) => requestUpdateAuthenticationPassword(params).then(() => {
        window.localStorage.clear()
        this.props.onSuccess()
        return true
    }).catch(err => {
        FormUtils.setFormError(this.formRef, err.response?.data, "password")
        return false
    })

    render() {
        return (<ModalForm title="修改密码"
                           open={this.state.open}
                           onOpenChange={open => this.setState({ open })}
                           formRef={this.formRef}
                           grid
                           width={480}
                           modalProps={{ destroyOnClose: true }}
                           rowProps={{ gutter: [16, 0] }}
                           onFinish={this.onFinish}>

            <ProFormText colProps={{ span: 24 }} name="acceptor" label="Acceptor" hidden
                         initialValue={this.state.acceptor}/>

            <ProFormText colProps={{ span: 24 }} name="user" label="用户ID" hidden
                         initialValue={SecurityUtils.getUser()?.id}/>

            <ProFormText.Password colProps={{ span: 24 }} name="password" label="密码"
                                  rules={[
                                      { required: true, message: "请输入密码" },
                                      { min: 6, message: "密码长度应在[6,24]" },
                                      { max: 24, message: "密码长度应在[6,24]" },
                                      {
                                          pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,24}/,
                                          message: "密码应包含大小写字母和数字"
                                      }
                                  ]}/>

            <ProFormText.Password colProps={{ span: 24 }} name="confirmPassword" label="确认密码"
                                  rules={[
                                      { required: true, message: "请输入密码" },
                                      { min: 6, message: "密码长度应在[6,32]" },
                                      { max: 32, message: "密码长度应在[6,32]" },
                                      ({ getFieldValue }) => ({
                                          validator(_, value) {
                                              if (!value || getFieldValue('password') === value) {
                                                  return Promise.resolve()
                                              }
                                              return Promise.reject(new Error('确认密码匹配失败'))
                                          },
                                      })
                                  ]}/>

            <ProFormText colProps={{ span: 16 }}
                         name="captcha"
                         label="验证码"
                         placeholder="验证码"
                         rules={[{ required: true, message: "请输入验证码" }]}/>

            <img alt="验证码（点击刷新）"
                 style={{ width: 140, height: 31, marginTop: 30, border: ".5px solid #d9d9d9", borderRadius: 4 }}
                 src={this.state.image}
                 onClick={this.onClick}/>

        </ModalForm>)
    }

}

export default function WithRouterUpdatePasswordModal() {
    const navigate = useNavigate()
    const onSuccess = () => navigate("/", { replace: true })
    return (<UpdatePasswordModal navigate={navigate} onSuccess={onSuccess}/>)
}

WithRouterUpdatePasswordModal.open = () => {
    EventBus.emit(AuthenticationEvent.onTriggerUpdatePassword)
}