import React from "react";
import {
    DrawerForm,
    ProFormDigit,
    ProFormField,
    ProFormSelect,
    ProFormText,
    ProFormTreeSelect
} from "@ant-design/pro-components";
import Quill from "quill";
import 'quill/dist/quill.snow.css';

import EventBus from "@/framework/util/EventBusUtils";
import FormUtils from "@/framework/util/FormUtils";
import Rule from "@/framework/form/Rule";

import { requestArticleById, requestUpdateArticle } from "@/content/article/api/ArticleAPI"
import ArticleEvent from "@/content/article/event/ArticleEvent"
import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker";
import DictionaryVariableUtils from "@/common/dictionary/variable/util/DictionaryVariableUtils";
import { requestSectionByTree } from "@/content/section/api/SectionAPI";
import SecurityUtils from "@/framework/util/SecurityUtils";

export default class UpdateArticleDrawer extends React.Component {

    state = {
        open: false
    }

    quill

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(ArticleEvent.onTriggerUpdateArticle, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(ArticleEvent.onTriggerUpdateArticle, console.log)
    }

    onFinish = (params) => {
        params.content = this.handleContentImg(this.quill.root.innerHTML)
        params.type = params.type.join()
        return requestUpdateArticle(params).then(() => {
            EventBus.emit(ArticleEvent.onArticleUpdated)
            return true
        }).catch((err) => {
            console.error("编辑文章失败", err);
            FormUtils.setFormError(this.formRef, err.response?.data, "name")
            return false;
        })
    }

    handleContentImg = (htmlString) => {
        // 创建一个 DOMParser 实例
        const parser = new DOMParser();
        // 解析 HTML 字符串为 DOM 文档
        const doc = parser.parseFromString(htmlString, 'text/html');
        // 获取所有的 img 标签
        const images = doc.querySelectorAll('img');
        // 遍历所有 img 标签并添加宽度属性
        images.forEach(img => {
            img.style.width = "100%";
        });
        // 返回处理后的 HTML 字符串
        return doc.body.innerHTML
    }

    onTrigger = (item) => this.setState({ open: true }, () => {
        const entity = JSON.parse(JSON.stringify(item));
        entity.type = entity.type.split(",")
        setTimeout(() => this.formRef.current?.setFieldsValue(entity), 100);
        requestArticleById(item.id).then(res => {
            this.quill.clipboard.dangerouslyPasteHTML(res.data.content)
        })
    })

    onRequestParentTree = (params) => requestSectionByTree(params).then(res => {
        if (SecurityUtils.hasSuperPermission()) {
            res.data.push({ name: "无栏目", id: "0", disabled: false })
        }
        return res.data
    }).catch(() => {
        return [{ name: "无栏目", id: "0", disabled: false }]
    })

    onOpenChange = open => {
        this.setState({ open }, () => {
            if (open) {
                this.quill = new Quill("#editor", { theme: "snow" })
            }
        })
    }

    render() {
        return (<DrawerForm title="编辑文章"
                            drawerProps={{ destroyOnClose: true, width: window.innerWidth < 700 ? "100%" : "60%" }}
                            open={this.state.open}
                            onOpenChange={this.onOpenChange}
                            formRef={this.formRef}
                            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden/>
            <ProFormField name="cover">
                <AttachmentPicker accept={["image/png", "image/jpeg"]}/>
            </ProFormField>
            <ProFormTreeSelect width="sm" name="section" label="父栏目" rules={Rule.require}
                               fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                               request={this.onRequestParentTree}/>
            <ProFormText width="sm" name="title" label="标题" rules={Rule.require}/>
            <ProFormText width="md" name="subtitle" label="副标题"/>
            <ProFormText width="sm" name="author" label="作者" rules={Rule.require}/>
            <ProFormDigit width="xs" name="sort" label="排序"
                          initialValue={1}
                          fieldProps={{ precision: 0, max: 999 }}
                          rules={Rule.require}/>
            <ProFormSelect width="md" name="type" label="类型"
                           mode="tags"
                           request={() => DictionaryVariableUtils.requestDvByList('article-type')}
                           rules={Rule.require}/>
            <ProFormDigit name="numView" label="查看次数" initialValue={0}
                          rules={Rule.require}/>
            <div id="editor" style={{ height: 480 }}></div>
        </DrawerForm>)
    }
}