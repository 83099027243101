import React from "react";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {ModalForm, ProFormField, ProFormText, ProFormTextArea} from "@ant-design/pro-components";

import FormUtils from "@/framework/util/FormUtils";
import EventBus from "@/framework/util/EventBusUtils";
import Rule from "@/framework/form/Rule";

import {requestCreateAnnouncement} from "../api/AnnouncementAPI";
import AnnouncementEvent from "../event/AnnouncementEvent";
import AttachmentPicker from "../../attachment/file/component/AttachmentPicker";

export default function CreateAnnouncementModal() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined/>}>新增公告</Button>)

    const onFinish = (params) => requestCreateAnnouncement(params).then(res => {
        EventBus.emit(AnnouncementEvent.onAnnouncementCreated, res.data)
        formRef.current?.resetFields()
        return true
    }).catch(err => {
        console.error("新增公告失败", err)
        FormUtils.setFormError(formRef, err.response.data, "title")
        return false
    })

    return (<ModalForm title="新增公告"
                       modalProps={{destroyOnClose: true, width: 560}}
                       formRef={formRef}
                       trigger={trigger}
                       isKeyPressSubmit
                       onFinish={onFinish}>

        <ProFormField label="封面" name="cover">
            <AttachmentPicker accept={["image/png", "image/jpeg"]}/>
        </ProFormField>

        <ProFormText width="md" name="title" label="标题" rules={Rule.requireText}/>

        <ProFormText width="md" name="subtitle" label="副标题" rules={Rule.text}/>

        <ProFormTextArea name="content" label="公告内容" rules={Rule.requireTextarea}
                         fieldProps={{rows: 5, showCount: true}}/>

    </ModalForm>)

}