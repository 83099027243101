import {requestMetaById} from "../../common/meta/api/MetaAPI";

function onFormRendered(task, variables, extensionProperties, formRef) {
    if (!extensionProperties["onFormRendered"]) {
        return
    }
    requestMetaById(extensionProperties["onFormRendered"]).then(res => {
        // eslint-disable-next-line no-new-func
        const onFormRendered = new Function("task", "variables", "extensionProperties", "formRef", res.data.content)
        onFormRendered(task, variables, extensionProperties, formRef);
    }).catch(err => {
        console.log(err)
    })
}

function beforeTaskComplete(task, variables, extensionProperties, formRef) {
    if (!extensionProperties["beforeTaskComplete"]) {
        return
    }
    requestMetaById(extensionProperties["beforeTaskComplete"]).then(res => {
        // eslint-disable-next-line no-new-func
        const beforeTaskComplete = new Function("task", "variables", "extensionProperties", "formRef", res.data.content)
        beforeTaskComplete(task, variables, extensionProperties, formRef);
    }).catch(err => {
        console.log(err)
    })
}

function onTaskCompleted(task, variables, extensionProperties, formRef) {
    if (!extensionProperties["onTaskCompleted"]) {
        return
    }
    requestMetaById(extensionProperties["onTaskCompleted"]).then(res => {
        // eslint-disable-next-line no-new-func
        const onTaskCompleted = new Function("task", "variables", "extensionProperties", "formRef", res.data.content)
        onTaskCompleted(task, variables, extensionProperties, formRef);
    }).catch(err => {
        console.log(err)
    })
}

const TaskLifecycleUtils = {
    onFormRendered: onFormRendered,
    beforeTaskComplete: beforeTaskComplete,
    onTaskCompleted: onTaskCompleted
}

export default TaskLifecycleUtils;