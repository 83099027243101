import PropTypes from "prop-types";
import {Button, Popconfirm} from "antd";

import EventBus from "@/framework/util/EventBusUtils";

import {requestDeleteCategory} from "../api/CategoryAPI";
import CategoryEvent from "../event/CategoryEvent";


export default function DeleteCategoryButton(props) {

    const onConfirm = () => requestDeleteCategory(props.id).then(res => {
        EventBus.emit(CategoryEvent.onCategoryDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除附件分类" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteCategoryButton.propTypes = {
    id: PropTypes.string.isRequired
}