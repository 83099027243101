import 'react-app-polyfill/stable';

import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { App, ConfigProvider } from "antd"
import { ProConfigProvider } from "@ant-design/pro-components"
import zhCN from 'antd/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

import "@/framework/axios/Axios"
import { getRoutes } from "./framework/route/Route"

import Login from "@/login"
import Framework from '@/Framework'
import NotfoundPage from "@/NotfoundPage"

const AntDesignTheme = {
    token: {
        borderRadius: 5
    }
}

dayjs.locale('zh-cn')

/**
 * 系统根节点——配置提供、路由渲染
 *
 * @description ConfigProvider 为 antd 提供的配置提供器，用来控制系统的语言
 * @description ProConfigProvider 为 ProComponents 提供的配置提供器，用来控制系统的主题（明暗）
 * @author Wayne Carder
 */
const Root = () => {

    const [dark, setDark] = React.useState(false)

    React.useEffect(() => {
        document.querySelector("#loader").remove()
        setDark(window.matchMedia("(prefers-color-scheme: dark)").matches)
        window.matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", (event) => setDark(event.matches))
    }, [])

    return (<ConfigProvider locale={zhCN} theme={AntDesignTheme}>
        <ProConfigProvider dark={dark}>
            <App>
                <BrowserRouter>
                    <Routes>
                        <Route index exact path="/" element={<Login />} />
                        <Route path="/home" element={<Framework />}>
                            {getRoutes().map(v => (<Route exact path={v.path} key={v.path} element={v.element} />))}
                            <Route path="/home/*" element={<NotfoundPage />} />
                        </Route>
                        <Route path="/*" element={<Login />} />
                    </Routes>
                </BrowserRouter>
            </App>
        </ProConfigProvider>
    </ConfigProvider>)
}

ReactDOM.createRoot(document.querySelector('#root')).render(<Root />)
