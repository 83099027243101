import PropTypes from "prop-types"
import { App, Button, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeletePost } from "@/member/post/api/PostAPI"
import PostEvent from "@/member/post/event/PostEvent"


export default function DeletePostButton({ id }) {

    const { message } = App.useApp()

    const onConfirm = () => requestDeletePost(id).then(res => {
        EventBus.emit(PostEvent.onPostDeleted, res.data)
        message.success("删除成功", 2).then()
        return true
    }).catch((err) => {
        console.error("删除岗位失败", err)
        message.error(!!err.response?.data.id ? err.response.data.id : err.message).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除岗位" okText="删除" cancelText="取消" okButtonProps={{ danger: true }}
        onCancel={onCancel}
        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeletePostButton.propTypes = {
    id: PropTypes.string.isRequired
}