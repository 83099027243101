/**
 * 新建sku
 */
export const requestCreateSku = (params) => window.axios.post("/specification/sku", params)

/**
 * 分页查询sku
 * @param params 查询参数
 */
export const requestSkuByPage = (params) => window.axios.get("/specification/sku/page", { params })

/**
 * 查询sku
 * @param params 查询参数
 */
export const requestSkuByList = (params) => window.axios.get("/specification/sku/list", { params })

/**
 * 查询指定IDsku
 * @param id {string} skuID
 */
export const requestSkuById = (id) => window.axios.get(`/specification/sku/${id}`)

/**
 * 更新sku
 */
export const requestUpdateSku = (params) => window.axios.patch(`/specification/sku/${params.id}`, params)

/**
 * 删除sku
 */
export const requestDeleteSku = (id) => window.axios.delete(`/specification/sku/${id}`)
