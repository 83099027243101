import React from "react"
import { PageContainer } from "@ant-design/pro-components"
import PermissionDataTable from "@/member/permission/component/PermissionDataTable"

/**
 * 权限管理表格页
 *
 * @description 该页面采用可编辑表格，除新增之外的操作皆在表格内完成
 * @description 该页面监听事件：onPermissionCreate
 *
 * @author Wayne Carder
 */
export default class Permissions extends React.Component {

    render() {
        return (<PageContainer>
            <PermissionDataTable />
        </PageContainer>)
    }

}