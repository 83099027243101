/**
 * 新建每日统计
 */
export const requestCreateDailyStatistic = (params) => window.axios.post("/statistic/daily", params)

/**
 * 分页查询每日统计
 * @param params 查询参数
 */
export const requestDailyStatisticByPage = (params) => window.axios.get("/statistic/daily/page", { params })

/**
 * 查询每日统计
 * @param params 查询参数
 */
export const requestDailyStatisticByList = (params) => window.axios.get("/statistic/daily/list", { params })

/**
 * 查询指定ID每日统计
 * @param id {string} 每日统计ID
 */
export const requestDailyStatisticById = (id) => window.axios.get(`/statistic/daily/${id}`)

/**
 * 更新每日统计
 */
export const requestUpdateDailyStatistic = (params) => window.axios.patch(`/statistic/daily/${params.id}`, params)

/**
 * 删除每日统计
 */
export const requestDeleteDailyStatistic = (id) => window.axios.delete(`/statistic/daily/${id}`)
