import React from "react"
import { ModalForm, ProFormRadio, ProFormText } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import { requestUpdateCategory } from "@/specification/category/api/CategoryAPI"
import CategoryEvent from "@/specification/category/event/CategoryEvent"

export default class UpdateCategoryModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(CategoryEvent.onTriggerUpdateCategory, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(CategoryEvent.onTriggerUpdateCategory, console.log)
    }

    onFinish = (params) => requestUpdateCategory(params).then(() => {
        EventBus.emit(CategoryEvent.onCategoryUpdated)
        return true
    }).catch((err) => {
        console.error("编辑类目失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    render() {
        return (<ModalForm title="编辑类目"
            modalProps={{ destroyOnClose: true, width: 560 }}
            open={this.state.open}
            onOpenChange={(open) => this.setState({ open })}
            formRef={this.formRef}
            onFinish={this.onFinish}>
            <ProFormText name="id" label="ID" hidden />
            <ProFormText name="name" label="名称" rules={Rule.require} />
            <ProFormRadio.Group name="status" label="状态" rules={Rule.require}
                initialValue={1}
                options={[
                    { label: "可用", value: 1 },
                    { label: "禁用", value: 0 }
                ]} />
        </ModalForm>)
    }
}