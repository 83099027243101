import React from "react"
import PropTypes from "prop-types"
import { Space } from "antd"

import AuthenticationDataTable from "@/member/authentication/component/AuthenticationDataTable"
import AuthenticationLogDataTable from "@/member/authentication/component/AuthenticationLogDataTable"

export default function TabForUserAuthentication(props) {
    return (<Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <AuthenticationDataTable user={props.user} />
        <AuthenticationLogDataTable user={props.user} />
    </Space>)
}

TabForUserAuthentication.propTypes = {
    user: PropTypes.object.isRequired
}