const WorkflowEvent = {
    onProcessDefinitionCreateEvent: "onProcessDefinitionCreateEvent",
    onProcessDefinitionUpdateEvent: "onProcessDefinitionUpdateEvent",
    onProcessDefinitionDeleteEvent: "onProcessDefinitionCreateEvent",
    onTriggerPreviewProcessDefinitionEvent: "onTriggerPreviewProcessDefinitionEvent",

    onProcessInstanceCreateEvent: "onProcessInstanceCreateEvent",
    onProcessInstanceUpdateEvent: "onProcessInstanceUpdateEvent",
    onProcessInstanceDeleteEvent: "onProcessInstanceDeleteEvent",
    onTriggerCreateProcessInstanceEvent: "onTriggerCreateProcessInstanceEvent",
    onTriggerCreateBatchProcessInstanceEvent: "onTriggerCreateBatchProcessInstanceEvent",
    onTriggerPreviewProcessInstanceEvent: "onTriggerPreviewProcessInstanceEvent",
    onTriggerTerminateProcessInstanceEvent: "onTriggerTerminateProcessInstanceEvent",

    onProcessTaskUpdateEvent: "onProcessTaskUpdateEvent",
    onTriggerCompleteProcessInstanceTaskEvent: "onTriggerCompleteProcessInstanceTaskEvent",
    onTriggerAssignProcessInstanceTaskEvent: "onTriggerAssignProcessInstanceTaskEvent",
    onTriggerPreviewProcessInstanceTaskEvent: "onTriggerPreviewProcessInstanceTaskEvent"
}

export default WorkflowEvent