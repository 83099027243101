import React from "react"
import { App, Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormRadio, ProFormText } from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule"
import { getAvailableEnumAsOptions } from "@/framework/enum/AvailableEnum"

import { requestCreatePermission } from "@/member/permission/api/PermissionAPI"
import PermissionEvent from "@/member/permission/event/PermissionEvent"

export default function CreatePermissionModal() {

    const { message } = App.useApp()
    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增</Button>)

    const onFinish = (params) => requestCreatePermission(params).then(res => {
        EventBus.emit(PermissionEvent.onPermissionCreated, res.data)
        message.success("新增成功", 2).then()
        return true
    }).catch(err => {
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增权限"
        modalProps={{ width: 560, destroyOnClose: true }}
        trigger={trigger}
        isKeyPressSubmit
        scrollToFirstError
        formRef={formRef}
        onFinish={onFinish}>

        <ProFormText width="md" name="name" label="名称" rules={Rule.requireText} />

        <ProFormText width="md" name="identifier" label="标识符" rules={Rule.requireText} />

        <ProFormRadio.Group width="md" name="available" label="状态" initialValue={"可用"} rules={Rule.require}
            options={getAvailableEnumAsOptions()} />

    </ModalForm>)
}