import React from 'react';
import {Drawer, Timeline} from "antd";
import EventBus from "../../../framework/util/EventBusUtils";
import MemberMarkEvent from "../event/MemberMarkEvent";
import CreateMemberMarkModal from "./CreateMemberMarkModal";
import {requestMemberMarkByList} from "../api/MemberMarkAPI";
import EmptyWrapper from "../../../framework/component/EmptyWrapper";

export default class PreviewMemberMarkDrawer extends React.Component {

    static open = (member) => {
        EventBus.emit(MemberMarkEvent.onTriggerCreateMemberMark, member)
    }

    state = {
        open: false,
        member: {},
        marks: []
    }

    componentDidMount() {
        EventBus.on(MemberMarkEvent.onTriggerCreateMemberMark, (member) => {
            this.setState({open: true, member}, () => {
                requestMemberMarkByList({member: member.id})
                    .then(res => this.setState({marks: res.data}))
                    .catch(() => this.setState({marks: []}))
            })
        })
        EventBus.on(MemberMarkEvent.onMemberMarkCreated, () => {
            requestMemberMarkByList({member: this.state.member.id})
                .then(res => this.setState({marks: res.data}))
                .catch(() => this.setState({marks: []}))
        })
    }

    componentWillUnmount() {
        EventBus.off(MemberMarkEvent.onTriggerCreateMemberMark, console.log)
    }

    render() {
        return (<Drawer title="会员标注"
                        destroyOnClose
                        size="large"
                        open={this.state.open}
                        onClose={() => this.setState({open: false})}
                        extra={(<CreateMemberMarkModal member={this.state.member}/>)}>
            <EmptyWrapper empty={this.state.marks.length < 1}>
                <Timeline mode="right" items={this.state.marks.map(v => ({label: v.createTime, children: v.mark}))}/>
            </EmptyWrapper>
        </Drawer>)
    }

}