import React from "react"
import { Button, Space } from "antd"
import { ProTable } from "@ant-design/pro-components"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import UserEvent from "@/member/user/event/UserEvent"
import CreateAuthenticationModal from "@/member/user/interaction/CreateAuthenticationModal"
import DeleteAuthenticationButton from "@/member/authentication/component/DeleteAuthenticationButton"
import UpdateAuthenticationModal from "@/member/user/interaction/UpdateAuthenticationModal"

const AuthenticationDataTableOperations = (props) => (<Space>
    <CreateAuthenticationModal user={props.user.id}/>
</Space>)

const AuthenticationDataTableColumnOperations = ({ item, user }) => {
    return (<Space>
        <PermissionWrapper permissions={["authentication:update"]}>
            <Button size="small" type="link"
                    onClick={() => EventBus.emit(UserEvent.onTriggerUpdateAuthentication, item)}>编辑</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["authentication:delete"]}>
            <DeleteAuthenticationButton id={item.id} disabled={user.authentications.length <= 1}/>
        </PermissionWrapper>
    </Space>)
}

export default function AuthenticationDataTable(props) {

    const columns = [
        { title: "ID", dataIndex: "id", hideInTable: true },
        { title: "认证凭证", dataIndex: "authentication", copyable: true },
        { title: "认证类型", dataIndex: "type" },
        { title: "认证方式", dataIndex: "authenticationMethod" },
        { title: "认证次数", dataIndex: "authenticationTimes", valueType: "digit", align: "right" },
        { title: "认证时间", dataIndex: "authenticationTime", valueType: "dateTime" },
        { title: "创建时间", dataIndex: "createTime", valueType: "dateTime" },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<AuthenticationDataTableColumnOperations item={item} user={props.user}/>)
        }
    ]
    return (<>
        <ProTable rowKey="id"
                  cardProps={{
                      title: "认证信息",
                      bordered: true,
                      headerBordered: true,
                      extra: (<AuthenticationDataTableOperations user={props.user}/>)
                  }}
                  scroll={{ x: "max-content" }}
                  tableStyle={{ marginTop: 16 }}
                  options={false}
                  search={false}
                  dataSource={props.user.authentications}
                  pagination={false}
                  columns={columns}/>
        <UpdateAuthenticationModal/>
    </>)

}