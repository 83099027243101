import PropTypes from "prop-types";
import {Button, Popconfirm} from "antd";

import EventBus from "@/framework/util/EventBusUtils";

import {requestDeleteCodeTemplate} from "../api/CodeTemplateAPI";
import CodeTemplateEvent from "../event/CodeTemplateEvent";

export default function DeleteCodeTemplateButton(props) {

    const onConfirm = () => requestDeleteCodeTemplate(props.id).then(res => {
        EventBus.emit(CodeTemplateEvent.onCodeTemplateDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除代码生成模版" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)
}

DeleteCodeTemplateButton.propTypes = {
    id: PropTypes.string.isRequired
}