export const requestCreateTenant = (params) => window.axios.post("/member/tenant", params)

export const requestTenantByPage = (params) => window.axios.get("/member/tenant/page", { params })

export const requestTenantByList = (params) => window.axios.get("/member/tenant/list", { params })

export const requestTenantById = (id) => window.axios.get(`/member/tenant/${id}`)

export const requestUpdateTenant = (params) => window.axios.patch(`/member/tenant/${params.id}`, params)

export const requestDeleteTenant = (id) => window.axios.delete(`/member/tenant/${id}`)