/**
 * 新建帮助分类
 */
export const requestCreateHelpCategory = (params) => window.axios.post("/help/category", params)

/**
 * 分页查询帮助分类
 * @param params 查询参数
 */
export const requestHelpCategoryByPage = (params) => window.axios.get("/help/category/page", {params})

/**
 * 查询帮助分类
 * @param params 查询参数
 */
export const requestHelpCategoryByList = (params) => window.axios.get("/help/category/list", {params})

export const requestHelpCategoryByTree = (params) => window.axios.get("/help/category/tree", {params})

/**
 * 查询指定ID帮助分类
 * @param id {string} 帮助分类ID
 */
export const requestHelpCategoryById = (id) => window.axios.get(`/help/category/${id}`)

/**
 * 更新帮助分类
 */
export const requestUpdateHelpCategory = (params) => window.axios.patch(`/help/category/${params.id}`, params)

/**
 * 删除帮助分类
 */
export const requestDeleteHelpCategory = (id) => window.axios.delete(`/help/category/${id}`)
