const AuthenticationMethodEnum = {
    "密码认证": { text: "密码认证", disabled: false },
    "验证码认证": { text: "验证码认证", disabled: false },
    "微信认证": { text: "微信认证", disabled: true },
    "支付宝认证": { text: "支付宝认证", disabled: true },
    "QQ认证": { text: "QQ认证", disabled: true },
    "钉钉认证": { text: "钉钉认证", disabled: true }
}

export const getAuthenticationMethodEnumAsOptions = () => {
    const options = []
    for (let key in AuthenticationMethodEnum) {
        options.push({
            label: key,
            value: AuthenticationMethodEnum[key].text,
            disabled: AuthenticationMethodEnum[key].disabled
        })
    }
    return options
}

export default AuthenticationMethodEnum