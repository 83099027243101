import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import ProcessDefinitionDataTable from "../component/table/ProcessDefinitionDataTable";

export default function ProcessDefinitionDataTablePage() {

    return (<PageContainer>
        <ProcessDefinitionDataTable/>
    </PageContainer>)

}