import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import PrintTemplateDataTable from "./component/PrintTemplateDataTable";

export default class PrintTemplate extends React.Component {

    render() {
        return (<PageContainer>
            <PrintTemplateDataTable/>
        </PageContainer>)
    }

}