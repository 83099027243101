export function download(blob, contentType, fileName) {
    const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }))
    const link = document.createElement("a")
    link.style.display = "none"
    link.href = url
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
}

const DownloadUtils = {
    download
}

export default DownloadUtils