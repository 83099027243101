import React from "react"
import {
    ModalForm,
    ProFormDependency,
    ProFormRadio,
    ProFormSelect,
    ProFormText,
    ProFormTextArea
} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import DepartmentEvent from "@/member/department/event/DepartmentEvent"
import { getLeafEnumAsOptions } from "@/member/department/common/LeafEnum"
import { requestUpdateDepartment } from "@/member/department/api/DepartmentAPI"
import { requestUserByList } from "@/member/user/api/UserAPI"

export default class UpdateDepartmentModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false
    }

    componentDidMount() {
        EventBus.on(DepartmentEvent.onTriggerDepartmentUpdate, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(DepartmentEvent.onTriggerDepartmentUpdate, console.log)
    }

    onTrigger = (department) => this.setState({ open: true }, () => {
        department.manager = department.manager === "0" ? undefined : department.manager
        department.auditor = department.auditor === "0" ? undefined : department.auditor
        setTimeout(() => this.formRef.current?.setFieldsValue(department), 100)
    })

    onFinish = (params) => requestUpdateDepartment(params).then(() => {
        EventBus.emit(DepartmentEvent.onDepartmentUpdated, params.id)
        this.formRef.current?.resetFields()
        return true
    }).catch(err => {
        console.error("编辑单位失败", err)
        FormUtils.setFormError(this.formRef, err.response.data, "name")
        return false
    })

    render() {
        return (<ModalForm title="编辑单位"
            modalProps={{ width: 560, destroyOnClose: true }}
            trigger={undefined}
            open={this.state.open}
            formRef={this.formRef}
            onOpenChange={open => this.setState({ open })}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />

            <ProFormText width="md" name="name" label="名称" rules={Rule.requireText} />

            <ProFormDependency name={["id"]}>
                {({ id }) => (<ProFormSelect width="md" name="manager" label="管理员"
                    placeholder="请选择单位管理员"
                    params={{ department: id }}
                    request={(params) => requestUserByList(params).then(res => res.data).catch(() => [])}
                    fieldProps={{ fieldNames: { label: "name", value: "id" } }} />)}
            </ProFormDependency>

            <ProFormDependency name={["id"]}>
                {({ id }) => (<ProFormSelect width="md" name="auditor" label="审核员"
                    placeholder="请选择单位审核员"
                    params={{ department: id }}
                    request={(params) => requestUserByList(params).then(res => res.data).catch(() => [])}
                    fieldProps={{ fieldNames: { label: "name", value: "id" } }} />)}
            </ProFormDependency>

            <ProFormRadio.Group name="leaf" width="md" label="叶子节点" rules={Rule.require}
                options={getLeafEnumAsOptions()} />

            <ProFormTextArea name="description" label="描述" rules={Rule.text} />

        </ModalForm>)
    }

}