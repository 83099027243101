import React from "react"
import {Button} from "antd"
import {PlusOutlined} from "@ant-design/icons"
import {ModalForm, ProFormSelect, ProFormText, ProFormTextArea} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule";

import {getDictionaryLabelDataTypeEnumAsOptions} from "../common/DictionaryLabelDataTypeEnum"
import {requestCreateDictionaryLabel} from "../api/DictionaryLabelAPI"
import DictionaryLabelEvent from "../event/DictionaryLabelEvent"

export default function CreateDictionaryLabelModal() {

    const formRef = React.useRef()

    const onFinish = (params) => requestCreateDictionaryLabel(params).then(res => {
        EventBus.emit(DictionaryLabelEvent.onDictionaryLabelCreated, res.data)
        formRef.current?.resetFields()
        return true
    }).catch(err => {
        FormUtils.setFormError(formRef, err.response?.data, "name")
        return false
    })

    return (<ModalForm title="新建字典"
                       formRef={formRef}
                       modalProps={{width: 560, destroyOnClose: true}}
                       trigger={(<Button type="primary" icon={<PlusOutlined/>}>新增字典</Button>)}
                       onFinish={onFinish}>

            <ProFormText width="md" name="label" label="显示名称"
                         tooltip="显示名称将用于数据展示，对用户输入进行引导和提示"
                         rules={Rule.requireText}/>

            <ProFormText width="md" name="name" label="名称"
                         tooltip="该值将被系统引用，新增后不可修改"
                         rules={Rule.requireText}/>

            <ProFormText width="md" name="businessKey" label="BusinessKey"
                         tooltip="该值将被系统引用，新增后不可修改"
                         rules={Rule.requireText}/>

            <ProFormSelect width="sm" name="dataType" label="数据类型"
                           options={getDictionaryLabelDataTypeEnumAsOptions()}
                           rules={Rule.require}/>

            <ProFormTextArea name="description" label="描述" rules={Rule.text}/>

        </ModalForm>
    )

}