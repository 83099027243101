import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { throttle } from "lodash"
import { LockOutlined, UserOutlined, VerifiedOutlined } from "@ant-design/icons"
import { LoginForm, nanoid, ProFormText } from "@ant-design/pro-components"

import SecurityUtils from "@/framework/util/SecurityUtils"
import FormUtils from "@/framework/util/FormUtils"

import { requestImageCaptcha } from "@/login/api/CaptchaAPI";
import { requestUsernameLogin } from "@/login/api/LoginAPI";
import { requestCurrentUser } from "@/member/user/api/UserAPI"

export default function PasswordLoginForm() {

    const formRef = useRef()
    const navigate = useNavigate()
    // 生成请求验证码的唯一ID，
    const [acceptor] = useState(nanoid())
    // 验证码Base64字符串
    const [image, setImage] = useState("")

    const onRequestCaptcha = () => {
        requestImageCaptcha({ acceptor, width: 135, height: 39, topic: "login" }).then(res => {
            setImage(res.data)
        }).catch(() => {
            setImage("")
        })
    }

    const throttleRequestCaptcha = throttle(onRequestCaptcha, 3000, { leading: true, trailing: false })

    const onFinish = data => {
        data.captcha = data.captcha.trim()
        return requestUsernameLogin(data).then(res => {
            SecurityUtils.setToken(res.data)
            // 登录成功后保存 Token 信息
            return requestCurrentUser().then(res => {
                // 登录成功，并且成功获取用户信息之后再跳转到首页
                SecurityUtils.setUser(res.data)
                setTimeout(() => navigate("/home/overview", { replace: true }), 200)
                return true
            }).catch(err => {
                FormUtils.setFormError(formRef, err.response.data, "authentication")
                return false
            })
        }).catch(err => {
            if (err.response.data.captcha === "验证码不存在或已失效" || err.response.data.authentication === "认证信息不存在或认证凭据错误") {
                onRequestCaptcha()
            }
            FormUtils.setFormError(formRef, err.response.data, "authentication")
            return false
        })
    }

    useEffect(onRequestCaptcha, [acceptor])

    return (<LoginForm formRef={formRef}
                       onFinish={onFinish}
                       size="large"
                       grid
                       rowProps={{gutter: [16, 0]}}
                       title="龙岭工会"
                       logo="/images/logo.png"
                       subTitle="Longling labour union">

        <ProFormText colProps={{ span: 24 }}
                     name="acceptor"
                     hidden
                     initialValue={acceptor}
                     placeholder="Identifier"/>

        <ProFormText colProps={{ span: 24 }}
                     name="authentication"
                     placeholder="用户名/邮箱/手机号码"
                     fieldProps={{ prefix: (<UserOutlined/>) }}
                     rules={[{ required: true, message: "请输入登录凭证" }]}/>

        <ProFormText.Password colProps={{ span: 24 }}
                              name="credential"
                              placeholder="请输入密码"
                              fieldProps={{ prefix: (<LockOutlined/>) }}
                              rules={[{ required: true, message: "请输入密码" }]}/>

        <ProFormText colProps={{ span: 14 }}
                     name="captcha"
                     placeholder="验证码"
                     fieldProps={{ prefix: (<VerifiedOutlined/>) }}
                     rules={[{ required: true, message: "请输入验证码" }]}/>

        <img alt="验证码（点击刷新）" style={{ width: 135, height: 39, border: ".5px solid #d9d9d9", borderRadius: 4 }}
             src={image}
             onClick={throttleRequestCaptcha}/>

    </LoginForm>)
}