import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { ModalForm, ProFormSelect, ProFormText } from "@ant-design/pro-components"
import { getAuthenticationTypeEnumAsOptions } from "@/member/user/common/AuthenticationTypeEnum"
import { getAuthenticationMethodEnumAsOptions } from "@/member/user/common/AuthenticationMethodEnum"
import { requestCreateAuthentication } from "@/member/user/api/AuthenticationAPI"
import EventBus from "@/framework/util/EventBusUtils"
import UserEvent from "@/member/user/event/UserEvent"
import FormUtils from "@/framework/util/FormUtils"

export default function CreateAuthenticationModal(props) {

    const formRef = React.useRef()

    const trigger = (<Button icon={<PlusOutlined />}>新增认证方式</Button>)

    const onFinish = (params) => requestCreateAuthentication(params).then(res => {
        EventBus.emit(UserEvent.onAuthenticationCreated, res.data)
        formRef.current?.resetFields()
        return true
    }).catch(err => {
        FormUtils.setFormError(this.formRef, err.response?.data, "authentication")
        return false
    })

    return (<ModalForm title="新增用户认证方式"
        grid
        formRef={formRef}
        rowProps={{ gutter: [16, 0] }}
        modalProps={{ width: 480, destroyOnClose: true }}
        trigger={trigger}
        onFinish={onFinish}>

        <ProFormText colProps={{ span: 24 }} name="user" label="用户ID"
            hidden
            initialValue={props.user}
            rules={[
                { required: true, message: "请输入认证凭证" }
            ]} />

        <ProFormText colProps={{ span: 24 }} name="authentication" label="认证凭证"
            rules={[
                { required: true, message: "请输入认证凭证" },
                { min: 4, message: "认证凭证长度应在[4,24]" },
                { max: 24, message: "认证凭证长度应在[4,24]" }
            ]} />

        <ProFormText.Password colProps={{ span: 24 }} name="credential" label="认证凭据"
            rules={[
                { required: true, message: "请输入认证凭据" },
                { min: 6, message: "认证凭据长度应在[6,32]" },
                { max: 32, message: "认证凭据长度应在[6,32]" }
            ]} />

        <ProFormSelect colProps={{ span: 12 }} name="type" label="认证类型"
            options={getAuthenticationTypeEnumAsOptions()}
            rules={[{ required: true, message: "请选择认证类型" }]} />

        <ProFormSelect colProps={{ span: 12 }} name="authenticationMethod" label="认证方式"
            options={getAuthenticationMethodEnumAsOptions()}
            rules={[{ required: true, message: "请选择认证类型" }]} />

    </ModalForm>)

}