import React from "react";
import {ModalForm, ProFormText} from "@ant-design/pro-components";

import EventBus from "../../framework/util/EventBusUtils";
import WorkflowEvent from "../event/WorkflowEvent";

import {requestDeleteProcessInstance} from "../api/WorkflowAPI";

export default class TerminateProcessInstanceModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false,
        instance: {}
    }

    componentDidMount() {
        EventBus.on(WorkflowEvent.onTriggerTerminateProcessInstanceEvent, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onTriggerTerminateProcessInstanceEvent, console.log)
    }

    onTrigger = (instance) => {
        this.setState({open: true, instance}, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(instance), 200)
        })
    }

    onFinish = (params) => requestDeleteProcessInstance(params).then(res => {
        EventBus.emit(WorkflowEvent.onProcessInstanceUpdateEvent, res.data)
        return true
    }).catch(() => {
        return false
    })

    render() {
        return (<ModalForm title={`终止【${this.state.instance.processDefinitionName}】流程`}
                           open={this.state.open}
                           formRef={this.formRef}
                           modalProps={{destroyOnClose: true, width: 480}}
                           onFinish={this.onFinish}
                           onOpenChange={open => this.setState({open})}>
            <ProFormText label="流程ID" name="id" hidden/>
            <ProFormText label="原因" name="reason" placeholder="请输入终止原因"/>
        </ModalForm>)
    }

}

TerminateProcessInstanceModal.open = (instance) => {
    EventBus.emit(WorkflowEvent.onTriggerTerminateProcessInstanceEvent, instance)
}