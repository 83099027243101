import React from "react"
import { Drawer, Tabs } from "antd"
import dayjs from "dayjs"

import NotfoundWrapper from "@/framework/component/NotfoundWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestUserById } from "@/member/user/api/UserAPI"
import TabForUserInfo from "@/member/user/component/tab/TabForUserInfo"
import TabForUserAuthentication from "@/member/user/component/tab/TabForUserAuthentication"
import TabForUserRole from "@/member/user/component/tab/TabForUserRole"
import UserEvent from "@/member/user/event/UserEvent"

export default class PreviewUserDrawer extends React.Component {

    state = {
        notfound: false,
        error: "",
        loading: true,
        user: {},
        id: undefined,
        open: false
    }

    componentDidMount() {
        EventBus.on(UserEvent.onTriggerPreviewUser, this.onTrigger)

        EventBus.on(UserEvent.onUserRoleUpdated, this.request)
        EventBus.on(UserEvent.onUserUpdated, (updateUser) => {
            if (updateUser?.id === this.state.id) {
                this.request()
            }
        })
        EventBus.on(UserEvent.onAuthenticationCreated, this.request)
        EventBus.on(UserEvent.onAuthenticationUpdated, this.request)
        EventBus.on(UserEvent.onAuthenticationDeleted, this.request)
        EventBus.on(UserEvent.onUserDeleted, () => this.setState({ open: false }))
    }

    componentWillUnmount() {
        EventBus.off(UserEvent.onTriggerPreviewUser, console.log)

        EventBus.off(UserEvent.onUserRoleUpdated, console.log)
        EventBus.off(UserEvent.onUserUpdated, console.log)
        EventBus.off(UserEvent.onAuthenticationCreated, console.log)
        EventBus.off(UserEvent.onAuthenticationUpdated, console.log)
        EventBus.off(UserEvent.onAuthenticationDeleted, console.log)
    }

    request = () => {
        this.setState({ loading: true })
        requestUserById(this.state.id).then(res => {
            const user = res.data
            user.authenticationTimes = 0
            user.authenticationTime = null
            for (let item of res.data.authentications) {
                // 累加登录次数
                user.authenticationTimes += Number(item.authenticationTimes)
                // 获取最后登录时间
                const authenticationTime = item.authenticationTime === null ? null : dayjs(item.authenticationTime, "YYYY-MM-DD HH:mm:ss")
                if (authenticationTime !== null) {
                    if (user.authenticationTime === null) {
                        user.authenticationTime = authenticationTime
                    } else {
                        if (authenticationTime.isAfter(user.authenticationTime)) {
                            user.authenticationTime = authenticationTime
                        }
                    }
                }
            }
            this.setState({ user, notfound: false, loading: false })
        }).catch(err => {
            this.setState({ error: err.response?.data, notfound: true, loading: false })
        })
    }

    onTrigger = (id) => {
        this.setState({ open: true, id }, () => this.request(id))
    }

    render() {
        return (<Drawer title="用户详情" width={window.innerWidth < 800 ? "100vw" : "80%"} destroyOnClose
            open={this.state.open}
            onClose={() => this.setState({ open: false })}>
            <NotfoundWrapper notfound={this.state.notfound} subTitle={this.state.error}>
                <Tabs type="card" defaultActiveKey="0" items={[
                    { key: "0", label: "基础信息", children: (<TabForUserInfo user={this.state.user} />) },
                    { key: "1", label: "认证信息", children: (<TabForUserAuthentication user={this.state.user} />) },
                    { key: "2", label: "角色信息", children: (<TabForUserRole user={this.state.user} />) }
                ]} />
            </NotfoundWrapper>
        </Drawer>)
    }

}

PreviewUserDrawer.open = (id) => {
    EventBus.emit(UserEvent.onTriggerPreviewUser, id)
}