import React from "react"
import { Button, Space } from "antd"
import { ProTable } from "@ant-design/pro-components"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestDailyStatisticByPage } from "@/statistic/daily/api/DailyStatisticAPI"
import DailyStatisticEvent from "@/statistic/daily/event/DailyStatisticEvent"
import DeleteDailyStatisticButton from "@/statistic/daily/component/DeleteDailyStatisticButton"
import CreateDailyStatisticModal from "@/statistic/daily/interaction/CreateDailyStatisticModal"
import UpdateDailyStatisticModal from "@/statistic/daily/interaction/UpdateDailyStatisticModal"
import { getOptions } from "@/statistic/StatisticModule"

const DailyStatisticDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["statistic-daily:insert"]}>
        <CreateDailyStatisticModal />
    </PermissionWrapper>
</Space>)

const DailyStatisticDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["statistic-daily:update"]}>
        <Button type="link" size="small"
            onClick={() => EventBus.emit(DailyStatisticEvent.onTriggerUpdateDailyStatistic, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["statistic-daily:delete"]}>
        <DeleteDailyStatisticButton id={props.item.id} />
    </PermissionWrapper>
</Space>)

export default class DailyStatisticDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "功能模块",
            dataIndex: "module",
            valueType: "select",
            request: new Promise(v => v(getOptions())).then(v => v)
        },
        {
            title: "指标名称",
            dataIndex: "label"
        },
        {
            title: "年份",
            dataIndex: "year",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "月份",
            dataIndex: "month",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "日期",
            dataIndex: "day",
            valueType: "date",
            render: (_, record) => `${record.year}-${record.month}-${record.day}`,
        },
        {
            title: "开发标识",
            dataIndex: "devIdentifier"
        },
        {
            title: "业务标识",
            dataIndex: "bizIdentifier"
        },
        {
            title: "统计值",
            dataIndex: "statisticValue",
            hideInSearch: true
        },
        {
            title: "备注",
            dataIndex: "comment",
            hideInSearch: true
        },
        {
            title: "创建人",
            dataIndex: "creator",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "修改人",
            dataIndex: "modifier",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "操作",
            valueType: "option",
            fixed: 'right',
            render: (_, item) => (<DailyStatisticDataTableColumnOperations item={item} />)
        }
    ]

    componentDidMount() {
        EventBus.on(DailyStatisticEvent.onDailyStatisticCreated, this.tableActionRef.current?.reload)
        EventBus.on(DailyStatisticEvent.onDailyStatisticUpdated, this.tableActionRef.current?.reload)
        EventBus.on(DailyStatisticEvent.onDailyStatisticDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(DailyStatisticEvent.onDailyStatisticCreated, console.log)
        EventBus.off(DailyStatisticEvent.onDailyStatisticUpdated, console.log)
        EventBus.off(DailyStatisticEvent.onDailyStatisticDeleted, console.log)
    }

    onRequestDailyStatisticPage = (params) => requestDailyStatisticByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                cardBordered
                columns={this.columns}
                actionRef={this.tableActionRef}
                toolBarRender={() => (<DailyStatisticDataTableOperations />)}
                request={this.onRequestDailyStatisticPage} />
            <PermissionWrapper permissions={["statistic-daily:update"]}>
                <UpdateDailyStatisticModal />
            </PermissionWrapper>
        </>)
    }

}