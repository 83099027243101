import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import MetaDataTable from "./component/MetaDataTable";

export default class Metas extends React.Component {

    render() {
        return (<PageContainer>
            <MetaDataTable/>
        </PageContainer>)
    }

}