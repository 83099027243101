import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import MemberApplyDataTable from "./component/MemberApplyDataTable";

export default class MemberApply extends React.Component {

    render() {
        return (<PageContainer>
            <MemberApplyDataTable/>
        </PageContainer>)
    }

}