import React from "react"
import { Avatar, Image } from "antd"

export default function ColumnForUserAvatar({ user }) {
    const avatar = user?.avatar
    if (!!avatar) {
        return <Avatar size="small" src={<Image preview={false} src={avatar} />} />
    } else {
        return (<Avatar size="small" >{user.name.slice(0, 1)}</Avatar>)
    }
}