import PropTypes from "prop-types";
import {Button, Popconfirm} from "antd";

import EventBus from "../../../framework/util/EventBusUtils";

import {requestDeleteActivity} from "../api/ActivityAPI";
import ActivityEvent from "../event/ActivityEvent";


export default function DeleteActivityButton(props) {

    const onConfirm = () => requestDeleteActivity(props.id).then(res => {
        EventBus.emit(ActivityEvent.onActivityDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除活动" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteActivityButton.propTypes = {
    id: PropTypes.string.isRequired
}