import PropTypes from "prop-types";
import {Button, Popconfirm} from "antd";

import EventBus from "../../../framework/util/EventBusUtils";

import {requestDeleteMemberApply} from "../api/MemberApplyAPI";
import MemberApplyEvent from "../event/MemberApplyEvent";


export default function DisapproveMemberApplyButton(props) {

    const onConfirm = () => requestDeleteMemberApply(props.id).then(res => {
        EventBus.emit(MemberApplyEvent.onMemberApplyDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="拒绝会员申请" okText="确认" cancelText="取消"
                        okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>拒绝</Button>
    </Popconfirm>)

}

DisapproveMemberApplyButton.propTypes = {
    id: PropTypes.string.isRequired
}