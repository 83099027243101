import React, { useState } from 'react'
import { PageContainer, ProCard } from "@ant-design/pro-components"

import CategoryTree from "./component/CategoryTree"
import CategoryDescription from "@/specification/category/component/CategoryDescription"
import SpuDataTable from "@/specification/spu/component/SpuDataTable"

const SpecificationCategories = () => {
    const [category, setCategory] = useState({})

    const tabs = {
        defaultActiveKey: "1",
        type: "card",
        items: [
            {
                key: "0",
                label: "详情",
                children: (<CategoryDescription category={category} />)
            },
            { key: "1", label: "SPU", children: (<SpuDataTable category={category.id} />) },
        ]
    }

    return (<PageContainer>
        <ProCard split="vertical" ghost bordered>
            <ProCard size="small" colSpan={6}>
                <CategoryTree onChange={setCategory} />
            </ProCard>
            <ProCard size="small" colSpan={18} tabs={tabs} />
        </ProCard>
    </PageContainer>)
}

export default SpecificationCategories
