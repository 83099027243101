import React from "react";
import PropTypes from "prop-types";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Input, Skeleton, Space, Tooltip, Tree } from "antd";
import { ProCard } from "@ant-design/pro-components";

import EventBus from "@/framework/util/EventBusUtils";
import EmptyWrapper from "@/framework/component/EmptyWrapper";

import { requestSectionByTree } from "@/content/section/api/SectionAPI";
import SectionEvent from "@/content/section/event/SectionEvent";
import CreateSectionModal from "@/content/section/interaction/CreateSectionModal";

export default class SectionTree extends React.Component {

    state = {
        loading: true,
        treeData: [],
        selectedKeys: [],
        defaultExpandedKeys: [],
        searchValue: ""
    }

    componentDidMount() {
        this.request()
        // 当新增单位时
        EventBus.on(SectionEvent.onSectionCreated, () => this.request())
        // 当单位更新时
        EventBus.on(SectionEvent.onSectionUpdated, (updateId) => {
            // 如果更新的单位信息时当前选中的单位，在刷新数据后重新选中单位
            this.request(undefined, (!!this.state.selectedKeys && this.state.selectedKeys[0] === updateId) ? updateId : undefined)
        })
        EventBus.on(SectionEvent.onSectionDeleted, () => requestSectionByTree().then(res => {
            if (!!res.data[0]) {
                this.setState({
                    selectedKeys: [res.data[0].id],
                    searchValue: ''
                }, () => this.props.onChange(res.data[0]))
            }
            const defaultExpandedKeys = this.extractExpandedRowKeys(res.data)
            this.setState({ loading: false, treeData: res.data, defaultExpandedKeys })
        }).catch(() => {
            this.setState({
                loading: false,
                treeData: [{ id: "0", name: "无栏目文章" }],
                selectedKeys: ["0"]
            }, () => this.props.onChange({ id: "0", name: "无栏目文章" }))
        }))
    }

    componentWillUnmount() {
        EventBus.off(SectionEvent.onSectionCreated, () => console.log("EventOff", SectionEvent.onSectionCreated))
        EventBus.off(SectionEvent.onSectionUpdated, () => console.log("EventOff", SectionEvent.onSectionUpdated))
        EventBus.off(SectionEvent.onSectionDeleted, () => console.log("EventOff", SectionEvent.onSectionDeleted))
    }


    request = (name, updateId) => {
        this.setState({ loading: true })
        requestSectionByTree({ name }).then(res => {
            if (this.state.selectedKeys.length < 1 && res.data.length > 0) {
                this.setState({ selectedKeys: [res.data[0].id] }, () => this.props.onChange(res.data[0]))
            }
            res.data.unshift({ id: "0", name: "无栏目文章" })
            const defaultExpandedKeys = this.extractExpandedRowKeys(res.data, updateId)
            this.setState({ loading: false, treeData: res.data, defaultExpandedKeys })
        }).catch(() => {
            this.setState({
                loading: false,
                treeData: [{ id: "0", name: "无栏目文章" }],
                selectedKeys: ["0"]
            }, () => this.props.onChange({ id: "0", name: "无栏目文章" }))
        })
    }

    extractExpandedRowKeys = (tree, updateId, keys = []) => {
        for (let v of tree) {
            if (!!updateId && (v.id === updateId)) {
                this.props.onChange(v)
            }
            if (v.children !== undefined && v.children !== null && v.children.length > 0) {
                keys.push(v.id)
                this.extractExpandedRowKeys(v.children, updateId, keys)
            }
        }
        return keys
    }

    onSelect = (selectedKeys, event) => {
        if (selectedKeys === undefined || selectedKeys === null || selectedKeys.length < 1) {
            return
        }
        this.setState({ selectedKeys: [...selectedKeys] }, () => {
            this.props.onChange({ ...event.node })
        })
    }

    onSearch = (name) => {
        this.request(name)
    }

    render() {
        return (<ProCard ghost>
            <Space.Compact block style={{ marginBottom: 16 }}>
                <Input.Search placeholder="搜索栏目"
                              value={this.state.searchValue}
                              onSearch={this.onSearch}
                              onChange={(e) => this.setState({ searchValue: e.target.value })}/>
                <Tooltip title="新建顶层栏目">
                    <Button icon={<PlusOutlined/>} onClick={() => CreateSectionModal.open("0")}/>
                </Tooltip>
                <Tooltip title="刷新">
                    <Button icon={<ReloadOutlined/>}
                            loading={this.state.loading}
                            onClick={() => this.setState({ searchValue: "" }, () => this.request())}/>
                </Tooltip>
            </Space.Compact>
            <Skeleton loading={this.state.loading} active>
                <EmptyWrapper empty={this.state.treeData.length < 1}>
                    <Tree blockNode showLine
                          defaultExpandedKeys={this.state.defaultExpandedKeys}
                          selectedKeys={this.state.selectedKeys}
                          fieldNames={{ title: "name", key: "id", children: "children" }}
                          onSelect={this.onSelect}
                          treeData={this.state.treeData}/>
                </EmptyWrapper>
            </Skeleton>
            <CreateSectionModal/>
        </ProCard>)
    }

}

SectionTree.propTypes = {
    onChange: PropTypes.func
}