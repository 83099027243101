import {Button, Popconfirm} from "antd";

import EventBus from "@/framework/util/EventBusUtils";

import {requestDeleteHelpCategory} from "../api/HelpAPI";
import HelpEvent from "../event/HelpEvent";

export default function DeleteHelpCategoryButton({category}) {

    const onConfirm = () => requestDeleteHelpCategory(category?.id).then((res) => {
        console.info("requestDeleteHelpCategory:" + category?.id, res.data)
        EventBus.emit(HelpEvent.onHelpCategoryDeleted, category?.key)
        return true
    }).catch((err) => {
        console.error("requestDeleteHelpCategory:" + category?.id, err)
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除帮助说明" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button danger>删除</Button>
    </Popconfirm>)

}
