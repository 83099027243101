import React from "react";
import {ModalForm, ProFormSelect, ProFormText} from "@ant-design/pro-components";

import EventBus from "../../framework/util/EventBusUtils";
import WorkflowEvent from "../event/WorkflowEvent";

import {requestAssignProcessInstanceTask} from "../api/WorkflowAPI";
import {requestUserByList} from "../../member/user/api/UserAPI";

export default class AssignProcessInstanceTaskModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false,
        task: {}
    }

    componentDidMount() {
        EventBus.on(WorkflowEvent.onTriggerAssignProcessInstanceTaskEvent, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onTriggerAssignProcessInstanceTaskEvent, console.log)
    }

    onTrigger = (task) => {
        this.setState({open: true, task}, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(task), 200)
        })
    }

    onFinish = (params) => requestAssignProcessInstanceTask(params).then(res => {
        EventBus.emit(WorkflowEvent.onProcessTaskUpdateEvent, res.data)
        return true
    }).catch(() => {
        return false
    })

    render() {
        return (<ModalForm title={`指派【${this.state.task.name}】任务`}
                           open={this.state.open}
                           formRef={this.formRef}
                           modalProps={{destroyOnClose: true, width: 480}}
                           onFinish={this.onFinish}
                           onOpenChange={open => this.setState({open})}>
            <ProFormText label="任务ID" name="id" hidden/>
            <ProFormSelect label="用户" name="user"
                           request={(params) => requestUserByList(params).then(res => res.data).catch(() => [])}
                           fieldProps={{fieldNames: {label: "name", value: "id"}}}/>
        </ModalForm>)
    }

}

AssignProcessInstanceTaskModal.open = (task) => {
    EventBus.emit(WorkflowEvent.onTriggerAssignProcessInstanceTaskEvent, task)
}