const AuthenticationTypeEnum = {
    "用户名": { text: "用户名", disabled: false },
    "电子邮箱": { text: "电子邮箱", disabled: false },
    "手机号码": { text: "手机号码", disabled: false },
    "微信": { text: "微信", disabled: true },
    "支付宝": { text: "支付宝", disabled: true },
    "QQ": { text: "QQ", disabled: true },
    "钉钉": { text: "钉钉", disabled: true }
}

export const getAuthenticationTypeEnumAsOptions = () => {
    const options = []
    for (let key in AuthenticationTypeEnum) {
        options.push({
            label: key,
            value: AuthenticationTypeEnum[key].text,
            disabled: AuthenticationTypeEnum[key].disabled
        })
    }
    return options
}

export default AuthenticationTypeEnum