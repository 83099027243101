import React from "react";
import {
    DrawerForm,
    ProFormRadio,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
    ProFormTreeSelect
} from "@ant-design/pro-components";

import EventBus from "../../../framework/util/EventBusUtils";
import FormUtils from "../../../framework/util/FormUtils";
import Rule from "../../../framework/form/Rule";

import {requestUpdateMember} from "../api/MemberAPI";
import MemberEvent from "../event/MemberEvent";
import DictionaryVariableUtils from "../../../common/dictionary/variable/util/DictionaryVariableUtils";

export default class UpdateMemberModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(MemberEvent.onTriggerUpdateMember, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(MemberEvent.onTriggerUpdateMember, console.log)
    }

    onFinish = (params) => {
        params.trade = params.trade.toString()
        params.operatePlatform = params.operatePlatform.toString()
        return requestUpdateMember(params).then(() => {
            EventBus.emit(MemberEvent.onMemberUpdated)
            return true
        }).catch((err) => {
            console.error("编辑会员失败", err);
            FormUtils.setFormError(this.formRef, err.response?.data, "name")
            return false;
        })
    }

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        form.trade = form.trade?.split(",")
        form.operatePlatform = form.operatePlatform?.split(",")
        this.setState({open: true}, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100);
        })
    }

    render() {
        return (<DrawerForm title="编辑会员"
                            drawerProps={{destroyOnClose: true, width: 640}}
                            open={this.state.open}
                            onOpenChange={open => this.setState({open})}
                            formRef={this.formRef}
                            onFinish={this.onFinish}>
            <ProFormText name="id" label="ID" hidden/>
            <ProFormText width="sm" name="name" label="单位名称" rules={Rule.require}/>
            <ProFormText width="lg" name="address" label="地址" rules={Rule.require}/>
            <ProFormTreeSelect width="xl" name="trade" label="行业"
                               fieldProps={{fieldNames: {label: "name", value: "variable"}, multiple: true}}
                               request={() => DictionaryVariableUtils.requestDvByTree("member-trade")}
                               rules={Rule.require}/>
            <ProFormSelect width="xl" name="operatePlatform" label="电商平台" mode="tags"
                           request={() => DictionaryVariableUtils.requestDvByList("member-operate-platform")}
                           rules={Rule.require}/>
            <ProFormRadio.Group name="businessDirection" label="业务方向"
                                initialValue="B2B"
                                options={[{label: "B2B", value: "B2B"}, {label: "B2C", value: "B2C"}]}
                                rules={Rule.require}/>
            <ProFormTextArea name="businessArea" label="业务范围" rules={Rule.require}/>
            <ProFormSelect width="sm" name="level" label="参会性质"
                           options={[
                               {label: "会员", value: "会员"},
                               {label: "副会长", value: "副会长"},
                               {label: "理事", value: "理事"},
                           ]}
                           rules={Rule.require}/>
        </DrawerForm>)
    }

}