import { ProDescriptions } from "@ant-design/pro-components";
import UpdateDepartmentModal from "@/member/department/interaction/UpdateDepartmentModal";
import { Button, Space, Typography } from "antd";
import React from "react";
import DeleteSectionButton from "@/content/section/component/DeleteSectionButton";
import BooleanWrapper from "@/framework/component/BooleanWrapper";
import PermissionWrapper from "@/framework/component/PermissionWrapper";
import UpdateSectionModal from "@/content/section/interaction/UpdateSectionModal";

function SectionDescriptionExtra({ section }) {
    return (<Space>
        <BooleanWrapper value={section.id !== "0"}>
            <PermissionWrapper permissions={["article:delete"]}>
                <DeleteSectionButton id={section.id}/>
            </PermissionWrapper>
        </BooleanWrapper>
        <PermissionWrapper permissions={["article:update"]}>
            <BooleanWrapper value={section.id !== "0"}>
                <Button size="small" type="link" onClick={() => UpdateSectionModal.open(section)}>编辑</Button>
            </BooleanWrapper>
            <UpdateSectionModal/>
        </PermissionWrapper>
    </Space>)
}

export default function SectionDescription({ section }) {
    if (!!section) {
        return (<><ProDescriptions title="单位信息"
                                   dataSource={section}
                                   bordered
                                   size="small"
                                   extra={<SectionDescriptionExtra section={section}/>}>
            <ProDescriptions.Item label="名称" dataIndex="name"/>
            <ProDescriptions.Item label="创建时间" dataIndex="createTime" valueType="fromNow"/>
            <ProDescriptions.Item label="叶子结点" dataIndex="leaf"/>
            <ProDescriptions.Item label="cover" valueType="image" dataIndex="icon"/>
        </ProDescriptions>
            <UpdateDepartmentModal/>
        </>)
    } else {
        return (<Typography.Paragraph>请选择栏目</Typography.Paragraph>)
    }

}