import React from "react"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"

import { Button, Result } from "antd"
import { ProCard } from "@ant-design/pro-components"

export const Notfound = ({ title, subTitle }) => {

    const navigate = useNavigate()
    const back = () => navigate(-1)

    return (<ProCard>
        <Result status="404"
            style={{ height: '100%' }}
            title={title}
            subTitle={subTitle}
            extra={<Button type="primary" onClick={back}>返回</Button>} />
    </ProCard>)

}

Notfound.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
}

export default function NotfoundWrapper({ title = "您所访问的资源不存在", subTitle, notfound, children }) {
    return notfound ? (<Notfound title={title} subTitle={subTitle} />) : children
}

NotfoundWrapper.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    notfound: PropTypes.bool.isRequired
}