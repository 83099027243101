import React from "react"
import {SwapOutlined} from "@ant-design/icons"
import {Button, Card, Image, List, Menu, Space, Tooltip} from "antd"
import {PageContainer, ProCard, ProList} from "@ant-design/pro-components"

import {DISABLE} from "../../framework/enum/AvailableEnum"
import PermissionWrapper from "../../framework/component/PermissionWrapper";

import {requestCategoryByList} from "./category/api/CategoryAPI"
import {requestFileByPage} from "./file/api/FileAPI"
import UploadFileModal from "./file/interaction/UploadFileModal"
import EventBus from "../../framework/util/EventBusUtils"
import FileEvent from "./file/event/FileEvent"
import DeleteFileButton from "./file/component/DeleteFileButton"
import UpdateFileCategoryModal from "./file/interaction/UpdateFileCategoryModal";
import CategoryEvent from "./category/event/CategoryEvent";
import CreateCategoryModal from "./category/interaction/CreateCategoryModal";

const CategoryToolBar = () => (<Space>
    <PermissionWrapper permissions={["attachment-category:insert"]}>
        <CreateCategoryModal/>
    </PermissionWrapper>
</Space>)

const FileToolBar = (props) => (<Space>
    <UploadFileModal category={props.category}/>
</Space>)

const FileItem = (props) => {

    const cover = props.item.type.includes("image") ?
        (<Image alt="文件预览" height={160} src={props.item.url}/>) :
        (<Image alt="文件预览" height={160} src={"/icons/file.svg"} preview={false} style={{objectFit: "none"}}/>)

    const actions = [
        <Tooltip title="移动" key="move">
            <Button size="small" type="text" icon={<SwapOutlined/>}
                    onClick={() => EventBus.emit(FileEvent.onTriggerFileCategoryUpdate, props.item)}/>
        </Tooltip>,
        <Tooltip title="删除" key="delete">
            <DeleteFileButton id={props.item.id}/>
        </Tooltip>
    ]

    return (<Card cover={cover} actions={actions} size="small">
        <Card.Meta description={props.item.name}/>
    </Card>)

}

export default class Attachment extends React.Component {

    actionRef = React.createRef()

    state = {
        loading: true,
        categories: [],
        category: {key: -1, label: "全部文件", disabled: false},
    }

    componentDidMount() {
        this.onRequestCategories()
        EventBus.on(CategoryEvent.onCategoryCreated, this.onRequestCategories)
        EventBus.on(FileEvent.onFileCreated, this.actionRef.current?.reload)
        EventBus.on(FileEvent.onFileUpdated, this.actionRef.current?.reload)
        EventBus.on(FileEvent.onFileDeleted, this.actionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(CategoryEvent.onCategoryCreated, console.log)
        EventBus.off(FileEvent.onFileCreated, console.log)
        EventBus.off(FileEvent.onFileUpdated, console.log)
        EventBus.off(FileEvent.onFileDeleted, console.log)
    }

    onRequestCategories = () => requestCategoryByList().then(res => {
        const categories = []
        categories.push({key: -1, label: "全部文件", disabled: false})
        categories.push({key: 0, label: "未分类", disabled: false})
        for (let item of res.data) {
            categories.push({key: item.id, label: item.name, disabled: DISABLE === item.available})
        }
        this.setState({categories})
    }).catch(err => {
        console.error("请求文件分类失败", err)
    })

    onRequestFiles = (params) => {
        const category = this.state.category.key >= 0 ? this.state.category.key : undefined
        return requestFileByPage({category, ...params}).then(res => {
            return res.data
        }).catch(() => {
            return {data: [], success: true, total: 0}
        })
    }

    onCategorySelected = (item) => this.setState({category: item}, () => this.actionRef.current?.reload(true))

    render() {
        return (<PageContainer>
            <ProCard split="vertical">
                <ProCard colSpan="20%" title="分类" headerBordered extra={<CategoryToolBar/>}>
                    <Menu items={this.state.categories}
                          defaultSelectedKeys={["-1"]}
                          onSelect={this.onCategorySelected}/>
                </ProCard>
                <ProCard title="文件" headerBordered extra={<FileToolBar category={this.state.category.key}/>}>
                    <ProList rowKey="id"
                             actionRef={this.actionRef}
                             grid={{gutter: 12, column: 4}}
                             request={this.onRequestFiles}
                             pagination={{pageSize: 15}}
                             renderItem={(item) => <List.Item><FileItem item={item}/></List.Item>}/>
                </ProCard>
            </ProCard>

            <PermissionWrapper permissions={["attachment-file:update"]}>
                <UpdateFileCategoryModal/>
            </PermissionWrapper>
        </PageContainer>)
    }

}
