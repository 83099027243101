import React from "react"
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import {
    DrawerForm,
    ProFormDateTimePicker,
    ProFormDateTimeRangePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormField,
    ProFormRadio,
    ProFormSelect,
    ProFormText
} from "@ant-design/pro-components"

import FormUtils from "../../../framework/util/FormUtils"
import EventBus from "../../../framework/util/EventBusUtils"
import Rule from "../../../framework/form/Rule";

import { requestCreateActivity } from "../api/ActivityAPI"
import ActivityEvent from "../event/ActivityEvent"
import AttachmentPicker from "../../../common/attachment/file/component/AttachmentPicker";
import DictionaryVariableUtils from "../../../common/dictionary/variable/util/DictionaryVariableUtils";

export default function CreateActivityDrawer() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined/>}>新增活动</Button>)

    const onFinish = (params) => {
        if (params.allowSignup === 1) {
            params.signupStartTime = params.signupTime[0]
            params.signupEndTime = params.signupTime[1]
            params.signupTime = undefined
        }
        return requestCreateActivity(params).then(res => {
            EventBus.emit(ActivityEvent.onActivityCreated, res.data)
            return true
        }).catch(err => {
            console.log("新增活动失败", err)
            FormUtils.setFormError(formRef, err.response.data, "name")
            return false
        })
    }

    return (<DrawerForm title="新增活动"
                        drawerProps={{ destroyOnClose: true, width: 640 }}
                        formRef={formRef}
                        trigger={trigger}
                        onFinish={onFinish}>

        <ProFormField label="封面" name="cover">
            <AttachmentPicker accept={["image/png", "image/jpeg"]}/>
        </ProFormField>

        <ProFormText width="md" name="name" label="名称" rules={[
            ...Rule.require,
            { max: 20, message: "此项长度不能超过20" }
        ]}/>

        <ProFormSelect width="sm" name="type" label="活动类型" rules={Rule.require}
                       request={() => DictionaryVariableUtils.requestDvByList("activity-type")}/>

        <ProFormDateTimePicker width="sm" name="time" label="活动时间"
                               fieldProps={{ format: "YYYY-MM-DD HH:mm" }}
                               rules={Rule.require}/>

        <ProFormText width="xl" name="address" label="活动地址" rules={Rule.require}/>

        <ProFormDigit width="xs" name="maxCount" label="最大活动人数"
                      fieldProps={{ precision: 0 }}
                      rules={Rule.require}/>
        <ProFormDigit width="xs" name="virtualSignupCount" label="虚拟报名人数"
                      initialValue={0}
                      fieldProps={{ precision: 0 }}/>

        <ProFormRadio.Group label="允许报名" name="allowSignup"
                            initialValue={0}
                            options={[{ label: "开放报名", value: 1 }, { label: "禁止报名", value: 0 }]}
                            rules={Rule.require}/>

        <ProFormDependency name={["allowSignup"]}>
            {({ allowSignup }) => {
                return allowSignup === 1 ? (<ProFormDateTimeRangePicker width="md" name="signupTime" label="报名时间"
                                                                        fieldProps={{ format: "YYYY-MM-DD HH:mm" }}
                                                                        rules={Rule.require}/>) : undefined
            }}
        </ProFormDependency>

        <ProFormDependency name={["allowSignup"]}>
            {({ allowSignup }) => {
                return allowSignup === 1 ? (
                    <ProFormDigit width="xs" name="signupCount" label="最大报名人数" fieldProps={{ precision: 0 }}
                                  rules={Rule.require}/>) : undefined
            }}
        </ProFormDependency>

        <ProFormText label="活动海报" name="content" tooltip="海报URL地址"/>
    </DrawerForm>)
}