import PropTypes from "prop-types"
import { App, Button, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeleteTenant } from "@/member/tenant/api/TenantAPI"
import TenantEvent from "@/member/tenant/event/TenantEvent"

export default function DeleteTenantButton({ id }) {

    const { message } = App.useApp()

    const onConfirm = () => requestDeleteTenant(id).then(res => {
        EventBus.emit(TenantEvent.onTenantDeleted, res.data)
        message.success("删除成功", 2).then()
        return true
    }).catch(() => {
        message.error("删除失败", 2).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除租户" okText="确认" cancelText="取消" okButtonProps={{ danger: true }}
        onCancel={onCancel}
        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteTenantButton.propTypes = {
    id: PropTypes.string.isRequired
}