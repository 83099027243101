import React from "react";
import {Button, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {ProTable} from "@ant-design/pro-components";

import {requestCodeTemplateByPage} from "../api/CodeTemplateAPI";
import DeleteCodeTemplateButton from "./DeleteCodeTemplateButton";
import PermissionWrapper from "@/framework/component/PermissionWrapper";

const CodeTemplateDataTableColumnState = {
    persistenceKey: "ColumnStateForPermission",
    persistenceType: "localStorage",
    defaultValue: {
        id: {show: false},
        modifyTime: {show: false}
    }
}

const CodeTemplateTableOperations = () => {
    const navigate = useNavigate()
    return (<Space>
        <PermissionWrapper permissions={["*:*"]}>
            <Button type="primary" onClick={() => navigate("/home/common/code/template/new")}>新增代码生成模版</Button>
        </PermissionWrapper>
    </Space>)
}

const CodeTemplateColumnOperations = (props) => {
    const navigate = useNavigate()
    return (<Space>
        <PermissionWrapper permissions={["code-template:update"]}>
            <Button type="link" size="small"
                    onClick={() => navigate(`/home/common/code/template/${props.item.id}`)}>编辑</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["code-template:delete"]}>
            <DeleteCodeTemplateButton id={props.item.id}/>
        </PermissionWrapper>
    </Space>)
}

const CodeTemplateDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        copyable: true,
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "名称",
        dataIndex: "name",
        copyable: true,
        ellipsis: true
    },
    {
        title: "描述",
        dataIndex: "description",
        hideInSearch: true,
        ellipsis: true
    },
    {
        title: "创建时间",
        dataIndex: "createTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "修改时间",
        dataIndex: "modifyTime",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "操作",
        valueType: "option",
        fixed: 'right',
        render: (_, item) => (<CodeTemplateColumnOperations item={item}/>)
    }
]

export default class CodeTemplateTable extends React.Component {

    actionRef = React.createRef()

    request = (params) => requestCodeTemplateByPage(params).then(res => res.data).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        return (<ProTable rowKey="id"
                          cardBordered
                          scroll={{x: "max-content"}}
                          columns={CodeTemplateDataTableColumns}
                          columnsState={CodeTemplateDataTableColumnState}
                          actionRef={this.actionRef}
                          toolBarRender={() => (<CodeTemplateTableOperations/>)}
                          request={this.request}/>);
    }

}