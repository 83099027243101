/**
 * 新增代码生成表
 */
export const requestCreateCodeTable = (params) => window.axios.post("/common/code/table", params)

/**
 * 分页查询代码生成表
 * @param params 查询参数
 */
export const requestCodeTableByPage = (params) => window.axios.get("/common/code/table/page", {params})

/**
 * 查询代码生成表
 * @param params 查询参数
 */
export const requestCodeTableByList = (params) => window.axios.get("/common/code/table/list", {params})

/**
 * 查询指定ID代码生成表
 * @param id {string} 代码生成表ID
 */
export const requestCodeTableById = (id) => window.axios.get(`/common/code/table/${id}`)

/**
 * 更新代码生成表
 */
export const requestUpdateCodeTable = (params) => window.axios.patch(`/common/code/table/${params.id}`, params)

/**
 * 删除代码生成表
 */
export const requestDeleteCodeTable = (id) => window.axios.delete(`/common/code/table/${id}`)
