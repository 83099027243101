import React from "react"
import {Button} from "antd"
import {PlusOutlined} from "@ant-design/icons"
import {DrawerForm, ProFormRadio, ProFormText} from "@ant-design/pro-components"

import FormUtils from "../../../framework/util/FormUtils"
import EventBus from "../../../framework/util/EventBusUtils"
import Rule from "../../../framework/form/Rule";

import {requestCreateActivityMember} from "../api/ActivityMemberAPI"
import ActivityMemberEvent from "../event/ActivityMemberEvent"

export default function CreateActivityMemberDrawer({activity}) {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined/>}>新增活动参与人员</Button>)

    const onFinish = (params) => {
        params.activity = activity.id
        params.assigned = !!params.seatNumber
        params.temporary = params.origin === "会员" ? 0 : 1
        return requestCreateActivityMember(activity.id,[params]).then(res => {
            EventBus.emit(ActivityMemberEvent.onActivityMemberCreated, res.data)
            return true
        }).catch(err => {
            console.log("新增活动参与人员失败", err)
            FormUtils.setFormError(formRef, err.response.data, "name")
            return false
        })
    }

    return (<DrawerForm title="新增活动参与人员"
                        drawerProps={{destroyOnClose: true, width: 640}}
                        formRef={formRef}
                        trigger={trigger}
                        onFinish={onFinish}>
        <ProFormText width="sm" name="userName" label="姓名" rules={Rule.require}/>
        <ProFormText width="md" name="userOrgName" label="组织名称" rules={Rule.require}/>
        <ProFormText width="md" name="userCellphone" label="手机号码" rules={Rule.require}/>
        <ProFormText width="sm" name="seatNumber" label="座位号"/>
        <ProFormRadio.Group name="origin" label="参会人来源"
                            initialValue="会员"
                            options={[
                                {label: "会员", value: "会员"},
                                {label: "非会员", value: "非会员"},
                            ]}
                            rules={Rule.require}/>
        <ProFormText name="manager" label="负责人"/>
    </DrawerForm>)
}