import React from "react";
import {
    ProFormDatePicker,
    ProFormDateTimePicker,
    ProFormDependency,
    ProFormDigit,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
    ProFormTimePicker,
    ProFormTreeSelect
} from "@ant-design/pro-components";

const ConvertStratagem = {
    "number": convertProFromDigit,
    "textfield": convertProFormText,
    "image": convertProFormImage,
    "textarea": convertProFormTextArea,
    "select": convertProFormSelect,
    "datetime": convertProFormDateTimePicker
}

export function formConverter(components, formRef) {
    const fields = []
    if (components === undefined || components === null || components.length < 1) {
        return fields
    }
    for (let component of components) {
        const converter = !!ConvertStratagem[component.type] ? ConvertStratagem[component.type] : ConvertStratagem["textfield"]
        fields.push(converter(component, formRef))
    }
    return fields
}

function convertProFormDateTimePicker(component, formRef) {
    switch (component.subtype) {
        case "date":
            return (<ProFormDatePicker key={component.key} label={component.label} name={component.key}
                                       width="100%"
                                       rules={getFieldRules(component)}
                                       fieldProps={{format: v => v.format("YYYY-MM-DD")}}
                                       hidden={component.properties?.hidden}/>)
        case "time":
            return (<ProFormTimePicker key={component.key} label={component.label} name={component.key}
                                       width="100%"
                                       rules={getFieldRules(component)}
                                       fieldProps={{format: v => v.format("HH:mm")}}
                                       hidden={component.properties?.hidden}/>)
        case "datetime":
            return (<ProFormDateTimePicker key={component.key} label={component.label} name={component.key}
                                           width="100%"
                                           rules={getFieldRules(component)}
                                           fieldProps={{format: v => v.format("YYYY-MM-DD HH:mm")}}
                                           hidden={component.properties?.hidden}/>)
        default:
            return undefined
    }
}

function convertProFromDigit(component, formRef) {
    return (<ProFormDigit key={component.key} label={component.label} name={component.key}
                          rules={getFieldRules(component)}
                          hidden={component.properties?.hidden}
                          initialValue={component.defaultValue}/>)
}

function convertProFormSelect(component, formRef) {
    if (true === component.properties?.tree) {
        return (<ProFormTreeSelect key={component.key} label={component.label} name={component.key}
                                   fieldProps={getFieldProps(component)}
                                   params={getFiledRequestParam(component)}
                                   request={getFieldRequest(component)}
                                   rules={getFieldRules(component)}
                                   hidden={component.properties?.hidden}
                                   initialValue={component.defaultValue}/>)
    }
    const deconstruct = component.properties?.deconstruct
    let onSelect = undefined
    if (!!deconstruct) {
        const deconstructs = deconstruct.split(",");
        onSelect = (_, v) => {
            const params = {}
            for (let datum of deconstructs) {
                params[datum] = v[datum]
            }
            formRef.current?.setFieldsValue(params)
        }
    }
    if (!!component.properties?.dependency) {
        return (<ProFormDependency name={[component.properties?.dependency]}>
            {(data) => {
                const filedRequestParam = getFiledRequestParam(component);
                filedRequestParam[component.properties?.dependency] = data[component.properties?.dependency]
                return (<ProFormSelect key={component.key} label={component.label} name={component.key}
                                       fieldProps={{...getFieldProps(component), onSelect}}
                                       params={filedRequestParam}
                                       request={getFieldRequest(component)}
                                       rules={getFieldRules(component)}
                                       hidden={component.properties?.hidden}
                                       initialValue={component.defaultValue}/>)
            }}
        </ProFormDependency>)
    }
    return (<ProFormSelect key={component.key} label={component.label} name={component.key}
                           fieldProps={{...getFieldProps(component), onSelect}}
                           params={getFiledRequestParam(component)}
                           request={getFieldRequest(component)}
                           rules={getFieldRules(component)}
                           hidden={component.properties?.hidden}
                           initialValue={component.defaultValue}/>)
}

function convertProFormTextArea(component, formRef) {
    return (<ProFormTextArea key={component.key} label={component.label} name={component.key}
                             rules={getFieldRules(component)}
                             hidden={component.properties?.hidden}
                             initialValue={component.defaultValue}/>)
}

function convertProFormText(component, formRef) {
    return (<ProFormText key={component.key} label={component.label} name={component.key}
                         rules={getFieldRules(component)}
                         hidden={component.properties?.hidden}
                         initialValue={component.defaultValue}/>)
}

function convertProFormImage({properties}, formRef) {
    return (<ProFormText key={properties?.key} label={properties?.label} name={properties?.key}
                         hidden={properties?.hidden}
                         initialValue={properties?.defaultValue}/>)
}

function getFiledRequestParam(component) {
    const {requestParam} = component.properties
    return !!requestParam ? JSON.parse(requestParam) : undefined
}

function getFieldRequest(component) {
    if (!!component.properties?.requestUrl) {
        const {requestUrl} = component.properties
        return (params) => window.axios.get(requestUrl, {params}).then(res => {
            return res.data
        }).catch(err => {
            console.error("数据源请求失败" + component.key, err)
            return []
        })
    }
    return undefined
}

function getFieldRules(component) {
    const rules = []
    if (!!component.validate?.required) {
        rules.push({required: true, message: "此项不能为空"})
    }
    return rules
}

function getFieldProps(component) {
    if (!!component.properties?.fieldProps) {
        return JSON.parse(component.properties.fieldProps)
    }
    return undefined
}