import React from "react";
import { App, Button, Popconfirm } from "antd";
import { requestNotifyActivityMember } from "@/association/activity/api/ActivityMemberAPI";

export default function NotifyActivityMemberButton({ activity }) {

    const { message } = App.useApp()

    const onConfirm = () => requestNotifyActivityMember(activity).then(() => {
        message.success("通知成功", 2).then()
    }).catch(() => {
        message.error("通知失败", 2).then()
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="通知用户" okText="确认" cancelText="取消"
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small">
            通知
        </Button>
    </Popconfirm>)

}