import React from "react"
import { Button, Space } from "antd"
import { ProTable } from "@ant-design/pro-components"

import PermissionWrapper from "@/framework/component/PermissionWrapper"
import EventBus from "@/framework/util/EventBusUtils"

import { requestPrintTemplateByPage } from "../api/PrintTemplateAPI"
import PrintTemplateEvent from "../event/PrintTemplateEvent"
import DeletePrintTemplateButton from "./DeletePrintTemplateButton"
import CreatePrintTemplateDrawer from "../interaction/CreatePrintTemplateDrawer"
import UpdatePrintTemplateModal from "../interaction/UpdatePrintTemplateModal"

const PrintTemplateDataTableOperations = () => (<Space>
    <PermissionWrapper permissions={["print-template:insert"]}>
        <CreatePrintTemplateDrawer />
    </PermissionWrapper>
</Space>)

const PrintTemplateDataTableColumnOperations = (props) => (<Space>
    <PermissionWrapper permissions={["print-template:update"]}>
        <Button type="link" size="small"
            onClick={() => EventBus.emit(PrintTemplateEvent.onTriggerUpdatePrintTemplate, props.item)}>编辑</Button>
    </PermissionWrapper>
    <PermissionWrapper permissions={["print-template:delete"]}>
        <DeletePrintTemplateButton id={props.item.id} />
    </PermissionWrapper>
</Space>)

export default class PrintTemplateDataTable extends React.Component {

    tableActionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "模版名称",
            dataIndex: "name"
        },
        {
            title: "描述",
            dataIndex: "description"
        },
        {
            title: "备注",
            dataIndex: "comment"
        },
        {
            title: "内容",
            dataIndex: "content"
        },
        {
            title: "创建人",
            dataIndex: "creator"
        },
        {
            title: "创建时间",
            dataIndex: "createTime"
        },
        {
            title: "修改人",
            dataIndex: "modifier"
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime"
        },
        {
            title: "操作",
            valueType: "option",
            fixed: 'right',
            render: (_, item) => (<PrintTemplateDataTableColumnOperations item={item} />)
        }
    ]

    componentDidMount() {
        EventBus.on(PrintTemplateEvent.onPrintTemplateCreated, this.tableActionRef.current?.reload)
        EventBus.on(PrintTemplateEvent.onPrintTemplateUpdated, this.tableActionRef.current?.reload)
        EventBus.on(PrintTemplateEvent.onPrintTemplateDeleted, this.tableActionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(PrintTemplateEvent.onPrintTemplateCreated, console.log)
        EventBus.off(PrintTemplateEvent.onPrintTemplateUpdated, console.log)
        EventBus.off(PrintTemplateEvent.onPrintTemplateDeleted, console.log)
    }

    onRequestPrintTemplatePage = (params) => requestPrintTemplateByPage(params).then(res => res.data).catch(() => {
        return { data: [], success: true, total: 0 }
    })

    render() {
        return (<>
            <ProTable rowKey="id"
                cardBordered
                columns={this.columns}
                actionRef={this.tableActionRef}
                toolBarRender={() => (<PrintTemplateDataTableOperations />)}
                request={this.onRequestPrintTemplatePage} />
            <PermissionWrapper permissions={["print-template:update"]}>
                <UpdatePrintTemplateModal />
            </PermissionWrapper>
        </>)
    }

}