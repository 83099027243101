import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import CodeTemplateTable from "./component/CodeTemplateTable";

export default function CodeTemplates() {

    return (<PageContainer>
        <CodeTemplateTable/>
    </PageContainer>)

}