import PropTypes from "prop-types"
import { App, Button, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeleteSpu } from "@/specification/spu/api/SpuAPI"
import SpuEvent from "@/specification/spu/event/SpuEvent"


export default function DeleteSpuButton({ id }) {

    const { message } = App.useApp()

    const onConfirm = () => requestDeleteSpu(id).then(res => {
        EventBus.emit(SpuEvent.onSpuDeleted, res.data)
        message.success("删除成功", 2).then()
        return true
    }).catch(() => {
        message.error("删除失败", 2).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除Spu" okText="确认" cancelText="取消" okButtonProps={{ danger: true }}
        onCancel={onCancel}
        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteSpuButton.propTypes = {
    id: PropTypes.string.isRequired
}