import React from "react";
import { Button, Modal, QRCode, Space } from "antd";
import EventBus from "@/framework/util/EventBusUtils";
import ActivityEvent from "@/association/activity/event/ActivityEvent";

export default class PreviewActivityQrCodeModal extends React.Component {

    static open = (item) => {
        EventBus.emit(ActivityEvent.onTriggerPreviewActivityQrCode, item)
    }

    state = {
        open: false,
        activity: 0
    }

    componentDidMount() {
        EventBus.on(ActivityEvent.onTriggerPreviewActivityQrCode, (item) => {
            this.setState({ open: true, activity: item.id })
        })
    }

    componentWillUnmount() {
        EventBus.off(ActivityEvent.onTriggerPreviewActivityQrCode, console.log)
    }

    downloadQrCode = () => {
        const canvas = document.getElementById('activity-qr-code')?.querySelector('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = '活动二维码.png';
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    footer = (<Space>
        <Button onClick={this.downloadQrCode}>下载二维码</Button>
        <Button type="primary" onClick={() => this.setState({ open: false })}>确认</Button>
    </Space>)

    render() {
        return (<Modal title="活动二维码"
                       width={400}
                       open={this.state.open}
                       onCancel={() => this.setState({ open: false })}
                       footer={this.footer}>
            <div id="activity-qr-code" style={{ display: "flex", justifyContent: "center" }}>
                <QRCode value={`{"activity": "${this.state.activity}"}`} style={{ marginBottom: 16 }}/>
            </div>
        </Modal>)
    }

}