import PropTypes from "prop-types"
import { App, Button, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeleteDepartment } from "@/member/department/api/DepartmentAPI"
import DepartmentEvent from "@/member/department/event/DepartmentEvent"

export default function DeleteDepartmentButton({ id, ...restProps }) {

    const { message } = App.useApp()

    const onConfirm = () => requestDeleteDepartment(id).then(() => {
        EventBus.emit(DepartmentEvent.onDepartmentDeleted, id)
        message.success("操作成功", 2).then()
        return true
    }).catch((err) => {
        console.error("删除岗位失败", err)
        message.error(!!err.response?.data.id ? err.response.data.id : err.message).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除单位" okText="确认" cancelText="取消" okButtonProps={{ danger: true }}
        onCancel={onCancel}
        onConfirm={onConfirm}>
        <Button type="link" size="small" danger {...restProps}>删除</Button>
    </Popconfirm>)
}

DeleteDepartmentButton.propTypes = {
    id: PropTypes.string.isRequired
}