import React from "react"
import {
    AreaChartOutlined,
    DatabaseOutlined,
    BankOutlined,
    EyeOutlined,
    FileWordOutlined,
    NodeIndexOutlined,
    ProductOutlined,
    SettingOutlined,
    SmileOutlined,
    UserOutlined
} from "@ant-design/icons"

import SecurityUtils from "@/framework/util/SecurityUtils"

import Overview from "@/overview"
import Permissions from "@/member/permission"
import Roles from "@/member/role"
import Tenants from "@/member/tenant"
import UserDetailPage from "@/member/user/UserDetailPage"
import Dictionaries from "@/common/dictionary"
import Departments from "@/member/department"
import Attachment from "@/common/attachment"
import CodeTables from "@/common/code/table"
import CodeTemplates from "@/common/code/template"
import CreateCodeTable from "@/common/code/table/CreateCodeTable"
import CreateCodeTemplate from "@/common/code/template/CreateCodeTemplate"
import UpdateCodeTable from "@/common/code/table/UpdateCodeTable"
import UpdateCodeTemplate from "@/common/code/template/UpdateCodeTemplate"
import Announcements from "@/common/announcement"
import Categories from "@/common/attachment/category"
import DictionaryVariableDetailsPage from "@/common/dictionary/variable/DictionaryVariableDetailsPage"
import HelpCenterPage from "@/common/help/HelpCenterPage"
import ProcessDefinitionDataTablePage from "@/workflow/page/ProcessDefinitionDataTablePage"
import ProcessTodoTaskDataTablePage from "@/workflow/page/ProcessTodoTaskDataTablePage"
import HelpManagePage from "@/common/help/page/HelpManagePage"
import ProcessInstanceDataTablePage from "@/workflow/page/ProcessInstanceDataTablePage"
import Metas from "@/common/meta"
import ProcessDefinitionInstanceDataTablePage from "@/workflow/page/ProcessDefinitionInstanceDataTablePage"
import Specifications from "@/specification/specification"
import CreateSpuPage from "@/specification/spu/interaction/CreateSpuPage"
import SpecificationCategories from "@/specification/category"
import Statistic from "@/statistic"
import PrintTemplate from "@/common/print"
import Section from "@/content"
import Warehouse from "@/warehouse/warehouse"

import Activity from "../../association/activity";
import Member from "../../association/member";
import MemberApply from "../../association/apply";
import Partner from "@/association/partner";

const isDev = process.env.NODE_ENV === "development"

export const route = [
    {
        name: "系统概览",
        icon: <EyeOutlined />,
        path: "/home/overview",
        element: <Overview />
    },
    {
        name: "协会事务",
        icon: <BankOutlined/>,
        path: "/home/association",
        children: [
            {
                name: "会员申请",
                path: "/home/association/member-apply",
                element: <MemberApply/>,
                permissions: ["association-member-apply:manage"]
            },
            {
                name: "协会会员",
                path: "/home/association/member",
                element: <Member/>,
                permissions: ["association-member:manage"]
            },
            {
                name: "协会活动",
                path: "/home/association/activity",
                element: <Activity/>,
                permissions: ["association-activity:manage"]
            },
            {
                name: "合作伙伴",
                path: "/home/association/partner",
                element: <Partner/>,
                permissions: ["association-partner:manage"]
            }
        ]
    },
    {
        name: "系统用户",
        icon: <UserOutlined />,
        path: "/home/member",
        children: [
            {
                name: "功能权限",
                path: "/home/member/permission",
                element: <Permissions />,
                permissions: ["permission:manage"]
            },
            {
                name: "系统角色",
                path: "/home/member/role",
                element: <Roles />,
                permissions: ["role:manage"]
            },
            {
                name: "用户详情",
                path: "/home/member/user/:id",
                hideInMenu: true,
                element: <UserDetailPage />,
                permissions: ["user:select"]
            },
            {
                name: "单位与用户",
                path: "/home/member/department",
                element: <Departments />,
                permissions: ["department:manage"]
            },
            {
                name: "系统租户",
                path: "/home/member/tenant",
                element: <Tenants />,
                permissions: ["tenant:manage"]
            }
        ]
    },
    {
        name: "系统设置",
        icon: <SettingOutlined />,
        path: "/home/common",
        children: [
            {
                name: "字典管理",
                path: "/home/common/dictionary",
                element: <Dictionaries />,
                permissions: ["dictionary-label:manage", "dictionary-value:manage"],
            },
            {
                name: "字典值管理",
                path: "/home/common/dictionary/:dictionary/variable",
                hideInMenu: true,
                element: <DictionaryVariableDetailsPage />,
                permissions: ["dictionary-variable:manage"]
            },
            {
                name: "附件管理",
                path: "/home/common/attachment/file",
                element: <Attachment />,
                permissions: ["attachment-file:manage", "attachment-category:manage"]
            },
            {
                name: "附件分类管理",
                path: "/home/common/attachment/category",
                element: <Categories />,
                permissions: ["attachment-category:manage"]
            },
            {
                name: "公告管理",
                path: "/home/common/announcement",
                element: <Announcements />,
                permissions: ["announcement:manage"]
            },
            {
                name: "帮助内容管理",
                path: "/home/common/help",
                element: <HelpManagePage />,
                permissions: ["help:manage"]
            },
            {
                name: "Meta管理",
                path: "/home/common/meta",
                element: <Metas />,
                permissions: ["meta:manage"]
            },
            {
                name: "打印模版",
                path: "/home/common/print-template",
                element: <PrintTemplate />,
                permissions: ["print-template:manage"]
            },
            {
                name: "代码生成",
                path: "/home/common/code",
                hideInMenu: !isDev,
                children: [
                    {
                        name: "代码生成表",
                        path: "/home/common/code/table",
                        element: <CodeTables />,
                        permissions: ["code-table:manage"],
                    },
                    {
                        name: "新增代码生成表",
                        path: "/home/common/code/table/new",
                        element: <CreateCodeTable />,
                        permissions: ["code-table:insert"],
                        hideInMenu: true
                    },
                    {
                        name: "编辑代码生成表",
                        path: "/home/common/code/table/:id/edit",
                        element: <UpdateCodeTable />,
                        permissions: ["code-table:update"],
                        hideInMenu: true,
                    },
                    {
                        name: "代码生成模版",
                        path: "/home/common/code/template",
                        element: <CodeTemplates />,
                        permissions: ["code-template:manage"],
                    },
                    {
                        name: "新增代码生成模版",
                        path: "/home/common/code/template/new",
                        element: <CreateCodeTemplate />,
                        permissions: ["code-template:insert"],
                        hideInMenu: true,
                    },
                    {
                        name: "编辑代码生成模版",
                        path: "/home/common/code/template/:id",
                        element: <UpdateCodeTemplate />,
                        permissions: ["code-template:update"],
                        hideInMenu: true,
                    }
                ]
            }
        ]
    },
    {
        name: "系统统计",
        icon: <AreaChartOutlined />,
        path: "/home/statistic",
        element: <Statistic />,
        hideInMenu: true
    },
    {
        name: "规格中心",
        icon: <ProductOutlined />,
        path: "/home/specification",
        hideInMenu: true,
        children: [
            {
                name: "规格管理",
                path: "/home/specification/specification",
                element: <Specifications />,
            },
            {
                name: "产品管理",
                path: "/home/specification/spu",
                element: <SpecificationCategories />
            },
            {
                name: "新建SPU",
                path: "/home/specification/spu/new-spu",
                element: <CreateSpuPage />,
                hideInMenu: true
            },
        ]
    },
    {
        name: "流程中心",
        icon: <NodeIndexOutlined />,
        path: "/home/workflow",
        hideInMenu: true,
        children: [
            {
                name: "流程定义",
                path: "/home/workflow/process-definition",
                element: <ProcessDefinitionDataTablePage />
            },
            {
                name: "流程记录",
                path: "/home/workflow/process-instance",
                element: <ProcessInstanceDataTablePage />
            },
            {
                name: "流程记录",
                path: "/home/workflow/process-definition/:processDefinitionId/process-instance",
                element: <ProcessDefinitionInstanceDataTablePage />,
                hideInMenu: true
            },
            {
                name: "我的待办",
                path: "/home/workflow/todo-task",
                element: <ProcessTodoTaskDataTablePage />
            }
        ]
    },
    {
        name: "仓储管理",
        icon: <DatabaseOutlined />,
        path: "/home/warehouse",
        hideInMenu: true,
        children: [
            {
                name: "仓库管理",
                path: "/home/warehouse/warehouse",
                element: <Warehouse />
            }
        ]
    },
    {
        name: "文章管理",
        icon: <FileWordOutlined />,
        path: "/home/content/article",
        element: <Section />,
        permissions: ["article:manage"]
    },
    {
        name: "帮助中心",
        icon: <SmileOutlined />,
        path: "/home/help-center",
        element: <HelpCenterPage />
    }
]

const extractMenuFromRoutes = (routes = route, menu = []) => {
    for (let item of routes) {
        if (undefined === item.children || null === item.children || item.children.length < 1) {
            if (SecurityUtils.hasPermission(item.permissions)) {
                menu.push({
                    name: item.name,
                    icon: item.icon,
                    path: item.path,
                    hideInMenu: item.hideInMenu,
                    hideChildrenInMenu: item.hideChildrenInMenu,
                    flatMenu: item.flatMenu
                })
            }
        } else {
            const childrenMenus = []
            extractMenuFromRoutes(item.children, childrenMenus)
            if (childrenMenus.length > 0) {
                menu.push({
                    name: item.name,
                    icon: item.icon,
                    path: item.path,
                    children: childrenMenus,
                    hideInMenu: item.hideInMenu,
                    hideChildrenInMenu: item.hideChildrenInMenu,
                    flatMenu: item.flatMenu
                })
            }
        }
    }
    return menu
}

export const getRoutes = (routes = route, result = []) => {
    for (const item of routes) {
        if (item.children !== null && item.children !== undefined && item.children.length > 0) {
            getRoutes(item.children, result)
        } else {
            result.push({ path: item.path.replace("/home/", ""), element: item.element })
        }
    }
    return result
}

export const getMenus = () => new Promise((resolve) => resolve(extractMenuFromRoutes(route, [])))


