import React from "react"
import { ModalForm, ProFormText } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import { requestUpdateDailyStatistic } from "@/statistic/daily/api/DailyStatisticAPI"
import DailyStatisticEvent from "@/statistic/daily/event/DailyStatisticEvent"

export default class UpdateDailyStatisticModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(DailyStatisticEvent.onTriggerUpdateDailyStatistic, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(DailyStatisticEvent.onTriggerUpdateDailyStatistic, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({ open })
    }

    onFinish = (params) => requestUpdateDailyStatistic(params).then(() => {
        EventBus.emit(DailyStatisticEvent.onDailyStatisticUpdated)
        return true
    }).catch((err) => {
        console.error("编辑每日统计失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    render() {
        return (<ModalForm title="编辑每日统计"
            layout="horizontal"
            modalProps={{ destroyOnClose: true, width: 560 }}
            open={this.state.open}
            onOpenChange={this.toggle}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />
            <ProFormText name="comment" label="备注" rules={Rule.require} />
        </ModalForm>)
    }
}