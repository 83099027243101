import React from "react"
import { EditableProTable } from "@ant-design/pro-components"
import { Button, Drawer, message, Space } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import SpuEvent from "@/specification/spu/event/SpuEvent"
import { requestDeleteSku, requestSkuByList, requestUpdateSku } from "@/specification/sku/api/SkuAPI"
import UpdateSpuSkuExtraModal from "@/specification/sku/interaction/UpdateSpuSkuExtraModal"
import SkuEvent from "@/specification/sku/event/SkuEvent"
import { requestSpecificationPropertyByList } from "@/specification/property/api/SpecificationPropertyAPI"

const defaultColumns = [
    {
        title: "ID",
        dataIndex: "id",
        hideInTable: true,
    },
    {
        title: "图片",
        dataIndex: "cover",
        valueType: "image",
        editable: false,
        width: 64,
        tooltip: "单个SKU图片设置暂未开放"
    }
]

/**
 * 编辑规格参数
 */
export default class UpdateSpuSkuDrawer extends React.Component {

    state = {
        open: false,
        loading: false,
        spu: {},
        skus: [],
        columns: []
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(SpuEvent.onTriggerUpdateSpuParam, this.onTrigger)
        EventBus.on(SkuEvent.onSkuUpdated, () => this.requestSkuList(this.state.spu))
    }

    componentWillUnmount() {
        EventBus.off(SpuEvent.onTriggerUpdateSpuParam, console.log)
        EventBus.off(SkuEvent.onSkuUpdated, console.log)
    }

    onTrigger = (spu) => {
        this.setState({ open: true, loading: true, spu }, () => this.requestSkuList(JSON.parse(JSON.stringify(spu))))
    }

    /**
     * 获取列表数据
     * @param spu
     */
    requestSkuList = (spu) => this.setState({ spu }, () => {
        requestSkuByList({ spu: spu.id }).then(res => {
            // 将SKU的Properties拆解，并设置到对象中
            for (const datum of res.data) {
                const spuProperties = spu.properties.split("|")
                datum.properties.split("|").forEach((v, i) => datum[spuProperties[i]] = v)
            }
            // 将SPU的Properties拆解，并设置为Column
            const columns = [
                ...defaultColumns,
                ...spu.properties.split("|").map((v) => ({
                    title: v,
                    dataIndex: v,
                    fieldProps: { fieldNames: { label: "property", value: "property" }, showSearch: false },
                    request: () => requestSpecificationPropertyByList({ specificationName: v }).then(res => res.data)
                })),
                { title: "条码", dataIndex: "barCode" },
                { title: "编码", dataIndex: "sn" },
                { title: '操作', valueType: 'option', width: 200, render: this.actionRender }
            ]
            this.setState({ skus: res.data, columns })
        }).catch(err => {
            this.setState({ skus: [] })
            console.warn("获取列表失败：", err)
        }).finally(() => {
            this.setState({ loading: false })
        })
    })

    actionRender = (_, row, __, action) => (<Space size="small">
        <Button type="link" size="small" onClick={() => action.startEditable(row.id)}>编辑</Button>
        <Button type="link" size="small" onClick={() => UpdateSpuSkuExtraModal.open(row)}>设置属性</Button>
    </Space>)

    onEdit = (_, row) => {
        const propertiesArray = []
        for (let spuProperty of this.state.spu.properties.split("|")) {
            propertiesArray.push(row[spuProperty])
        }
        const params = {
            id: row.id,
            properties: propertiesArray.join("|"),
            sn: row.sn,
            barCode: row.barCode
        }
        return requestUpdateSku(params).then(res => {
            EventBus.emit(SkuEvent.onSkuUpdated, res.data)
            return true
        }).catch(() => {
            message.warning("规格重复，请检查产品规格", 3).then()
            EventBus.emit(SkuEvent.onSkuUpdated)
            return false
        })
    }

    onDelete = (_, { id }) => requestDeleteSku(id).then(() => true).catch(() => false)

    render() {
        return (<Drawer title="管理SPU规格"
                        width="100%"
                        destroyOnClose
                        open={this.state.open}
                        onClose={() => this.setState({ open: false, columns: [], skus: [], spu: {} })}>

            <EditableProTable rowKey="id"
                              cardProps={{ ghost: true }}
                              columns={this.state.columns}
                              loading={this.state.loading}
                              value={this.state.skus}
                              recordCreatorProps={false}
                              onChange={skus => this.setState({ skus })}
                              editable={{ type: "single", onDelete: this.onDelete, onSave: this.onEdit }}/>

            <UpdateSpuSkuExtraModal/>
        </Drawer>)
    }

}

UpdateSpuSkuDrawer.open = (item) => {
    EventBus.emit(SpuEvent.onTriggerUpdateSpuParam, item)
}
