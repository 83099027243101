/**
 * 设置表单错误提示
 * @param formRef {React.RefObject} 表单引用
 * @param errorMessage {object | string} 表单错误信息（服务端返回的 data 对象或者 string 字符串）
 * @param defaultField {string} 如果服务端返回了字符串，将设置到这个名称的字段上
 *
 * @author Wayne
 * @version 1.0.0
 */
export const setFormError = (formRef, errorMessage, defaultField) => {
    if (formRef === undefined || formRef === null || formRef.current === undefined || formRef.current === null) {
        return
    }
    if (errorMessage === undefined || errorMessage == null) {
        return
    }
    if (typeof (errorMessage) === "object") {
        const errorInfos = []
        for (let name in errorMessage) {
            errorInfos.push({ name, errors: [errorMessage[name]] })
        }
        formRef.current.setFields(errorInfos)
    } else {
        if (!!defaultField) {
            formRef.current.setFields([{ name: [defaultField], errors: [errorMessage] }])
        }
    }
}

const FormUtils = {
    setFormError
}

export default FormUtils