import React from "react"
import {
    DrawerForm,
    ProFormDigit,
    ProFormField,
    ProFormText,
    ProFormTextArea,
    ProFormTreeSelect
} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import { requestUpdateSpu } from "@/specification/spu/api/SpuAPI"
import SpuEvent from "@/specification/spu/event/SpuEvent"
import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker"
import { requestCategoryByTree } from "@/specification/category/api/CategoryAPI"

export default class UpdateSpuDrawer extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(SpuEvent.onTriggerUpdateSpu, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(SpuEvent.onTriggerUpdateSpu, console.log)
    }

    toggle = (open) => {
        if (open === this.state.open) {
            return
        }
        this.setState({ open })
    }

    onFinish = (params) => requestUpdateSpu(params).then(() => {
        EventBus.emit(SpuEvent.onSpuUpdated)
        return true
    }).catch((err) => {
        console.error("编辑Spu失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    render() {
        return (<DrawerForm title="编辑SPU"
            drawerProps={{ destroyOnClose: true, width: 560 }}
            open={this.state.open}
            onOpenChange={this.toggle}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />
            <ProFormField label="封面" name="cover" rules={Rule.require}>
                <AttachmentPicker accept={["image/png", "image/jpeg", "image/jpg"]} />
            </ProFormField>
            <ProFormText width="sm" name="name" label="名称" rules={Rule.require} />
            <ProFormText width="sm" name="sn" label="编号" rules={Rule.require} />
            <ProFormTreeSelect width="sm" label="类目" name="category"
                fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                request={() => requestCategoryByTree().then(res => res.data).catch(() => [])}
                rules={Rule.require} />
            <ProFormDigit width="xs" name="sort" label="排序" rules={Rule.require} />
            <ProFormTextArea name="comment" label="备注" rules={Rule.textarea} />
        </DrawerForm>)
    }

}

UpdateSpuDrawer.open = (item) => {
    EventBus.emit(SpuEvent.onTriggerUpdateSpu, item)
}