/**
 * 新建活动参与人员
 */
export const requestCreateActivityMember = (activity, params) => window.axios.post(`/association/activity/${activity}/member`, params)

/**
 * 分页查询活动参与人员
 * @param params 查询参数
 */
export const requestActivityMemberByPage = (params) => window.axios.get("/association/activity/member/page", { params })

/**
 * 查询活动参与人员
 * @param params 查询参数
 */
export const requestActivityMemberByList = (params) => window.axios.get("/association/activity/member/list", { params })

export const requestActivityMemberAsExcel = (activity) => window.axios.get(`/association/activity/${activity}/member/excel`, { responseType: "blob" })
export const requestActivityMemberExcelTemplate = () => window.axios.get(`/association/activity/member/excel-template`, { responseType: "blob" })

/**
 * 查询指定ID活动参与人员
 * @param id {string} 活动参与人员ID
 */
export const requestActivityMemberById = (id) => window.axios.get(`/association/activity/member/${id}`)

/**
 * 更新活动参与人员
 */
export const requestUpdateActivityMember = (params) => window.axios.patch(`/association/activity/member/${params.id}`, params)

export const requestNotifyActivityMember = (activity) => window.axios.patch(`/association/activity/${activity}/member/sms-notify`)

/**
 * 删除活动参与人员
 */
export const requestDeleteActivityMember = (id) => window.axios.delete(`/association/activity/member/${id}`)
