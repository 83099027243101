/**
 * 新增代码生成模版
 */
export const requestCreateCodeTemplate = (params) => window.axios.post("/common/code/template", params)

/**
 * 分页查询代码生成模版
 * @param params 查询参数
 */
export const requestCodeTemplateByPage = (params) => window.axios.get("/common/code/template/page", {params})

/**
 * 查询代码生成模版
 * @param params 查询参数
 */
export const requestCodeTemplateByList = (params) => window.axios.get("/common/code/template/list", {params})

/**
 * 查询指定ID代码生成模版
 * @param id {string} 代码生成模版ID
 */
export const requestCodeTemplateById = (id) => window.axios.get(`/common/code/template/${id}`)

export const requestMatchCodeTemplate = (id, codeTable) => window.axios.get(`/common/code/template/${id}/match`, {params: {codeTable}})

/**
 * 更新代码生成模版
 */
export const requestUpdateCodeTemplate = (params) => window.axios.patch(`/common/code/template/${params.id}`, params)

/**
 * 删除代码生成模版
 */
export const requestDeleteCodeTemplate = (id) => window.axios.delete(`/common/code/template/${id}`)
