import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import ProcessInstanceDataTable from "../component/table/ProcessInstanceDataTable";

export default function ProcessInstanceDataTablePage() {

    return (<PageContainer>
        <ProcessInstanceDataTable/>
    </PageContainer>)

}