import React from "react"
import {Button, Tooltip} from "antd"
import {PlusOutlined} from "@ant-design/icons"
import {ModalForm, ProFormRadio, ProFormText} from "@ant-design/pro-components"

import FormUtils from "@/framework/util/FormUtils"
import EventBus from "@/framework/util/EventBusUtils"
import Rule from "@/framework/form/Rule";

import {requestCreateHelpCategory} from "../api/HelpAPI"
import HelpCategoryEvent from "../event/HelpEvent"

export default function CreateHelpCategoryModal(props) {

    const formRef = React.useRef()

    const trigger = (<Tooltip title="新建顶层分类"><Button icon={<PlusOutlined/>}/></Tooltip>)

    const onFinish = (params) => requestCreateHelpCategory(params).then(() => {
        EventBus.emit(HelpCategoryEvent.onHelpCategoryCreated)
        return true
    }).catch(err => {
        console.log("新增帮助分类失败", err)
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增帮助分类"
                       modalProps={{width: 560, destroyOnClose: true}}
                       formRef={formRef}
                       trigger={!!props.trigger ? props.trigger : trigger}
                       onFinish={onFinish}>
        <ProFormText width="md" name="key" label="Key" rules={Rule.require}/>
        <ProFormText width="md" name="name" label="分类名称" rules={Rule.require}/>
        <ProFormText name="parent" label="父分类ID" rules={Rule.require} initialValue={props.parent} hidden/>
        <ProFormRadio.Group name="leaf" label="叶子结点" rules={Rule.require} initialValue={0}
                            options={[{label: "否", value: 0}, {label: "是", value: 1}]}/>
    </ModalForm>)
}