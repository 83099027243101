import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import AnnouncementDataTable from "./component/AnnouncementDataTable";

export default class Announcements extends React.Component {

    render() {
        return (<PageContainer>
            <AnnouncementDataTable/>
        </PageContainer>)
    }

}