import PropTypes from "prop-types";
import {Button, Popconfirm} from "antd";

import EventBus from "../../../framework/util/EventBusUtils";

import {requestDeleteActivityMember} from "../api/ActivityMemberAPI";
import ActivityMemberEvent from "../event/ActivityMemberEvent";


export default function DeleteActivityMemberButton(props) {

    const onConfirm = () => requestDeleteActivityMember(props.id).then(res => {
        EventBus.emit(ActivityMemberEvent.onActivityMemberDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除活动参与人员" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteActivityMemberButton.propTypes = {
    id: PropTypes.string.isRequired
}