import React from "react"
import { ModalForm, ProFormText, ProFormTreeSelect } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import { requestUpdateGroup } from "@/member/group/api/GroupAPI"
import GroupEvent from "@/member/group/event/GroupEvent"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"

export default class UpdateGroupModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(GroupEvent.onTriggerUpdateGroup, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(GroupEvent.onTriggerUpdateGroup, console.log)
    }

    onFinish = (params) => requestUpdateGroup(params).then(() => {
        EventBus.emit(GroupEvent.onGroupUpdated)
        return true
    }).catch((err) => {
        console.error("编辑用户组失败", err)
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        this.setState({ open: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    render() {
        return (<ModalForm title="编辑用户组"
            modalProps={{ destroyOnClose: true, width: 560 }}
            open={this.state.open}
            onOpenChange={open => this.setState({ open })}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />

            <ProFormTreeSelect width="md" name="department" label="单位"
                rules={Rule.require}
                fieldProps={{ fieldNames: { label: "name", value: "id", children: "children" } }}
                request={() => requestDepartmentByTree().then(res => res.data).catch(() => [])} />

            <ProFormText width="md" name="name" label="名称" rules={Rule.require} />

            <ProFormText width="md" name="identifier" label="标识符" rules={Rule.require} disabled />

        </ModalForm>)
    }
}