import React from "react"
import {App, Button} from "antd"
import {PlusOutlined} from "@ant-design/icons"
import {ModalForm, ProFormText, ProFormTextArea} from "@ant-design/pro-components"

import FormUtils from "../../../framework/util/FormUtils"
import EventBus from "../../../framework/util/EventBusUtils"
import Rule from "../../../framework/form/Rule";

import {requestCreateMemberMark} from "../api/MemberMarkAPI"
import MemberMarkEvent from "../event/MemberMarkEvent"

export default function CreateMemberMarkModal({member}) {

    const {message} = App.useApp()
    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined/>}>新增会员标注</Button>)

    const onFinish = (params) => requestCreateMemberMark(params).then(res => {
        message.success("操作成功", 2).then()
        EventBus.emit(MemberMarkEvent.onMemberMarkCreated, res.data)
        return true
    }).catch(err => {
        console.log("新增会员标注失败", err)
        message.error("操作失败", 2).then()
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<ModalForm title="新增会员标注"
                       modalProps={{destroyOnClose: true, width: 560}}
                       formRef={formRef}
                       trigger={trigger}
                       onFinish={onFinish}>
        <ProFormText name="member" label="会员ID" hidden
                     initialValue={member?.id}
                     rules={Rule.require}/>
        <ProFormTextArea name="mark" label="标注内容" rules={Rule.require}/>
    </ModalForm>)
}