import React from "react";
import {ModalForm, ProFormSelect} from "@ant-design/pro-components";

import EventBus from "@/framework/util/EventBusUtils";
import FormUtils from "@/framework/util/FormUtils";

import {requestCodeTemplateById, requestCodeTemplateByList} from "../api/CodeTemplateAPI";
import CodeTemplateEvent from "../event/CodeTemplateEvent";

export default class CodeTemplateSelectModal extends React.Component {

    formRef = React.createRef()

    state = {
        open: false
    }

    componentDidMount() {
        EventBus.on(CodeTemplateEvent.onTriggerSelectCodeTemplate, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(CodeTemplateEvent.onTriggerSelectCodeTemplate, console.log)
    }

    onTrigger = () => this.setState({open: true})

    onRequestCodeTemplateOptions = () => requestCodeTemplateByList().then(res => {
        return res.data.map(v => ({label: v.name, value: v.id}))
    }).catch(() => {
        return []
    })

    onFinish = (params) => requestCodeTemplateById(params.id).then(res => {
        EventBus.emit(CodeTemplateEvent.onCodeTemplateSelected, res.data)
        return true
    }).catch(err => {
        FormUtils.setFormError(this.formRef, err.response?.data, "id")
        return false
    })

    render() {
        return (<ModalForm title="选择代码生成模板"
                           width={480}
                           onFinish={this.onFinish}
                           onOpenChange={open => this.setState({open})}
                           formRef={this.formRef}
                           trigger={undefined}
                           open={this.state.open}>
            <ProFormSelect name="id" label="代码生成模板" request={this.onRequestCodeTemplateOptions}/>
        </ModalForm>)
    }

}