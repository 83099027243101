import PropTypes from "prop-types"
import { App, Button, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeleteWarehouse } from "@/warehouse/warehouse/api/WarehouseAPI"
import WarehouseEvent from "@/warehouse/warehouse/event/WarehouseEvent"


export default function DeleteWarehouseButton(props) {

    const { message } = App.useApp()

    const onConfirm = () => requestDeleteWarehouse(props.id).then(res => {
        message.success("删除成功", 2).then()
        EventBus.emit(WarehouseEvent.onWarehouseDeleted, res.data)
        return true
    }).catch(() => {
        message.error("删除失败", 2).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除仓库" okText="确认" cancelText="取消" okButtonProps={{ danger: true }}
        onCancel={onCancel}
        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteWarehouseButton.propTypes = {
    id: PropTypes.string.isRequired
}