import React, { useState } from "react";
import { App, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import DownloadUtils from "@/framework/util/DownloadUtils";

import { requestActivityMemberAsExcel } from "@/association/activity/api/ActivityMemberAPI";

export default function ExportActivityMemberButton({ activity }) {

    const [loading, setLoading] = useState(false)

    const { message } = App.useApp()

    const onClick = () => {
        setLoading(true)
        requestActivityMemberAsExcel(activity).then(res => {
            DownloadUtils.download(res.data, res.headers.contentType, "导出活动与会人员记录.xlsx")
        }).catch(() => {
            message.error("导出失败，请稍后重试", 2).then()
        }).finally(() => {
            setLoading(false)
        })
    }

    return (<Button loading={loading} onClick={onClick} icon={<DownloadOutlined/>}>
        导出Excel
    </Button>)

}