/**
 * 新建合作伙伴
 */
export const requestCreateCooperativePartner = (params) => window.axios.post("/association/cooperative-partner", params)

/**
 * 分页查询合作伙伴
 * @param params 查询参数
 */
export const requestCooperativePartnerByPage = (params) => window.axios.get("/association/cooperative-partner/page", {params})

/**
 * 查询合作伙伴
 * @param params 查询参数
 */
export const requestCooperativePartnerByList = (params) => window.axios.get("/association/cooperative-partner/list", {params})

/**
 * 查询指定ID合作伙伴
 * @param id {string} 合作伙伴ID
 */
export const requestCooperativePartnerById = (id) => window.axios.get(`/association/cooperative-partner/${id}`)

/**
 * 更新合作伙伴
 */
export const requestUpdateCooperativePartner = (params) => window.axios.patch(`/association/cooperative-partner/${params.id}`, params)

/**
 * 删除合作伙伴
 */
export const requestDeleteCooperativePartner = (id) => window.axios.delete(`/association/cooperative-partner/${id}`)
