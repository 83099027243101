import React from "react"
import { PlusOutlined } from "@ant-design/icons"
import { Button } from "antd"
import {
    DrawerForm,
    ProFormDatePicker,
    ProFormDependency,
    ProFormField,
    ProFormRadio,
    ProFormSelect,
    ProFormText
} from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import { getAvailableEnumAsOptions } from "@/framework/enum/AvailableEnum"

import { requestCreateTenant } from "@/member/tenant/api/TenantAPI"
import TenantEvent from "@/member/tenant/event/TenantEvent"
import Rule from "@/framework/form/Rule"
import { requestDictionaryVariableByList } from "@/common/dictionary/variable/api/DictionaryVariableAPI"
import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker"

export default function CreateTenantDrawer() {

    const formRef = React.useRef()

    const trigger = (<Button type="primary" icon={<PlusOutlined />}>新增租户</Button>)

    const onFinish = (params) => requestCreateTenant(params).then(res => {
        EventBus.emit(TenantEvent.onTenantCreated, res.data)
        formRef.current?.resetFields()
        return true
    }).catch(err => {
        if (!!err.response.data.authentication) {
            err.response.data.managerCellphone = err.response.data.authentication
        }
        FormUtils.setFormError(formRef, err.response.data, "name")
        return false
    })

    return (<DrawerForm title="新增租户"
        drawerProps={{ width: 640, destroyOnClose: true }}
        formRef={formRef}
        trigger={trigger}
        onFinish={onFinish}>

        <ProFormField label="企业Logo" name="logo">
            <AttachmentPicker count={1} accept={["image/png", "image/jpeg"]} />
        </ProFormField>

        <ProFormText width="md" name="name" label="名称" placeholder="请输入租户名称"
            rules={Rule.requireText} />

        <ProFormSelect width="sm" name="trade" label="行业"
            rules={Rule.require}
            formItemProps={{ fieldNames: { label: "label", value: "variable" } }}
            request={() => requestDictionaryVariableByList({ dictionaryName: "trade" }).then(res => res.data).catch(() => [])} />

        <ProFormSelect width="xs" name="type" label="类型"
            options={[
                { label: "临时租户", value: "临时租户" },
                { label: "永久租户", value: "永久租户" }
            ]} />

        <ProFormDependency name={["type"]}>
            {({ type }) => {
                return type === "永久租户" ?
                    undefined :
                    (<ProFormDatePicker width="sm" label="有效期" name="shelfLife" />)
            }}
        </ProFormDependency>

        <ProFormText width="sm" name="managerName" label="管理员姓名"
            rules={Rule.requireText} />

        <ProFormText width="sm" name="managerCellphone" label="管理员手机号码"
            rules={Rule.requireText} />

        <ProFormText.Password width="md" name="managerCredential" label="管理员密码"
            rules={[
                { required: true, message: "请输入密码" },
                { min: 6, message: "密码长度应在[6,32]" },
                { max: 32, message: "密码长度应在[6,32]" }
            ]} />

        <ProFormText.Password
            width="md"
            name="confirmCredential" label="确认密码"
            rules={[
                { required: true, message: "请输入密码" },
                { min: 6, message: "密码长度应在[6,32]" },
                { max: 32, message: "密码长度应在[6,32]" },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('managerCredential') === value) {
                            return Promise.resolve()
                        }
                        return Promise.reject(new Error('确认密码匹配失败'))
                    },
                })
            ]} />

        <ProFormRadio.Group name="available" label="可用状态" rules={Rule.require}
            initialValue="可用" options={getAvailableEnumAsOptions()} />

    </DrawerForm>)
}