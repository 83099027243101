import PropTypes from "prop-types"
import { App, Button, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeleteMonthlyStatistic } from "@/statistic/monthly/api/MonthlyStatisticAPI"
import MonthlyStatisticEvent from "@/statistic/monthly/event/MonthlyStatisticEvent"


export default function DeleteMonthlyStatisticButton({ id }) {

    const { message } = App.useApp()

    const onConfirm = () => requestDeleteMonthlyStatistic(id).then(res => {
        EventBus.emit(MonthlyStatisticEvent.onMonthlyStatisticDeleted, res.data)
        message.success("删除成功", 2).then()
        return true
    }).catch(() => {
        message.error("删除失败", 2).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除每月统计" okText="删除" cancelText="取消" okButtonProps={{ danger: true }}
        onCancel={onCancel}
        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteMonthlyStatisticButton.propTypes = {
    id: PropTypes.string.isRequired
}