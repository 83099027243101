import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import MemberDataTable from "./component/MemberDataTable";

export default class Member extends React.Component {

    render() {
        return (<PageContainer>
            <MemberDataTable/>
        </PageContainer>)
    }

}