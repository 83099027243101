import React from "react"
import { PageContainer } from "@ant-design/pro-components"
import WarehouseDataTable from "@/warehouse/warehouse/component/WarehouseDataTable"

export default class Warehouse extends React.Component {

    render() {
        return (<PageContainer>
            <WarehouseDataTable />
        </PageContainer>)
    }

}