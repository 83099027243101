import React, {useEffect, useState} from "react";
import {ProDescriptions} from "@ant-design/pro-components";

import {requestFormDeploymentByName} from "../../api/WorkflowAPI";
import ColumnsConverter from "../../util/ColumnsConverter";

export default function ProcessInstanceTaskFormDescription({task, variables, ...restProps}) {

    const [loading, setLoading] = useState(true)
    const [columns, setColumns] = useState([])
    const [hasForm, setHasForm] = useState(true);

    useEffect(() => {
        const taskFormDeploymentName = !!variables ? variables[`_form_${task?.taskDefinitionKey}`] : undefined;
        if (!taskFormDeploymentName) {
            setHasForm(false)
            return
        }
        setHasForm(true)
        requestFormDeploymentByName(taskFormDeploymentName)
            .then(res => setColumns(ColumnsConverter(res.data)))
            .catch(err => console.error("任务表单查询失败", err))
            .finally(() => setLoading(false))
    }, [task, variables]);

    return hasForm ? (<ProDescriptions key={task?.id}
                                       loading={loading}
                                       columns={columns}
                                       dataSource={variables}
                                       {...restProps}/>) : undefined

}