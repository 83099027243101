import React from "react";
import {PageContainer} from "@ant-design/pro-components";
import DictionaryLabelTable from "./label/component/DictionaryLableTable";

export default class Dictionaries extends React.Component {

    render() {
        return (<PageContainer>
            <DictionaryLabelTable/>
        </PageContainer>)
    }

}