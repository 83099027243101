import React from "react";
import {PageContainer, ProTable} from "@ant-design/pro-components";

import SecurityUtils from "../../framework/util/SecurityUtils";
import EventBus from "../../framework/util/EventBusUtils";

import {
    ProcessInstanceTaskDataTableColumnsState,
    ProcessTaskDataTableColumnOperations
} from "../component/table/ProcessTaskDataTable";
import CompleteProcessInstanceTaskDrawer from "../interaction/CompleteProcessInstanceTaskDrawer";
import AssignProcessInstanceTaskModal from "../interaction/AssignProcessInstanceTaskModal";
import PreviewProcessTaskModal from "../interaction/PreviewProcessTaskModal";
import {requestProcessDefinitionByList, requestProcessInstanceTaskByPage} from "../api/WorkflowAPI";
import WorkflowEvent from "../event/WorkflowEvent";

const ProcessTaskDataTableColumns = [
    {
        title: "ID",
        dataIndex: "id",
        copyable: true,
        ellipsis: true,
        hideInSearch: true
    },
    {
        title: "流程",
        dataIndex: "processDefinitionId",
        valueType: "select",
        fieldProps: {showSearch: true, debounce: 500, fieldNames: {label: "name", value: "id"}},
        request: () => requestProcessDefinitionByList().then(res => res.data).catch(() => [])
    },
    {
        title: "流程流水号",
        dataIndex: "processInstanceId",
        hideInSearch: true
    },
    {
        title: "待办名称",
        dataIndex: "name",
        copyable: true,
        ellipsis: true
    },
    {
        title: "Key",
        dataIndex: "taskDefinitionKey",
        fieldProps: {placeholder: "此项为精确匹配"},
        copyable: true,
        ellipsis: true
    },
    {
        title: "优先级",
        dataIndex: "priority",
        hideInSearch: true
    },
    {
        title: "挂起",
        dataIndex: "suspended",
        hideInSearch: true,
        valueType: "select",
        valueEnum: {
            "false": {text: "否", status: "success"},
            "true": {text: "是", status: "error"}
        }
    },
    {
        title: "截止日期",
        dataIndex: "due",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "创建时间",
        dataIndex: "created",
        valueType: "fromNow",
        hideInSearch: true
    },
    {
        title: "创建时间",
        dataIndex: "createdPicker",
        valueType: "dateRange",
        hideInTable: true
    },
    {
        title: "操作",
        valueType: "option",
        render: (_, item) => (<ProcessTaskDataTableColumnOperations item={item}/>)
    }
]

export default class ProcessTodoTaskDataTablePage extends React.Component {

    actionRef = React.createRef()

    componentDidMount() {
        EventBus.on(WorkflowEvent.onProcessTaskUpdateEvent, this.actionRef.current?.reload)
    }

    componentWillUnmount() {
        EventBus.off(WorkflowEvent.onProcessTaskUpdateEvent, console.log)
    }

    request = (params) => {
        if (!!params.createdPicker) {
            params.startTime = params.createdPicker[0] + " 00:00:00"
            params.endTime = params.createdPicker[1] + " 23:59:59"
            params.createdPicker = undefined
        }
        return requestProcessInstanceTaskByPage(params).then(res => {
            return res.data
        }).catch(() => {
            return {data: [], success: true, total: 0}
        })
    }

    render() {
        return (<PageContainer>
            <ProTable rowKey="id"
                      cardBordered
                      scroll={{x: "max-content"}}
                      columns={ProcessTaskDataTableColumns}
                      columnsState={ProcessInstanceTaskDataTableColumnsState}
                      params={{assignee: SecurityUtils.getUser().id}}
                      actionRef={this.actionRef}
                      request={this.request}/>
            <CompleteProcessInstanceTaskDrawer/>
            <AssignProcessInstanceTaskModal/>
            <PreviewProcessTaskModal/>
        </PageContainer>)
    }
}