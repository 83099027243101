import React from "react"
import PropTypes from "prop-types"
import { ProCard, ProDescriptions } from "@ant-design/pro-components"
import { requestDictionaryVariableByList } from "@/common/dictionary/variable/api/DictionaryVariableAPI"
import { getUser } from "@/framework/util/SecurityUtils"
import EventBus from "@/framework/util/EventBusUtils"
import UserEvent from "@/member/user/event/UserEvent"
import { Button, Space } from "antd"
import PermissionWrapper from "@/framework/component/PermissionWrapper"
import UpdateUserRolesModal from "@/member/user/interaction/UpdateUserRolesModal"
import DeleteUserModal from "@/member/user/interaction/DeleteUserModal"
import UpdateUserGroupsModal from "@/member/user/interaction/UpdateUserGroupsModal"
import AvailableEnum from "@/framework/enum/AvailableEnum"

const UserOperations = (props) => {
    const user = getUser()
    if (props.user.id === undefined || props.user.id === null || user.id === props.user.id) {
        return undefined
    }
    const onEditUser = () => {
        const user = props.user
        EventBus.emit(UserEvent.onTriggerUpdateUser, user)
    }
    return (<Space>
        <PermissionWrapper permissions={["user:update"]}>
            <Button type="link" size="small" onClick={onEditUser}>编辑</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["user:update"]}>
            <Button type="link" size="small" onClick={() => UpdateUserRolesModal.open(props.user)}>设置角色</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["user:update"]}>
            <Button type="link" size="small"
                onClick={() => UpdateUserGroupsModal.open(props.user)}>设置用户组</Button>
        </PermissionWrapper>
        <PermissionWrapper permissions={["user:delete"]}>
            <DeleteUserModal user={props.user} />
        </PermissionWrapper>
    </Space>)
}

/**
 * 用户基础信息组件
 *
 * @description 返回一个显示用户基础信息的 ProDescription 组件
 *
 * @author Wayne Carder
 */
export default function TabForUserInfo(props) {

    const onRequestDictionary = (dictionaryName) => requestDictionaryVariableByList({ dictionaryName }).then(res => {
        return res.data?.map(v => ({ label: v.label, value: v.variable.toString() }))
    }).catch(err => {
        console.warn(`查询字典选项失败(${dictionaryName})`, err)
        return []
    })

    return (<ProCard title="基础信息" extra={<UserOperations user={props.user} />} bordered headerBordered>
        <ProDescriptions dataSource={props.user} column={24}>
            <ProDescriptions.Item label="头像" span={24} dataIndex="avatar" valueType="avatar" />

            <ProDescriptions.Item label="ID" span={24} dataIndex="id" copyable ellipsis />

            <ProDescriptions.Item label="姓名" span={12} dataIndex="name" />
            <ProDescriptions.Item label="出生日期" span={12} dataIndex="birthday" valueType="date" />

            <ProDescriptions.Item label="可用状态" span={12} dataIndex="available" valueEnum={AvailableEnum} />
            <ProDescriptions.Item label="创建时间" span={12} dataIndex="createTime" valueType="fromNow" />

            <ProDescriptions.Item label="登录次数" span={12} dataIndex="authenticationTimes" valueType="digtl" />
            <ProDescriptions.Item label="登录时间" span={12} dataIndex="authenticationTime" valueType="fromNow" />

            <ProDescriptions.Item label="状态" span={12} dataIndex="status" valueType="select"
                request={() => onRequestDictionary("user-status")} />
            <ProDescriptions.Item label="类型" span={12} dataIndex="type" valueType="select"
                request={() => onRequestDictionary("user-type")} />

            <ProDescriptions.Item label="级别" span={24} dataIndex="grade" valueType="select"
                request={() => onRequestDictionary("user-grade")} />

            <ProDescriptions.Item label="政治面貌" span={12} dataIndex="policy" valueType="select"
                request={() => onRequestDictionary("policy")} />
            <ProDescriptions.Item label="入党时间" span={12} dataIndex="authenticationTime" valueType="fromNow" />

            <ProDescriptions.Item label="籍贯" span={12}
                dataIndex="nativePlace" valueType="select"
                request={() => onRequestDictionary("native-place")} />
            <ProDescriptions.Item label="民族" span={12} dataIndex="nation"
                valueType="select"
                request={() => onRequestDictionary("nation")} />

            <ProDescriptions.Item label="毕业院校" span={{ xs: 24, sm: 24, md: 12, lg: 6, xl: 6, xxl: 6 }}
                dataIndex="school" valueType="text" />
            <ProDescriptions.Item label="学历" span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6, xxl: 6 }}
                dataIndex="education" valueType="select"
                request={() => onRequestDictionary("education")} />
            <ProDescriptions.Item label="学位" span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6, xxl: 6 }}
                dataIndex="degree" valueType="select"
                request={() => onRequestDictionary("degree")} />
            <ProDescriptions.Item label="专业" span={{ xs: 24, sm: 24, md: 12, lg: 6, xl: 6, xxl: 6 }}
                dataIndex="major" valueType="text" />
        </ProDescriptions>
    </ProCard>)
}

TabForUserInfo.propTypes = {
    /**
     * 用户信息
     */
    user: PropTypes.object.isRequired
}