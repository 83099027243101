import PropTypes from "prop-types";
import { Button, Popconfirm } from "antd";

import EventBus from "@/framework/util/EventBusUtils";

import { requestDeleteSection } from "@/content/section/api/SectionAPI";
import SectionEvent from "@/content/section/event/SectionEvent";


export default function DeleteSectionButton({ id }) {

    const onConfirm = () => requestDeleteSection(id).then(res => {
        EventBus.emit(SectionEvent.onSectionDeleted, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除栏目" okText="确认" cancelText="取消" okButtonProps={{ danger: true }}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteSectionButton.propTypes = {
    id: PropTypes.string.isRequired
}