import React from "react"
import { ProTable } from "@ant-design/pro-components"

import PropTypes from "prop-types"

import { requestAuthenticationLogByPage } from "@/member/user/api/UserAPI"

export default function AuthenticationLogDataTable(props) {
    const columns = [
        { title: "ID", dataIndex: "id", hideInTable: true },
        { title: "认证凭证", dataIndex: "authentication" },
        { title: "认证时间", dataIndex: "time", valueType: "fromNow" },
        { title: "认证结果", dataIndex: "result" }
    ]
    return (<ProTable cardProps={{ title: "认证记录", bordered: true, headerBordered: true }}
        rowKey="id"
        tableStyle={{ marginTop: 16 }}
        options={false}
        params={{ user: props.user.id }}
        request={(params) => requestAuthenticationLogByPage(params).then(res => res.data)}
        search={false}
        columns={columns} />)
}

AuthenticationLogDataTable.propTypes = {
    user: PropTypes.object.isRequired
}