const UserEvent = {
    onUserCreated: "onUserCreated",
    onUserUpdated: "onUserUpdated",
    onUserDeleted: "onUserDeleted",
    onTriggerUpdateUser: "onTriggerUpdateUser",
    onTriggerUpdateUserRole: "onTriggerUpdateUserRole",
    onTriggerUpdateUserGroup: "onTriggerUpdateUserGroup",
    onTriggerPreviewUser: "onTriggerPreviewUser",
    onTriggerUpdateAuthentication: "onTriggerUpdateAuthentication",
    onUserRoleUpdated: "onUserRoleUpdated",
    onAuthenticationCreated: "onAuthenticationCreated",
    onAuthenticationUpdated: "onAuthenticationUpdated",
    onAuthenticationDeleted: "onAuthenticationDeleted",
    onAuthenticationFailure: "onAuthenticationFailure"
}

export default UserEvent