import React from "react"
import { message } from "antd"
import { ModalForm, ProFormRadio, ProFormText } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import { setFormError } from "@/framework/util/FormUtils"
import Rule from "@/framework/form/Rule"

import { requestUpdatePermission } from "@/member/permission/api/PermissionAPI"
import PermissionEvent from "@/member/permission/event/PermissionEvent"
import { getAvailableEnumAsOptions } from "@/framework/enum/AvailableEnum"

export default class UpdatePermissionModal extends React.Component {

    state = {
        open: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(PermissionEvent.onTriggerUpdatePermission, (item) => {
            const form = JSON.parse(JSON.stringify(item))
            this.setState({ open: true }, () => setTimeout(() => this.formRef.current?.setFieldsValue(form), 100))
        })
    }

    componentWillUnmount() {
        EventBus.off(PermissionEvent.onTriggerUpdatePermission, () => console.log("EventOff", PermissionEvent.onTriggerUpdatePermission))
    }

    onFinish = (params) => requestUpdatePermission(params).then(() => {
        EventBus.emit(PermissionEvent.onPermissionUpdated)
        message.success("编辑成功", 2).then()
        return true
    }).catch((err) => {
        setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    render() {
        return (<ModalForm title="编辑权限"
            modalProps={{ width: 560, destroyOnClose: true }}
            isKeyPressSubmit
            scrollToFirstError
            open={this.state.open}
            onOpenChange={open => this.setState({ open })}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />

            <ProFormText width="md" name="name" label="名称" rules={Rule.requireText} />

            <ProFormText width="md" name="identifier" label="标识符" disabled tooltip="此项不可编辑" />

            <ProFormRadio.Group width="md" name="available" label="状态" rules={Rule.require}
                options={getAvailableEnumAsOptions()} />

        </ModalForm>)
    }
}

UpdatePermissionModal.open = (item) => {
    EventBus.emit(PermissionEvent.onTriggerUpdatePermission, item)
}