function splitIdAndNameFromPatch(path) {
    const substring = path.split("/")
    return [substring[substring.length - 2], substring[substring.length - 1]];
}

function convertPathToObj(path) {
    const [id, name] = splitIdAndNameFromPatch(path);
    return ({uid: id, name: name, status: "done", url: path})
}

const FormUtils = {
    splitIdAndNameFromPatch,
    convertPathToObj
}

export default  FormUtils