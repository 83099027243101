import {Empty} from "antd";
import PropTypes from "prop-types";

export default function EmptyWrapper({empty, children}) {
    return empty ? (<Empty/>) : children
}

EmptyWrapper.propTypes = {
    empty: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired
}