import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { message } from "antd"
import {
    PageContainer,
    ProCard,
    ProFormField,
    ProFormSelect,
    ProFormText,
    ProFormTextArea,
    ProFormTreeSelect,
    StepsForm
} from "@ant-design/pro-components"

import Rule from "@/framework/form/Rule"
import AttachmentPicker from "@/common/attachment/file/component/AttachmentPicker"

import { requestSpecificationByList } from "@/specification/specification/api/SpecificationAPI"
import { requestCategoryByTree } from "@/specification/category/api/CategoryAPI"
import { requestSpecificationPropertyByList } from "@/specification/property/api/SpecificationPropertyAPI"
import { requestCreateSpu } from "@/specification/spu/api/SpuAPI"
import SkuDataTable from "@/specification/sku/component/SkuDataTable"

/**
 * 新建SPU表单页面
 */
export default function CreateSpuPage() {

    const navigate = useNavigate()
    const formRef = useRef()
    const basicFormRef = useRef()
    const skuDataTableRef = useRef()
    // SPU 包含那些规格分类
    const [specification, setSpecification] = useState([])
    // 从指定的规格分类中选定了哪些规格值
    const [properties, setProperties] = useState({})
    // 规格的笛卡尔积
    // const [cartesianProduct, setCartesianProduct] = useState([])

    const onPropertiesChange = (_, v) => {
        if (!!v) {
            setProperties({ ...properties, [v[0].specification]: v })
        }
    }

    const onFinish = (form) => {
        const params = { name: form.name, category: form.category, cover: form.cover, comment: form.comment, sn: form.sn }
        const skus = skuDataTableRef.current?.getSkus()
        params.barCode = Array.from(new Set(skus.map(v => v.barCode))).join(",")
        params.skus = skus.map(v => ({
            properties: v.property,
            sn: v.sn,
            barCode: v.barCode,
            cover: v.cover,
            extra: v.extra
        }))
        params.properties = specification.map(v => v.label).join("|")
        return requestCreateSpu(params).then(() => {
            message.success("新建SPU成功", 2).then()
            navigate(-1)
            return true
        }).catch(err => {
            // TODO 表单错误回显
            return false
        })
    }

    return (<PageContainer header={{ onBack: () => navigate(-1) }}>
        <ProCard>
            <StepsForm formRef={formRef} onFinish={onFinish}>

                <StepsForm.StepForm title="SPU信息" formRef={basicFormRef} stepProps={{ description: "SPU基础信息" }}>
                    <ProFormField label="封面" name="cover" rules={Rule.require}>
                        <AttachmentPicker accept={["image/png", "image/jpeg"]} />
                    </ProFormField>
                    <ProFormText label="名称" name="name" rules={Rule.requireText} />
                    <ProFormText label="编码" name="sn" rules={Rule.requireText} />
                    <ProFormTreeSelect label="类目" name="category"
                        fieldProps={{ fieldNames: { label: "name", value: "id" } }}
                        request={() => requestCategoryByTree().then(res => res.data).catch(() => [])}
                        rules={Rule.require} />
                    <ProFormSelect label="产品规格" name="specification" mode="multiple"
                        fieldProps={{ fieldNames: { label: "name", value: "id" }, showSearch: false }}
                        onChange={(_, v) => setSpecification(v)}
                        request={() => requestSpecificationByList().then(res => res.data)}
                        rules={Rule.require} />
                    <ProFormTextArea label="备注" name="comment" />
                </StepsForm.StepForm>

                <StepsForm.StepForm title="规格信息" stepProps={{ description: "选择颜色、材质等信息" }}>
                    {specification.map(({ label, value }) => (
                        <ProFormSelect key={value} label={label} name={value} mode="multiple"
                            fieldProps={{ fieldNames: { label: "property", value: "id" }, showSearch: false }}
                            onChange={onPropertiesChange}
                            request={() => requestSpecificationPropertyByList({ specification: value }).then(res => res.data)}
                            rules={Rule.require} />
                    ))}
                </StepsForm.StepForm>

                <StepsForm.StepForm title="SKU信息" stepProps={{ description: "输入各个SKU的信息" }}>
                    <SkuDataTable specification={specification}
                        basicFormRef={basicFormRef}
                        properties={properties}
                        ref={skuDataTableRef} />
                </StepsForm.StepForm>
            </StepsForm>
        </ProCard>
    </PageContainer>)

}
