import React from "react"
import { ModalForm, ProFormDigit, ProFormText, ProFormTextArea, ProFormTreeSelect } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import FormUtils from "@/framework/util/FormUtils"
import SecurityUtils, { getUser } from "@/framework/util/SecurityUtils"

import { requestUpdatePost } from "@/member/post/api/PostAPI"
import PostEvent from "@/member/post/event/PostEvent"
import Rule from "@/framework/form/Rule"
import { requestDepartmentByTree } from "@/member/department/api/DepartmentAPI"

export default class UpdatePostModal extends React.Component {

    state = {
        visible: false
    }

    formRef = React.createRef()

    componentDidMount() {
        EventBus.on(PostEvent.onTriggerUpdatePost, this.onTrigger)
    }

    componentWillUnmount() {
        EventBus.off(PostEvent.onTriggerUpdatePost, console.log)
    }

    toggle = (visible) => {
        if (visible === this.state.visible) {
            return
        }
        this.setState({ visible })
    }

    onFinish = (params) => requestUpdatePost(params).then(() => {
        EventBus.emit(PostEvent.onPostUpdated)
        this.formRef.current?.resetFields()
        return true
    }).catch((err) => {
        FormUtils.setFormError(this.formRef, err.response?.data, "name")
        return false
    })

    onTrigger = (item) => {
        const form = JSON.parse(JSON.stringify(item))
        form.department = item.department.id
        this.setState({ visible: true }, () => {
            setTimeout(() => this.formRef.current?.setFieldsValue(form), 100)
        })
    }

    onRequestDepartmentTree = (params) => requestDepartmentByTree(params).then(res => {
        if (SecurityUtils.hasSuperPermission()) {
            res.data.push({ name: "通用岗位（无归属单位）", id: "0", disabled: false })
        }
        return res.data
    }).catch(() => {
        return []
    })

    render() {
        return (<ModalForm title="编辑岗位"
            open={this.state.visible}
            onOpenChange={this.toggle}
            modalProps={{ destroyOnClose: true, width: 560 }}
            formRef={this.formRef}
            onFinish={this.onFinish}>

            <ProFormText name="id" label="ID" hidden />

            <ProFormTreeSelect width="md" name="department" label="单位"
                params={{
                    parent: SecurityUtils.hasSuperPermission() ? undefined : getUser().department.id
                }}
                fieldProps={{ showSearch: true, debounce: 500, fieldNames: { label: "name", value: "id" } }}
                request={this.onRequestDepartmentTree}
                rules={Rule.require} />

            <ProFormText width="md" name="name" label="名称" rules={Rule.requireText} />

            <ProFormDigit width="xs" name="level" label="级别" rules={Rule.require}
                min={1} max={99} fieldProps={{ precision: 0 }} />

            <ProFormTextArea name="duty" label="岗位职责" rules={Rule.text} />

            <ProFormTextArea name="description" label="描述" rules={Rule.text} />

        </ModalForm>)
    }
}