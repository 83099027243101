import {requestDeleteHistoryProcessInstanceById} from "../../api/WorkflowAPI";
import EventBus from "@/framework/util/EventBusUtils";
import WorkflowEvent from "../../event/WorkflowEvent";
import {Button, Popconfirm} from "antd";
import React from "react";

export default function DeleteProcessInstanceButton({id}) {

    const onConfirm = () => requestDeleteHistoryProcessInstanceById(id).then(res => {
        EventBus.emit(WorkflowEvent.onProcessInstanceDeleteEvent, res.data)
        return true
    }).catch(() => {
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除流程实例" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}