import PropTypes from "prop-types"
import { App, Button, Popconfirm } from "antd"

import EventBus from "@/framework/util/EventBusUtils"

import { requestDeleteSpecification } from "@/specification/specification/api/SpecificationAPI"
import SpecificationEvent from "@/specification/specification/event/SpecificationEvent"


export default function DeleteSpecificationButton({ id }) {

    const { message } = App.useApp()

    const onConfirm = () => requestDeleteSpecification(id).then(res => {
        EventBus.emit(SpecificationEvent.onSpecificationDeleted, res.data)
        message.success("删除成功", 2).then()
        return true
    }).catch(() => {
        message.error("删除失败", 2).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除规格" okText="删除" cancelText="取消" okButtonProps={{ danger: true }}
        onCancel={onCancel}
        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}

DeleteSpecificationButton.propTypes = {
    id: PropTypes.string.isRequired
}