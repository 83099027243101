import React from "react";
import {Button, message, Popconfirm} from "antd";
import {requestDeleteDictionaryVariableById} from "../api/DictionaryVariableAPI";
import EventBus from "@/framework/util/EventBusUtils";
import DictionaryVariableEvent from "../event/DictionaryVariableEvent";

export default function DeleteDictionaryVariableButton({id}) {

    const onConfirm = () => requestDeleteDictionaryVariableById(id).then(res => {
        EventBus.emit(DictionaryVariableEvent.onDictionaryVariableDeleted, res.data)
        message.success("删除成功").then()
        return true
    }).catch(err => {
        message.error(!!err.response?.data.id ? err.response.data.id : err.message).then()
        return false
    })

    const onCancel = () => new Promise(resolve => resolve(true))

    return (<Popconfirm title="删除字典值" okText="删除" cancelText="取消" okButtonProps={{danger: true}}
                        onCancel={onCancel}
                        onConfirm={onConfirm}>
        <Button type="link" size="small" danger>删除</Button>
    </Popconfirm>)

}