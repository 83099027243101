import React from "react";
import {Drawer, Tabs} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";
import EventBus from "@/framework/util/EventBusUtils";
import CodeTemplateEvent from "../event/CodeTemplateEvent";
import CodeTemplateSelectModal from "./CodeTemplateSelectModal";
import CodeTemplate from "../component/CodeTemplate";

export default class MatchCodeTemplateDrawer extends React.Component {

    state = {
        open: false,
        codeTable: {},
        activeKey: "说明",
        tabItems: [
            {
                key: "说明",
                label: "说明",
                children: "点击\"+\"选择模版后即可生成代码",
                closable: false
            }
        ]
    }

    componentDidMount() {
        EventBus.on(CodeTemplateEvent.onTriggerMatchCodeTemplate, this.onTrigger)
        EventBus.on(CodeTemplateEvent.onCodeTemplateSelected, this.onAddTabSuccess)
    }

    componentWillUnmount() {
        EventBus.off(CodeTemplateEvent.onTriggerMatchCodeTemplate, console.log)
    }

    onTrigger = (codeTable) => this.setState({codeTable, open: true})

    onTabsEdit = (targetKey, action) => {
        if (action === "add") {
            this.onAddTab()
        } else {
            this.onRemoveTab(targetKey)
        }
    }

    onAddTab = () => {
        EventBus.emit(CodeTemplateEvent.onTriggerSelectCodeTemplate)
    }

    onAddTabSuccess = (template) => {
        let isExisting = false
        for (let tabItem of this.state.tabItems) {
            if (template.name === tabItem.label) {
                isExisting = true
                break
            }
        }
        if (isExisting) {
            this.setState({activeKey: template.name})
            return
        }
        this.state.tabItems.push({
            key: template.name,
            label: template.name,
            closable: true,
            children: (<CodeTemplate template={template.id} codeTable={this.state.codeTable.id}/>)
        })
        this.setState({tabItems: [...this.state.tabItems], activeKey: template.name})
    }

    onRemoveTab = (key) => {
        let removeIndex = -1
        for (let i = 0; i < this.state.tabItems.length; i++) {
            if (key === this.state.tabItems[i].key) {
                removeIndex = i
                break
            }
        }
        if (removeIndex >= 0) {
            this.state.tabItems.splice(removeIndex, 1)
            this.setState({tabItems: [...this.state.tabItems], activeKey: this.state.tabItems[this.state.tabItems.length -1].key})
        }
    }

    onClose = () => {
        this.setState({
            open: false,
            activeKey: "description",
            tabItems: [
                {
                    key: "说明",
                    label: "说明",
                    children: "点击\"+\"选择模版后即可生成代码",
                    closable: false
                }
            ]
        })
    }

    render() {
        return (<Drawer title="代码生成"
                        open={this.state.open}
                        destroyOnClose
                        maxWidth={378}
                        width={880}
                        onClose={this.onClose}>
            <ProDescriptions dataSource={this.state.codeTable} column={4}>
                <ProDescriptions.Item span={1} label="表名" dataIndex="name"/>
                <ProDescriptions.Item span={1} label="实体名称" dataIndex="entityName"/>
                <ProDescriptions.Item span={2} label="注释" dataIndex="comment"/>
                <ProDescriptions.Item span={4} label="包名" dataIndex="packageName"/>
            </ProDescriptions>
            <Tabs type="editable-card" size="small" items={this.state.tabItems} activeKey={this.state.activeKey}
                  onChange={activeKey => this.setState({activeKey})}
                  onEdit={this.onTabsEdit}/>
            <CodeTemplateSelectModal/>
        </Drawer>)
    }

}