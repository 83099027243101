import React from "react";
import {Button, Space, Typography} from "antd";
import {ProTable} from "@ant-design/pro-components";
import EventBus from "@/framework/util/EventBusUtils";

import {requestHelpItemByPage} from "../api/HelpItemAPI";
import HelpEvent from "../event/HelpEvent";
import {PlusOutlined} from "@ant-design/icons";
import CreateHelpItemDrawer from "../interaction/CreateHelpItemDrawer";
import UpdateHelpItemDrawer from "../interaction/UpdateHelpItemDrawer";
import DeleteHelpItemButton from "./DeleteHelpItemButton";

/**
 * 帮助细则表格操作项
 * @param category 帮助分类对象
 */
function HelpItemDataTableOperations({category}) {
    return (<Space>
        <CreateHelpItemDrawer category={category}
                              trigger={(<Button type="primary" disabled={category?.leaf === 0}
                                                icon={<PlusOutlined/>}>新建说明细则</Button>)}/>
    </Space>)
}

/**
 * 帮助细则字段操作项
 * @param item 帮助细则对象
 */
function HelpItemDataTableColumnOperations({item}) {
    return (<Space>
        <Button type="link" size="small" onClick={() => UpdateHelpItemDrawer.open(item)}>编辑</Button>
        <DeleteHelpItemButton id={item.id}/>
    </Space>)
}

/**
 * 帮助细则说明表格
 */
export default class HelpItemDataTable extends React.Component {

    actionRef = React.createRef()

    columns = [
        {
            title: "ID",
            dataIndex: "id",
            hideInTable: true,
            hideInSearch: true
        },
        {
            title: "分类ID",
            dataIndex: "category",
            hideInTable: true
        },
        {
            title: "标题",
            dataIndex: "title",
            tooltip: "前端不展示，仅作复制信息归纳用",
            ellipsis: true
        },
        {
            title: "内容",
            dataIndex: "content",
            hideInTable: true
        },
        {
            title: "创建人ID",
            dataIndex: "creator",
            hideInTable: true
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            valueType: "fromNow"
        },
        {
            title: "修改人ID",
            dataIndex: "modifier",
            hideInTable: true
        },
        {
            title: "修改时间",
            dataIndex: "modifyTime",
            hideInTable: true
        },
        {
            title: "操作",
            valueType: "option",
            render: (_, item) => (<HelpItemDataTableColumnOperations item={item}/>)
        }
    ]

    componentDidMount() {
        EventBus.on(HelpEvent.onHelpItemCreated, () => this.actionRef.current?.reload())
        EventBus.on(HelpEvent.onHelpItemUpdated, () => this.actionRef.current?.reload())
        EventBus.on(HelpEvent.onHelpItemDeleted, () => this.actionRef.current?.reload())
    }

    componentWillUnmount() {
        EventBus.off(HelpEvent.onHelpItemCreated, console.log)
        EventBus.off(HelpEvent.onHelpItemUpdated, console.log)
        EventBus.off(HelpEvent.onHelpItemDeleted, console.log)
    }

    request = (params) => requestHelpItemByPage(params).then(res => {
        return res.data
    }).catch(() => {
        return {data: [], success: true, total: 0}
    })

    render() {
        if (!this.props.category) {
            return (<Typography>请选择分类</Typography>)
        }
        return (<>
            <ProTable rowKey="id"
                      search={false}
                      scroll={{x: "max-content"}}
                      cardProps={{ghost: true}}
                      params={{category: this.props.category.id}}
                      columns={this.columns}
                      actionRef={this.actionRef}
                      toolBarRender={() => (<HelpItemDataTableOperations category={this.props.category}/>)}
                      request={this.request}/>
            <UpdateHelpItemDrawer/>
        </>);
    }

}