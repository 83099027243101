import React from "react"
import { Alert, Skeleton, Transfer } from "antd"
import { ModalForm, ProFormItem, ProFormText } from "@ant-design/pro-components"

import EventBus from "@/framework/util/EventBusUtils"
import { setFormError } from "@/framework/util/FormUtils"
import { DISABLE } from "@/framework/enum/AvailableEnum"

import { requestPermissionByList } from "@/member/permission/api/PermissionAPI"
import { requestRolePermissionByList, requestUpdateRolePermissions } from "@/member/role/api/RoleAPI"
import RoleEvent from "@/member/role/event/RoleEvent"

export default function UpdateRolePermissionsModal() {

    const formRef = React.useRef()
    const [open, setOpen] = React.useState(false)
    const [loadingSource, setLoadingSource] = React.useState(true)
    const [loadingTarget, setLoadingTarget] = React.useState(true)
    const [source, setSource] = React.useState([])
    const [target, setTarget] = React.useState([])

    React.useEffect(() => {
        EventBus.on(RoleEvent.onTriggerUpdateRolePermissions, (id) => {
            setOpen(true)
            setTimeout(() => formRef.current?.setFieldsValue({ id }), 100)
            requestSource()
            requestTarget(id)
        })
        return () => {
            EventBus.off(RoleEvent.onTriggerUpdateRolePermissions, console.log)
        }
    }, [])

    const requestSource = () => {
        setLoadingSource(true)
        return requestPermissionByList().then(res => {
            setLoadingSource(false)
            setSource(res.data.map(item => ({ key: item.id, title: item.name, disabled: DISABLE === item.available })))
        }).catch(() => {
            setLoadingSource(false)
            setSource([])
        })
    }

    const requestTarget = (id) => {
        setLoadingTarget(true)
        return requestRolePermissionByList(id).then(res => {
            setLoadingTarget(false)
            setTarget(res.data.map(item => item.id))
        }).catch(() => {
            setLoadingTarget(false)
            setTarget([])
        })
    }

    const onFinish = (params) => requestUpdateRolePermissions(params).then(() => {
        formRef.current?.resetFields()
        return true
    }).catch(err => {
        setFormError(formRef, err.response?.data, "permissions")
        return false
    })

    return (<ModalForm title="设置角色权限"
        open={open}
        onOpenChange={open => setOpen(open)}
        formRef={formRef}
        modalProps={{ width: 560, destroyOnClose: true }}
        onFinish={onFinish}>

        <Alert style={{ marginBottom: 8 }} closable message="*:manage（查看**管理页面）类权限用于授权用户查看侧边菜单栏页面" />

        <ProFormText name="id" label="ID" hidden />

        <Skeleton active loading={loadingSource || loadingTarget}>
            <ProFormItem name="permissions">
                <Transfer showSearch
                    style={{ marginTop: 8 }}
                    onChange={(targetKeys) => setTarget(targetKeys)}
                    listStyle={{ width: "100%", height: 400 }}
                    dataSource={source}
                    targetKeys={target}
                    render={(item) => item.title} />
            </ProFormItem>
        </Skeleton>

    </ModalForm>)
}