import React from "react";
import {Button, Space, Typography} from "antd";
import {ProDescriptions} from "@ant-design/pro-components";
import CreateHelpCategoryModal from "../interaction/CreateHelpCategoryModal";
import DeleteHelpCategoryButton from "./DeleteHelpCategoryButton";
import UpdateHelpCategoryModal from "../interaction/UpdateHelpCategoryModal";

function HelpCategoryDescriptionExtra({category}) {
    return (<Space>
        <CreateHelpCategoryModal trigger={(<Button disabled={category?.leaf === 1}>新增子说明</Button>)} parent={category?.id}/>
        <UpdateHelpCategoryModal item={category}/>
        <DeleteHelpCategoryButton category={category}/>
    </Space>)
}

/**
 * 帮助分类 Description
 *
 * @param category 帮助分类对象
 * @author Wayne Carder
 */
export default function HelpCategoryDescription({category}) {

    if (!category) {
        return (<Typography>请先选择分类</Typography>)
    }
    return (<ProDescriptions extra={<HelpCategoryDescriptionExtra category={category}/>}
                             dataSource={category}
                             column={4}
                             bordered>
        <ProDescriptions.Item span={4} label="ID" dataIndex="id"/>
        <ProDescriptions.Item span={4} label="Key" dataIndex="key" copyable/>
        <ProDescriptions.Item span={2} label="名称" dataIndex="name"/>
        <ProDescriptions.Item span={2} label="叶子节点" dataIndex="leaf"
                              valueEnum={{
                                  "0": {text: "否", status: "success"},
                                  "1": {text: "是", status: "error"}
                              }}/>
    </ProDescriptions>)

}