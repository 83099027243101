import React, { useState } from "react";
import { App, Button } from "antd";
import { requestMemberAsExcel } from "@/association/member/api/MemberAPI";
import DownloadUtils from "@/framework/util/DownloadUtils";
import { DownloadOutlined } from "@ant-design/icons";

export default function ExportMemberButton() {

    const [loading, setLoading] = useState(false)

    const { message } = App.useApp()

    const onClick = () => {
        setLoading(true)
        requestMemberAsExcel().then(res => {
            DownloadUtils.download(res.data, res.headers.contentType, "导出会员记录.xlsx")
        }).catch(() => {
            message.error("导出失败，请稍后重试", 2).then()
        }).finally(() => {
            setLoading(false)
        })
    }

    return (<Button loading={loading} onClick={onClick} icon={<DownloadOutlined/>}>
        导出Excel
    </Button>)

}