import React from "react";
import PropTypes from "prop-types";
import {Button, Menu, Modal, Pagination, Space} from "antd";
import {CheckCard, ProCard} from "@ant-design/pro-components";

import EventBus from "@/framework/util/EventBusUtils";
import {DISABLE} from "@/framework/enum/AvailableEnum";
import FileEvent from "../event/FileEvent";
import {requestCategoryByList} from "../../category/api/CategoryAPI";
import {requestFileByList, requestFileByPage} from "../api/FileAPI";
import UploadFileModal from "./UploadFileModal";
import {UploadOutlined} from "@ant-design/icons";

const categoryForAll = {key: -1, label: "全部文件", disabled: false}
const categoryForUnknown = {key: 0, label: "未分类", disabled: false}

const FileToolBar = ({category}) => (<Space>
    <Button type="primary" onClick={() => UploadFileModal.open(category)}>
        <UploadOutlined/>上传文件
    </Button>
</Space>)

export default class SelectAttachmentFileModal extends React.Component {

    multiple = false

    state = {
        open: false,
        loading: true,
        categories: [],
        category: categoryForAll,
        files: [],
        totalFiles: 0,
        selectedFiles: [],
        defaultValue: [],
        confirmLoading: false
    }

    onTriggerSelectFile = (defaultValue) => {
        this.setState({open: true, defaultValue: defaultValue.map(v => v.id)})
    }

    componentDidMount() {
        this.multiple = this.props.count > 1
        this.onRequestCategories()
        this.onRequestFiles()
        EventBus.on(FileEvent.onFileCreated, () => this.onRequestFiles())
        EventBus.on(FileEvent.onFileDeleted, this.onRequestFiles)
    }

    componentWillUnmount() {
        EventBus.off(FileEvent.onFileCreated, console.log)
        EventBus.off(FileEvent.onFileDeleted, console.log)
    }

    onRequestCategories = () => requestCategoryByList().then(res => {
        const categories = []
        categories.push(categoryForAll)
        categories.push(categoryForUnknown)
        for (let item of res.data) {
            categories.push({key: item.id, label: item.name, disabled: DISABLE === item.available})
        }
        this.setState({categories})
    }).catch(err => {
        this.setState({categories: [categoryForAll, categoryForUnknown]})
        console.error("请求文件分类失败", err)
    })

    onRequestFiles = (current = 1, pageSize = 15, category) => {
        this.setState({loading: true})
        category = this.state.category.key >= 0 ? this.state.category.key : undefined
        requestFileByPage({current, pageSize, category}).then(res => {
            this.setState({files: res.data.data, totalFiles: res.data.total, loading: false})
        }).catch(() => {
            this.setState({files: [], totalFiles: 0, loading: false})
        })
    }

    checkableFileBuilder = (file) => ({
        ...file,
        value: file.id,
        disabled: this.props.accept.includes("*") ? false : !this.props.accept.includes(file.type),
        title: file.name,
        avatar: file.type.includes("image") ? file.url : "/icons/file.svg"
    })

    onOk = () => requestFileByList({id: this.state.selectedFiles.toString()}).then(res => {
        this.setState({confirmLoading: false, open: false})
        this.props.onOk(res.data)
        return true
    }).catch(() => {
        this.setState({confirmLoading: false, open: false})
        this.props.onOk([])
        return true
    })

    onCancel = () => {
        this.setState({selectedFiles: [], open: false})
        this.props.onCancel()
    }

    render() {
        return (<Modal open={this.state.open}
                       title="附件选择"
                       confirmLoading={this.state.confirmLoading}
                       width={960}
                       centered
                       destroyOnClose
                       okButtonProps={{disabled: this.state.selectedFiles.length < 1}}
                       onCancel={this.onCancel}
                       onOk={this.onOk}>
            <ProCard split="vertical">
                <ProCard colSpan="20%" ghost>
                    <Menu items={this.state.categories} defaultSelectedKeys={["-1"]}
                          onSelect={(item) => this.setState({category: item}, this.onRequestFiles)}/>
                </ProCard>
                <ProCard title="文件" headerBordered extra={<FileToolBar category={this.state.category.key}/>}
                         size="small">
                    <CheckCard.Group multiple={this.multiple} size="small" loading={this.state.loading}
                                     defaultValue={this.state.defaultValue}
                                     onChange={v => this.setState({selectedFiles: this.multiple ? v : [v]})}
                                     options={this.state.files?.map(this.checkableFileBuilder)}/>
                    <Pagination onChange={this.onRequestFiles} pageSize={15} total={this.state.totalFiles}/>
                </ProCard>
            </ProCard>
        </Modal>)
    }

}

SelectAttachmentFileModal.propTypes = {
    open: PropTypes.bool,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    count: PropTypes.number,
    accept: PropTypes.arrayOf(String),
    initialValue: PropTypes.array
}

SelectAttachmentFileModal.defultProps = {
    open: false,
    count: 1,
    accept: ["*"]
}
